import React, { Component } from "react";
import CustomNavbar from "../../components/CustomNavbar";

class ErrorPage extends Component {
  render() {
    return (
      <div
        style={{ height: "100vh", paddingTop: "30vh", background: "#F7F8FC" }}
      >
        <center>
          <CustomNavbar showBackButton {...this.props} />
          <h1>404 - No fruits found</h1>
          <span>(url: {window.location.href})</span>
        </center>
      </div>
    );
  }
}

export default ErrorPage;
