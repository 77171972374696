import { Link } from "react-router-dom"
import styled from "styled-components"
import PatternLogo from "../../assets/images/pattern1.png"
import disableScroll from "disable-scroll"
import { t } from "i18next"
import { iosGenerateHaptic, isNativeIOS } from "../../tools/ios"
import { HapticEffect, OrganisationUid } from "../../interfaces"
import { connect } from "react-redux"

const AUTHORIZED_ORGANISATION_CONGRESS = [
	OrganisationUid.BIOCODEX,
	OrganisationUid.JUISCI,
	OrganisationUid.OPELLA
]

const HomeNavigation = (props) => {
	const { pathname } = window.location
	const userOrganisation = props.user.user.organisations?.[0]?.uid
	const showCongress = !!props.user?.user && !!userOrganisation

	const disableCongress =
		!AUTHORIZED_ORGANISATION_CONGRESS.includes(userOrganisation)

	const biocodexCountryRestriction =
		props.user?.user.country === "US" &&
		userOrganisation === OrganisationUid.BIOCODEX

	const showCongressButton = showCongress && !biocodexCountryRestriction

	const isTakeda = userOrganisation?.includes("takeda")

	const renderCongressTitle = () => {
		switch (userOrganisation) {
			case OrganisationUid.OPELLA:
				return "Ressources"
			case OrganisationUid.BIOCODEX:
			case OrganisationUid.TAKEDA:
				return "Highlights"
			default:
				return t("navigation.congress")
		}
	}

	return (
		<StyledHeader>
			<div className="switcher-container">
				<Link to="/home/foryou">
					<Switcher selected={pathname.includes("foryou")}>
						{t("navigation.foryou")}
					</Switcher>
				</Link>
				<Link
					to="/home"
					onClick={() => iosGenerateHaptic(HapticEffect.SELECTION)}
				>
					<Switcher
						onClick={() => {
							if (pathname !== "/home") return
							disableScroll.off()
							window.scrollTo(0, 0)
						}}
						invert={true}
						selected={pathname === "/home"}
					>
						{t("navigation.discovery")}
					</Switcher>
				</Link>
				{showCongressButton ? (
					<Link
						style={{
							pointerEvents: disableCongress ? "none" : "auto"
						}}
						to={"/home/congress"}
						onClick={() =>
							iosGenerateHaptic(HapticEffect.SELECTION)
						}
					>
						<Switcher
							className={disableCongress ? "disabled" : ""}
							selected={
								pathname.includes("congress") ||
								pathname.includes("infographic")
							}
						>
							{renderCongressTitle()}
						</Switcher>
					</Link>
				) : null}
			</div>
			{isTakeda ? (
				<div
					className={`fixed-footer ${
						isNativeIOS ? "safe-area-space" : ""
					}`}
				>
					{t("navigation.takedaFooter")}
				</div>
			) : null}
		</StyledHeader>
	)
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {}

const connector = connect(mapState, mapDispatch)

export default connector(HomeNavigation)

const StyledHeader = styled.div`
	display: flex;
	justify-content: center;
	padding: 25px 0;
	background-image: url(${PatternLogo});
	background-color: #f7f8fc;
	background-size: cover;
	margin-top: 24px;

	.fixed-footer {
		color: #ed1c24;
		font-family: Inter;
		font-size: 11px;
		font-weight: 600;
		line-height: 12.1px;
		text-align: center;

		position: fixed;
		bottom: 0px;
		left: 0px;
		right: 0px;
		padding: 12px 0;
		background: #f9f9f9;
		z-index: 999;

		&.safe-area-space {
			padding-bottom: 36px;
		}
	}

	.switcher-container {
		background: #fff3ce;
		width: content-fit;
		padding: 5px;
		border-radius: 25px;
		width: fit-content;
		height: 100%;
	}
`

const Switcher = styled.button`
	outline: none;
	border: none;
	box-shadow: none;
	border-radius: 20px;
	background: ${(props) => (props.selected ? "#FFC408" : "#FFF3CE")};
	padding: 6px 18px;
	cursor: pointer;
	margin: 0 2px;

	// Text style
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	text-align: center;
	color: ${(props) => (props.selected ? "#212121" : "#fece67")};

	&.disabled {
		cursor: not-allowed;
		color: #313b42;
		opacity: 0.1;
	}
`
