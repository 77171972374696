const translations = {
  "auth/claims-too-large":
    "La charge utile des revendications fournie à setCustomUserClaims() est supérieure à la taille maximale autorisée de 1000 octets.",
  "auth/email-already-exists":
    "L'e-mail fourni est déjà utilisé par un utilisateur existant. Chaque utilisateur doit avoir un email unique.",
  "auth/id-token-expired":
    "Le jeton d'identification Firebase fourni a expiré.",
  "auth/id-token-revoked": "Le jeton d'identification Firebase a été révoqué.",
  "auth/insufficient-permission":
    "Les informations d'identification utilisées pour initialiser le SDK Admin ne disposent pas des autorisations suffisantes pour accéder à la ressource d'authentification demandée. Reportez - vous à mettre en place un projet Firebase pour la documentation sur la façon de générer une information d' identification avec les autorisations appropriées et l' utiliser pour authentifier l'administrateur SDKs.",
  "auth/internal-error":
    "Le serveur d'authentification a rencontré une erreur inattendue lors de la tentative de traitement de la demande. Le message d'erreur doit contenir la réponse du serveur d'authentification contenant des informations supplémentaires. Si le voyant reste d'erreur, s'il vous plaît signaler le problème à notre rapport de bug canal de support.",
  "auth/invalid-argument":
    "Un argument non valide a été fourni à une méthode d'authentification. Le message d'erreur doit contenir des informations supplémentaires.",
  "auth/invalid-claims":
    "Les attributs de revendications personnalisées fournies à setCustomUserClaims() ne sont pas valides.",
  "auth/invalid-continue-uri":
    "L'URL de continuation doit être une chaîne d'URL valide.",
  "auth/invalid-creation-time":
    "L'heure de création doit être une chaîne de date UTC valide.",
  "auth/invalid-credential":
    "Les informations d'identification utilisées pour authentifier les SDK Admin ne peuvent pas être utilisées pour effectuer l'action souhaitée. Certaines méthodes d'authentification telles que createCustomToken() et verifyIdToken() nécessitent le SDK pour être initialisé avec un diplôme de certificat , par opposition à un jeton d' actualisation ou de l' application des titres de compétences par défaut. Voir Initialiser le SDK pour la documentation sur la façon d'authentifier les SDKs d'administration avec un titre de certificat.",
  "auth/invalid-disabled-field":
    "La valeur fournie pour les disabled propriété utilisateur est invalide. Ce doit être un booléen.",
  "auth/invalid-display-name":
    "La valeur fournie pour la displayName propriété utilisateur est invalide. Il doit s'agir d'une chaîne non vide.",
  "auth/invalid-dynamic-link-domain":
    "Le domaine de lien dynamique fourni n'est pas configuré ou autorisé pour le projet en cours.",
  "auth/invalid-email":
    "La valeur fournie pour l' email - email propriété utilisateur est invalide. Il doit s'agir d'une adresse e-mail de chaîne.",
  "auth/invalid-email-verified":
    "La valeur fournie pour la emailVerified propriété utilisateur est invalide. Ce doit être un booléen.",
  "auth/invalid-hash-algorithm":
    "L'algorithme de hachage doit correspondre à l'une des chaînes de la liste des algorithmes pris en charge.",
  "auth/invalid-hash-block-size":
    "La taille du bloc de hachage doit être un nombre valide.",
  "auth/invalid-hash-derived-key-length":
    "La longueur de clé dérivée du hachage doit être un nombre valide.",
  "auth/invalid-hash-key":
    "La clé de hachage doit être un tampon d'octets valide.",
  "auth/invalid-hash-memory-cost":
    "Le coût de la mémoire de hachage doit être un nombre valide.",
  "auth/invalid-hash-parallelization":
    "La parallélisation de hachage doit être un nombre valide.",
  "auth/invalid-hash-rounds":
    "Les tours de hachage doivent être un nombre valide.",
  "auth/invalid-hash-salt-separator":
    "Le champ séparateur de sel de l'algorithme de hachage doit être un tampon d'octets valide.",
  "auth/invalid-id-token":
    "Le jeton d'identification fourni n'est pas un jeton d'identification Firebase valide.",
  "auth/invalid-last-sign-in-time":
    "La dernière heure de connexion doit être une chaîne de date UTC valide.",
  "auth/invalid-page-token":
    "La page suivante prévue jeton dans listUsers() est invalide. Il doit s'agir d'une chaîne valide non vide.",
  "auth/invalid-password":
    "La valeur fournie pour le password de password propriété utilisateur est invalide. Il doit s'agir d'une chaîne d'au moins six caractères.",
  "auth/invalid-password-hash":
    "Le hachage du mot de passe doit être un tampon d'octets valide.",
  "auth/invalid-password-salt":
    "Le mot de passe salt doit être un tampon d'octets valide",
  "auth/invalid-phone-number":
    "La valeur fournie pour l' phoneNumber est invalide. Il doit s'agir d'une chaîne d'identifiant conforme à la norme E.164 non vide.",
  "auth/invalid-photo-url":
    "La valeur fournie pour la photoURL propriété utilisateur est invalide. Il doit s'agir d'une URL de chaîne.",
  "auth/invalid-provider-data":
    "Le providerData doit être un tableau valide d'objets UserInfo.",
  "auth/invalid-provider-id":
    "Le providerId doit être une chaîne d'identifiant de fournisseur prise en charge valide.",
  "auth/invalid-oauth-responsetype":
    "Seulement exactement un OAuth responseType doit être réglé sur true.",
  "auth/invalid-session-cookie-duration":
    "La durée du cookie de session doit être un nombre valide en millisecondes compris entre 5 minutes et 2 semaines.",
  "auth/invalid-uid":
    "La condition uid doit être une chaîne non vide avec au maximum 128 caractères.",
  "auth/invalid-user-import":
    "L'enregistrement d'utilisateur à importer n'est pas valide.",
  "auth/maximum-user-count-exceeded":
    "Le nombre maximal autorisé d'utilisateurs à importer a été dépassé.",
  "auth/missing-android-pkg-name":
    "Un nom de package Android doit être fourni si l'application Android doit être installée.",
  "auth/missing-continue-uri":
    "Une URL de continuation valide doit être fournie dans la demande.",
  "auth/missing-hash-algorithm":
    "L'importation d'utilisateurs avec des hachages de mot de passe nécessite que l'algorithme de hachage et ses paramètres soient fournis.",
  "auth/missing-ios-bundle-id": "Il manque un ID de bundle iOS à la demande.",
  "auth/missing-uid":
    "Un uid identificateur est requis pour l'opération en cours.",
  "auth/missing-oauth-client-secret":
    "Le secret client de configuration OAuth est requis pour activer le flux de code OIDC.",
  "auth/operation-not-allowed":
    "Le fournisseur de connexion fourni est désactivé pour votre projet Firebase. Activez - le à partir du signe dans la méthode section de la console Firebase.",
  "auth/phone-number-already-exists":
    "La condition phoneNumber est déjà utilisé par un utilisateur existant. Chaque utilisateur doit avoir une expérience unique phoneNumber .",
  "auth/project-not-found":
    "Aucun projet Firebase n'a été trouvé pour les identifiants utilisés pour initialiser les SDK Admin. Reportez - vous à mettre en place un projet Firebase pour la documentation sur la façon de générer un titre pour votre projet et de l' utiliser pour authentifier l'administrateur SDKs.",
  "auth/reserved-claims":
    "Une ou plusieurs demandes d'utilisateurs personnalisés fournis à setCustomUserClaims() sont réservés. Par exemple, OIDC revendications spécifiques tels que (sous, IAT, iss, exp, aud, auth_time, etc.) ne doivent pas être utilisés comme clés pour les demandes personnalisées.",
  "auth/session-cookie-expired":
    "Le cookie de session Firebase fourni a expiré.",
  "auth/session-cookie-revoked": "Le cookie de session Firebase a été révoqué.",
  "auth/uid-already-exists":
    "Le fourni uid est déjà utilisé par un utilisateur existant. Chaque utilisateur doit avoir un cadre unique uid .",
  "auth/unauthorized-continue-uri":
    "Le domaine de l'URL de continuation n'est pas sur liste blanche. Ajoutez le domaine à la liste blanche dans la console Firebase.",
  "auth/user-not-found":
    "Il n'existe aucun enregistrement utilisateur correspondant à l'identifiant fourni.",
  "auth/too-many-requests":
    "L'accès à ce compte a été temporairement désactivé en raison de nombreuses tentatives de connexion infructueuses. Vous pouvez le rétablir immédiatement en réinitialisant votre mot de passe ou vous pouvez réessayer plus tard.",
  "auth/wrong-password":
    "Le mot de passe n'est pas valide ou l'utilisateur ne possède pas de mot de passe.",

  else: "Une erreur s'est produite. Votre compte a peut-être été désactivé. Veuillez réessayer plus tard.",
};

export default translations;
