import { Component } from "react"
import styled from "styled-components"
import CustomIcon from "../../components/CustomIcon"
import {
	Avatar,
	Divider,
	ProfileContent,
	ProfileHeader,
	SearchInput,
	Spacer
} from "../../components/global"
import MessageBar from "../../components/MessageBar"
import { Tabs } from "antd-mobile"
import { Row, Col } from "react-grid-system"
import {
	acceptFollowRequest,
	getListPendingFollowers,
	rejectFollowRequest
} from "../../services/user.services"
import Loader from "../../components/Loader"
import Moment from "react-moment"
import Fuse from "fuse.js"
import {
	deleteNotification,
	getNotifications
} from "../../services/notifications.services"
import { withTranslation } from "react-i18next"
import "moment/locale/fr"
import Swipeout from "rc-swipeout"
import customToast from "../../components/CustomToast"
import { pickGradient } from "../../tools/utils"
import i18n from "../../config/i18n"
import { getUser } from "../../services/user.services"
import { t } from "i18next"
import ProfilePicture from "../../components/ProfilePicture"
import { pickUserImage } from "../../services/image.services"

class MessageList extends Component {
	state = {
		tabIndex: 0,
		requests: [],
		requestsLoading: true,
		searchValue: "",

		// Notifications
		notifications: [],

		authUser: null

		// update: 0,
	}

	async componentDidMount() {
		try {
			const [authUser, notifications, requests] = await Promise.all([
				getUser(),
				getNotifications(), // Getting user notifications...
				getListPendingFollowers() // Getting list of pending followers...
			])

			this.setState({
				authUser,
				notifications,
				requests,
				tabIndex: parseInt(localStorage.getItem("msgTabIndex")),
				requestsLoading: false
			})

			// Setting an interval to "refresh smoothly" the page because of no re-render props...
			// setInterval(() => this.setState({ update: this.state.update + 1 }), 100);
		} catch (error) {
			customToast(t("toast.error.global"))
			console.error("Err MessageList didMount", error.message)
			this.props.history.replace("/profile")
		}
	}

	searchMessages() {
		const { loading, searchValue, authUser } = this.state

		const fuse = new Fuse(this.props?.socketHook?.conversationsList, {
			keys: ["users.fullname", "users.firstname", "users.lastname"],
			includeScore: true,
			findAllMatches: true,
			threshold: 0.2
		})

		var results = fuse.search(this.state.searchValue)

		var messagesResults = results.map((result) => {
			return result.item
		})

		if (loading) {
			return <Loader />
		} else {
			if (searchValue.length > 0) {
				if (messagesResults.length > 0) {
					return messagesResults.map((conversation, index) => {
						var other_user
						if (conversation?.users[1]?.uid !== authUser.uid) {
							other_user = conversation?.users[1]
						} else {
							other_user = conversation?.users[0]
						}
						const messages = conversation?.messages
						const last_date = conversation?.meta?.lastModified

						return (
							<MessageRow
								key={index}
								onClick={() =>
									this.props.history.push(
										`/profile/messages/conversation/${conversation?._id}`
									)
								}
								avatar={pickUserImage(other_user)}
								fullname={`${other_user?.firstname} ${other_user?.lastname}`}
								message={
									messages[messages.length - 1]?.message ??
									"No message"
								}
								date={last_date}
							/>
						)
					})
				} else {
					return (
						<center>
							<Spacer height="15px" />
							<NoResultText color="#212121">
								{this.props.t("messages.noMessageFound")}.
							</NoResultText>
						</center>
					)
				}
			} else {
				return (
					<center>
						<Spacer height="15px" />
						<NoResultText color="#212121">
							{this.props.t("messages.searchUsers")}
						</NoResultText>
					</center>
				)
			}
		}
	}

	deleteConversation(conversation_id, index) {
		this.props.socketHook.markConversationAsDeleted(conversation_id)
		customToast(
			this.props.t("toast.success.deletedConversation"),
			"success"
		)
	}

	render() {
		const {
			tabIndex,
			requests,
			requestsLoading,
			searchValue,
			notifications,
			authUser
		} = this.state
		const { t } = this.props

		return (
			<div>
				<MessageBar {...this.props} />
				{requestsLoading ? (
					<Loader />
				) : (
					<div
						style={{
							background: "#f7f8fc",
							minHeight: `calc(${window.innerHeight}px - 56px)`
						}}
					>
						<ProfileHeader>
							<div style={{ position: "relative" }}>
								<div
									style={{
										position: "absolute",
										left: 15,
										top: 7,
										transform: "scale(0.7)"
									}}
								>
									<CustomIcon
										iconName="search"
										color="#90A4AE"
									/>
								</div>
								<SearchInput
									style={{ paddingLeft: 40, margin: 0 }}
									placeholder={t("messages.search")}
									onInput={(e) =>
										this.setState({
											searchValue: e.target.value
										})
									}
								/>
							</div>
						</ProfileHeader>

						<SlidingTabs
							swipeable={false}
							tabs={[
								{
									title: (
										<div
											style={{
												fontWeight: tabIndex < 1 && 700
											}}
										>
											{t("messages.all")}
										</div>
									)
								},
								{
									title: (
										<div
											style={{
												fontWeight: tabIndex > 0 && 700
											}}
										>
											{t("messages.requests")} (
											{requests?.length ?? 0})
										</div>
									)
								}
							]}
							tabBarBackgroundColor="#f7f8fc"
							tabBarActiveTextColor="#212121"
							tabBarUnderlineStyle={{
								borderColor: "#212121",
								borderWidth: "1px"
							}}
							tabBarTextStyle={{
								fontFamily: "Poppins",
								fontSize: "14px",
								lineHeight: "19px",
								color: "#212121",
								padding: "30px 0"
							}}
							initialPage={parseInt(
								localStorage.getItem("msgTabIndex")
							)}
							onChange={(_, index) => {
								this.setState({ tabIndex: index })
								localStorage.setItem("msgTabIndex", index)
							}}
							onTabClick={(tab, index) => {
								this.setState({ tabIndex: index })
								localStorage.setItem("msgTabIndex", index)
							}}
						>
							<ProfileContent>
								{searchValue.length > 0 ? (
									this.searchMessages()
								) : (
									<>
										{this.props?.socketHook
											?.conversationsList?.length > 0 ? (
											<>
												{this.props?.socketHook?.conversationsList?.map(
													(conversation, index) => {
														var other_user
														if (
															conversation
																?.users[1]
																?.uid !==
															authUser.uid
														) {
															other_user =
																conversation
																	?.users[1]
														} else {
															other_user =
																conversation
																	?.users[0]
														}
														var lastMsg =
															conversation
																?.messages[
																conversation
																	?.messages
																	.length - 1
															]?.message

														var last_date =
															conversation?.meta
																?.lastModified

														if (
															!conversation.isDeleted
														) {
															return (
																<MessageRow
																	key={
																		conversation?._id
																	}
																	onClick={() =>
																		this.props.history.push(
																			`/profile/messages/conversation/${conversation?._id}`
																		)
																	}
																	onDelete={() =>
																		this.deleteConversation(
																			conversation?._id,
																			index
																		)
																	}
																	avatar={pickUserImage(
																		other_user
																	)}
																	fullname={`${other_user?.firstname} ${other_user?.lastname}`}
																	message={
																		lastMsg ??
																		"No message"
																	}
																	date={
																		last_date
																	}
																	readStatus={
																		conversation?.unreadMessages >
																		0
																	}
																/>
															)
														}
													}
												)}
											</>
										) : (
											<center>
												<Spacer height="15px" />
												<NoResultText color="#212121">
													{this.props.t(
														"messages.noUsersFound"
													)}
												</NoResultText>
											</center>
										)}
									</>
								)}
							</ProfileContent>

							<ProfileContent>
								{requests?.length === 0 && (
									<>
										<NoResultText color="#212121">
											{t("messages.noRequestReceived")}
										</NoResultText>
									</>
								)}
								{requests?.map((request, index) => {
									return (
										<RequestRow
											key={
												request?._id +
												index +
												"--request-row"
											}
											t={t}
											notifications={notifications}
											onClick={() =>
												this.props.history.push(
													`/profile/user/${request?.follower?.uid}`
												)
											}
											request_id={request?._id}
											follower={request?.follower}
											fullname={
												request?.follower?.firstname +
												" " +
												request?.follower?.lastname
											}
											profession={
												request?.follower?.profession
											}
											afterAction={() => {
												var { requests } = this.state
												requests?.splice(index, 1)
												this.setState({ requests })
											}}
										/>
									)
								})}
							</ProfileContent>
						</SlidingTabs>
					</div>
				)}
			</div>
		)
	}
}

export default withTranslation()(MessageList)

export var MessageRow = (props) => {
	var app_lang = localStorage.getItem("i18nextLng")

	return (
		<>
			<Swipeout
				autoClose={true}
				style={{ padding: 0, margin: 0 }}
				right={[
					{
						text: <CustomIcon iconName="trash" color="white" />,
						onPress: props.onDelete,
						style: {
							backgroundColor: "red",
							color: "white",
							height: "auto",
							margin: 0,
							padding: 0
						}
					}
				]}
			>
				<MessageRowWrapper onClick={props?.onClick}>
					<Row>
						<Col xs={2}>
							<Avatar
								src={props?.avatar}
								style={{
									background: pickGradient(
										props?.fullname.split(" ")[0]?.length %
											5
									)
								}}
							/>
						</Col>
						<Col>
							<Row style={{ margin: 0 }} justify="between">
								<h5
									style={{
										fontWeight: props.readStatus ? 700 : 400
									}}
								>
									{props?.fullname}
								</h5>
								<span style={{ paddingRight: 5 }}>
									<Moment
										locale={app_lang === "fr" ? "fr" : "en"}
										fromNow
									>
										{props?.date}
									</Moment>
								</span>
							</Row>

							<p
								style={{
									fontWeight: props.readStatus ? 600 : 300
								}}
							>
								{props?.message}
							</p>
						</Col>
					</Row>
				</MessageRowWrapper>
			</Swipeout>
			<Divider style={{ margin: 0 }} />
		</>
	)
}

export const RequestRow = (props) => {
	return (
		<MessageRowWrapper>
			<Row>
				<Col xs={2} onClick={props.onClick}>
					<ProfilePicture user={props.follower} />
				</Col>
				<Col xs={7} onClick={props.onClick}>
					<b>{props.fullname}</b>
					<p>
						{props.profession?.translations[i18n.resolvedLanguage]}
					</p>
				</Col>
				<Col
					xs={1}
					style={{ marginLeft: -10 }}
					onClick={() => {
						acceptFollowRequest(props.request_id)
						props.notifications.map((notification) => {
							if (
								notification?.action?.follow?._id ===
								props.request_id
							)
								deleteNotification(notification?._id)
						})
						props.afterAction()
					}}
				>
					<CustomIcon iconName="accept_req" />
				</Col>
				<Col
					xs={1}
					style={{ marginLeft: 10 }}
					onClick={() => {
						rejectFollowRequest(props.request_id)
						props.afterAction()
					}}
				>
					<CustomIcon iconName="deny_req" />
				</Col>
			</Row>
			<Divider style={{ margin: "20px 0" }} />
		</MessageRowWrapper>
	)
}

const NoResultText = styled.p`
	color: ${(props) => props.color ?? "#FFFFFF"};
	font-family: "Poppins";
	font-weight: 400;
	font-size: 15px;
	text-align: center;
`

const MessageRowWrapper = styled.div`
	background: #f7f8fc;
	padding: 20px 0;

	span {
		font-family: Poppins;
		font-style: normal;
		font-weight: 300;
		font-size: 11px;
		line-height: 16px;
		color: #81939c;
	}

	p {
		font-family: Poppins;
		font-style: normal;
		font-weight: 300;
		font-size: 13px;
		line-height: 130%;
		letter-spacing: 0.02em;
		color: #4c5861;
		margin: 0;
	}

	h5 {
		font-family: Poppins;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 21px;
		color: #212121;
		margin: 0;
	}

	.rc-swipeout-content {
		background: #f7f8fc;
	}
`

const SlidingTabs = styled(Tabs)`
	background: #f7f8fc;
`
