import { Component } from "react";
import { Spacer } from "./global";
import { Checkbox } from "antd-mobile";
import styled from "styled-components";

const AgreeItem = Checkbox.AgreeItem;

class CustomCheckbox extends Component {
  render() {
    return (
      <CheckboxWrapper>
        <Spacer height='5px' />
        <AgreeItem
          onChange={this.props.onChange}
          style={{ marginLeft: 0, paddingLeft: 0, alignItems: "center" }}
          checked={!!this.props.checked}
          className={this.props.className}
        >
          <div className='--custom-checkbox-title'>{this.props.title}</div>
        </AgreeItem>
      </CheckboxWrapper>
    );
  }
}

export default CustomCheckbox;

const CheckboxWrapper = styled.div`
  .--custom-checkbox-title {
    font-family: Roboto;
    font-weight: 600;
    font-size: 18px;
  }
`;
