import { IContent } from "./content.interface";

export enum VideoFormatEnum {
  ARTICLE = "article",
  STORY = "story",
}

export interface IVideo extends IContent {
  sourceURL: string;
  embedURL: string;
  videoFormat: VideoFormatEnum;
  _id: string;
}
