export type language = "en" | "fr";

// Global variable to handle traductions
/**
 * The language where is going to be translated the text error
 */
let LANGUAGE: language = "en";

// Functions
/**
 * Sets the language where is going to be translated the text error
 * @param language The language
 */
export const setLanguage = (language: language): void => {
  LANGUAGE = language;
};

export const getTranslation = (code: string): string => {
  const translations = require(`./languages/${LANGUAGE.substring(0, 2)}`);
  let translation = translations["default"][code];

  if (!translation) {
    translation = translations["default"]["else"];
  }

  return translation;
};
