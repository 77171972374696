import React, { Component } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { Row } from "react-grid-system"
import CustomNavBar from "../../components/CustomNavbar"
import { CustomButton, Spacer } from "../../components/global"
import { CustomInput } from "../../components/CustomInput"
import { getProfessionsList } from "../../services/professions.services"
import disableScroll from "disable-scroll"
import { withTranslation } from "react-i18next"
import { gtmBasicEvent } from "../../tools/reactgaEvents"
import { getFirebaseToken, signupEmailPassword } from "../../services/firebase"
import customToast from "../../components/CustomToast"
import CustomDatePicker from "../../components/CustomDatePicker"
import moment from "moment"
import { getOrganisationFromInviteCode } from "../../services/auth.services"
import CustomCheckbox from "../../components/CustomCheckbox"
import { t } from "i18next"
import Loader from "../../components/Loader"
import ComboBox from "../../components/skeleton/ComboBox"
import i18n from "../../config/i18n"
import { GTMBasicEvent, OrganisationUid } from "../../interfaces"
import { getCountryList, POPULAR_COUNTRY_CODES } from "./countryList"
import SimpleNavbar from "../../components/SimpleNavbar"
import { getOrganisationById } from "../../services/organisations.services"

const EXAMPLE_EMAIL = "example@juisci.com"
const countryList = getCountryList(i18n.resolvedLanguage)

class SignupPage extends Component {
	state = {
		showPassword: false,
		loading: false,
		firstname: "",
		lastname: "",
		birthdate: null,
		email: "",
		country: "",
		password: "",
		confirmPassword: "",
		errorMessage: "",
		organisation: null,
		rpps: "",
		legacyChecked: false,
		over18: false
	}

	async componentDidMount() {
		disableScroll.off()
		window.scrollTo(0, 0)

		const isAuthentified = await getFirebaseToken()

		if (!!isAuthentified) this.props.history.replace("/profile")

		if (!!localStorage.getItem("_signup_state")) {
			const state = JSON.parse(localStorage.getItem("_signup_state"))
			this.setState(state)
			localStorage.removeItem("_signup_state")
		}

		await this.getInviteCodeFromUrlQuery()

		const inviteCode = new URLSearchParams(this.props.location.search).get(
			"inviteCode"
		)

		if (!!inviteCode) {
			const { organisation } = await getOrganisationFromInviteCode(
				inviteCode
			)

			this.setState({
				organisation
			})
		} else if (!this.state.organisation) {
			this.setState({
				organisation: false
			})
		}

		getProfessionsList().then((res) => {
			this.setState({ professions: res })
		})
		this.setState({
			email: !!this.props.location?.state?.email
				? this.props.location?.state?.email
				: this.state.email.length > 0
				? this.state.email
				: EXAMPLE_EMAIL
		})

		gtmBasicEvent("view_sign_up")
	}

	async getInviteCodeFromUrlQuery() {
		const query = new URLSearchParams(window.location.search)
		const organisationUid = query.get("org")

		const organisation = await getOrganisationById(organisationUid)

		console.log(organisation)

		this.setState({
			organisation
		})
	}

	async handleSubmit(event) {
		event.preventDefault()
		this.setState({ loading: true })

		const inviteCode = new URLSearchParams(this.props.location.search).get(
			"inviteCode"
		)

		const isTakeda =
			this.state.organisation &&
			this.state.organisation.uid === OrganisationUid.TAKEDA

		const isUCB =
			this.state.organisation &&
			this.state.organisation.uid === OrganisationUid.UCB

		const {
			email,
			password,
			confirmPassword,
			firstname,
			lastname,
			birthdate,
			country
		} = this.state

		if (countryList[country] === undefined) {
			customToast(t("signup.error.country"))
			this.setState({ loading: false })
			return
		}

		if (password !== confirmPassword) {
			customToast(t("signup.error.passwords"))
			this.setState({ loading: false })
			return
		}

		if (!this.state.legacyChecked) {
			customToast(t("signup.error.legacy"))
			this.setState({ loading: false })
			return
		}

		let data = {
			firstname,
			lastname,
			birthdate: birthdate ? new Date(birthdate) : null,
			country
		}

		if (!!inviteCode) {
			data.invitationCode = inviteCode

			if (!this.state.over18 && isTakeda) {
				customToast(t("signup.error.over18"))
				this.setState({ loading: false })
				return
			}

			if (isUCB) {
				const numericRegex = /^\d+$/
				const inputValueLength = this.state.rpps.length

				if (
					!numericRegex.test(this.state.rpps) ||
					inputValueLength < 9 ||
					inputValueLength > 14
				) {
					customToast(t("signup.error.rpps"))
					this.setState({ loading: false })
					return
				}
			}
		}

		if (!inviteCode && !!this.state.organisation)
			data.organisations = [this.state.organisation.uid]

		try {
			const signupResult = await signupEmailPassword(
				email,
				password,
				data
			)
			if (!signupResult)
				throw new Error(
					"Error creating account with signupEmailPassword firebase method"
				)
		} catch (error) {
			console.error("signupPage", error)
			this.setState({ loading: false })
		}
	}

	handleStoreState = () => {
		localStorage.setItem(
			"_signup_state",
			JSON.stringify({
				firstname: this.state.firstname,
				lastname: this.state.lastname,
				birthdate: this.state.birthdate,
				email: this.state.email,
				country: this.state.country,
				password: this.state.password,
				confirmPassword: this.state.confirmPassword
			})
		)
	}

	render() {
		const { showPassword, loading, organisation } = this.state

		const isBiocodex =
			organisation && organisation.uid === OrganisationUid.BIOCODEX

		const isTakeda = organisation && organisation?.uid?.includes("takeda")

		const isUCB = organisation && organisation.uid === OrganisationUid.UCB

		const countryOptions = Object.keys(countryList)
			.map((key) => ({
				value: key,
				label: countryList[key]
			}))
			.sort((a, b) => {
				// Si 'a' est favori, il vient avant 'b'
				if (
					POPULAR_COUNTRY_CODES.includes(a.value) &&
					!POPULAR_COUNTRY_CODES.includes(b.value)
				) {
					return -1
				}
				// Si 'b' est favori, il vient après 'a'
				if (
					!POPULAR_COUNTRY_CODES.includes(a.value) &&
					POPULAR_COUNTRY_CODES.includes(b.value)
				) {
					return 1
				}
				// Trier les non favoris alphabétiquement
				if (
					!POPULAR_COUNTRY_CODES.includes(a.value) &&
					!POPULAR_COUNTRY_CODES.includes(b.value)
				) {
					return a.label.localeCompare(b.label)
				}
				// Si les deux sont favoris, on les laisse dans leur ordre original dans 'POPULAR_COUNTRY_CODES'
				return (
					POPULAR_COUNTRY_CODES.indexOf(a.value) -
					POPULAR_COUNTRY_CODES.indexOf(b.value)
				)
			})

		if (organisation === null) return <Loader />

		return (
			<>
				{organisation ? <SimpleNavbar /> : <CustomNavBar />}
				<StyledSignup>
					<SignupTitle>
						{organisation && (
							<img
								src={organisation.logo?.url}
								alt=""
								className="organisation-logo"
							/>
						)}
						<h1>{t("signup.title")}</h1>
						<p>{t("signup.subtitle")}</p>
					</SignupTitle>

					{/* Form for Signing Up */}
					<form onSubmit={this.handleSubmit.bind(this)} action="#">
						<CustomInput
							type="email"
							name="email"
							placeholder={t("signup.email")}
							value={this.state.email}
							onInput={(e) =>
								this.setState({ email: e.target.value })
							}
							InputProps={{
								style: {
									color:
										this.state.email === EXAMPLE_EMAIL
											? "#81939c"
											: "#212121"
								},
								onFocus: () =>
									this.state.email === EXAMPLE_EMAIL
										? this.setState({ email: "" })
										: null
							}}
							required
						/>
						<Spacer height="20px" />
						<CustomInput
							type="text"
							name="firstname"
							placeholder={t("signup.firstname")}
							value={this.state.firstname}
							onInput={(e) => {
								this.setState({ firstname: e.target.value })
								gtmBasicEvent(GTMBasicEvent.START_SIGN_UP)
							}}
							style={{ marginBottom: "20px" }}
							required
						/>
						<Spacer height="20px" />

						<CustomInput
							type="text"
							name="lastname"
							placeholder={t("signup.lastname")}
							value={this.state.lastname}
							onInput={(e) =>
								this.setState({ lastname: e.target.value })
							}
							style={{ marginBottom: "20px" }}
							required
						/>

						<Spacer height="20px" />
						{isUCB && (
							<CustomInput
								type="text"
								name="rpps"
								placeholder={t("signup.rpps")}
								value={this.state.rpps}
								onInput={(e) =>
									this.setState({ rpps: e.target.value })
								}
								style={{ marginBottom: "20px" }}
								required={isUCB}
							/>
						)}

						{isUCB && <Spacer height="20px" />}

						{!isTakeda && (
							<CustomDatePicker
								defaultValue={this.state.birthdate}
								onChange={(date) => {
									this.setState({
										birthdate:
											moment(date).format("YYYY-MM-DD")
									})
								}}
							/>
						)}
						{!isTakeda && <Spacer height="20px" />}

						<ComboBox
							options={countryOptions}
							placeholder={t("signup.country")}
							groupBy={(option) =>
								POPULAR_COUNTRY_CODES.includes(option.value)
									? t("common.mostSelected")
									: t("signup.otherCountries")
							}
							onChange={(country) =>
								!!country &&
								this.setState({ country: country.value })
							}
							required
						/>

						<Spacer height="20px" />

						<CustomInput
							onInput={(e) =>
								this.setState({ password: e.target.value })
							}
							value={this.state.password}
							type={showPassword ? "text" : "password"}
							placeholder={t("signup.password")}
							name="password"
							style={{ marginBottom: "20px" }}
							required
							filled={this.state.password}
							suffixClick={() =>
								this.setState({ showPassword: !showPassword })
							}
							suffix={
								<ShowHideButton>
									<Row justify="end" align="center">
										<p>
											{showPassword
												? t("signup.hide")
												: t("signup.show")}
										</p>
									</Row>
								</ShowHideButton>
							}
						/>
						<Spacer height="20px" />

						<CustomInput
							onInput={(e) =>
								this.setState({
									confirmPassword: e.target.value
								})
							}
							value={this.state.confirmPassword}
							type={showPassword ? "text" : "password"}
							placeholder={t("signup.confirmPassword")}
							name="confirmPassword"
							style={{ marginBottom: "20px" }}
							required
							filled={this.state.confirmPassword}
							suffixClick={() =>
								this.setState({ showPassword: !showPassword })
							}
							suffix={
								<ShowHideButton>
									<Row justify="end" align="center">
										<p>
											{showPassword
												? t("signup.hide")
												: t("signup.show")}
										</p>
									</Row>
								</ShowHideButton>
							}
						/>
						<Spacer height="20px" />

						{isTakeda && (
							<CustomCheckbox
								checked={this.state.over18}
								title={
									<span style={{ lineHeight: "200%" }}>
										{t("signup.certify18")}
									</span>
								}
								onChange={() => {
									this.setState({
										over18: !this.state.over18
									})
								}}
							/>
						)}

						<CustomCheckbox
							checked={this.state.legacyChecked}
							title={
								<div
									style={{ fontSize: 13, lineHeight: "160%" }}
								>
									{isTakeda
										? t(
												"signup.acceptTermsAndConditionsTakeda"
										  )
										: t(
												"signup.acceptTermsAndConditions"
										  )}{" "}
									<Link
										onClick={this.handleStoreState.bind(
											this
										)}
										to={{
											pathname: "/terms",
											search: isBiocodex
												? "?version=biocodex"
												: isTakeda
												? "?version=takeda"
												: null,
											state: {
												isPush: true
											}
										}}
									>
										{t("settings.terms")}
									</Link>{" "}
									&{" "}
									<Link
										onClick={this.handleStoreState.bind(
											this
										)}
										to={{
											pathname: "/policy",
											search: isBiocodex
												? "?version=biocodex"
												: isTakeda
												? "?version=takeda"
												: null,
											state: {
												isPush: true
											}
										}}
									>
										{t("settings.policies")}
									</Link>
									{isTakeda &&
										` ${t("signup.ofJuisciLymphomas")}`}
									{"."}
								</div>
							}
							onChange={() => {
								this.setState({
									legacyChecked: !this.state.legacyChecked
								})
							}}
						/>

						<Spacer height="20px" />

						<CustomButton
							className={loading ? "loading disabled" : ""}
							type="submit"
						>
							{t("common.register")}
						</CustomButton>
					</form>
					<Spacer />
					<center>
						<span
							style={{
								fontSize: "16px",
								fontWeight: 400,
								color: "#212121",
								textAlign: "center",
								fontFamily: "Poppins"
							}}
						>
							{t("signup.alreadyJuisci")}{" "}
							<Link
								to="/login"
								style={{
									fontWeight: 700,
									color: "#CE0868",
									textDecoration: "underline"
								}}
							>
								{this.props.i18n.resolvedLanguage === "fr"
									? "Connectez-vous !"
									: "Log in!"}
							</Link>
						</span>
					</center>
				</StyledSignup>
			</>
		)
	}
}

export default withTranslation()(SignupPage)

const ShowHideButton = styled.div`
	p {
		font-family: Roboto !important;
		color: #212121;
		font-size: 12px;
		font-weight: 700;
		text-transform: capitalize;
		margin-right: 20px;
		margin-left: 10px;
	}
`

const SignupTitle = styled.div`
	color: #212121;
	text-align: center;
	padding-top: 70px;

	h1 {
		font-family: "Poppins";
		font-size: 24px;
		line-height: 33.6px;
		margin-bottom: 0px;
	}

	p {
		font-family: Roboto !important;
		font-size: 16px;
		margin-top: 5px;
		margin-bottom: 35px;
	}
`

const StyledSignup = styled.div`
	min-height: 80vh;
	max-height: 100%;
	background: #ecf0f5;
	padding: 0px 30px;
	padding-bottom: 100px;

	.--checkbox-title {
		font-family: Roboto;
		font-style: normal;
		font-weight: 600;
		font-size: 13px;

		a {
			color: #ce0868;
			font-weight: 600;
		}
	}

	.organisation-logo {
		width: 150px;
		max-height: 48px;
		object-fit: contain;

		margin: 21px 0px 0px;
		background: white;
		padding: 16px;
		border-radius: 15px;
	}
`
