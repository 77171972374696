import { Component } from "react"
import styled from "styled-components"
import { SpecialtyTag } from "./global"
import { renderPublicationDate } from "../tools/utils"
import { t } from "i18next"
import { Link } from "react-router-dom"
import Swipeout from "rc-swipeout"
import { convertContentToItemData, gtmItemsData } from "../tools/reactgaEvents"
import { ItemDataVariant, ItemsDataEvent } from "../interfaces"
import CustomIcon from "./CustomIcon"
import i18n from "../config/i18n"

class PreviewCard extends Component {
	state = { isDeleted: false }

	handleSelectItem = () => {
		const { content } = this.props
		const contentType =
			content?.content_format === "Video"
				? "video"
				: content?.content_format === "Nectar"
				? "nectar"
				: "article"

		if (contentType === "video")
			gtmItemsData(
				ItemsDataEvent.SELECT_ITEM,
				convertContentToItemData(
					[content],
					this.props.itemListName,
					content.videoFormat === "story"
						? ItemDataVariant.VIDEO_STORY
						: ItemDataVariant.VIDEO_ARTICLE
				)
			)
		else if (contentType === "article")
			gtmItemsData(
				ItemsDataEvent.SELECT_ITEM,
				convertContentToItemData(
					[content],
					this.props.itemListName ?? undefined,
					ItemDataVariant.ARTICLE
				)
			)
	}

	render() {
		const { content, disabled, onRemove, onClick } = this.props
		const contentType =
			content?.content_format === "Video"
				? "video"
				: content?.content_format === "Nectar"
				? "nectar"
				: "article"

		const youtubeId =
			contentType === "video" && content.videoFormat === "article"
				? content.sourceURL?.split("v=")[1]
				: undefined

		return (
			<StyledCard
				style={{
					...this.props.style,
					display: this.state.isDeleted ? "none" : "block"
				}}
				onClick={() => {
					onClick && onClick()
					this.props.itemListName && this.handleSelectItem()
				}}
			>
				<Swipeout
					style={{ borderRadius: "10px" }}
					disabled={!onRemove || disabled}
					right={[
						{
							text: (
								<CustomIcon
									className="trash-icon"
									iconName="trash"
									color="white"
								/>
							),
							onPress: () => {
								onRemove()
								this.setState({ isDeleted: true })
							},
							style: {
								position: "relative",
								backgroundColor: "red",
								color: "white",
								borderRadius: "0px 15px 15px 0px",
								transform: "translateY(2px)",
								height: "calc(100% - 2px)",
								width: 56
							}
						}
					]}
				>
					<div className="card-content">
						<Link
							className="react-link"
							to={{
								pathname:
									(contentType === "article"
										? "/post/"
										: contentType === "nectar"
										? "/nectar/"
										: "/video/") + content.slug,
								state: { ...this.props.locationState }
							}}
						>
							<div
								className={
									contentType == "video"
										? "content-infos"
										: ""
								}
							>
								{content.medical_specialties?.map(
									(specialty, index) => {
										if (index < 3)
											return (
												<SpecialtyTag
													key={
														specialty._id +
														"--specialtytagsearch" +
														content._id +
														index
													}
												>
													{
														specialty.translations[
															i18n.resolvedLanguage ??
																"en"
														]
													}
												</SpecialtyTag>
											)
									}
								)}
								<div className={`content-type ${contentType}`}>
									{contentType === "video"
										? "VIDEO"
										: contentType === "nectar"
										? "QUESTION"
										: content?.article_type}
								</div>
								<h5>{content.title}</h5>
								<div className="footer">
									<div className="footer-el">
										{renderPublicationDate(
											content?.publication_date
										)}
										<div className="bold">
											{contentType === "video"
												? content[
														content?.publisher ??
															"company"
												  ]?.name
												: contentType === "nectar"
												? "Juisci Originals" // TODO: Mettre ici le nom du publisher d'un nectar
												: content.journal?.name}
										</div>
									</div>

									{contentType === "article" && (
										<div className="footer-el">
											{t("common.authors")}
											<div className="bold">
												{content?.primary_author}{" "}
												{content?.authors?.length
													? i18n.resolvedLanguage ===
													  "fr"
														? `et ${content.authors.length} autres auteurs`
														: `and ${content.authors.length} other authors`
													: ""}
											</div>
										</div>
									)}
								</div>
							</div>

							{contentType === "video" && (
								<div
									className="video-thumbnail"
									style={{
										backgroundSize: "cover",
										backgroundImage: `url("${
											content?.videoFormat === "story"
												? content?.apiVideo?.thumbnail
												: `https://img.youtube.com/vi/${
														youtubeId?.split("&")[0]
												  }/hqdefault.jpg`
										}")`
									}}
								/>
							)}
						</Link>
					</div>
				</Swipeout>
			</StyledCard>
		)
	}
}

export default PreviewCard

const StyledCard = styled.div`
	border-radius: 15px;
	overflow: hidden;
	box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
	text-align: left;
	margin: 10px 0;

	.trash-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) !important;
	}

	.card-content {
		background: linear-gradient(
				180deg,
				rgba(255, 255, 255, 0) 59.9%,
				#fff3ce 100%
			),
			#fff;

		box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
		padding: 24px 16px;

		.react-link {
			color: #212121;
			text-decoration: none;
			display: flex;
			&:focus,
			&:hover,
			&:visited,
			&:link,
			&:active {
				text-decoration: none;
			}
		}

		.content-type {
			font-family: "Inter";
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 120%;
			letter-spacing: 0.03em;
			text-transform: uppercase;
			color: #f1353a;
			margin: 12px 0;

			&.nectar {
				color: #2aa85e;
			}
		}

		h5 {
			font-family: "Inter";
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			line-height: 120%;
			letter-spacing: -6e-5em;
			margin-top: 8px;
		}

		.footer {
			display: flex;
			justify-content: space-between;
			align-items: start;
			gap: 8px;

			.footer-el {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 400;
				font-size: 10px;
				line-height: 12px;
				min-width: 100px;

				.bold {
					display: block;
					font-weight: 700;
				}
			}
		}

		.video-thumbnail {
			width: 80px;
			height: auto;
			border-radius: 10px;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}

		.content-infos {
			width: calc(100% - 80px);
			padding-right: 16px;
		}
	}
`
