import styled from "styled-components"
import { t } from "i18next"
import SettingsNavbar from "../../components/SettingsNavbar"
import { Spacer } from "../../components/global"
import { useHistory } from "react-router-dom"
import { useEffect, useState } from "react"
import { getOrganisationSharingCode } from "../../services/organisations.services"
import Loader from "../../components/Loader"
import NoResultDraw from "../../assets/images/illustrations/search_no_results.svg"

export default function QrCodePage() {
	const history = useHistory()
	const [qrCodeImage, setQrCodeImage] = useState(null)
	const [isError, setIsError] = useState(false)

	useEffect(() => {
		const getQrCode = async () => {
			try {
				const code = await getOrganisationSharingCode()
				const fileBlob = URL.createObjectURL(code)
				setQrCodeImage(fileBlob)
			} catch (error) {
				console.error(error)
				setIsError(true)
			}
		}

		getQrCode()
	}, [])

	return (
		<StyledPage>
			<SettingsNavbar
				goBack={() => history.goBack()}
				pageTitle={t("settings.qrcode.header")}
			/>
			<Spacer height={"48px"} />
			{isError ? (
				<div>
					<img
						className="empty-illustration"
						src={NoResultDraw}
						alt=""
					/>
					<p>{t("settings.qrcode.notAvailable")}</p>
				</div>
			) : !qrCodeImage ? (
				<Loader loaderOnly />
			) : (
				<div
					style={{
						position: "relative",
						width: "100%",
						paddingBottom: "100%",
						background: "white",
						borderRadius: 20,
						overflow: "hidden",
						transform: "scale(0.9)"
					}}
				>
					<img
						src={qrCodeImage}
						alt="Organisation QR Code"
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							width: "100%",
							height: "100%",
							objectFit: "cover",
							borderRadius: 20
						}}
					/>
				</div>
			)}
			<Spacer />
			<h3>{t("settings.qrcode.title")}</h3>
			<p>{t("settings.qrcode.description")}</p>
		</StyledPage>
	)
}

const StyledPage = styled.div`
	background-color: #ffc408;
	height: 100dvh;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 24px;

	h3 {
		font-family: Inter;
		font-size: 24px;
		font-weight: 700;
		line-height: 26.4px;
		letter-spacing: -0.025em;
		text-align: center;
		color: #212121;
		margin: 0;
	}

	p {
		font-family: Inter;
		font-size: 16px;
		font-weight: 400;
		line-height: 19.2px;
		letter-spacing: -0.0001em;
		text-align: center;
		color: #212121;
	}

	.empty-illustration {
		width: 100%;
		max-width: 150px;
		margin: 0 auto 24px;
		display: block;
		background: #ffffff;
		padding: 20px;
		border-radius: 200px;
	}
`
