import { Component } from "react"
import { Col, Row } from "react-grid-system"
import styled from "styled-components"
import CustomNavbar from "../../components/CustomNavbar"
import Loader from "../../components/Loader"
import i18n from "../../config/i18n"
import { t } from "i18next"
import {
	getCompany,
	getCompanyPlaylists
} from "../../services/company.services"
import { Divider, Spacer } from "../../components/global"
import CustomIcon from "../../components/CustomIcon"
import { userApi } from "../../redux/user/user.service"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import customToast from "../../components/CustomToast"
import Moment from "react-moment"
import { Link } from "react-router-dom"
import { iosGenerateHaptic, iosPlaySound } from "../../tools/ios"
import {
	HapticEffect,
	ItemDataEventListName,
	ItemDataVariant,
	ItemsDataEvent,
	SoundEffect
} from "../../interfaces"
import {
	convertContentToItemData,
	gtmItemsData
} from "../../tools/reactgaEvents"

const eventColors = {
	webinar: "#FE5E56",
	webinaire: "#FE5E56",
	actualité: "#FEA937",
	news: "#FEA937",
	évènement: "#FF7923",
	event: "#FF7923",
	congrès: "#FF699C",
	congress: "#FF699C",
	"info médicale": "#FFC409",
	"medical information": "#FFC409"
}

class CompanyPage extends Component {
	state = {
		company: undefined,
		playlists: undefined
	}

	componentDidMount() {
		document.querySelector("html").style.background = "#fff"
		window.scrollTo(0, 0)
		this.fetchCompany()
	}

	async fetchCompany() {
		const company = await getCompany(this.props.match.params.id)
		const { docs: playlists } = await getCompanyPlaylists({
			companyId: this.props.match.params.id
		})

		gtmItemsData(
			ItemsDataEvent.VIEW_ITEM_LIST,
			convertContentToItemData(
				playlists,
				ItemDataEventListName.PLAYLIST_PAGE,
				ItemDataVariant.ARTICLE
			)
		)

		this.setState({ company, playlists })
	}

	shareCompany() {
		const my_uid = this.props.user?.user?.uid
		const { name, _id } = this.state.company

		const shareUrl = `/company/${_id}?shared=${my_uid ?? "null"}&org=${
			this.props.user.user?.organisations?.[0]?.uid || "null"
		}`

		const shareText =
			i18n.language === "fr"
				? `Découvrez les dernières nouvelles de ${name} sur Juisci !`
				: `Discover the latest news of ${name} on Juisci now!`

		if (navigator.share) {
			navigator
				.share({
					title: document.title,
					text: shareText,
					url: shareUrl
				})
				.then(() => {
					iosGenerateHaptic(HapticEffect.SUCCESS)
					iosPlaySound(SoundEffect.SHARE)
					customToast(t("toast.success.sharedCompany"), "success")
					gtmItemsData(ItemsDataEvent.ORGANISATION_SHARE, [
						{
							item_name: name,
							item_list_name:
								ItemDataEventListName.ORGANISATION_PAGE,
							item_id: _id,
							item_brand: null,
							item_category: null,
							item_category2: "standard",
							item_variant: ItemDataVariant.ORGANISATION,
							quantity: 1
						}
					])
				})
		} else {
			customToast(t("toast.error.browser"))
		}
	}

	handleRedirectWebsite() {
		const { name, _id } = this.state.company

		gtmItemsData(ItemsDataEvent.ORGANISATION_REDIRECT, [
			{
				item_name: name,
				item_list_name: ItemDataEventListName.ORGANISATION_PAGE,
				item_id: _id,
				item_brand: name,
				item_category: "organisation",
				item_category2: "standard",
				item_variant: ItemDataVariant.ORGANISATION,
				quantity: 1
			}
		])
	}

	// Get YouTube thumbnail: https://i3.ytimg.com/vi/A13S0KnLhVg/mqdefault.jpg

	render() {
		const { company, playlists } = this.state
		return (
			<PageWrapper>
				<CustomNavbar {...this.props} showBackButton />
				{company ? (
					<div>
						<div className="header">
							<Row align="center">
								<Col xs={3}>
									<img
										src={company?.images[0]?.url}
										alt=""
										onClick={() =>
											getCompany(
												this.props.match.params.id
											).then((company) =>
												console.log(company)
											)
										}
									/>
								</Col>
								<Col xs={5 + 1 / 2}>
									<h5>{company?.name}</h5>
									<h6>{company?.type}</h6>
								</Col>
								<Col xs={3 / 2}>
									<a
										href={company?.website}
										target="blank"
										onClick={this.handleRedirectWebsite.bind(
											this
										)}
									>
										<CustomIcon
											iconName="website"
											scale={1.2}
										/>
									</a>
								</Col>
								<Col xs={1}>
									<div
										className="share"
										onClick={this.shareCompany.bind(this)}
									>
										<CustomIcon iconName="share-company" />
									</div>
								</Col>
							</Row>
							<p>{company?.description}</p>
						</div>
						<div className="content">
							{company?.video_link && (
								<div>
									<h3>{t("content.company.moreInfo")}</h3>
									<a
										href={company?.video_link}
										target="blank"
									>
										<VideoCard
											thumbnail={`https://i3.ytimg.com/vi/${
												company?.video_link?.split(
													"v="
												)[1]
											}/mqdefault.jpg`}
										>
											<div className="tag">Vidéo</div>
											<div className="play-icon">
												<CustomIcon
													scale={1.5}
													iconName="play_circle"
												/>
											</div>
										</VideoCard>
									</a>
									<Divider />
								</div>
							)}
							<div>
								<h3>Infos & {t("common.news")}</h3>
							</div>

							{company?.infos?.length > 0 ? (
								company?.infos.map((info) => (
									<EventItem info={info} company={company} />
								))
							) : (
								<p className="empty-events">
									{t("content.company.noEvents")}
								</p>
							)}
							<Divider />
							{playlists === undefined && <Loader loaderOnly />}
							{playlists?.length > 0 && (
								<div>
									<h3>
										{i18n.language === "fr" ? (
											<>Playlist(s) de {company.name}</>
										) : (
											<>{company.name}'s playlist(s)</>
										)}
									</h3>
									{playlists && (
										<HScrollView className="scrollbar-hidden">
											{playlists?.map((playlist) => (
												<Link
													to={{
														pathname:
															"/playlist/public/" +
															playlist?._id,
														state: {
															playlist
														}
													}}
													key={playlist?._id}
												>
													<PlaylistSquare>
														<p>
															{playlist?.title}
															<div className="subtitle">
																{playlist
																	?.metrics
																	?.articles +
																	" " +
																	t(
																		"common.shots"
																	)}
															</div>
														</p>
													</PlaylistSquare>
												</Link>
											))}
										</HScrollView>
									)}
								</div>
							)}
							<Spacer height="40px" />
						</div>
					</div>
				) : (
					<Loader />
				)}
			</PageWrapper>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(CompanyPage))

const EventItem = ({ info, company }) => {
	const background = eventColors[info?.type?.toLowerCase()]

	return (
		<a href={info.link} target="blank">
			<EventCard style={{ background }}>
				<Row justify="between" align="top">
					<Col xs={8}>
						<Row align="center">
							<Col xs={4}>
								<img src={company?.images[0]?.url} alt="" />
							</Col>
							<Col xs={8}>
								<h5>{company.name}</h5>
								<h6>{company.type}</h6>
							</Col>
						</Row>
					</Col>
					{
						<Col xs={4}>
							<div className="publication_date">
								<Moment locale={i18n.language} fromNow>
									{info.creation_date}
								</Moment>
							</div>
						</Col>
					}
				</Row>
				<Row style={{ margin: 0 }}>
					<div className="tag">{info?.type ?? "Évènement"}</div>
					{info.event_date && (
						<div className="tag date">
							<Moment
								locale={i18n.language}
								format={
									i18n.language === "fr"
										? "D MMMM YYYY"
										: "MMMM D, YYYY"
								}
								withTitle
								style={{
									textTransform:
										i18n.language === "fr"
											? "lowercase"
											: "capitalize"
								}}
							>
								{info.event_date}
							</Moment>
						</div>
					)}
				</Row>
				<p className="description">
					{info.content.split("\n").map((subcontent, index) => (
						<span key={subcontent + "br" + index}>
							{subcontent}
							<br />
						</span>
					))}
				</p>
				<div className="cta_link">
					<CustomIcon
						iconName="next-arrow"
						color="white"
						color2="#212121"
						scale={0.7}
						style={{ marginBottom: -10 }}
					/>
					<p className="link">{info.cta ?? "En savoir plus"}</p>
				</div>
			</EventCard>
		</a>
	)
}

const PageWrapper = styled.div`
	div.header {
		background: #ecf0f5;
		padding: 80px 24px 24px;
		box-sizing: border-box;

		img {
			width: 64px;
			height: 64px;
			object-fit: contain;
		}

		h5 {
			font-weight: 600;
			font-size: 16px;
			line-height: 150%;
			margin: 0;
			font-family: "Poppins";
		}

		h6 {
			font-size: 13px;
			font-family: "Poppins";
			font-weight: 400;
			margin: 0;
		}

		p {
			font-weight: 400;
			font-size: 14px;
			line-height: 160%;
		}

		div.share {
			/* margin-left: 20px; */
		}
	}

	div.content {
		background: #fff;
		padding: 10px 24px 24px;
		box-sizing: border-box;

		h3 {
			font-family: "Work Sans";
			font-style: normal;
			font-weight: 900;
			font-size: 21px;
			line-height: 120%;
			letter-spacing: 0.02em;
		}
	}

	div.h-scroll {
		display: inline;

		div.playlist-box {
			font-family: "Poppins" !important;
			display: table;
			padding: 25px 10px;
			color: #000;
			border-radius: 20px;
			background: linear-gradient(
				144.76deg,
				#ffffff 0%,
				#ecf0f5 70.37%,
				#fcc408 139.87%
			);

			width: h5 {
				font-weight: 600;
				font-size: 13px;
				margin: 0;
				margin-bottom: 16px;
				text-align: center;
			}
			h6 {
				font-weight: 400;
				font-size: 11px;
				margin: 0;
				text-align: center;
			}
		}
	}
`
const EventCard = styled.div`
	margin: 15px 0;
	padding: 16px;
	box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
	border-radius: 15px;
	color: #212121;

	.cta_link {
		display: flex;
		align-items: center;
	}

	p {
		font-weight: 400;
		font-size: 15px;
		line-height: 120%;
		font-family: "Roboto" !important;

		&.date {
			font-family: "Poppins" !important;
			font-size: 11px;
			margin: 0;
		}

		&.description {
			margin-bottom: 10px;
		}

		&.link {
			font-weight: 700;
			font-size: 14px;
			line-height: 21px;
			font-family: "Poppins" !important;
			margin: 0;
		}
	}

	div.tag {
		background: #fff;
		color: #212121;
		border-radius: 10px;
		font-size: 12px;
		font-weight: 400;
		font-family: "Poppins";
		display: table;
		padding: 5px 10px;
		box-sizing: border-box;
		margin-top: 15px;

		&.date {
			background: #212121;
			color: #fff;
			margin-left: 16px;
			font-weight: 500;
		}
	}

	div.publication_date {
		color: #212121;
		font-weight: 700;
		font-size: 10px;
		line-height: 120%;
		text-align: right;
		letter-spacing: 0.02em;
		font-family: "Poppins";
		margin-top: 5px;
	}

	img {
		width: 40px;
		height: 40px;
		object-fit: contain;
		background-color: #fff;
		box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
		padding: 0px 10px;
		border-radius: 10px;
	}

	h5 {
		font-weight: 600;
		font-size: 14px;
		line-height: 100%;
		margin: 0;
		font-family: "Poppins";
	}

	h6 {
		font-size: 11px;
		font-family: "Poppins";
		font-weight: 400;
		margin: 0;
	}
`

const VideoCard = styled.div`
	position: relative;
	background-image: linear-gradient(
			rgba(255, 255, 255, 0.1) 0%,
			rgba(255, 255, 255, 0.1) 50%,
			rgba(0, 0, 0, 0.4) 100%
		),
		url(${(props) => props.thumbnail});

	background-size: cover;
	height: 200px;
	width: 100%;
	border-radius: 20px;
	padding: 15px;
	box-sizing: border-box;

	div.play-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	div.tag {
		background: #212121;
		border-radius: 10px;
		color: #fff;
		font-size: 12px;
		font-weight: 400;
		font-family: "Poppins";
		display: table;
		padding: 5px 10px;
		box-sizing: border-box;
	}
`

const PlaylistSquare = styled.div`
	background: linear-gradient(
		144.76deg,
		#ffffff 0%,
		#ecf0f5 70.37%,
		#fcc408 139.87%
	);
	color: black;
	box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
	padding: 24px;
	width: 100px;
	height: 100px;
	text-align: center;
	display: inline-block;
	white-space: normal;
	margin-right: 12px;
	border-radius: 20px;
	position: relative;

	p {
		font-family: "Poppins";
		font-weight: 600;
		font-size: 13px;
		position: absolute;
		left: 50%;
		top: 40%;
		width: 90%;
		transform: translate(-50%, -50%);
	}

	div.subtitle {
		font-family: "Poppins";
		font-weight: normal;
		font-size: 10px;
		margin-top: 10px;
	}
`

const HScrollView = styled.div`
	margin: 0;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	display: inline-block;
	vertical-align: middle;
	width: 100%;
	padding-right: 50px;
	margin-left: -24px;
	padding-left: 24px;
`
