import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import JuisciLogo from "../assets/core/juisci-logo.svg";
import CustomIcon from "./CustomIcon";

class SimpleNavbar extends Component {
  render() {
    const preventGoBack =
      parseInt(
        new URLSearchParams(this.props.location.search).get("preventGoBack")
      ) ||
      window.location.pathname === "/signup/onboarding/profile" ||
      this.props.preventGoBack;

    return (
      <StyledWrapper>
        <div className='page-navbar'>
          <CustomIcon
            onClick={
              !preventGoBack
                ? this.props.onGoBack
                  ? this.props.onGoBack
                  : () => this.props.history.goBack()
                : null
            }
            iconName='back-yellow'
            style={{ opacity: !!preventGoBack ? 0 : 1 }}
          />
          <img
            src={JuisciLogo}
            alt=''
            onClick={() => {
              this.props.history.push("/home");
              !!this.props.onLogoClick && this.props.onLogoClick();
            }}
          />
          {this.props.endIcon ? (
            this.props.endIcon
          ) : (
            <div style={{ width: 32 }} />
          )}
        </div>
      </StyledWrapper>
    );
  }
}

export default withRouter(SimpleNavbar);

const StyledWrapper = styled.div`
  .page-navbar {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;
    margin: 0 auto;
    padding: 10px 22px;

    /** Flex */
    display: flex;
    justify-content: space-between;
    align-items: center;

    /** Style */
    background: #fff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

    /** Juisci Logo */
    img {
      transform: translateY(-3px);
      height: 30px;
      width: auto;
    }
  }
`;
