import React, { Component } from "react"
import { t } from "i18next"
import CustomSearchInput from "../../../components/CustomSearchInput"
import { Spacer } from "../../../components/global"
import Loader from "../../../components/Loader"
import SimpleNavbar from "../../../components/SimpleNavbar"
import { getProfessionSpecialization } from "../../../services/professions.services"
import { StyledOnboarding } from "./onboardingProfession"
import Fuse from "fuse.js"
import i18n from "../../../config/i18n"
import { store } from "../../../redux"
import { OrganisationUid } from "../../../interfaces"
import { getFirebaseToken } from "../../../services/firebase"
import { connect } from "react-redux"
import { userApi } from "../../../redux/user/user.service"

const ORGANISATIONS_SPECIALTIES_EXCEPTIONS = {
	[OrganisationUid.BIOCODEX]: ["neurology", "pediatrics"],
	[OrganisationUid.TAKEDA]: ["hematologic-oncology"],
	[OrganisationUid.SFRO]: ["radiotherapy-oncology"],
	[OrganisationUid.SFR]: ["radiology"],
	[OrganisationUid.ESR]: ["radiology"],
	[OrganisationUid.UCB]: ["neurology"]
}

class OnboardingSpecialty extends Component {
	state = {
		profession: undefined,
		specialties: undefined,
		searchResults: undefined,
		searchValue: ""
	}

	async componentDidMount() {
		const { state } = this.props.location
		this.setState({ ...state })
		window.scrollTo(0, 0)
		if (!(await getFirebaseToken())) this.props.history.replace("/login")
	}

	handleSearch(searchValue) {
		const { specialties } = this.state
		this.setState({ searchValue })

		if (searchValue.length) {
			const options = {
				includeScore: true,
				threshold: 0.25,
				keys: ["translations.en", "translations.fr", "uid"]
			}

			const fuse = new Fuse(specialties, options)

			let searchResults = fuse.search(searchValue).map((res) => res.item)

			this.setState({ searchResults })
		} else {
			this.setState({ searchResults: undefined })
		}
	}

	handleCancelSearch() {
		this.setState({ searchValue: "" })
	}

	handleGetSpecialty() {
		const { profession } = this.props.location.state

		getProfessionSpecialization(profession).then((specialties) => {
			this.setState({
				specialties,
				profession
			})
		})
	}

	handleSelectSpecialty(specialty) {
		const {
			profession,
			specialties,
			firstname,
			lastname,
			birthdate,
			country
		} = this.state

		this.props.history.push({
			pathname: "/signup/onboarding/specialty/other",
			state: {
				profession,
				specialty,
				specialties,
				firstname,
				lastname,
				birthdate,
				country
			}
		})
	}

	filterSpecialtiesByOrganisation(specialty, reverse = false) {
		const settings =
			this.props.user?.user?.organisations?.[0]?.organisationSettings

		if (
			!settings?.medicalSpecialties?.length ||
			this.state.searchValue.length
		)
			return true

		const result = settings.medicalSpecialties?.includes(specialty._id)

		return reverse ? !result : result
	}

	render() {
		const { profession, specialties, searchResults, searchValue } =
			this.state

		const organisation = this.props.user?.user?.organisations?.[0]

		if (!profession || !specialties) return <Loader />

		return (
			<StyledOnboarding>
				<SimpleNavbar />
				<div className="header">
					<h2>
						{t("signup.onboarding.h2.specialty")}{" "}
						{profession.translations[i18n.resolvedLanguage]}
					</h2>
					<h5>{t("signup.onboarding.h5.specialty")}</h5>
				</div>
				<div className="selection-rows">
					<h5>{t("signup.onboarding.indicate.specialty")}:</h5>
					<Spacer />
					<CustomSearchInput
						placeholder={t("search.search")}
						onSearch={this.handleSearch.bind(this)}
						onClear={() =>
							this.setState({ searchResults: undefined })
						}
					/>
				</div>
				{searchValue.length ||
				!organisation?.organisationSettings?.medicalSpecialties
					?.length ? null : (
					<div className="selection-rows popular">
						<h3>{t("common.mostPopular")}</h3>
						{specialties
							.filter((specialty) =>
								this.filterSpecialtiesByOrganisation(specialty)
							)
							.map((specialty, index) => (
								<div
									className="row"
									key={specialty._id + "neru" + index}
								>
									<p>
										{
											specialty.translations[
												i18n.resolvedLanguage
											]
										}
									</p>
									<button
										onClick={() =>
											this.handleSelectSpecialty(
												specialty
											)
										}
									>
										{t("common.select")}
									</button>
								</div>
							))}
					</div>
				)}
				{
					<div className="selection-rows">
						<h3>{t("common.specialties")}</h3>
						{(searchResults ? searchResults : specialties)
							.sort((a, b) =>
								a.translations[
									i18n.resolvedLanguage
								].localeCompare(
									b.translations[i18n.resolvedLanguage]
								)
							)
							.filter((el) =>
								this.filterSpecialtiesByOrganisation(el, true)
							)
							.map((specialty, index) => (
								<div
									className="row"
									key={specialty._id + "rwkey" + index}
								>
									<p>
										{
											specialty.translations[
												i18n.resolvedLanguage
											]
										}
									</p>
									<button
										onClick={() =>
											this.handleSelectSpecialty(
												specialty
											)
										}
									>
										{t("common.select")}
									</button>
								</div>
							))}

						{!searchResults?.length && searchValue?.length ? (
							<p className="empty-text">
								{t("signup.onboarding.emptyText")}
							</p>
						) : (
							<></>
						)}
					</div>
				}
			</StyledOnboarding>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(OnboardingSpecialty)
