import { Component } from "react"
import CustomSheet from "../CustomSheet"
import JuisciPipe from "../../assets/core/pipe-icon.svg"
import { CustomButton, Spacer } from "../global"
import { t } from "i18next"
import { RemoveScroll } from "react-remove-scroll"
import { RemoveScrollBar } from "react-remove-scroll-bar"
import i18n from "../../config/i18n"
import { CustomInput } from "../CustomInput"
import CustomIcon from "../CustomIcon"
import { withTranslation } from "react-i18next"
import { Checkbox } from "antd-mobile"
import {
	addToPlaylist,
	createPlaylist
} from "../../services/playlists.services"
import customToast from "../CustomToast"
import { isNativeIOS } from "../../tools/ios"
import { saveContentToPlaylists } from "../../services/contentApi"
import { store } from "../../redux"
import {
	convertContentToItemData,
	gtmItemsData
} from "../../tools/reactgaEvents"
import {
	ItemDataEventListName,
	ItemDataVariant,
	ItemsDataEvent
} from "../../interfaces"

const AgreeItem = Checkbox.AgreeItem

class PlaylistSheet extends Component {
	state = {
		// Step 0. No playlist
		// Step 1. Create playlist
		// Step 2. Add to playlist
		step: 0,
		playlistTitle: "",
		selectedPlaylists: [],
		enableBackButton: false,
		confirmInProgress: false
	}

	componentDidMount() {
		// If user has at least one playlist -> Step 2
		if (this.props?.playlists?.length) {
			let selectedPlaylists = [...this.state.selectedPlaylists]
			this.props?.playlists?.forEach(
				({ playlist, _id, contentVideos }) => {
					if (
						playlist?.find(
							(article) =>
								article._id === this.props?.article?._id
						) ||
						contentVideos?.find(
							(videoId) => videoId === this.props?.article?._id
						)
					) {
						selectedPlaylists.push(_id)
					}
				}
			)

			this.setState({
				selectedPlaylists,
				step: 2,
				enableBackButton: true
			})
		}
	}

	// Add playlist to selected list
	handleAddToList(title) {
		const tmp = this.state.selectedPlaylists

		if (tmp.includes(title)) {
			const index = tmp.indexOf(title)
			tmp.splice(index, 1)
		} else {
			tmp.push(title)
		}

		this.setState({ selectedPlaylists: tmp })
	}

	// Creating a new playlist & add content
	async handleCreatePlaylist() {
		this.setState({
			creationInProgress: true
		})

		if (!this.state.playlistTitle.length) {
			return customToast(t("toast.error.playlistName.missing"), "error")
		}

		// Create playlist
		const newPlaylist = await createPlaylist({
			title: this.state.playlistTitle
		})

		// Handle error
		if (!!newPlaylist.error) {
			this.setState({
				enableBackButton: true,
				creationInProgress: false
			})
			return customToast(t("toast.error.playlistName.exists"), "error")
		}

		this.props.onClose()

		// Handle videos
		if (this.props.type === "video") {
			this.props.onConfirmCreatePlaylist(newPlaylist)
			return
		}

		// Add article to the new playlist
		await addToPlaylist(this.props.article?.slug, newPlaylist._id)

		this.setState({
			playlistTitle: "",
			enableBackButton: true,
			creationInProgress: false
		})

		this.props.onComplete &&
			this.props.onComplete({
				contentIsInPlaylist: true
			})

		customToast(t("toast.success.createdPlaylist"))

		// Update playlist data
		await this.props.getPlaylists()
	}

	// Confirm and add article to selected user's playlists
	handleConfirmList = async () => {
		this.setState({ confirmInProgress: true })

		const {
			contentIsInPlaylist,
			contentWasAdded,
			contentWasRemoved,
			playlistsToAdd,
			playlistsToRemove
		} = saveContentToPlaylists(
			this.props.article,
			this.state.selectedPlaylists
		)

		/** Function complete callback */
		if (contentWasAdded || contentWasRemoved) {
			this.props.onComplete &&
				this.props.onComplete({
					contentIsInPlaylist,
					playlistsToAdd,
					playlistsToRemove
				})
		}

		/** Close callback */
		this.props.onClose()

		if (this.props?.article) {
			const eventlistName = !!this.props.itemListName
				? this.props.itemListName
				: window.location.pathname.includes("foryou")
				? ItemDataEventListName.FORYOU
				: window.location.pathname.includes("video")
				? ItemDataEventListName.VIDEO_ARTICLE_PAGE
				: ItemDataEventListName.ARTICLE

			gtmItemsData(
				ItemsDataEvent.ARTICLE_SAVE,
				convertContentToItemData(
					[this.props.article],
					eventlistName,
					ItemDataVariant.ARTICLE
				)
			)
		}

		this.setState({ confirmInProgress: false })
	}

	render() {
		const firstname = store.getState().user?.user?.firstname

		if (!this.props.showSheet) return null

		switch (this.state.step) {
			// Create playlist
			case 1:
				return (
					<CustomSheet
						show={this.state.step === 1}
						title={t("playlist.create.title")}
						onChange={this.props.onClose}
						backContent={
							<div
								onClick={() => this.setState({ step: 2 })}
								style={{
									marginBottom: -15,
									marginLeft: 5,
									width: 35,
									display:
										this.state.enableBackButton &&
										this.props?.playlists?.length
											? "block"
											: "none"
								}}
							>
								<CustomIcon iconName="back-arrow" />
							</div>
						}
						bottomContent={
							<>
								<CustomButton
									onClick={() => this.handleCreatePlaylist()}
									className={
										this.state.creationInProgress
											? "loading disabled"
											: "purple-gradient"
									}
									style={{ textTransform: "capitalize" }}
								>
									{t("common.save")}
								</CustomButton>
								{isNativeIOS && <Spacer height="5px" />}
							</>
						}
					>
						<div style={{ overflow: "hidden" }}>
							<Spacer />
							<CustomInput
								onInput={(e) =>
									this.setState({
										playlistTitle: e.target.value
									})
								}
								placeholder={t("playlist.create.name")}
							/>
							<Spacer />
						</div>
					</CustomSheet>
				)

			// Add to playlist
			case 2:
				const isPlaylistChecked = (playlist) =>
					this.state.selectedPlaylists.includes(playlist._id)

				return (
					<CustomSheet
						show={this.state.step === 2}
						title={t("content.playlists.add.title")}
						onChange={this.props.onClose}
						bottomContent={
							<>
								<CustomButton
									onClick={() => this.setState({ step: 1 })}
									style={{
										background: "#000",
										color: "#fff"
									}}
								>
									{t("content.playlists.create")}
								</CustomButton>
								<CustomButton
									onClick={() =>
										this.props.type === "video"
											? this.props.onConfirm(
													this.state.selectedPlaylists
											  )
											: this.handleConfirmList()
									}
									className={
										this.state.confirmInProgress
											? "loading disabled"
											: "purple-gradient"
									}
									style={{
										textTransform: "capitalize",
										marginTop: 16
									}}
								>
									{t("common.save")}
								</CustomButton>
								{isNativeIOS && <Spacer height="5px" />}
							</>
						}
					>
						<div style={{ paddingBottom: "100px" }}>
							<Spacer height="10px" />
							<h5>{t("content.playlists.add.content")}</h5>
							{this.props?.playlists &&
								this.props?.playlists?.map((playlist) => {
									return (
										<div key={playlist._id}>
											<AgreeItem
												onChange={() =>
													this.handleAddToList(
														playlist._id
													)
												}
												style={{
													marginLeft: 0,
													paddingLeft: 0
												}}
												checked={isPlaylistChecked(
													playlist
												)}
											>
												<span
													style={{
														color: "#212121",
														fontWeight: 600,
														fontSize: 18,
														fontFamily: "Inter"
													}}
												>
													{playlist.title}
												</span>
											</AgreeItem>
										</div>
									)
								})}
						</div>
					</CustomSheet>
				)

			// No playlist
			default:
				return (
					<CustomSheet
						onChange={this.props.onClose}
						title={t("content.playlists.create")}
						show={this.state.step === 0}
						bottomContent={
							<>
								<CustomButton
									style={{
										background: "black",
										color: "white"
									}}
									onClick={() => this.setState({ step: 1 })}
								>
									{t("content.playlists.create")}
								</CustomButton>
								{isNativeIOS && <Spacer height="5px" />}
							</>
						}
					>
						<div style={{ overflow: "hidden" }}>
							<RemoveScroll />
							<RemoveScrollBar />
							<center>
								<img
									style={{ marginTop: "20px" }}
									src={JuisciPipe}
									alt=""
									height="100px"
								/>
								<p
									style={{
										fontSize: 15,
										margin: "20px 32px"
									}}
								>
									{t("content.playlists.create.missing", {
										firstname: firstname || t("common.doc")
									})}
								</p>
							</center>
						</div>
					</CustomSheet>
				)
		}
	}
}

export default withTranslation()(PlaylistSheet)
