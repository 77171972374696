import React, { Component } from "react"
import styled from "styled-components"
import disableScroll from "disable-scroll"
import JuisciLogo from "./assets/core/logo-with-tagline.png"
import BrowserBG from "./assets/images/bg_pattern.png"
import MobileJuisci from "./assets/images/browser-page-mobile.png"
import DashboardJuisci from "./assets/images/browser-page-dashboard.png"
import { withTranslation } from "react-i18next"
import { t } from "i18next"
import i18n from "./config/i18n"
import { SupportedLanguagesEnum } from "./interfaces"
import { InlineWidget } from "react-calendly"

class BrowserPage extends Component {
	componentDidMount() {
		disableScroll.off()
	}

	renderTitle() {
		if (i18n.resolvedLanguage === SupportedLanguagesEnum.EN)
			return (
				<h1>
					🍋 The Free Juisci App is a mobile-
					<br />
					first experience to enjoy your <span>JUI</span>ce
					<br /> of <span>SCI</span>
					ence on-the-go.
				</h1>
			)

		if (i18n.resolvedLanguage === SupportedLanguagesEnum.FR)
			return (
				<h1>
					🍋 La version gratuite de Juisci est disponible uniquement
					sur mobile pour savourer son Jus de Science en toute
					mobilité.
				</h1>
			)
	}

	render() {
		return (
			<BrowserWrapper>
				<BrowserContent>
					<LogoBrand src={JuisciLogo} />
					<div className="top-message">
						{t("desktop.oopsMessage")}
					</div>
					<h1>{this.renderTitle()}</h1>
					<MobilePhone src={MobileJuisci} alt="" />

					<h5>
						{t("desktop.needBiggerScreen")}
						<br />
						{i18n.resolvedLanguage === SupportedLanguagesEnum.EN ? (
							<span>
								{"Discover our company version:"}
								<a href="https://www.juisci.com/services">
									{"Juisci Workspace."}
								</a>
							</span>
						) : (
							<span>
								{"Découvrez "}
								<a href="https://www.juisci.com/fr/services">
									{"Juisci Workspace"}
								</a>
								{", notre version pour entreprises."}
							</span>
						)}
					</h5>
					<img
						className="dashboard-img"
						src={DashboardJuisci}
						alt=""
					/>

					<h5>{t("desktop.browserVersionBookDemoMessage")}</h5>
					<InlineWidget
						styles={{
							height: "800px",
							overflow: "hidden"
						}}
						pageSettings={{
							hideEventTypeDetails: true,
							hideLandingPageDetails: true
						}}
						url="https://calendly.com/juisci-demo/juisci-demo"
					/>

					<div className="footer">
						<div className="left">
							<a href="https://www.juisci.com/terms-of-use">
								{t("desktop.terms")}
							</a>
							<a href="https://www.juisci.com/privacy-policy">
								{t("desktop.privacy")}
							</a>
						</div>
						<div className="right">
							{`© 2024, Juisci SAS. ${t("desktop.copyright")}.`}
						</div>
					</div>
				</BrowserContent>
			</BrowserWrapper>
		)
	}
}

export default withTranslation()(BrowserPage)

const LogoBrand = styled.img`
	height: 88px;
	margin: 48px 0px;
`

const MobilePhone = styled.img`
	margin: 24px 0;
	width: 152px;
	height: 332px;
`

const BrowserContent = styled.div`
	background: #fff !important;
	padding: 21px 80px;
	margin: 0 auto;
	width: 50%;
	min-width: 414px;
	min-height: 100vh;
	max-height: 100%;
	padding-bottom: 40px;

	h1 {
		//styleName: Mobile/title-xxl;
		font-family: "Inter";
		font-size: 32px;
		font-weight: 700;
		line-height: 35px;
		letter-spacing: 0em;
		text-align: center;

		span {
			color: #ff8800;
		}
	}

	h5 {
		font-family: Roboto;
		font-size: 18px;
		font-weight: 400;
		line-height: 25px;
		letter-spacing: 0em;
		text-align: center;
		white-space: pre-line;
		a {
			color: #ff8800;
			font-weight: 700;
			text-decoration: none;
		}
	}

	.top-message {
		//styleName: Mobile/body-long;
		font-family: Roboto;
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: 0.02em;
		text-align: center;
	}

	.dashboard-img {
		width: 60%;
	}

	.footer {
		padding: 32px 0px;
		border-top: 0.5px solid #212121;

		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 24px;

		.right {
			font-family: Roboto;
			font-size: 12px;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: 0.04em;
			text-align: left;
			color: #212121;
		}

		.left {
			display: flex;
			align-items: center;
			gap: 8px;

			a {
				//styleName: Mobile/body-small;
				font-family: Roboto;
				font-size: 12px;
				font-weight: 400;
				line-height: 22px;
				letter-spacing: 0.04em;
				text-align: left;
				text-decoration: none;
				color: #212121;
			}
		}
	}
`

const BrowserWrapper = styled.div`
	background: #f7f8fc;
	background-image: url(${BrowserBG}) !important;
	/* background-repeat: repeat; */
	/* background-size: cover; */
	min-height: 100dvh;
	max-height: 100%;
	color: #212121;
	text-align: center;
	font-family: "Inter" !important;
`
