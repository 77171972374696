import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import articlesReducer, {
  setSuggestedArticles,
} from "./articles/articles.reducer";
import { articlesApi } from "./articles/articles.service";
import usersReducer, { setSearch } from "./search/search.reducer";
import { searchApi } from "./search/search.service";
import userReducer from "./user/user.reducer";
import { userApi } from "./user/user.service";
import { IUser } from "../interfaces/user.interface";
import { IArticle } from "../interfaces/article.interface";
import { IJournal } from "../interfaces/journal.interface";
import globalReducer from "./global/global.reducer";

export const store = configureStore({
  reducer: {
    [articlesApi.reducerPath]: articlesApi.reducer,
    articles: articlesReducer,
    [searchApi.reducerPath]: searchApi.reducer,
    search: usersReducer,
    [userApi.reducerPath]: userApi.reducer,
    user: userReducer,
    global: globalReducer,
  },
  // Adding the api middlewares enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      articlesApi.middleware,
      userApi.middleware,
      searchApi.middleware
    ),
});

export const setSearchDatas = (
  users: IUser[],
  journals: IJournal[],
  scrollPosition: number,
  searchMode: string
) => store.dispatch(setSearch({ users, scrollPosition, searchMode }));

export const setSuggestedArticlesDatas = (articles: IArticle[]) =>
  store.dispatch(setSuggestedArticles(articles));

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
