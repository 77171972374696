import React, { Component } from "react"
import {
	CustomContainer,
	CustomTextarea,
	Spacer
} from "../../components/global"
import styled from "styled-components"
import CustomSelect from "../../components/customSelect"
import { WhiteSpace, Toast } from "antd-mobile"
import {
	getProfessionsList,
	getProfessionSpecialization
} from "../../services/professions.services"
import { getUser, patchUser } from "../../services/user.services"
import Loader from "../../components/Loader"
import disableScroll from "disable-scroll"
import EditProfileBar from "../../components/EditProfileBar"
import { withTranslation } from "react-i18next"
import customToast from "../../components/CustomToast"
import { connect } from "react-redux"
import { userApi } from "../../redux/user/user.service"
import { CustomInput } from "../../components/CustomInput"
import { getFirebaseToken } from "../../services/firebase"

class EditBioPage extends Component {
	state = {
		userInfos: {},
		loading: true,
		professions: [],
		specialities: [],
		subspecialities: [],
		firstname: "",
		lastname: "",
		username: "",
		userProfession: "",
		userSpeciality: "",
		userSubspeciality: [],
		isEdited: false
	}

	async componentDidMount() {
		window.scrollTo(0, 0)
		disableScroll.off() // Disable Scroll

		const isAuthentified = await getFirebaseToken()
		if (isAuthentified === null) {
			// Redirect home if not found
			this.props.history.push("/login")
		}

		getUser().then((res) => {
			// Get User Infos

			var data = res
			this.setState({
				userInfos: res,
				isLogged: true,
				firstname: res.firstname,
				lastname: res.lastname,
				username: res.username ?? res.firstname,
				userProfession: res.profession ?? ""
			})

			// Get profession List
			getProfessionsList().then((res) => {
				this.setState({ professions: res })

				// Get speciality list based on current profession
				getProfessionSpecialization(data.profession).then((res) => {
					this.setState({
						specialities: res,
						subspecialities: res[data.medical_specialty],
						userSpeciality: data.medical_specialty ?? "",
						userSubspeciality: data.medical_subspecialty ?? "",
						loading: false
					})
				})
			})
		})
	}

	handleChangeProfession(e) {
		this.setState({ userProfession: e.target.value })
		getProfessionSpecialization(e.target.value).then((res) => {
			this.setState({
				specialities: res,
				subspecialities: [],
				userSpeciality: Object.keys(res)[0] ?? "",
				userSubspeciality: []
			})
		})

		this.setState({ isEdited: true })
	}

	handleChangeSubprofession(e) {
		var mySubprofession = e.target.value
		this.setState({
			subspecialities: this.state.specialities[mySubprofession],
			userSpeciality: mySubprofession
		})

		this.setState({ isEdited: true })
	}

	handleChangeSpeciality(e) {
		this.setState({ userSubspeciality: e.target.value })
		this.setState({ isEdited: true })
	}

	async handleSubmit(event) {
		event.preventDefault()

		const {
			userProfession,
			userSpeciality,
			userSubspeciality,
			firstname,
			lastname,
			username,
			bio
		} = this.state

		var data = {
			firstname: firstname,
			lastname: lastname,
			username: username,
			profession: userProfession,
			medical_specialty: userSpeciality,
			medical_subspecialty: userSubspeciality,
			bio: bio
		}

		patchUser(data).then((res) => {
			if (res.status === 404) {
				this.setState({ errorMessage: res.message })
			} else if (res.status === 500) {
				this.setState({ errorMessage: res.message })
			} else {
				customToast(this.props.t("toast.success.updatedProfile"))
				this.props.getUser().then(() => this.props.history.goBack())
			}
		})
	}

	render() {
		const { professions, specialities, userInfos, loading, isEdited } =
			this.state

		const { t } = this.props

		return (
			<>
				<form onSubmit={this.handleSubmit.bind(this)} action="#">
					<EditProfileBar
						{...this.props}
						title={t("profile.editProfile.title")}
						isEdited={isEdited}
						save
					/>
					{loading ? (
						<Loader />
					) : (
						<CustomContainer
							style={{ padding: "10px 30px 100px 30px" }}
						>
							<Spacer height="20px" />
							<CustomInputLabel for="firstname">
								{t("signup.firstname")}
							</CustomInputLabel>
							<CustomInput
								defaultValue={userInfos.firstname}
								placeholder={t("signup.firstname")}
								name="firstname"
								onInput={(e) =>
									this.setState({
										firstname: e.target.value,
										isEdited: true
									})
								}
								required
							/>

							<WhiteSpace size="sm" />
							<CustomInputLabel for="lastname">
								{t("signup.lastname")}
							</CustomInputLabel>
							<CustomInput
								defaultValue={userInfos.lastname}
								placeholder={t("signup.lastname")}
								name="lastname"
								onInput={(e) =>
									this.setState({
										lastname: e.target.value,
										isEdited: true
									})
								}
								required
							/>

							<WhiteSpace size="sm" />
							<CustomInputLabel for="username">
								{t("signup.username")}
							</CustomInputLabel>
							<CustomInput
								defaultValue={userInfos.username}
								placeholder={t("signup.username")}
								name="username"
								onInput={(e) =>
									this.setState({
										username: e.target.value,
										isEdited: true
									})
								}
								required
							/>

							<WhiteSpace size="sm" />
							<CustomInputLabel for="profession">
								Profession
							</CustomInputLabel>
							<CustomSelect
								defaultValue={`${userInfos.profession}`}
								options={professions}
								label="Profession"
								name="profession"
								onChange={(e) => this.handleChangeProfession(e)}
								required
							/>
							<WhiteSpace size="lg" />

							{this.state.userProfession === "Researcher" ? (
								<CustomInput
									defaultValue={userInfos.medical_specialty}
									placeholder={t("signup.specialty")}
									name="speciality"
									onInput={(e) => {
										this.handleChangeSubprofession(e)
										this.setState({ userSubspeciality: "" })
									}}
								/>
							) : (
								<>
									<CustomInputLabel for="spe">
										{t("signup.specialty")}
									</CustomInputLabel>
									<div
										style={{
											display:
												specialities.length === 0
													? "none"
													: "block"
										}}
									>
										<CustomSelect
											defaultValue={`${userInfos.medical_specialty}`}
											options={Object.keys(specialities)}
											label={t("signup.specialty")}
											name="speciality"
											onChange={(e) =>
												this.handleChangeSubprofession(
													e
												)
											}
											required
										/>
										<WhiteSpace size="lg" />
									</div>
								</>
							)}

							<CustomInputLabel for="bio">
								{t("profile.editProfile.biography")}
							</CustomInputLabel>
							<CustomTextarea
								rows="5"
								defaultValue={userInfos.bio}
								placeholder={t("profile.editProfile.biography")}
								name="bio"
								onInput={(e) => {
									this.setState({
										bio: e.target.value,
										isEdited: true
									})
								}}
								required={false}
							/>
						</CustomContainer>
					)}
				</form>
			</>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(EditBioPage))

const CustomInputLabel = styled.div`
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 900;
	font-size: 21px;
	line-height: 30px;
	letter-spacing: 0.02em;
	color: #212121;
	margin-bottom: 12px;
`
