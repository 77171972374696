import React, { Component } from "react";
import { Col, Row } from "react-grid-system";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { renderPublicationDate } from "../../tools/utils";

const CIRCLES_BACKGROUND = ["#FAE6F0", "#FFF9E6"];

class PlaylistPost extends Component {
  render() {
    const {
      title,
      meta,
      company,
      medical_specialties,
      _id,
      user,
      sponsored,
      playlist,
      metrics,
    } = this.props.playlist;

    const contentLanguage = localStorage.getItem("contentLanguage");
    const numberJournals =
      metrics?.journals ??
      Array.from(new Set(playlist?.map((article) => article?.journal)))
        ?.length ??
      0;
    const numberArticles = metrics?.articles ?? playlist?.length ?? 0;

    return (
      <StyledWrapper
        onClick={() => {
          this.props.onClick();
          setTimeout(() => {
            this.props.history.push({
              pathname: sponsored
                ? `/playlist/public/${_id}`
                : `/profile/user/${user.uid}/playlist/${_id}`,
              state: {
                playlist: this.props.playlist,
              },
            });
          }, 100);
        }}
      >
        <div className='prefix' />
        <div className='content-card'>
          <div className='title'>{title ?? ""}</div>
          <Row>
            <Col xs={3 + 1 / 2}>
              <div className='subtitle'>
                {renderPublicationDate(meta?.creationDate)}
              </div>
              {medical_specialties &&
                medical_specialties?.slice(0, 2).map(
                  (specialty, index) =>
                    specialty && (
                      <div
                        key={"--playlist-post-medical-spe" + index}
                        className={`subtitle bold ${
                          index > 0 ? "specialty" : ""
                        }`}
                      >
                        {specialty?.translations[contentLanguage]}
                      </div>
                    )
                )}
            </Col>

            <Col xs={3}>
              <div className='subtitle'>
                {contentLanguage === "en" ? "Created by" : "Créée par"}
              </div>
              <div className='subtitle bold'>
                {sponsored ? company?.name : user?.name}
              </div>
            </Col>
            <Col xs={3 - 1 / 2}>
              <div className='subtitle center'>Articles</div>
              <div
                className='circle-count'
                style={{
                  background: CIRCLES_BACKGROUND[0],
                }}
              >
                {numberArticles}
              </div>
            </Col>
            <Col xs={3}>
              <div className='subtitle center'>
                {contentLanguage === "fr" ? "Journaux" : "Journals"}
              </div>
              <div
                className='circle-count'
                style={{
                  background: CIRCLES_BACKGROUND[1],
                }}
              >
                {numberJournals}
              </div>
            </Col>
          </Row>
        </div>
      </StyledWrapper>
    );
  }
}

export default withRouter(PlaylistPost);

const StyledWrapper = styled.div`
  display: flex;
  margin: 8px 0;
  gap: 12px;

  .content-card {
    background: #fff;
    box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
    border-radius: 15px;
    margin: 0;
    padding: 24px 16px;
    box-sizing: border-box;
    width: 100%;
  }

  .prefix {
    background: linear-gradient(
      199.25deg,
      #d2dce2 0%,
      #ecf0f5 67.31%,
      #fcc408 133.77%
    );
    box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
    border-radius: 20px;
    width: 24px;
    height: auto;
  }

  .tag {
    background: #fff;
    color: #212121;
    border-radius: 10px;
    font-size: 10px;
    font-weight: 400;
    font-family: "Poppins";
    display: table;
    padding: 3px 8px;
    box-sizing: border-box;
    margin: 5px 0 0;
  }

  .circle-count {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #212121;
    box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
    border-radius: 50%;
    background-color: #0276fd;
    color: black;
    background-color: #ffffff;
    height: 30px;
    width: 30px;
    text-align: center;
    margin: 5px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #212121;
    margin: 0 0 24px;
  }

  .subtitle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 140%;
    color: #212121;
    margin: 3px 0 0;

    &.center {
      text-align: center;
    }

    &.specialty {
      margin: 0;
    }

    &.bold {
      font-weight: 700;
    }
  }
`;
