import axios from "axios";
import { API_URL } from "../config";
import { catchError, handleError } from "./response";
import { getApiRequestService } from "./index.services";
import { SupportedLanguagesEnum } from "../interfaces";

export const getContentCongress = async (params: {
  limit?: number;
  offset?: number;
  language?: SupportedLanguagesEnum;
  congress?: string;
  medical_specialties?: string[];
  tags?: string[];
}) => {
  try {
    const apiInstance = await getApiRequestService();

    const res = await apiInstance.get("/content/congress", {
      params,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getContentById = async (id: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/content/${id}`);
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getContentFromSlug = async (slug: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/content/slug/${slug}`);
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const incrementShareInfographic = async (slug: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.patch(`/content/slug/share/${slug}`);
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};
