import React, { Component } from "react"
import styled from "styled-components"
import { CustomContainer, Spacer } from "../../components/global"
import { CustomInput } from "../../components/CustomInput"
import Loader from "../../components/Loader"
import disableScroll from "disable-scroll"
import EditProfileBar from "../../components/EditProfileBar"
import CustomIcon from "../../components/CustomIcon"
import {
	addPublishedArticle,
	deletePublishedArticle,
	getUser
} from "../../services/user.services"
import { getArticlesList } from "../../services/articles.services"
import Fuse from "fuse.js"
import ProfilePost from "../../components/ProfilePost"
import { withTranslation } from "react-i18next"
import customToast from "../../components/CustomToast"
import { connect } from "react-redux"
import { userApi } from "../../redux/user/user.service"
import { getFirebaseToken } from "../../services/firebase"
import PreviewCard from "../../components/PreviewCard"

class EditPublishedArticles extends Component {
	state = {
		constArticles: [],
		deletingArticles: [],
		articlesLoaded: false,
		userLoaded: false,
		searchValue: ""
	}

	async componentDidMount() {
		window.scrollTo(0, 0)

		disableScroll.off() // Disable Scroll

		const isAuthentified = await getFirebaseToken()
		if (!isAuthentified) {
			this.props.history.push("/login")
		} else {
			getUser().then((user) => {
				this.setState({ user: user, userLoaded: true })
			})

			getArticlesList(0, "", 1000).then((articles) => {
				this.setState({ constArticles: articles, articlesLoaded: true })
			})
		}
	}

	async handleSubmit(event) {
		event.preventDefault()
		if (this.state.isEdited) {
			customToast(this.props.t("toast.success.editedPaper"))
		}
		await this.props.getUser()
		this.props.history.goBack()
	}

	handleDelete(article) {
		deletePublishedArticle(article?.slug).then((_) => {
			getUser().then((user) => {
				this.setState({ user: user, userLoaded: true, isEdited: true })
			})
			customToast(this.props.t("toast.success.deletedArticle"))
		})
	}

	searchArticles() {
		const articles = this.state.constArticles
		const { user } = this.state

		const fuse = new Fuse(articles, {
			keys: [
				"title",
				"conclusion",
				["medical_specialties"],
				"primary_author",
				["authors"],
				"keywords"
			],
			includeScore: true,
			findAllMatches: true,
			threshold: 0.2
		})

		const results = fuse.search(this.state.searchValue)
		const articlesResults = []

		results.map((result) => {
			const fullName = user?.firstname + " " + user?.lastname
			const authors = result?.item?.authors
			const primary_author = result?.item?.primary_author

			if (
				primary_author.toLocaleLowerCase() ===
				fullName.toLocaleLowerCase()
			) {
				if (user?.published_articles.length < 1) {
					articlesResults.push(result.item)
				} else {
					user?.published_articles.map((p_article) => {
						articlesResults.push(result.item)

						if (result.item.slug !== p_article?.slug) {
							articlesResults.push(result.item)
						}
					})
				}
			} else {
				authors.map((author) => {
					if (
						author.toLocaleLowerCase() ===
						fullName.toLocaleLowerCase()
					) {
						if (user?.published_articles.length < 1) {
							articlesResults.push(result.item)
						} else {
							user?.published_articles.map((p_article) => {
								if (result.item.slug !== p_article?.slug) {
									articlesResults.push(result.item)
								}
							})
						}
					}
				})
			}
			return articlesResults
		})

		if (articlesResults.length > 0) {
			return articlesResults.map((article, index) => {
				if (article !== undefined) {
					return (
						<PreviewCard
							key={article._id}
							content={article}
							onClick={() => {
								addPublishedArticle(article?.slug).then((_) => {
									getUser().then((user) => {
										this.setState({
											user: user,
											userLoaded: true,
											isEdited: true
										})
									})
									customToast(
										this.props.t(
											"toast.success.addedArticle"
										),
										"success"
									)
								})

								this.setState({ searchValue: "" })
							}}
						/>
					)
				}
			})
		} else if (this.state.searchValue.length > 0) {
			return <p>No articles found for this authors</p>
		}
	}

	render() {
		const { user, userLoaded, articlesLoaded } = this.state
		const { t } = this.props

		return (
			<>
				<form onSubmit={this.handleSubmit.bind(this)} action="#">
					<EditProfileBar
						{...this.props}
						title={t("profile.editProfile.editPapers.title")}
						save
					/>
					{!userLoaded && !articlesLoaded ? (
						<Loader />
					) : (
						<CustomContainer
							style={{ padding: "10px 30px 100px 30px" }}
						>
							<Spacer height="20px" />
							<CustomInputLabel>
								{t("profile.editProfile.editPapers.search")}
							</CustomInputLabel>

							<div style={{ position: "relative" }}>
								<div
									style={{
										position: "absolute",
										left: 15,
										top: 7,
										transform: "scale(0.7)"
									}}
								>
									<CustomIcon
										iconName="search"
										color="#90A4AE"
									/>
								</div>

								<CustomInput
									onKeyPress={(e) => {
										e.key === "Enter" && e.preventDefault()
									}}
									required={false}
									name="workspace"
									style={{ paddingLeft: 40, margin: 0 }}
									placeholder={t(
										"profile.editProfile.editPapers.search"
									)}
									onInput={(e) =>
										this.setState({
											searchValue: e.target.value
										})
									}
								/>
							</div>
							{this.searchArticles()}
							<Spacer />
							<CustomInputLabel>
								{t("profile.editProfile.editPapers.me")}
							</CustomInputLabel>
							{!user?.published_articles.length ? (
								<p style={{ fontFamily: "Poppins" }}>
									{t("profile.editProfile.editPapers.empty")}
								</p>
							) : (
								user?.published_articles?.map((article) => {
									return (
										<PreviewCard
											key={article._id}
											content={article}
											onRemove={() =>
												this.handleDelete(article)
											}
										/>
									)
								})
							)}
						</CustomContainer>
					)}
				</form>
			</>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(EditPublishedArticles))

const CustomInputLabel = styled.div`
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 900;
	font-size: 21px;
	line-height: 30px;
	letter-spacing: 0.02em;
	color: #212121;
	margin-bottom: 12px;
`
