import { Component } from "react"
import styled from "styled-components"
import { Row, Col } from "react-grid-system"
import BackIcon from "../assets/icons/back-orange.svg"
import disableScroll from "disable-scroll"
import { Link } from "react-router-dom"
import CustomIcon from "./CustomIcon"
import { Spacer, ShareProfileText } from "./global"
import { iosSetBackgroundColor, isNativeIOS } from "../tools/ios"
import { getListPendingFollowers, getUser } from "../services/user.services"
import { getNotifications } from "../services/notifications.services"
import { withTranslation } from "react-i18next"
import { socketWrapper } from "../view/messagePage/socketWrapper"
import customToast from "./CustomToast"
import { gtmPageView } from "../tools/reactgaEvents"
import { BottomSheet } from "react-spring-bottom-sheet"
import { t } from "i18next"
import i18n from "../config/i18n"
import ShareSheet from "./ShareSheet"
import { getFirebaseToken } from "../services/firebase"
import { store } from "../redux"

class ProfileBar extends Component {
	state = {
		showSheet: false,
		nb_msg: 0,
		countNewMsg: 0,
		has_notif: false,
		authUser: null
	}

	async componentDidMount() {
		try {
			const isAuthenticated = await getFirebaseToken()

			const { socket, unreadConversations, refreshConversations } =
				this.props.socketHook
			refreshConversations()

			// SCROLL TO TOP
			window.scrollTo(0, 0)

			// IOS BACKGROUND COLOR
			if (isNativeIOS) {
				iosSetBackgroundColor("#FFFFFF")
			}

			// Getting all messages and requests if connected...
			if (!!isAuthenticated) {
				i18n.changeLanguage(this.props?.user?.language)
				// Init page_view event...
				gtmPageView(this.props?.user?.user?._id)

				getListPendingFollowers().then((p_followers) => {
					this.setState({ nb_msg: p_followers?.length })
				})

				const authUser = await getUser()
				this.setState({ authUser })

				this.fetchNotifications()
			} else {
				gtmPageView(undefined, this.props.match.url)
			}

			// Socket...
			this.setState({ countNewMsg: unreadConversations })

			// Detecting if new message has come to refresh our newMsgCount...
			socket?.on("newMessage", () => {
				refreshConversations()
				this.fetchNotifications()
			})
		} catch (error) {
			console.error("Err ProfileBar didMount", error.message)
			customToast(t("toast.error.global"))
			this.props.history.replace("/home")
		}
	}

	componentDidUpdate() {
		if (this.state.showSheet) disableScroll.on()
		else disableScroll.off()
	}

	fetchNotifications = async () => {
		try {
			const res = await getNotifications()
			const notifications = res?.some((v) => !v?.deleted && !v?.read)
			this.setState({ has_notif: notifications.length > 0 })
		} catch (error) {
			console.error("err fetchNotifications", error)
			throw error
		}
	}

	async createConversation() {
		const { followedByMe, isFollower } = this.props
		const other_uid = this.props.match.params.id

		const { socket, refreshConversations, createConversation } =
			this.props.socketHook

		const canCommunicate = followedByMe || isFollower
		const followingPending =
			this.props.following?.pending?.find(
				(el) => el?.following.uid === other_uid
			) !== undefined
		const followerPending =
			this.props.followers?.pending?.find(
				(el) => el?.follower?.uid === other_uid
			) !== undefined

		// TEST
		// socket.onAny((eventName, ...args) => {
		//   console.log("socket onAny", { eventName }, ...args);
		// });
		//

		if (canCommunicate) {
			if (!socket.connected) {
				socket.connect()
			}

			// Rafraîchir les conversations
			refreshConversations()

			let secondAttemptDone = false
			let destination = null

			// Récupérer les conversations
			socket.on("allConversations", (conversations) => {
				destination = conversations?.find((conversation) =>
					conversation.users?.find((user) => user?.uid === other_uid)
				)

				if (!destination) {
					if (!secondAttemptDone) {
						const users = [this.state.authUser.uid, other_uid]
						this.props.socketHook.createConversation(users)

						refreshConversations()
						secondAttemptDone = true
						console.log("SECOND ATTEMPT: CREATE CONV")
					} else {
						console.log("conversation creation failed...")
					}
				} else {
					console.log("PASSED", window.location.pathname)
					if (window.location.pathname !== "/profile") {
						this.props.history.push(
							`/profile/messages/conversation/${destination?._id}`
						)
					}
				}
			})
		} else if (followingPending) {
			this.props.t("toast.error.followingPending")
		} else if (followerPending) {
			this.props.t("toast.error.followerPending")
		} else {
			this.setState({ showSheet: false })
			customToast(this.props.t("toast.error.needFollow"))
		}
	}

	handleGoBack() {
		const { search } = this.props.history.location

		if (search.includes("?shared")) {
			this.props.history.replace("/home")
		} else if (localStorage.getItem("wasShared")) {
			this.props.history.replace("/home")
			localStorage.removeItem("wasShared")
		} else if (window.location.pathname === "/profile") {
			this.props.history.replace("/home")
		} else if (search.includes("?search")) {
			this.props.history.replace("/search")
		} else if (!!this.props.location.key) {
			this.props.history.goBack()
		} else {
			this.props.history.replace("/home")
		}
	}

	// Share profile
	shareProfile() {
		const { user } = this.props
		const { authUser } = this.state

		if (navigator.share) {
			navigator
				.share({
					title: document.title,
					text: ShareProfileText(this.props.me.fullname),
					url: `/profile/user/${user?.uid}?shared=${
						authUser.uid ?? "null"
					}`
				})
				.then(() => {
					customToast(
						this.props.t("toast.success.profileShared"),
						"success"
					)
				})
				.catch(() => {})
		} else {
			customToast(this.props.t("toast.error.browser"))
		}
	}

	render() {
		const { isMe, t } = this.props
		const { showSheet, nb_msg, has_notif } = this.state
		return (
			<>
				<BarWrapper>
					<Row justify="between" align="center">
						<Col xs={2} onClick={() => this.handleGoBack()}>
							<StyledIcons src={BackIcon} alt="" />
						</Col>
						<Col></Col>
						{isMe ? (
							<Col xs={4}>
								<Row justify="around">
									<Link to="/settings">
										<CustomIcon
											iconName="gear"
											color="black"
										/>
									</Link>
									<Link to="/profile/notifications">
										{has_notif ? (
											<Notif>
												<CustomIcon
													iconName="bell"
													color={
														window.location
															.pathname ==
														"/profile/notifications"
															? "#FFC408"
															: "black"
													}
												/>
											</Notif>
										) : (
											<CustomIcon
												iconName="bell"
												color={
													window.location.pathname ==
													"/profile/notifications"
														? "#FFC408"
														: "black"
												}
											/>
										)}
									</Link>

									<Link to="/profile/messages">
										{nb_msg > 0 ||
										this.props.socketHook
											.unreadConversations > 0 ? (
											<Badge
												count={
													nb_msg +
													this.props.socketHook
														.unreadConversations
												}
											>
												<CustomIcon
													iconName="message"
													color="black"
												/>
											</Badge>
										) : (
											<CustomIcon
												iconName="message"
												color="black"
											/>
										)}
									</Link>
								</Row>
							</Col>
						) : (
							<Col
								xs={2}
								onClick={() =>
									this.setState({ showSheet: true })
								}
							>
								<CustomIcon iconName="share_playlist" />
							</Col>
						)}
					</Row>
				</BarWrapper>
				{/* If it is shared... */}
				<ShareSheet type="profile" />
				<BottomSheet
					expandOnContentDrag
					snapPoints={() => [300, 500]}
					open={showSheet}
					onDismiss={() =>
						setTimeout(
							() => this.setState({ showSheet: false }),
							100
						)
					}
				>
					<SheetWrapper isNative={isNativeIOS}>
						<Row
							style={{ margin: 0 }}
							align="center"
							gutterWidth={45}
							onClick={this.shareProfile.bind(this)}
						>
							<Col xs={1}>
								<CustomIcon
									iconName="share-alt"
									scale={0.8}
									color="#212121"
								/>
							</Col>
							<Col xs={10}>
								<p
									style={{
										fontSize: "17px",
										fontWeight: 600,
										margin: 0
									}}
								>
									{t("sheet.profile.share.title")}
								</p>
								<p
									style={{
										fontSize: "15px",
										fontWeight: 400,
										margin: 0
									}}
								>
									{t("sheet.profile.share.subtitle")}
								</p>
							</Col>
						</Row>
						<Spacer height="30px" />
						{!!store.getState().user?.user && (
							<Row
								style={{ margin: 0 }}
								align="center"
								gutterWidth={45}
								onClick={() => this.createConversation()}
							>
								<Col xs={1}>
									<CustomIcon
										iconName="message"
										color="#212121"
									/>
								</Col>
								<Col xs={10}>
									<p
										style={{
											fontSize: "17px",
											fontWeight: 600,
											margin: 0
										}}
									>
										{t("sheet.profile.message.title")}
									</p>
									<p
										style={{
											fontSize: "15px",
											fontWeight: 400,
											margin: 0
										}}
									>
										{t("sheet.profile.message.subtitle")}
									</p>
								</Col>
							</Row>
						)}
						{isNativeIOS && <Spacer height="100px" />}
					</SheetWrapper>
				</BottomSheet>
			</>
		)
	}
}

export default socketWrapper(withTranslation()(ProfileBar))

export const Badge = (props) => {
	return (
		<>
			<div style={{ position: "relative" }}>
				<span
					style={{
						position: "absolute",
						top: -6,
						right: -6,
						color: "#212121",
						background: "#FFC408",
						fontSize: "9px",
						fontWeight: 700,
						fontFamily: "Poppins",
						borderRadius: "50%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "14px",
						height: "14px",
						zIndex: 10
					}}
				>
					{props.count > 99 ? 99 : props.count}
				</span>
				{props.children}
			</div>
		</>
	)
}

export const Notif = (props) => {
	return (
		<>
			<div style={{ position: "relative" }}>
				<span
					style={{
						position: "absolute",
						top: 0,
						right: -4,
						color: "#212121",
						background: "#FFC408",
						fontSize: "9px",
						fontWeight: 700,
						fontFamily: "Poppins",
						borderRadius: "50%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "6px",
						height: "6px"
					}}
				>
					{props.count > 99 ? 99 : props.count}
				</span>
				{props.children}
			</div>
		</>
	)
}

const BarWrapper = styled.div`
	background: white;
	padding: 10px 22px;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
`

const StyledIcons = styled.img`
	height: 32px;
	width: auto;
`

const SheetWrapper = styled.div`
	padding: 55px 5px;
	min-height: ${window.innerHeight / 8}px;
	padding-bottom: ${(props) => props.isNative && "60px"};
`
