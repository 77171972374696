import { Component } from "react"
import styled from "styled-components"
import { Link, withRouter } from "react-router-dom"
import { Row } from "react-grid-system"
import CustomNavBar from "../../components/CustomNavbar"
import { Checkbox, NoticeBar } from "antd-mobile"
import {
	CustomContainer,
	CustomButton,
	Divider,
	Spacer
} from "../../components/global"
import { CustomInput } from "../../components/CustomInput"
import {
	auth,
	getFirebaseToken,
	loginEmailPassword,
	loginWithApple,
	loginWithGoogle
} from "../../services/firebase"
import disableScroll from "disable-scroll"
import { withTranslation } from "react-i18next"
import CustomIcon from "../../components/CustomIcon"

const AgreeItem = Checkbox.AgreeItem

class LoginPage extends Component {
	state = {
		showPassword: false,
		rememberMe: false,
		email: "",
		password: "",
		errorMessage: "",
		btnLoading: false,
		loadingApple: false,
		loadingGoogle: false
	}

	async componentDidMount() {
		disableScroll.off()
		window.scrollTo(0, 0)

		// NOTE: Remove legacy field. Do NOT delete this line.
		localStorage.removeItem("authData")

		const isAuthentified = await getFirebaseToken()

		// If user connected, redirect to homepage
		if (!!isAuthentified) {
			this.props.history.replace("/home")
		}

		// Detect if remembered user...
		const rememberEmail = localStorage.getItem("rememberEmail")
		if (rememberEmail)
			this.setState({ email: rememberEmail, rememberMe: true })

		if (this.props.location.state?.previousPath === "/search")
			localStorage.setItem("_last_search", true)
	}

	componentWillUnmount() {
		localStorage.removeItem("_cache_notification")
	}

	async handleSubmit(e) {
		e.preventDefault()

		const { email, password, rememberMe } = this.state

		this.setState({ errorMessage: "", btnLoading: true })
		const isLogin = await loginEmailPassword(email, password)

		if (!isLogin)
			this.setState({
				btnLoading: false
			})

		if (rememberMe) {
			localStorage.setItem("rememberEmail", email)
		} else {
			localStorage.removeItem("rememberEmail")
		}
	}

	async handleOAuth(provider) {
		if (provider === "apple") {
			this.setState({ loadingApple: true })
			await loginWithApple()
		} else if (provider === "google") {
			this.setState({ loadingGoogle: true })
			loginWithGoogle()
		}

		setTimeout(() => {
			this.setState({ loadingApple: false, loadingGoogle: false })
		}, 10000)
	}

	render() {
		const { showPassword, rememberMe, errorMessage } = this.state

		return (
			<>
				<CustomNavBar i18n={this.props.i18n} />

				<PageContainer
					style={{
						display: "block",
						background: "#FFC408",
						padding: "0 40px",
						paddingTop: 60,
						height: window.innerHeight,
						boxSizing: "border-box"
					}}
				>
					{/* Error message on login */}
					{errorMessage !== "" && (
						<NoticeBar
							style={{ background: "#CE0868", color: "white" }}
							mode="closable"
							icon={null}
							onClick={() => this.setState({ errorMessage: "" })}
						>
							{errorMessage}
						</NoticeBar>
					)}
					{/* Title & Email input */}
					<form
						onSubmit={(e) => {
							e.preventDefault()
							this.handleSubmit(e)
						}}
					>
						<div style={{ marginTop: "3vh" }}>&nbsp;</div>

						<Row justify="center" align="center">
							<LoginTitle>
								{this.props.t("login.title1")}
							</LoginTitle>

							<div
								style={{
									position: "relative",
									width: "100%",
									marginRight: -10,
									padding: 0
								}}
							>
								<CustomInput
									placeholder={this.props.t("login.email")}
									type="email"
									onInput={(e) =>
										this.setState({ email: e.target.value })
									}
									required
									value={this.state.email}
									style={{ margin: 0 }}
								/>
								<div
									onClick={() => this.setState({ email: "" })}
									style={{
										position: "absolute",
										display: this.state.email.length
											? "block"
											: "none",
										right: 25,
										top: 26,
										transform: "scale(2)"
									}}
								>
									<CustomIcon iconName="cross_circle" />
								</div>
							</div>
						</Row>

						{/* Show/Hide button */}
						<ShowHideButton
							onClick={() =>
								this.setState({ showPassword: !showPassword })
							}
						>
							<Row justify="end" align="center">
								<p>
									{showPassword
										? this.props.t("login.hide")
										: this.props.t("login.show")}
								</p>
							</Row>
						</ShowHideButton>

						{/* Password Input */}
						<Row justify="center" align="center">
							<div
								style={{
									position: "relative",
									width: "100%",
									marginRight: -10,
									padding: 0
								}}
							>
								<CustomInput
									type={showPassword ? "text" : "password"}
									placeholder={this.props.t("login.password")}
									onInput={(e) =>
										this.setState({
											password: e.target.value
										})
									}
									style={{ marginBottom: "20px" }}
									value={this.state.password}
									required
								/>
								<div
									onClick={() =>
										this.setState({ password: "" })
									}
									style={{
										position: "absolute",
										display: this.state.password.length
											? "block"
											: "none",
										right: 25,
										top: 26,
										transform: "scale(2)"
									}}
								>
									<CustomIcon iconName="cross_circle" />
								</div>
							</div>
						</Row>

						{/* Agree checkbox & Forget password */}
						<Row justify="between" align="center">
							<AgreeItem
								className="login-checkbox"
								data-seed="logId"
								checked={rememberMe}
								onChange={() =>
									this.setState({ rememberMe: !rememberMe })
								}
							>
								<TermsLink>
									{this.props.t("login.rememberMe")}
								</TermsLink>
							</AgreeItem>

							<ForgetLink to="/login/reset">
								{this.props.t("login.forgotPassword")}
							</ForgetLink>
						</Row>

						<Spacer height="30px" />
						{/* Login button */}
						<Row justify="center" align="center">
							<CustomButton
								className={
									this.state.btnLoading
										? "loading disabled"
										: "pink"
								}
								type="submit"
							>
								{this.props.t("login.login")}
							</CustomButton>
						</Row>
						<Divider />
						{/* No account text */}
						<center>
							<p
								style={{
									fontSize: "15px",
									textAlign: "center",
									marginBottom: 0
								}}
							>
								{this.props.t("login.noAccount")}
							</p>
							<Spacer height="5px" />
							<ForgetLink
								to={{
									pathname: "/signup/prev",
									state: {
										email: ""
									}
								}}
								style={{
									fontSize: "15px",
									fontWeight: "800",
									fontFamily: "Poppins"
								}}
							>
								{this.props.t("login.registerNow")}
							</ForgetLink>
						</center>
					</form>
					<p className="credits">
						{this.props.t("login.credits.byCreating")}{" "}
						<Link
							className="link"
							to={{
								pathname: "/terms",
								state: {
									isPush: true,
									hideTitle: true
								}
							}}
						>
							{this.props.t("settings.terms")}
						</Link>{" "}
						{this.props.t("login.credits.and")}{" "}
						<Link
							className="link"
							to={{
								pathname: "/policy",
								state: {
									isPush: true,
									hideTitle: true
								}
							}}
						>
							{this.props.t("settings.policies")}
						</Link>
						.
					</p>
				</PageContainer>
			</>
		)
	}
}

export default withTranslation()(withRouter(LoginPage))

const PageContainer = styled(CustomContainer)`
	min-height: 100vh;
	height: 100%;
	box-sizing: border-box;

	.credits {
		font-family: "Roboto" !important;
		font-style: normal;
		font-weight: 600;
		font-size: 13px;
		line-height: 140%;
		text-align: center;
		color: #212121;
		padding: 0 30px;
		margin-top: 50px;

		.link {
			color: #ce0868;
			text-decoration: underline;
		}
	}
`

const ForgetLink = styled(Link)`
	font-size: 13px;
	color: #ce0868;
	margin: 0;
	font-weight: 600;
	font-family: Roboto;
	text-decoration: underline;
	margin-top: 5px;
`

const LoginTitle = styled.div`
	color: #212121;
	font-size: 17px;
	line-height: 24px;
	font-weight: 600;
	text-align: center;
	margin-bottom: 30px;
	font-family: "Poppins";
`

const LoginSubtitle = styled.div`
	color: #212121;
	font-size: 14px;
	line-height: 19px;
	font-weight: 500;
	text-align: center;
	margin-bottom: 15px;
`

const ShowHideButton = styled.div`
	p {
		margin-right: 5px;
		color: #212121;
		font-size: 12px;
		font-weight: 700;
		text-transform: capitalize;
	}
`

const TermsLink = styled.p`
	font-size: 13px;
	color: #212121;
	font-weight: 600;
	margin: 0;
	margin-top: 3px;
	margin-left: 0px;
	font-family: Roboto !important;
`
