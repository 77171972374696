import { IArticle } from "./article.interface";
import { IUser } from "./user.interface";

export interface IImage {
  path: string;
  url: string;
  legend?: string;
  _id: string;
}

export interface IMeta {
  creationDate: Date;
  lastModified: Date;
  status: "draft" | "published";
}

export interface IMetric {
  favorites: number;
  shares: number;
}

export interface IComment {
  article: IArticle;
  message: string;
  user: IUser;
  meta: {
    creationDate: Date;
    lastModified: Date;
  };
}

export enum SupportedLanguagesEnum {
  EN = "en",
  FR = "fr",
  IT = "it",
  ES = "es",
  DE = "de",
}

export enum OrganisationUid {
  JUISCI = "juisci",
  BIOCODEX = "biocodex-rd",
  SFRO = "sfro",
  SFR = "sfr",
  ROYAL_CANIN = "royal-canin",
  SPILF = "spilf",
  MGEN = "mgen",
  OPELLA = "opella-body-pain",
  ESR = "esr",
  TAKEDA = "takeda-lymphoma-app",
  UCB = "ucb-rare-diseases",
  ORPEA = "orpea-eldery-app",
  SANOFI_AUDIOROOM = "sanoficoughcoldfluaudioroom",
}

export enum SoundEffect {
  LIKE = "like",
  SAVE = "save",
  SHARE = "share",
}

export enum HapticEffect {
  SELECTION = "selection",
  SUCCESS = "success",
  BLENDER = "blender",
  ERROR = "error",
}

export enum PlaylistAction {
  ADD = "add",
  REMOVE = "remove",
  NONE = "none",
}

// Scientific publications, Medical guidelines, Editorial content, Videos, Podcasts,
export enum PreferedContent {
  SCIENTIFIC_PUBLICATIONS = "Scientific publications",
  MEDICAL_GUIDELINES = "Medical guidelines",
  EDITORIAL_CONTENT = "Editorial content",
  VIDEOS = "Videos",
  PODCASTS = "Podcasts",
}

/* For GTM */
export enum ItemsDataEvent {
  VIEW_ITEM = "view_item",
  VIEW_ITEM_LIST = "view_item_list",
  SELECT_ITEM = "select_item",
  ARTICLE_SAVE = "article_save",
  ARTICLE_LIKE = "article_like",
  ARTICLE_SHARE = "article_share",
  AUDIO_PLAYED = "audio_played",
  AUDIO_PROGREESS = "audio_progress",
  AUDIO_COMPLETE = "audio_complete",
  VIEW_ORIGINAL_PAPER = "view_original_paper",
  // TODO
  ORGANISATION_SHARE = "organisation_share",
  ORGANISATION_REDIRECT = "organisation_redirect",
  SET_FILTER = "set_filter",
  VIDEO_PLAYED = "video_played",
  VIDEO_SHARE = "video_share",
  VIDEO_SAVE = "video_save",
  VIDEO_LIKE = "video_like",
  INFOGRAPHIC_SHARE = "infographic_share",
}

export enum ItemDataEventListName {
  HOME = "home",
  FORYOU = "foryou",
  SEARCH = "search",
  FILTERS = "filters",
  DISCOVERY_MAIN_CAOURSEL = "discovery_main_carousel",
  DISCOVERY_SECOND_CAROUSEL = "discovery_second_carousel",
  DISCOVERY_VIDEOS = "discovery_videos",
  DISCOVERY_PLAYLIST = "discovery_playlist",
  ARTICLE_PAGE = "article_page",
  JOURNAL_PAGE = "journal_page",
  PLAYLIST_PAGE = "playlist_page",
  ORGANISATION_PAGE = "organisation_page",
  STORY_LIST_PAGE = "story_list_page",
  VIDEO_ARTICLE_PAGE = "videos_article_page",
  INFOGRAPHIC_PAGE = "infographic_page",
}

export enum ItemDataVariant {
  ARTICLE = "article",
  PLAYLIST = "playlist",
  PODCASTS = "podcasts",
  PROFILE = "profile",
  JOURNALS = "journals",
  ORGANISATION = "organisation",
  VIDEO_STORY = "video_story",
  VIDEO_ARTICLE = "video_article",
  INFOGRAPHIC = "infographic",
}

export enum GTMBasicEvent {
  SEARCH_SECTION_SELECTED = "search_section_selected",
  PROFILE_FOLLOW = "profile_follow",
  BLENDER_EXECUTE = "blender_execute",
  VIEW_PROFILE = "view_profile",
  START_SIGN_UP = "start_sign_up",
  JOURNAL_FOLLOW = "journal_follow",
  SKIP_ONBOARDING = "skip_onboarding",
  COMPLETE_ONBOARDING = "complete_onboarding",
  VIEW_ONBOARDING = "view_onboarding",
  UPDATE_USER_INTERESTS = "update_user_interests",
  AUTH_LOGIN = "login",
  AUTH_SIGNUP = "sign_up",
}
