//
// All Android JS Functions here...
//
import { getCurrentOS } from "./utils";

export const isNativeAndroid =
  getCurrentOS() === "android" && !!window.services;

export const androidRequestPushAuthorization = () => {
  console.log("android_push_auth");
};

export const androidGetFirebaseToken = () => {
  if (isNativeAndroid) {
    const res = JSON.parse(window.services.getFirebaseToken());
    return res.token;
  } else console.error("ERROR_BAD_OS");
};

export const androidClearData = () => {
  if (isNativeAndroid) window.services?.clearData();
  else console.error("ERROR_BAD_OS");
};

export const androidOffline = () => {
  if (isNativeAndroid) window.services?.offline();
  else console.error("ERROR_BAD_OS");
};

export const androidShare = (content, link) => {
  if (isNativeAndroid) window.services.share(link + "\n" + content);
  else console.error("ERROR_BAD_OS");
};

export const androidOpen = (link) => {
  if (isNativeAndroid) window.services.open(link);
  else console.error("ERROR_BAD_OS");
};

export const androidSignIn = async () => {
  if (isNativeAndroid)
    window.services.startSignInFlow("google", "androidSignInCallback");
  else console.error("ERROR_BAD_OS");
};

export const androidSignOut = async () => {
  if (isNativeAndroid) window.services.signOut();
  else console.error("ERROR_BAD_OS");
};

export const androidRequestReview = async () => {
  if (isNativeAndroid) window.services.requestReview();
  else console.error("ERROR_BAD_OS");
};
