import React, { Component } from "react"
import {
	CustomContainer,
	CustomInput,
	SearchInput,
	Spacer
} from "../../components/global"
import styled from "styled-components"
import { Toast } from "antd-mobile"
import Loader from "../../components/Loader"
import disableScroll from "disable-scroll"
import EditProfileBar from "../../components/EditProfileBar"
import CustomIcon from "../../components/CustomIcon"

import PlacesAutocomplete from "react-places-autocomplete"
import { geocodeByAddress, getLatLng } from "react-places-autocomplete"
import { getUser, patchUser } from "../../services/user.services"
import { withTranslation } from "react-i18next"
import customToast from "../../components/CustomToast"
import { connect } from "react-redux"
import { userApi } from "../../redux/user/user.service"
import { getFirebaseToken } from "../../services/firebase"

class EditLocationPage extends Component {
	state = {
		userInfos: {},
		isloading: true,
		searchValue: "",
		location: "",
		coord: {},
		hasSelected: false,
		isEdited: false
	}

	async componentDidMount() {
		window.scrollTo(0, 0)
		disableScroll.on() // Disable Scroll

		const isAuthentified = await getFirebaseToken()

		if (!isAuthentified) {
			// Redirect home if not found
			this.props.history.push("/login")
		}

		getUser().then((user) => {
			const location = user?.location?.location

			var coord = { lng: user?.location?.lon, lat: user?.location?.lat }

			this.setState({
				searchValue: location,
				location,
				coord,
				isloading: false
			})
		})
	}

	handleSubmit = async (event) => {
		event.preventDefault()

		const { location, coord, hasSelected } = this.state

		// Detect if user has selected a correct place...
		if (hasSelected) {
			var place = {
				location,
				lat: coord.lat,
				lon: coord.lng
			}

			var data = {
				location: place
			}

			patchUser(data).then(async () => {
				customToast(
					this.props.t("toast.success.editedLocation"),
					"success"
				)
				await this.props.getUser()
				setTimeout(() => this.props.history.goBack(), 1500)
			})
		} else {
			customToast(this.props.t("toast.error.incorrectPlace"), "error")
		}
	}

	handleSelect = (location) => {
		this.setState({ location, hasSelected: true, isEdited: true })

		geocodeByAddress(location)
			.then((results) => getLatLng(results[0]))
			.then((latLng) => {
				this.setState({ coord: latLng })
			})
			.catch((error) => console.error("Error", error))
	}

	render() {
		const { isloading, location, searchValue, isEdited } = this.state
		const { t } = this.props
		return (
			<>
				<form onSubmit={this.handleSubmit.bind(this)} action="#">
					<EditProfileBar
						{...this.props}
						title={t("profile.editProfile.editLocation.title")}
						save
						isEdited={isEdited}
					/>
					{isloading ? (
						<Loader />
					) : (
						<CustomContainer
							style={{ padding: "10px 45px 100px 30px" }}
						>
							<Spacer height="20px" />
							<CustomInputLabel for="firstname">
								{t("profile.editProfile.workplace")}
							</CustomInputLabel>
							{location?.length > 0 && (
								<>
									<CurrentLocation>
										{location}
									</CurrentLocation>
									<Spacer />
								</>
							)}

							<PlacesAutocomplete
								value={searchValue}
								onChange={(value) => {
									this.setState({
										searchValue: value,
										hasSelected: false,
										isEdited: true
									})
								}}
								onSelect={this.handleSelect}
							>
								{({
									getInputProps,
									suggestions,
									getSuggestionItemProps,
									loading
								}) => (
									<div>
										<div style={{ position: "relative" }}>
											<div
												style={{
													position: "absolute",
													left: 15,
													top: 7,
													transform: "scale(0.7)"
												}}
											>
												<CustomIcon
													iconName="search"
													color="#90A4AE"
												/>
											</div>
											<SearchInput
												style={{
													paddingLeft: 40,
													margin: 0,
													margin: 0
												}}
												{...getInputProps({
													placeholder: "Type address"
												})}
											/>
											<ClearButton
												onClick={() =>
													this.setState({
														searchValue: "",
														isEdited: true
													})
												}
											>
												{t(
													"profile.editProfile.editLocation.clear"
												)}
											</ClearButton>
										</div>

										<SuggestionWrapper>
											{loading ? (
												<div>
													{t("common.loading")}...
												</div>
											) : null}

											{suggestions.map((suggestion) => {
												return (
													<p
														{...getSuggestionItemProps(
															suggestion
														)}
													>
														{suggestion.description}
													</p>
												)
											})}
										</SuggestionWrapper>
									</div>
								)}
							</PlacesAutocomplete>
						</CustomContainer>
					)}
				</form>
			</>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}
const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(EditLocationPage))

const ClearButton = styled.div`
	font-family: "Poppins";
	font-size: 15px;
	font-weight: 600;
	color: #90a4ae;
	text-decoration: underline;
	margin: 10px 0;
	float: right;
`

const SuggestionWrapper = styled.div`
	padding: 10px;
	border-radius: 20px;

	p {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 18px;
		letter-spacing: 0.02em;
		color: #212121;
		margin-bottom: 12px;
	}
`

const CustomInputLabel = styled.div`
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 900;
	font-size: 21px;
	line-height: 30px;
	letter-spacing: 0.02em;
	color: #212121;
	margin-bottom: 12px;
`

const CurrentLocation = styled.div`
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 23px;
	letter-spacing: 0.02em;
	color: #212121;
`
