import axios from "axios";
import { API_URL } from "../config";
import i18n from "../config/i18n";
import { catchError } from "./response";
import { getApiRequestService } from "./index.services";

export const getCompany = async (id: string) => {
  try {
    const apiInstance = await getApiRequestService();

    const res = await apiInstance.get(`/company/${id}`);
    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const getCompanyPlaylists = async ({
  companyId,
}: {
  companyId: string;
}) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/playlist/company/${companyId}`);
    return res.data;
  } catch (error) {
    return catchError(error);
  }
};
