import { Component } from "react"
import styled from "styled-components"
import { Row, Col } from "react-grid-system"
import BackIcon from "../assets/icons/back-orange.svg"
import { iosSetBackgroundColor, isNativeIOS } from "../tools/ios"
import { withTranslation } from "react-i18next"
import { gtmPageView } from "../tools/reactgaEvents"
import { SaveButton } from "./global"

class EditProfileBar extends Component {
	componentDidMount() {
		// IOS BACKGROUND COLOR
		if (isNativeIOS) {
			iosSetBackgroundColor("#FFFFFF")
		}

		const user = this.props.user

		gtmPageView(user?._id)
		if (window.location.pathname !== "/settings/edit/profile/bio") {
			if (
				user?.firstname?.length === 0 &&
				user?.lastname?.length === 0 &&
				user?.username?.length === 0 &&
				user?.profession?.length === 0
			) {
				this.props.history.push("/settings/edit/profile/bio")
			}
		}
	}

	render() {
		const { isEdited } = this.props
		return (
			<BarWrapper isEdited={isEdited}>
				<Row justify="center" align="center">
					<Col
						xs={2}
						onClick={() => {
							if (
								this.props.history.location.state?.lastPage ===
								"successSignup"
							)
								this.props.history.push("/home")
							else this.props.history.goBack()
						}}
					>
						<StyledIcons src={BackIcon} alt="" />
					</Col>
					<Col>
						<center>
							<h3>{this.props.title}</h3>
						</center>
					</Col>
					<Col xs={2 + 1 / 2} style={{ padding: 0 }}>
						{this.props.save && (
							<SaveButton
								type="submit"
								disabled={!this.props.edited}
								className={!this.props.edited ? "disabled" : ""}
							>
								{this.props.t("common.save")}
							</SaveButton>
						)}
					</Col>
				</Row>
			</BarWrapper>
		)
	}
}

export default withTranslation()(EditProfileBar)

const BarWrapper = styled.div`
	background: white;
	padding: 10px 22px;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 999;

	h3 {
		text-align: center;
		font-family: "Poppins";
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 100%;
		color: #212121;
		margin: 0;
	}
`

const StyledIcons = styled.img`
	height: 32px;
	width: auto;
`
