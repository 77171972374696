import React, { Component } from "react"
import styled from "styled-components"
import { t } from "i18next"
import { CustomInput } from "../../components/CustomInput"
import { CustomButton, Spacer } from "../../components/global"
import { setPassword } from "../../services/auth.services"
import customToast from "../../components/CustomToast"
import SimpleNavbar from "../../components/SimpleNavbar"
import { auth, fbLogout, loginEmailPassword } from "../../services/firebase"
import { iosAuthSignOut } from "../../tools/ios"
import { androidSignOut } from "../../tools/android"
import { getEmailFromUrl } from "../../tools/utils"
import Loader from "../../components/Loader"

class NewPasswordPage extends Component {
	state = {
		password: "",
		confirmPassword: "",
		showPassword: false,
		btnLoading: false,
		pageLoading: true
	}

	componentDidMount() {
		window.scrollTo(0, 0)

		// redirect if not logged in
		auth.onAuthStateChanged((user) => {
			if (!user) this.props.history.push("/home")
			else this.setState({ pageLoading: false })
		})
	}

	handleClick = async () => {
		this.setState({ btnLoading: true })
		if (this.state.password !== this.state.confirmPassword)
			customToast(t("signup.error.passwords"))
		else if (this.state.password.length < 6)
			customToast(t("signup.error.passwordLength"))
		else {
			await setPassword({
				password: this.state.password
			})
			await loginEmailPassword(getEmailFromUrl(), this.state.password)
			this.props.history.push("/profile")
		}
		this.setState({ btnLoading: false })
	}

	onLeavePage() {
		// We deconnect the user from the app if he leaves the page
		fbLogout()
		androidSignOut()
		iosAuthSignOut()

		// We clear the local storage to avoid any problem
		localStorage.removeItem("didAuth")
		localStorage.removeItem("my_uid")
	}

	render() {
		if (this.state.pageLoading) return <Loader />

		return (
			<div>
				<SimpleNavbar
					preventGoBack
					onLogoClick={() => this.onLeavePage()}
				/>
				<PageWrapper>
					<h3>{t("login.newPassword.title")}</h3>
					<p>{t("login.newPassword.subtitle")}</p>
					<Spacer height="16px" />

					<CustomInput
						onInput={(e) =>
							this.setState({ password: e.target.value })
						}
						type={this.state.showPassword ? "text" : "password"}
						placeholder={t("signup.password")}
						name="password"
						style={{ marginBottom: "20px" }}
						required
						filled={this.state.password}
						suffixClick={() =>
							this.setState({
								showPassword: !this.state.showPassword
							})
						}
						suffix={
							<p className="show-hide-button">
								{this.state.showPassword
									? t("signup.hide")
									: t("signup.show")}
							</p>
						}
					/>
					<Spacer height="16px" />
					<CustomInput
						onInput={(e) =>
							this.setState({ confirmPassword: e.target.value })
						}
						type={this.state.showPassword ? "text" : "password"}
						placeholder={t("signup.confirmPassword")}
						name="confirmPassword"
						style={{ marginBottom: "20px" }}
						required
						filled={this.state.confirmPassword}
						suffixClick={() =>
							this.setState({
								showPassword: !this.state.showPassword
							})
						}
						suffix={
							<p className="show-hide-button">
								{this.state.showPassword
									? t("signup.hide")
									: t("signup.show")}
							</p>
						}
					/>
					<Spacer height="16px" />
					<CustomButton
						onClick={() => this.handleClick()}
						disabled={
							!this.state.confirmPassword.length ||
							!this.state.password.length
						}
						className={
							this.state.btnLoading
								? "loading disabled"
								: !this.state.confirmPassword.length ||
								  !this.state.password.length
								? "disabled"
								: ""
						}
					>
						{t("common.confirm")}
					</CustomButton>
				</PageWrapper>
			</div>
		)
	}
}

export default NewPasswordPage

const PageWrapper = styled.div`
	background: #ecf0f5;
	height: 100vh;
	box-sizing: border-box;
	padding: 15vh 30px 21px;
	text-align: center;

	h3 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 120%;
		text-align: center;
		letter-spacing: -0.025em;
		color: #212121;
		margin: 0;
	}

	p {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		color: #212121;
		margin: 8px 0 24px;

		&.show-hide-button {
			font-family: Roboto !important;
			color: #212121;
			font-size: 12px;
			font-weight: 700;
			text-transform: capitalize;
			margin: 0 4px 0 0;
		}
	}
`
