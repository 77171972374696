import axios from "axios";
import { API_URL } from "../config";
import { auth, getFirebaseToken } from "./firebase";

export const getApiRequestService = async (
  params: any | undefined = undefined
) => {
  const accessToken = await getFirebaseToken();
  console.log(
    !!accessToken
      ? "ApiRequestService GOT accessToken"
      : "ApiRequestService NO accessToken"
  );

  const headers = {
    ...params,
  };

  if (accessToken) headers.Authorization = `Bearer ${accessToken}`;

  return axios.create({
    baseURL: `${API_URL}`,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Cache-Control": "no-cache",
      "ngrok-skip-browser-warning": "any",
      ...headers,
    },
  });
};
