import axios from "axios";
import { API_URL } from "../config";
import { catchError, handleError } from "./response";
import { getApiRequestService } from "./index.services";

export const getJournalInfos = async (journal_slug: any) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/journal/${journal_slug.toLowerCase()}`);

    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const followJournal = async (id: string) => {
  try {
    const data = { id };

    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post("/user/me/journal", data);
    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const unfollowJournal = async (id: string) => {
  try {
    const data = { id };
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete("/user/me/journal", {
      data,
    });

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const searchJournal = async (
  search: string,
  offset?: number,
  limit?: number
) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(
      `/journal/search-v2?search=${search}&offset=${offset ?? 0}&limit=${
        limit ?? 100
      }`
    );

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const getJournalsList = async (params?: Record<string, string>) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get("/journal", { params });

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};
