import { Component } from "react"
import { Col, Row } from "react-grid-system"
import { connect } from "react-redux"
import styled from "styled-components"
import { Avatar, Divider, Spacer } from "../../components/global"
import Loader from "../../components/Loader"
import ProfileBar from "../../components/ProfileBar"
import { userApi } from "../../redux/user/user.service"
import {
	getUserListFollowers,
	getUserListFollowings
} from "../../services/user.services"
import { pickGradient } from "../../tools/utils"
import i18n from "../../config/i18n"
import { t } from "i18next"
import ProfilePicture from "../../components/ProfilePicture"
import { getFirebaseToken } from "../../services/firebase"

class FollowView extends Component {
	state = {
		follows: [],
		followsLoaded: false
	}

	componentDidMount() {
		const userID = this.props.match.params.id
		const { isMe, type } = this.props

		/** Profile page */
		if (isMe) {
			this.fetchProfile()
		} else if (userID !== undefined) {
			/** User page */
			if (type === "following") {
				getUserListFollowings(userID).then((followings) => {
					this.setState({ follows: followings, followsLoaded: true })
				})
			} else if (type === "followers") {
				getUserListFollowers(userID).then((followers) => {
					this.setState({ follows: followers, followsLoaded: true })
				})
			}
		} else {
			/** Error catch */
			this.props.history.goBack()
		}
	}

	fetchProfile = async () => {
		const { type } = this.props
		const isAuthentified = await getFirebaseToken()

		if (!this.props.user.user && !!isAuthentified) {
			await Promise.all([
				this.props.getUser(),
				this.props.getPlaylists(),
				this.props.getSaves()
			])
		}
		if (type === "followers" && this.props.user.followers.isUninitialized) {
			await Promise.all([
				this.props.getFollowers(),
				this.props.getPendingFollowers()
			])
		}
		if (type == "following" && this.props.user.following.isUninitialized) {
			await Promise.all([
				this.props.getFollowing(),
				this.props.getPendingFollowing()
			])
		}

		this.setState({ followsLoaded: true })
	}

	render() {
		const { isMe, type } = this.props
		const { follows, followsLoaded } = this.state

		const list = isMe ? this.props.user[type].active : follows

		return (
			<div>
				<ProfileBar
					{...this.props}
					isMe={isMe}
					prevState={this.props.location.state}
				/>
				<ProfileContent>
					<Spacer height="50px" />

					<h3>
						{{
							following: t("profile.following"),
							followers: t("profile.followers")
						}[type] ?? ""}
					</h3>
					<Spacer />
					{followsLoaded ? (
						<>
							{list.map((follow, index) => {
								if (follow)
									return (
										<FollowRow
											key={follow?.uid}
											follow={follow}
											onClick={() =>
												this.props.history.push(
													`/profile/user/${follow?.uid}`
												)
											}
										/>
									)
							})}
							{list.length === 0 && (
								<NoResultText color="#212121">
									{{
										following: t("profile.noFollowing"),
										followers: t("profile.noFollower")
									}[type] ?? ""}
								</NoResultText>
							)}
						</>
					) : (
						<Loader />
					)}
				</ProfileContent>
			</div>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate,
	getSaves: userApi.endpoints.getSaves.initiate,
	getPlaylists: userApi.endpoints.getPlaylists.initiate,
	getFollowers: userApi.endpoints.getFollowers.initiate,
	getPendingFollowers: userApi.endpoints.getPendingFollowers.initiate,
	getFollowing: userApi.endpoints.getFollowing.initiate,
	getPendingFollowing: userApi.endpoints.getPendingFollowing.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(FollowView)

export const FollowRow = (props) => {
	return (
		<FollowWrapper onClick={props.onClick}>
			<Row gutterWidth={20} justify="start" align="center">
				<Col xs={2}>
					<ProfilePicture user={props.follow} />
				</Col>
				<Col>
					<h5>{props.follow.fullname}</h5>
					<p>
						{
							props.follow?.profession?.translations[
								i18n.resolvedLanguage
							]
						}
					</p>
				</Col>
			</Row>
			<Divider style={{ margin: "10px 0 20px 0" }} />
		</FollowWrapper>
	)
}

const NoResultText = styled.p`
	color: ${(props) => props.color ?? "#FFFFFF"};
	font-family: "Poppins";
	font-weight: 400;
	font-size: 15px;
	text-align: center;
`

const FollowButton = styled.button`
	outline: none;
	border: none;
	box-shadow: none;
	padding: 3px 13px;
	border-radius: 20px;
	box-shadow: 1px 1px 10px rgba(33, 33, 33, 0.1);
	background: #ffc408;
	margin-bottom: 10px;

	color: #212121;
	font-family: "Poppins";
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	//   width: 72px;
`

const FollowWrapper = styled.div`
	font-family: Poppins;

	h5 {
		margin: 0;
		font-weight: 600;
		font-size: 14px;
		line-height: 21px;
	}

	p {
		margin: 0;
		margin-bottom: 10px;
		font-weight: 300;
		font-size: 10px;
		line-height: 15px;
		letter-spacing: 0.04em;
		text-transform: uppercase;
	}
`

const ProfileContent = styled.div`
	background: #f7f8fc;
	color: #212121;
	padding: 30px 20px;
	min-height: calc(${window.innerHeight}px - 60px);

	h3 {
		font-size: 21px;
		line-height: 25.2px;
		font-weight: 900;
		margin: 0;
		font-family: "Work Sans" !important;
		text-transform: capitalize;
	}

	h4 {
		font-size: 16px;
		line-height: 20.8px;
		font-weight: 400;
		margin-top: 5px;
	}

	h4 b {
		font-weight: 600;
	}
`

const ProfileHeader = styled.div`
	padding: 30px 21px;
	background: #ecf0f5;
	color: #212121;
	font-family: "Poppins";

	h3 {
		line-height: 17px;
		font-size: 17px;
		font-weight: 300;
		margin: 10px 0;
	}

	h3 b {
		font-weight: 700;
	}

	h4 {
		line-height: 15px;
		font-size: 11px;
		font-weight: 300;
		margin: 0;
		margin-right: 20px;
	}

	h4 b {
		font-weight: 600;
		font-size: 14px;
	}

	h5 {
		margin: 0;
		text-transform: capitalize;
		font-size: 11px;
		line-height: 15.4px;
		font-weight: 300;
	}

	h5 span {
		text-transform: uppercase;
	}

	p {
		font-size: 14px;
		line-height: 22.4px;
		font-weight: 400;
		margin: 0;
	}
`
