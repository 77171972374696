import { Component } from "react"
import styled from "styled-components"
import { NavBar } from "antd-mobile"
import { Link, withRouter } from "react-router-dom"
import Logo from "../assets/core/pipe-full-icon.svg"
import CustomIcon from "./CustomIcon"
import { push } from "react-router-redirect"
import BackIcon from "../assets/icons/back-orange.svg"
import { iosSetBackgroundColor, isNativeIOS } from "../tools/ios"
import { withTranslation } from "react-i18next"
import { gtmPageView } from "../tools/reactgaEvents"
import LoginSheet from "./signup/LoginSheet"
import XSeparator from "../assets/icons/x-sperarator.svg"
import ShareSheet from "./ShareSheet"
import { getFirebaseToken } from "../services/firebase"
import { store } from "../redux"
import { connect } from "react-redux"
import { userApi } from "../redux/user/user.service"
import { SupportedLanguagesEnum } from "../interfaces"
import customToast from "./CustomToast"
import { t } from "i18next"
import { setContentLanguage } from "../redux/global/global.reducer"
import { LanguageDrawer } from "./LanguageDrawer"

class CustomNavBar extends Component {
	listener = null

	state = {
		showActionSheet: false,
		showDrawer: false,
		email: "",
		searchValue: "",
		scrollPosition: 0,
		contentLanguage:
			localStorage.getItem("contentLanguage") || SupportedLanguagesEnum.EN
	}

	async componentDidMount() {
		// IOS BACKGROUND COLOR
		if (isNativeIOS) {
			iosSetBackgroundColor("#FFFFFF")
		}

		const isAuthenticated = await getFirebaseToken()

		if (this.props.user?.isUninitialized && isAuthenticated)
			this.props.getUser().then(() => {
				gtmPageView(this.props.user.user?._id, this.props.match.url)
			})

		this.setState({
			showActionSheet: false
		})

		this.listenToScrollEvent()
	}

	componentWillUnmount() {
		document.removeEventListener("scroll", () => {
			requestAnimationFrame(() => {
				this.calculateScrollDistance()
			})
		})
	}

	listenToScrollEvent = () => {
		document.addEventListener("scroll", () => {
			requestAnimationFrame(() => {
				this.calculateScrollDistance()
			})
		})
	}

	calculateScrollDistance = () => {
		const scrollTop = window.pageYOffset // how much the user has scrolled by
		const winHeight = window.innerHeight
		const docHeight = this.getDocHeight()

		const totalDocScrollLength = docHeight - winHeight
		const scrollPosition = Math.floor(
			(scrollTop / totalDocScrollLength) * 100
		)

		this.setState({
			scrollPosition
		})
	}

	getDocHeight = () => {
		return Math.max(
			document.body.scrollHeight,
			document.documentElement.scrollHeight,
			document.body.offsetHeight,
			document.documentElement.offsetHeight,
			document.body.clientHeight,
			document.documentElement.clientHeight
		)
	}

	deleteShare() {
		this.setState({ isShared: false })
		localStorage.removeItem("isShared")
	}

	handleGoBack() {
		const { search } = this.props.history.location
		if (search.includes("?shared")) {
			this.props.history.replace("/home")
		} else {
			this.props.history.goBack()
		}
	}

	handleChangeLanguage = (newLanguage) => {
		this.setState({ showDrawer: false })

		let flag = ""
		let newLanguageText = t("languages.english")

		// const newLanguage = this.state.contentLanguage;

		switch (newLanguage) {
			case SupportedLanguagesEnum.FR:
				newLanguageText = t("languages.french")
				break

			case SupportedLanguagesEnum.IT:
				newLanguageText = t("languages.italian")
				break

			case SupportedLanguagesEnum.ES:
				newLanguageText = t("languages.spanish")
				break

			case SupportedLanguagesEnum.DE:
				newLanguageText = t("languages.german")
				break

			default:
				newLanguageText = t("languages.english")
		}

		switch (newLanguage) {
			case SupportedLanguagesEnum.FR:
				flag = "🇫🇷"
				break

			case SupportedLanguagesEnum.IT:
				flag = "🇮🇹"
				break

			case SupportedLanguagesEnum.ES:
				flag = "🇪🇸"
				break

			case SupportedLanguagesEnum.DE:
				flag = "🇩🇪"
				break

			default:
				flag = "🇬🇧"
		}

		customToast(
			`${flag} ${t("toast.success.switchLanguage")} ${newLanguageText}`
		)

		store.dispatch(setContentLanguage(newLanguage))

		this.props.onSwitchLanguage && this.props.onSwitchLanguage(newLanguage)
	}

	render() {
		const { showActionSheet } = this.state
		const { showBackButton } = this.props

		const organisation =
			(!!this.props.user?.user?.organisations &&
				this.props.user?.user?.organisations[0]) ||
			this.props.organisation

		return (
			<StyledNavbar>
				<NavBar
					mode="light"
					leftContent={
						showBackButton ? (
							<div onClick={() => this.handleGoBack()}>
								<StyledIcons src={BackIcon} alt="" />
							</div>
						) : window.location.pathname === "/home" ? (
							<div
								onClick={() =>
									window.scrollTo({
										top: 0,
										left: 0,
										behavior: "smooth"
									})
								}
							>
								<NavbarLogo organisation={organisation} />
							</div>
						) : (
							<Link key="home" to="/home">
								<NavbarLogo organisation={organisation} />
							</Link>
						)
					}
					rightContent={[
						<div key="contentLanguage">
							<IconMargin
								onClick={() =>
									this.setState({
										showDrawer: true
									})
								}
							>
								<CustomIcon iconName="translate" scale={1.25} />
							</IconMargin>
						</div>,
						<div key="search">
							<IconMargin
								onClick={() => {
									window.scrollTo(0, 0)
									push("/search")
								}}
							>
								<CustomIcon iconName="search" color="black" />
							</IconMargin>
						</div>,
						<div key="profile">
							{!!store.getState().user?.user ? (
								<Link key="profile" to="/profile">
									<IconMargin style={{ marginRight: "10px" }}>
										<CustomIcon
											iconName="user"
											color="#FF4908"
										/>
									</IconMargin>
								</Link>
							) : (
								<IconMargin
									onClick={() =>
										this.setState({
											showActionSheet: !showActionSheet
										})
									}
									style={{ marginRight: "10px" }}
								>
									<CustomIcon iconName="user" color="black" />
								</IconMargin>
							)}
						</div>
					]}
					style={{
						padding: "5px 10px 5px 0",
						alignContent: "middle",
						position: "fixed",
						left: 0,
						right: 0,
						top: 0,
						zIndex: 90,
						margin: "0 auto",
						boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)"
					}}
				/>

				{/* Show Sharebar if article shared */}
				<ShareSheet />

				{/* Language content drawer */}
				<LanguageDrawer
					showDrawer={this.state.showDrawer}
					contentLanguage={this.state.contentLanguage}
					userOrganisationUid={
						this.props.user?.user?.organisations?.[0]?.uid
					}
					onDrawerClose={() => this.setState({ showDrawer: false })}
					handleSwitchLanguage={(contentLanguage) => {
						this.setState({ contentLanguage })
					}}
					handleChangeLanguage={this.handleChangeLanguage.bind(this)}
				/>

				<LoginSheet
					{...this.props}
					showActionSheet={this.state.showActionSheet}
					closeSheet={() => this.setState({ showActionSheet: false })}
				/>
			</StyledNavbar>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withRouter(withTranslation()(CustomNavBar)))

const NavbarLogo = ({ organisation }) => {
	return (
		<div style={{ display: "flex", alignItems: "end", gap: 8 }}>
			<div className="main-logo">
				<img src={Logo} alt="JUICI" className="juisci-logo" />
				{!!organisation && organisation?.uid !== "juisci" && (
					<img src={XSeparator} className="X-separator" />
				)}
				{!!organisation && organisation?.uid !== "juisci" && (
					<img
						src={organisation?.logo?.url}
						alt={organisation?.name}
						className="org-logo"
					/>
				)}
			</div>
			<span style={{ color: "#212121", fontFamily: "Poppins" }}>
				{window.location.host.includes("dev.juisci") ? " .dev" : ""}
			</span>
		</div>
	)
}

const StyledIcons = styled.img`
	height: 32px;
	width: auto;
`

const IconMargin = styled.div`
	margin-left: 30px;
`

const StyledNavbar = styled.div`
	.flag-icon {
		font-size: 24px;
	}

	.main-logo {
		display: flex;
		align-items: center;
		gap: 12px;

		.org-logo {
			max-height: 35px;
			max-width: 120px;
			object-fit: contain;
			transform: translateY(4px);
		}

		.juisci-logo {
			height: 35px;
		}

		.X-separator {
			height: 12px;
			width: 12px;
			transform: translateY(4px);
		}
	}
`
