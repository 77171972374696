import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { API_URL } from "../config";
import { getFirebaseToken } from "../services/firebase";

export const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  prepareHeaders: async (headers) => {
    const accessToken = await getFirebaseToken();

    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }

    headers.set("Cache-Control", "no-cache");
    headers.set("Access-Control-Allow-Origin", "*");
    headers.set("ngrok-skip-browser-warning", "any");

    return headers;
  },
});
