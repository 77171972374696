import React, { Component } from "react"
import { NoticeBar } from "antd-mobile"
import {
	CustomButton,
	CustomContainer,
	Divider,
	Spacer
} from "../../components/global"
import { CustomInput } from "../../components/CustomInput"
import disableScroll from "disable-scroll"
import { push } from "react-router-redirect"
import SettingsNavbar from "../../components/SettingsNavbar"
import { createPlaylist } from "../../services/playlists.services"
import { withTranslation } from "react-i18next"
import customToast from "../../components/CustomToast"
import { connect } from "react-redux"
import { userApi } from "../../redux/user/user.service"
import { getFirebaseToken } from "../../services/firebase"

class CreatePlaylist extends Component {
	state = {
		isLogged: false,
		playlist_name: "",
		error_message: "",
		loading: false
	}

	componentDidUpdate() {
		const { userLoaded, articlesLoaded } = this.state

		if (articlesLoaded && userLoaded) {
			disableScroll.off()
		}
	}

	async componentDidMount() {
		const { articlesLoaded } = this.state
		window.scrollTo(0, 0)

		disableScroll.on()
		if (articlesLoaded && userLoaded) {
			disableScroll.off()
		}

		const isAuthentified = await getFirebaseToken()
		if (!isAuthentified) {
			push("/login")
		}
	}

	handleCreate() {
		const { playlist_name } = this.state

		this.setState({
			loading: true
		})

		if (playlist_name.length > 0) {
			const data = { title: playlist_name }
			createPlaylist(data).then(() =>
				this.props.getPlaylists().then(() => {
					customToast(
						this.props.t("toast.success.createdPlaylist"),
						"success"
					)
					this.props.history.goBack()
				})
			)
		} else {
			this.setState({
				error_message: this.props.t("toast.error.playlistName.missing"),
				loading: false
			})
		}
	}

	render() {
		const { playlist_name, error_message } = this.state
		const { t } = this.props
		return (
			<>
				<SettingsNavbar
					{...this.props}
					pageTitle={
						<>
							<span
								style={{
									fontSize: "12px",
									fontWeight: "normal"
								}}
							>
								{t("playlist.create.title")}
							</span>
							<br />
							<span>
								{playlist_name.length > 0
									? playlist_name
									: t("playlist.create.placeholder")}
							</span>
						</>
					}
				/>
				<Spacer height="60px" />
				<CustomContainer
					style={{
						// background: "#F7F8FC",
						background: "#ECF0F5",
						padding: "20px 20px 50px 20px"
					}}
				>
					{/* Error Message on Empty submit */}
					{error_message.length > 0 && (
						<>
							<NoticeBar
								style={{
									background: "#CE0868",
									color: "white"
								}}
								mode="closable"
								icon={null}
								onClick={() =>
									this.setState({ error_message: "" })
								}
							>
								{error_message}
							</NoticeBar>
							<Spacer />
						</>
					)}

					{/* Form area */}
					{/* <h3>{t("playlist.create.name")}</h3> */}
					<Spacer />
					<CustomInput
						placeholder={t("playlist.create.title")}
						value={playlist_name}
						onInput={(e) =>
							this.setState({ playlist_name: e.target.value })
						}
					/>
					<Divider />
					<CustomButton
						className={this.state.loading ? "loading disabled" : ""}
						onClick={() => this.handleCreate()}
					>
						{t("common.create")}
					</CustomButton>
				</CustomContainer>
			</>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}
const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate,
	getPlaylists: userApi.endpoints.getPlaylists.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(CreatePlaylist))
