import React, { Component } from "react"
import { Trans, withTranslation } from "react-i18next"
import styled from "styled-components"
import { Spacer } from "../global"
import ForYouBg from "../../assets/images/foryou-bg.png"
import CustomNavbar from "../CustomNavbar"
import Lottie from "lottie-react"
import BlenderAnimation from "../../assets/animations/new_foryou.json"
import { recommanderMultiple } from "../../services/articles.services"
import { kycMiddleware, preventScroll } from "../../tools/utils"
import { iosGenerateHaptic, iosStopHaptic, isNativeIOS } from "../../tools/ios"
import { connect } from "react-redux"
import { userApi } from "../../redux/user/user.service"
import { t } from "i18next"
import {
	HapticEffect,
	ItemDataEventListName,
	ItemDataVariant,
	ItemsDataEvent
} from "../../interfaces"
import HomeNavigation from "./HomeNavigation"
import customToast from "../CustomToast"
import { getFirebaseToken } from "../../services/firebase"
import {
	convertContentToItemData,
	gtmItemsData
} from "../../tools/reactgaEvents"

// Phrases du Blender
export const blenderLines = {
	en: [
		<span>
			Hang tight, we rustle up <br /> some{" "}
			<strong>shots of science </strong>for you
		</span>,
		"A drop of science",
		"A dash of clinical protocol",
		"Distilling information"
	],
	fr: [
		<span>
			Ton jus de science personnalisé est en{" "}
			<strong>cours de préparation</strong>
		</span>,
		"Un concentré de science",
		"Un soupçon de protocole clinique",
		"Distillation d'informations"
	]
}

export function getBlenderLine(locale, step) {
	const lang = locale?.substring(0, 2)
	if (!["en", "fr"].includes(lang)) return null

	return blenderLines[lang][step]
}

const LINES_TIME = 2500

class ForYou extends Component {
	lottieRef = React.createRef()

	state = {
		playBlender: false, // Pour jouer l'animation
		articlesList: [], // Liste des articles de la ForYou
		countBlender: 0, // Étape de l'animation (max = 4)
		article: undefined, // Notre objet article
		firstBlenderAnimationDone: false,
		increaseInterval: true // Pour l'intervale du blender qu'on fait manuellement
	}

	async componentDidMount() {
		try {
			if (!(await getFirebaseToken())) {
				customToast(t("toast.error.notLogin"))
				this.props.history.replace("/login")
				return
			}

			// Effet haptique du blender
			iosGenerateHaptic(HapticEffect.BLENDER)

			// On remonte la page puis désactive le scroll
			window.scrollTo(0, 0)
			preventScroll(true)

			kycMiddleware(this, t("toast.error.notOnboarded.foryou"))

			recommanderMultiple({ offset: 0, n: 10 })
				.then((contents) => {
					const articles = contents.filter(
						(content) => !!content?.article_type
					)
					console.log("articles", articles)

					this.setState({ articles })

					gtmItemsData(
						ItemsDataEvent.VIEW_ITEM_LIST,
						convertContentToItemData(
							articles,
							ItemDataEventListName.FORYOU,
							ItemDataVariant.ARTICLE
						)
					)

					// Si l'animation du blender a déja été vue en entier,
					// on n'attend pas qu'une autre se termine
					if (this.state.firstBlenderAnimationDone) {
						this.navigateStory()
					}
				})
				.catch((error) =>
					console.error("Err recommanderMultiple", error.message)
				)

			this.props.getSaves()

			this.increaseBlenderCounter()
		} catch (error) {
			console.error("Err componentDidMount", error.message)
		}
	}

	componentWillUnmount() {
		preventScroll(false)
		iosStopHaptic()
		clearTimeout(this.increaseTimeout)
	}

	increaseBlenderCounter() {
		this.increaseTimeout = setTimeout(
			() => {
				this.setState({
					countBlender: (this.state.countBlender + 1) % 4
				})

				if (this.state.increaseInterval) this.increaseBlenderCounter()
			},
			this.state.countBlender % 4 === 0 ? LINES_TIME + 1000 : LINES_TIME
		)
	}

	navigateStory() {
		iosStopHaptic()
		this.props.history.push({
			pathname: "/home/foryou/story",
			state: { articles: this.state.articles }
		})
	}

	// Quand l'animation du blender se finit: on affiche l'article, on arrête l'animation et on reset le compteur des phrases
	async onFinishBlender() {
		const { articles } = this.state
		if (!articles) {
			this.setState({ firstBlenderAnimationDone: true })
			this.lottieRef?.current?.goToAndPlay(0)
		} else {
			this.navigateStory()
		}
	}

	render() {
		return (
			<div>
				<CustomNavbar {...this.props} />

				<ForYouWrapper>
					{/* Page Header */}
					<Spacer height="5px" />
					<HomeNavigation />

					{/* Page Content */}
					<>
						<HeaderTitle>
							<Trans i18nKey="home.foryou.headline">
								Get the latest publications
								<br />
								<b>recommended for you</b>, based on
								<br />
								<b>your reading</b> and that of{" "}
								<b>the community.</b>
							</Trans>
						</HeaderTitle>
						{/* Blender */}
						<Lottie
							animationData={BlenderAnimation}
							lottieRef={this.lottieRef}
							loop={false}
							onComplete={this.onFinishBlender.bind(this)}
							autoplay={true}
							style={{
								marginRight: "-15px",
								marginTop: "-30px",
								marginBottom: "-30px"
							}}
						/>
						{/* Fin du Blender */}
						<h5
							style={{
								fontSize: "17px",
								fontWeight: 400,
								fontFamily: "Poppins",
								color: "#212121",
								textAlign: "center",
								margin: "16px 21px"
							}}
							className="loading"
						>
							{getBlenderLine(
								this.props.i18n.language,
								this.state.countBlender
							)}
						</h5>
					</>
				</ForYouWrapper>
			</div>
		)
	}
}

function mapState(state) {
	const articles = state.articles
	const user = state.user
	return {
		articles,
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate,
	getSaves: userApi.endpoints.getSaves.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(ForYou))

const ForYouWrapper = styled.div`
	height: ${window.innerHeight}px;
	overflow: hidden;
	padding: 25px 0px;
	background-image: linear-gradient(
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0.7),
			rgba(255, 255, 255, 1)
		),
		url(${ForYouBg});
	background-color: #f7f8fc;
	background-size: fit;
	h4 {
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 120%;
		color: #212121;
		padding: 0 28px;
	}
`

const HeaderTitle = styled.h4`
	text-align: center;
	font-weight: 400 !important;
	span {
		font-size: 17px;
		line-height: 21px;
		font-weight: 400;
	}
	b,
	strong {
		font-weight: 700;
	}
`
