import React, { Component } from "react"
import ReactPlayer from "react-player"
import { Link, withRouter } from "react-router-dom"
import styled from "styled-components"
import Loader from "../../components/Loader"
import SimpleNavbar from "../../components/SimpleNavbar"
import { Spacer, SpecialtyTag } from "../../components/global"
import { kycMiddleware, renderPublicationDate } from "../../tools/utils"
import { IconWrapper } from "../postPage/postPage"
import CustomIcon from "../../components/CustomIcon"
import { t } from "i18next"
import ArticleCard from "../../components/ArticleCard"
import EditorialIcon from "../../assets/icons/card_editorial.svg"
import customToast from "../../components/CustomToast"
import { userApi } from "../../redux/user/user.service"
import { connect } from "react-redux"
import { store } from "../../redux"
import { setPlaylists, setSaves, setUser } from "../../redux/user/user.reducer"
import { androidShare } from "../../tools/android"
import { isAndroid } from "react-device-detect"
import i18n from "../../config/i18n"
import { getContentFromSlug } from "../../services/content.services"
import PlaylistSheet from "../../components/playlists/PlaylistSheet"
import {
	addContentToPlaylist,
	removeContentFromPlaylist
} from "../../services/playlists.services"
import { iosPlaySound } from "../../tools/ios"
import { ItemDataEventListName, SoundEffect } from "../../interfaces"
import ApiVideoPlayer from "@api.video/react-player"
import { getContentRelatedArticles } from "../../services/articles.services"
import ShareSheet from "../../components/ShareSheet"
import { VideoFormatEnum } from "../../interfaces/video.interface"
import StoryVideo from "../../components/videos/StoryVideo"
import { getFirebaseToken } from "../../services/firebase"

const YOUTUBE_CONFIG = {
	playerVars: {
		showinfo: 0,
		modestbranding: 1,
		iv_load_policy: 3,
		rel: 0,
		wmode: "transparent",
		playsinline: 1
	}
}

class VideoPage extends Component {
	videoPlayer = React.createRef()

	state = {
		video: undefined,
		showSave: false,
		relatedArticles: undefined,
		playing: false,
		isMuted: true,
		isLiked: false,
		isSaved: false
	}

	async componentDidMount() {
		const isAuthentified = await getFirebaseToken()
		if (!isAuthentified) {
			customToast(t("toast.error.notLogin"))
			this.props.history.replace("/login")
			return
		}

		window.scrollTo(0, 0)
		window.addEventListener("scroll", this.listenScroll)

		if (!this.props.user.user) await this.props.getUser()

		if (!this.props.user.user.playlists || !this.props.user.user.saves) {
			await this.props.getPlaylists()
		} else {
			this.props.getPlaylists()
		}

		try {
			await getContentFromSlug(this.props.match.params.slug).then(
				(video) => {
					this.setState(
						{
							video,
							isSaved:
								!!this.props.user.saves &&
								!!this.props.user.saves.find(
									(videoId) => videoId === video._id
								),
							isLiked:
								!!this.props.user.user.likedContent &&
								!!this.props.user.user.likedContent.find(
									(el) => el._id === video._id
								)
						},
						() => {
							setTimeout(() => {
								this.setState({ playing: !this.state.playing })
							}, 1000)
						}
					)
					this.fetchRelatedArticles()
				}
			)
		} catch (error) {
			customToast(t("toast.error.video"))
			this.props.history.goBack()
		}
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.listenScroll)
	}

	listenScroll = () => {
		if (window.scrollY > 60) this.setState({ scrolled: true })
		else this.setState({ scrolled: false })
	}

	fetchRelatedArticles = async () => {
		const { docs: relatedArticles } = await getContentRelatedArticles(
			this.state.video._id
		)
		this.setState({ relatedArticles })
	}

	handleLike = async () => {
		const { isLiked, video } = this.state

		const body = {
			slug: video.slug,
			action: isLiked ? "unlike" : "like"
		}

		this.setState({
			isLiked: !isLiked,
			video: {
				...this.state.video,
				metrics: {
					...this.state.video.metrics,
					likes: this.state.video.metrics.likes + (isLiked ? -1 : 1)
				}
			}
		})

		iosPlaySound(SoundEffect.LIKE)
		customToast(
			isLiked
				? t("toast.success.unlikedVideo")
				: t("toast.success.likedVideo")
		)

		const { data } = await this.props.patchUserContent(body)
		store.dispatch(setUser(data[0]))
	}

	handleShare = async () => {
		const isAuthentified = await getFirebaseToken()

		const {
			_id,
			slug,
			title,
			medical_specialties,
			journal,
			company,
			congress,
			publisher
		} = this.state.video

		const url =
			window.location.origin +
			`/video/${slug}?shared=${this.props.user?.user?.uid}&org=${this.props.user.user?.organisations?.[0]?.uid}`

		const publisherName =
			publisher === "journal"
				? journal.name
				: company
				? company.name
				: congress.name

		const shareText = t("sharing.shareVideoText", {
			title,
			publisher: publisherName
		})

		if (!!isAuthentified && !this.props.user.user.profession) {
			kycMiddleware(this, t("toast.error.notOnboarded.shareArticle"))
		} else if (isAndroid) {
			androidShare(url, shareText)
		} else if (navigator.share) {
			navigator
				.share({
					title: document.title,
					text: shareText,
					url
				})
				.then(async () => {
					await this.props.patchUserContent({ slug, action: "share" })
					iosPlaySound(SoundEffect.SHARE)
					customToast(t("toast.success.sharedVideo"))

					gtmItemData({
						event: "video_share",
						item_name: title,
						item_list_name: window.location.pathname,
						item_id: _id,
						item_brand: journal?.name,
						item_category: medical_specialties[0].uid,
						item_category3: "video"
					})
				})
		} else {
			customToast(t("toast.error.browser"))
		}
	}

	addPlaylistSuccess = (isInPlaylists) => {
		const video = {
			...this.state.video,
			metrics: {
				...this.state.video.metrics,
				saveds: this.state.video.metrics.saveds + isInPlaylists
			}
		}

		this.setState({ showSave: false, video, isSaved: isInPlaylists >= 0 })

		if (isInPlaylists >= 0) iosPlaySound(SoundEffect.SAVE)

		customToast(
			t(
				isInPlaylists >= 0
					? "toast.success.savedVideo"
					: "toast.success.unsavedVideo"
			)
		)
	}

	confirmAddPlaylist = async (checkedPlaylists) => {
		const prevSelectedPlaylists = this.props.user?.playlists.filter(
			({ contentVideos }) =>
				contentVideos?.find(
					(videoId) => videoId === this.state.video?._id
				)
		)

		const selectedPlaylists = checkedPlaylists.filter(
			(playlistId) =>
				!prevSelectedPlaylists
					.map((prev) => prev._id)
					.includes(playlistId)
		)

		const unselectedPlaylists = prevSelectedPlaylists.filter(
			(playlist) => !checkedPlaylists.includes(playlist._id)
		)

		let newPlaylist = [...this.props.user.playlists]

		selectedPlaylists.forEach((playlistId) => {
			const playlistIndex = newPlaylist.findIndex(
				(playlist) => playlist._id === playlistId
			)

			newPlaylist[playlistIndex] = {
				...newPlaylist[playlistIndex],
				contentVideos: [
					...newPlaylist[playlistIndex].contentVideos,
					this.state.video._id
				]
			}
		})

		unselectedPlaylists.forEach((pl) => {
			const playlistIndex = newPlaylist.findIndex(
				(playlist) => pl._id === playlist._id
			)

			newPlaylist[playlistIndex] = {
				...newPlaylist[playlistIndex],
				contentVideos: [
					...newPlaylist[playlistIndex].contentVideos.filter(
						(videoId) => videoId !== this.state.video._id
					)
				]
			}
		})

		const alreadyInStore = store
			.getState()
			.user.saves.includes(this.state.video._id)

		let isInPlaylists = 0

		let newSaves = Array.from(
			new Set([...store.getState().user.saves, this.state.video._id])
		)

		if (!alreadyInStore && checkedPlaylists.length > 0) {
			isInPlaylists = 1
		} else if (alreadyInStore && !checkedPlaylists.length) {
			newSaves = [
				...store
					.getState()
					.user.saves.filter(
						(videoId) => videoId !== this.state.video._id
					)
			]
			isInPlaylists = -1
		}

		store.dispatch(setSaves(newSaves))
		store.dispatch(setPlaylists(newPlaylist))

		this.addPlaylistSuccess(isInPlaylists)

		for (const playlist of unselectedPlaylists)
			await removeContentFromPlaylist(playlist._id, this.state.video._id)

		for (const playlistId of selectedPlaylists)
			await addContentToPlaylist(playlistId, this.state.video._id)

		await this.props.patchUserContent({
			slug: this.state.video.slug,
			action: selectedPlaylists.length > 0 ? "save" : "unsave"
		})
	}

	onConfirmCreatePlaylist = (newPlaylist) => {
		const playlist = {
			...newPlaylist,
			contentVideos: [this.state.video._id]
		}

		store.dispatch(setPlaylists([playlist, ...this.props.user.playlists]))
		store.dispatch(
			setUser({
				...this.props.user.user,
				savedContent: [
					...this.props.user.user.savedContent,
					this.state.video._id
				]
			})
		)
		addContentToPlaylist(playlist._id, this.state.video._id)

		this.setState({
			isSaved: true,
			video: {
				...this.state.video,
				metrics: {
					...this.state.video.metrics,
					saveds: this.state.video.metrics.saveds + 1
				}
			}
		})

		customToast(t("toast.success.savedVideo"))
	}

	render() {
		const contentLanguage = localStorage.getItem("contentLanguage")
		const { video } = this.state

		if (!video) return <Loader />

		if (video.videoFormat === VideoFormatEnum.STORY)
			return (
				<StyledVideoStory>
					<div className="story-navbar">
						<CustomIcon
							iconName="back-arrow"
							color2="#CE0868"
							onClick={() =>
								!!this.props.location.key
									? this.props.history.goBack()
									: this.props.history.replace(
											"/home/congress"
									  )
							}
						/>
						<h5>{video.title}</h5>
						<div style={{ width: 32 }} />
					</div>

					<ShareSheet />
					<StoryVideo
						height="100vh"
						videoIndex={0}
						story={video}
						visible
						onEnded={() => console.log("video story finished")}
					/>
				</StyledVideoStory>
			)

		return (
			<StyledContainer>
				{this.state.showSave && (
					<PlaylistSheet
						type="video"
						playlists={this.props.user?.playlists}
						showSheet={this.state.showSave}
						onClose={() => this.setState({ showSave: false })}
						article={video}
						getPlaylists={() => this.props.getPlaylists()}
						onConfirm={this.confirmAddPlaylist.bind(this)}
						onConfirmCreatePlaylist={this.onConfirmCreatePlaylist.bind(
							this
						)}
					/>
				)}
				<ShareSheet />
				<SimpleNavbar
					onGoBack={() =>
						!!this.props.location.key
							? this.props.history.goBack()
							: this.props.history.replace("/home/congress")
					}
				/>

				<Spacer height={this.state.scrolled ? "277px" : "54px"} />
				<div
					className={`video-player ${
						this.state.scrolled ? "fixed" : ""
					} `}
				>
					{this.state.video.apiVideo ? (
						<ApiVideoPlayer
							ref={this.videoPlayer}
							style={{
								width: "100%",
								height: "210px"
							}}
							video={{ id: this.state.video.apiVideo.videoId }}
							autoplay
							muted
							controls={["play", "unmute", "fullscreen"]}
							hideTitle
							onReady={(ready) => {
								this.setState({ ready })
								this.videoPlayer.current?.requestFullscreen()
							}}
						/>
					) : (
						<ReactPlayer
							width="100%"
							height="210px"
							url={video.sourceURL}
							config={{
								youtube: { ...YOUTUBE_CONFIG }
							}}
							playing={this.state.playing}
							onReady={(ready) => this.setState({ ready })}
							onStart={() => this.setState({ started: true })}
							onPlay={() => this.setState({ playing: true })}
							onPause={() => this.setState({ playing: false })}
							stopOnUnmount={true}
							playsinline={true}
						/>
					)}
				</div>
				<div className="video-infos">
					<div className="row-flex">
						<div>
							<div className="publication-date">
								{renderPublicationDate(video.publication_date)}
							</div>
							{video.medical_specialties
								?.slice(0, 2)
								.map((specialty) => (
									<SpecialtyTag
										key={specialty._id + "--specialtytag"}
									>
										{
											specialty.translations[
												contentLanguage
											]
										}
									</SpecialtyTag>
								))}
						</div>
						{video.publisher === "journal" ? (
							<Link to={`/journal/${video.journal.uid}`}>
								<img alt="" src={video.journal.image.url} />
							</Link>
						) : video.company ? (
							<Link to={`/company/${video.company._id}`}>
								<img alt="" src={video.company.images[0].url} />
							</Link>
						) : video.congress ? (
							<img alt="" src={video.congress?.image?.url} />
						) : (
							<div />
						)}
					</div>

					<h3>{video.title}</h3>

					<div className="content-controllers">
						<div
							style={{ display: "none" }}
							className="controller"
							onClick={() => this.setState({ showSave: true })}
						>
							<IconWrapper
								style={{
									paddingTop: "9px",
									background: this.state.isSaved
										? "linear-gradient(180deg, #FFD000 0%, #FFA100 100%)"
										: "#90A4AE",
									transform: "scale(0.8)",
									transition: "all ease-in-out 0.3s"
								}}
							>
								<CustomIcon iconName="marker" color="#fff" />
							</IconWrapper>
							<p>{video.metrics.saveds}</p>
						</div>

						<div
							className="controller"
							onClick={() => this.handleLike()}
						>
							<IconWrapper
								style={{
									paddingTop: "9px",
									background: this.state.isLiked
										? "#ff8800"
										: "#90A4AE",
									transform: "scale(0.8)",
									transition: "all ease-in-out 0.3s"
								}}
							>
								<CustomIcon
									iconName="heart"
									scale={1.2}
									color="#fff"
									style={{ marginBottom: -5 }}
								/>
							</IconWrapper>
							<p>{video.metrics.likes}</p>
						</div>

						<div
							className="controller"
							onClick={() => this.handleShare()}
						>
							<IconWrapper
								style={{
									marginRight: "5px",
									paddingTop: "9px",
									background: "#90A4AE",
									transform: "scale(0.8)",
									transition: "all ease-in-out 0.3s"
								}}
							>
								{this.state.iconSize > 0 ? (
									<CustomIcon
										iconName="twitter"
										scale={this.state.iconSize}
										color="#fff"
										style={{
											marginBottom: -5,
											transition: "all ease-in-out 0.3s"
										}}
									/>
								) : (
									<CustomIcon
										scale={1.2}
										iconName="share_plane"
										color="#fff"
									/>
								)}
							</IconWrapper>
							<p>{video.metrics.shares}</p>
						</div>
					</div>
				</div>
				{!!video.associatedArticles[0] && (
					<div className="associated-content">
						<Link to={"/post/" + video.associatedArticles[0].slug}>
							<div className="edito-card">
								<img
									src={EditorialIcon}
									className="editorial-icon"
									alt=""
								/>

								<div className="card-content">
									<div>
										<h5>
											{t(
												"content.video.associatedArticles.title"
											)}
										</h5>
										<p>
											{t(
												"content.video.associatedArticles.subtitle"
											)}
										</p>
									</div>
									<CustomIcon
										iconName="back-arrow"
										color2="#CE0868"
									/>
								</div>
							</div>
						</Link>
					</div>
				)}

				<div className="related-content">
					<div className="section-title">
						<CustomIcon iconName="paperscroll" />
						<h3>{t("content.video.relatedPublications")}</h3>
					</div>
					{!!this.state.relatedArticles ? (
						this.state.relatedArticles?.map((article) => (
							<ArticleCard
								itemListName={
									ItemDataEventListName.VIDEO_ARTICLE_PAGE
								}
								link={`/post/${article.slug}`}
								article={article}
								key={article._id + "--relatedArticles"}
							/>
						))
					) : (
						<Loader loaderOnly />
					)}
				</div>
			</StyledContainer>
		)
	}
}

function mapState(state) {
	const user = state.user
	const videos = state.videos
	return {
		user,
		videos
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate,
	getPlaylists: userApi.endpoints.getPlaylists.initiate,
	// getSavedVideos: userApi.endpoints.getSavedVideos.initiate,
	patchUserContent: userApi.endpoints.patchUserContent.initiate
}
const connector = connect(mapState, mapDispatch)

export default connector(withRouter(VideoPage))

const StyledContainer = styled.div`
	h3 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 120%;
		letter-spacing: 0.02em;
		color: #212121;
	}

	.video-player {
		z-index: 9 !important;
		position: relative;

		&.fixed {
			box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.2);
			position: fixed;
			top: 54px;
			left: 0;
			right: 0;
		}

		img.thumbnail {
			width: 100%;
		}
	}

	.video-infos {
		background: #ecf0f5;
		padding: 32px 22px;

		.row-flex {
			display: flex;
			justify-content: space-between;
			align-items: start;

			img {
				max-width: 80px;
				max-height: 40px;
				object-fit: contain;
			}

			.publication-date {
				font-family: "Inter";
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 120%;
				letter-spacing: 0.04em;
				color: #212121;
			}
		}
	}

	.content-controllers {
		display: flex;
		align-items: center;
		gap: 16px;

		.controller {
			display: flex;
			align-items: center;
			gap: 5px;

			p {
				font-family: "Inter";
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 115%;
				color: #90a4ae;
				margin: 0;
			}
		}
	}

	.related-content {
		padding: 16px 0px 32px;

		min-height: 45vh;
		background: linear-gradient(
			180deg,
			#ff8a00 0%,
			#fdb955 47.92%,
			#ffc408 100%
		);

		.section-title {
			display: flex;
			align-items: center;
			gap: 16px;
			padding: 32px 22px 16px;

			h3 {
				font-family: "Inter";
				font-style: normal;
				font-weight: 700;
				font-size: 20px;
				line-height: 110%;
				letter-spacing: -0.00017em;
				color: #212121;
				margin: 0;
			}

			.--custom-icon {
				transform: scale(1.3) translateY(2px) !important;
			}
		}
	}

	.associated-content {
		background: #fff;
		padding: 32px 22px;

		.edito-card {
			position: relative;
			background: #fff3ce;
			border-radius: 8px;
			padding: 30px 24px;

			img.editorial-icon {
				position: absolute;
				top: -16px;
				left: 12px;
				height: 32px;
			}

			h5,
			p {
				font-family: "Inter";
				font-style: normal;
				color: #313b42;
				margin: 0;
			}

			h5 {
				font-weight: 700;
				font-size: 16px;
				line-height: 100%;
				margin-bottom: 4px;
			}
			p {
				font-weight: 400;
				font-size: 14px;
				line-height: 110%;
			}

			.card-content {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 64px;

				.--custom-icon {
					transform: rotate(180deg) scale(1.5) !important;
					width: 32px;
					height: 32px;
					margin-right: 6px;
				}
			}
		}
	}
`

const StyledVideoStory = styled.div`
	height: 100%;
	box-sizing: border-box;
	overflow: hidden;
	background: black;
	position: relative;

	.story-carousel {
		height: 100vh;

		.carousel .slide iframe {
			width: 100% !important;
			margin: 0 !important;
		}

		.placeholder {
			position: relative;
			height: 100vh;
		}
	}

	.story-navbar {
		width: 100%;
		box-sizing: border-box;
		padding: 16px 23px;
		z-index: 902 !important;
		background: linear-gradient(
			180deg,
			rgba(33, 33, 33, 1) 0%,
			rgba(33, 33, 33, 0.6) 50%,
			rgba(33, 33, 33, 0) 100%
		) !important;
		/* background: rgba(31, 31, 31, 0.9);
    backdrop-filter: blur(10px); */
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 32px;

		h5 {
			font-family: "Inter";
			color: #fff;
			text-align: center;
			margin: 0;
			font-size: 14px;
			max-height: 33px;

			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.--custom-icon {
			width: 32px;
			height: 32px;
		}
	}
`
