/* disable-typescript */

import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useHistory, useParams } from "react-router-dom"
import CustomNavbar from "../../components/CustomNavbar"
import customToast from "../../components/CustomToast"
import { Flex, Spacer } from "../../components/global"
import Loader from "../../components/Loader"
import ShareSheet from "../../components/ShareSheet"
import { store } from "../../redux"
import { getFirebaseToken } from "../../services/firebase"
import { isNativeIOS } from "../../tools/ios"
import { gtmPageView } from "../../tools/reactgaEvents"
import { StyledHomePage } from "../homePage/homePage"
import BackIcon from "../../assets/icons/back-orange.svg"
import { getContentFromSlug } from "../../services/content.services"
import styled from "styled-components"
import { userApi } from "../../redux/user/user.service"
import PDFViewer from "../../components/PDFViewer"
import PreviewCard from "../../components/PreviewCard"
import { ItemDataEventListName } from "../../interfaces"
import CustomIcon from "../../components/CustomIcon"

const InfographicPage = () => {
	const history = useHistory()
	const params = useParams()
	const { t } = useTranslation()
	const [infographic, setInfographic] = useState(null)
	const [imageLoaded, setImageLoaded] = useState(false)

	const documentURL = infographic?.image?.url

	const setAuthToken = useCallback(async () => {
		window.scrollTo(0, 0)
		const isAuthentified = await getFirebaseToken()

		if (!isAuthentified) {
			customToast(t("toast.error.notLogin"))
			history.replace("/login")
			return
		}
	}, [history, t])

	const fetchInfographic = useCallback(async () => {
		const infographic = await getContentFromSlug(params.slug)

		setInfographic(infographic)
	}, [params.slug])

	const fetchUser = useCallback(async () => {
		if (!infographic) return

		if (store.getState().user.isUninitialized)
			await store.dispatch(userApi.endpoints.getUser.initiate())

		if (
			infographic?.organisations.some(
				(el) =>
					el.uid ===
					store.getState().user.user?.organisations?.[0]?.uid
			)
		)
			return

		customToast(t("toast.error.notAllowed"))
		history.replace("/home")
	}, [infographic])

	useEffect(() => {
		window.scrollTo(0, 0)
		gtmPageView()
		setAuthToken()
		fetchInfographic()
	}, [])

	useEffect(() => {
		fetchUser()
	}, [infographic])

	if (!infographic)
		return (
			<StyledPage>
				<CustomNavbar hideShareBar />
				<div className="content">
					<div className="top-icons">
						<img
							src={BackIcon}
							alt=""
							onClick={() => history.goBack()}
						/>
					</div>
					<Loader background="#858585" />
				</div>
			</StyledPage>
		)

	const associatedContentCount =
		infographic?.associatedArticles?.length +
		infographic?.associatedContent?.length

	const contentList = [
		...infographic?.associatedArticles,
		...infographic?.associatedContent
	]

	return (
		<StyledPage>
			<CustomNavbar hideShareBar />
			<div className="content">
				<div className="top-icons">
					<img
						src={BackIcon}
						alt=""
						onClick={() => history.goBack()}
					/>
				</div>
				<div>
					{!documentURL.includes(".pdf") ? (
						<div>
							{!imageLoaded && <Loader background="#858585" />}
							<img
								className="core-image"
								src={documentURL}
								alt=""
								loading="lazy"
								onLoad={() =>
									setTimeout(() => setImageLoaded(true), 750)
								}
							/>
						</div>
					) : (
						<PDFViewer url={documentURL} />
					)}

					{associatedContentCount ? (
						<div className="associated-content-section">
							<Flex>
								<CustomIcon
									iconName="paperscroll-bold"
									scale={0.8}
								/>
								<h3>{`${t(
									"content.infographic.sources"
								)} (${associatedContentCount})`}</h3>
							</Flex>
							{contentList?.map((article) => (
								<Link
									key={article.slug}
									to={`/post/${article.slug}`}
								>
									<PreviewCard
										disabled={true}
										content={article}
										itemListName={
											ItemDataEventListName.INFOGRAPHIC_PAGE
										}
									/>
								</Link>
							))}
						</div>
					) : null}

					{isNativeIOS && <Spacer />}
				</div>
			</div>
			<ShareSheet />
		</StyledPage>
	)
}

export default InfographicPage

const StyledPage = styled(StyledHomePage)`
	background: #ecf0f5;
	box-sizing: border-box;
	padding: 55px 0px 0px;
	height: 100%;
	overflow: hidden;
	position: relative;

	touch-action: manipulation;

	.content {
		box-sizing: border-box;
		position: relative;
		height: 100%;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}

	.associated-content-section {
		padding: 32px 16px;

		h3 {
			font-family: Inter;
			font-size: 21px;
			font-weight: 500;
			line-height: 26px;
			letter-spacing: -0.01em;
			text-align: left;
		}
	}

	.core-image {
		width: 100%;
		height: auto;
	}

	.core-pdf {
		height: auto;
		border-radius: 8px;
		margin-top: 92px;
	}

	.top-icons {
		position: fixed;
		top: 72px;
		left: 0px;
		right: 0px;
		padding: 0 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		z-index: 99;
	}
`
