import React, { Component } from "react"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import styled from "styled-components"
import disableScroll from "disable-scroll"
import CustomIcon from "../CustomIcon"
import { Carousel } from "react-responsive-carousel"
import { CustomIndicators } from "../../view/postPage/postPage"
import { t } from "i18next"
import { iosSetBackgroundColor, isNativeIOS } from "../../tools/ios"

class ImageViewer extends Component {
	state = {
		preventSwipe: false,
		captionVisible: true,
		currentScale: 1,
		captionClamped: true
	}

	render() {
		return (
			<ViewerWrapper open={this.props.open}>
				<TransformWrapper
					initialScale={1}
					minScale={1}
					onZoomStart={() =>
						this.setState({
							preventSwipe: true
						})
					}
					onZoomStop={({ state }) =>
						this.setState({
							currentScale: state.scale,
							preventSwipe: false
						})
					}
					pinch={{ step: 100 }}
					panning={{ disabled: this.state.currentScale <= 1 }}
				>
					{({ resetTransform }) => (
						<>
							<div
								onClick={() => {
									this.props.onClose()
									resetTransform()
									this.setState({
										captionVisible: true
									})
									if (isNativeIOS)
										iosSetBackgroundColor("#FFFFFF")
									disableScroll.off()
								}}
							>
								<CustomIcon
									style={{
										position: "absolute",
										top: window.innerHeight * 0.1,
										right: "40px",
										zIndex: 999
									}}
									iconName="close_alt"
								/>
							</div>
							<TransformComponent>
								<div
									onClick={() =>
										this.setState({
											captionVisible:
												!this.state.captionVisible
										})
									}
								>
									<Carousel
										swipeable={this.state.currentScale <= 1}
										autoFocus={false}
										autoPlay={false}
										preventMovementUntilSwipeScrollTolerance={
											true
										}
										swipeScrollTolerance={
											this.state.preventSwipe ? 2000 : 20
										}
										interval={1e12}
										stopOnHover={true}
										infiniteLoop={false}
										showIndicators={false}
										showStatus={false}
										showThumbs={false}
										showArrows={false}
										centerMode={false}
										selectedItem={this.props.imageIndex}
										onChange={(to) =>
											this.props.updateIndex(to)
										}
										className="img-content"
									>
										{this.props.imgs?.map((img, index) => (
											<img
												key={img._id + index}
												src={img.url}
												alt=""
											/>
										))}
									</Carousel>
								</div>
							</TransformComponent>
							<CaptionSection
								visible={
									this.state.captionVisible ||
									!this.state.captionClamped
								}
							>
								<div className="indicators">
									{this.props.imgs?.map(
										(_, index) =>
											index < 14 && (
												<CustomIndicators
													key={
														index +
														"customindicatorsimgviewercomponent"
													}
													active={
														this.props
															.imageIndex ===
														index
													}
												/>
											)
									)}
								</div>
								<p className="title">
									{t("common.figure")}{" "}
									{this.props.imageIndex + 1}
								</p>

								<p
									onClick={() =>
										this.setState({
											captionClamped:
												!this.state.captionClamped
										})
									}
									className={`caption-text ${
										this.state.captionClamped
											? "lineclamp"
											: ""
									}`}
								>
									{
										this.props.imgs[this.props.imageIndex]
											?.legend
									}
								</p>
							</CaptionSection>
						</>
					)}
				</TransformWrapper>
			</ViewerWrapper>
		)
	}
}

export default ImageViewer

const ViewerWrapper = styled.div`
	display: ${(props) => (props.open ? "block" : "none")};
	width: 100%;
	height: calc(100% + 1px);
	background: rgba(0, 0, 0, 0.9);
	backdrop-filter: blur(4px);
	z-index: 999 !important;
	padding: 0px;
	position: fixed;
	overflow: hidden;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin-top: -1px;

	.img-content img {
		width: 90% !important;
		max-height: ${window.innerHeight * 0.6}px;
		object-fit: contain;
		//padding: 20px;
		object-fit: content;
		margin: 0 auto;
		margin-top: ${window.innerHeight * 0.6}px;
		transform: translateY(-${window.innerHeight * 0.45}px);
	}

	.carousel .slider-wrapper.axis-horizontal .slider {
		align-items: center !important;
	}
`

const CaptionSection = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	background: #4c5861;
	padding: 30px 30px 60px 30px;
	border-radius: 20px 20px 0px 0px;
	bottom: ${(props) => (props.visible ? 0 : -window.innerHeight * 0.18)}px;
	transition: bottom ease-in-out 0.3s;
	// opacity: ${(props) => (props.visible ? 1 : 0)};

	max-height: 70%;
	overflow-y: scroll;

	p {
		color: #fff;
		font-family: "Poppins";
		font-size: 14px;
		line-height: 20px;
		margin: 0;

		&.title {
			margin-bottom: 10px;
			font-weight: 700;
		}
	}

	.indicators {
		display: block;
		margin: auto;
		text-align: center;
	}

	.caption-text {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 100;

		&.lineclamp {
			-webkit-line-clamp: 2;
		}
	}
`
