import { useContext } from 'react';
import { SocketContext, SocketContextProps } from '../context/socket.context';

export const useSocket = (): SocketContextProps => {
    const context = useContext(SocketContext);

    if (context === undefined) {
        throw new Error('useSocket must be used within an SocketContext.Provider');
    }

    return context;
};
