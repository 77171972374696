import { createSlice } from "@reduxjs/toolkit";
import { SupportedLanguagesEnum } from "../../interfaces";

export interface GlobalState {
  contentLanguage: SupportedLanguagesEnum;
}

const initialState: GlobalState = {
  contentLanguage:
    localStorage.getItem("contentLanguage") !== "undefined"
      ? (localStorage.getItem("contentLanguage") as SupportedLanguagesEnum)
      : SupportedLanguagesEnum.EN,
};

export const globalSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setContentLanguage: (state, action) => {
      localStorage.setItem("contentLanguage", action.payload);
      state.contentLanguage = action.payload;
    },
  },
});

export const { setContentLanguage } = globalSlice.actions;

export default globalSlice.reducer;
