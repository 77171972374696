import React, { Component } from "react"
import { Modal } from "antd-mobile"
import { CustomButton } from "../global"
import { t } from "i18next"
import customToast from "../CustomToast"

class DownloadNativeModal extends Component {
	redirectToStore = () => {
		const isIOS =
			/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
		const isAndroid =
			/Android/.test(navigator.userAgent) && !window.MSStream

		if (isIOS)
			window.location.href =
				"https://apps.apple.com/fr/app/juisci/id1588725828"
		else if (isAndroid)
			window.location.href = `https://play.google.com/store/apps/details?id=com.juisci.app&hl=us&gl=US`
		else customToast(t("toast.error.global"))

		this.props.onClose()
	}

	render() {
		return (
			<div>
				<Modal
					wrapClassName="journal-modal"
					visible={this.props.visible}
					transparent
					maskTransitionName
					maskClosable={true}
					onClose={() => this.props.onClose()}
				>
					<p
						style={{
							color: "#212121",
							fontWeight: 600,
							fontFamily: "Poppins",
							textAlign: "center",
							marginBottom: 25,
							marginTop: 25
						}}
					>
						{t("modal.download.description")}
					</p>
					<CustomButton
						className="small"
						onClick={() => this.redirectToStore()}
						style={{ marginBottom: 10, fontSize: 14 }}
					>
						{t("modal.download.title")}
					</CustomButton>
					<CustomButton
						style={{
							fontSize: 14
						}}
						onClick={() => this.props.onClose()}
						className="white small"
					>
						{t("common.maybeLater")}
					</CustomButton>
				</Modal>
			</div>
		)
	}
}

export default DownloadNativeModal
