import { Component, useEffect, useState } from "react"
import styled from "styled-components"
import CustomNavbar from "../../components/CustomNavbar"
import HomeNavigation from "../../components/home/HomeNavigation"
import FadeIn from "react-fade-in"
import SkeletonCard from "../../components/skeleton/SkeletonCard"
import VideoCard from "../../components/VideoCard"
import { getContentCongress } from "../../services/content.services"
import { getPageBackground } from "../../tools/utils"
import { StyledHomePage } from "./homePage"
import InfographicCard from "../../components/InfographicCard"
import { store } from "../../redux"
import { userApi } from "../../redux/user/user.service"
import Loader from "../../components/Loader"
import { ContentFormatsEnum } from "../../interfaces/content.interface"
import { getFirebaseToken } from "../../services/firebase"
import NoResultDraw from "../../assets/images/illustrations/search_no_results.svg"
import { t } from "i18next"
import { getCongressList } from "../../services/congress.service"
import CustomIcon from "../../components/CustomIcon"
import FilterSheet from "../../components/FilterSheet"
import InfiniteScroll from "react-infinite-scroll-component"

class CongressPage extends Component {
	state = {
		contentLanguage: localStorage.getItem("contentLanguage"),
		congressContent: null,
		congressList: null,
		fetchParams: {
			limit: 10,
			offset: 0,
			total: 0
		},
		selectedCongress: null,
		openFilterSheet: false
	}

	componentDidMount() {
		this.fetchUser()
		this.fetchContentCongress()
		this.fetchCongressList()
	}

	async fetchUser() {
		const isAuthentified = !!(await getFirebaseToken())
		if (isAuthentified && store.getState().user.isUninitialized)
			await store.dispatch(userApi.endpoints.getUser.initiate())

		if (store.getState().user?.user?.country === "US")
			return this.props.history.push("/home")
	}

	async fetchContentCongress(congressId = null, filters = null) {
		const { docs: contentList, meta } = await getContentCongress({
			language: this.state.contentLanguage,
			congress: congressId || this.state.selectedCongress?._id,
			limit: this.state.fetchParams.limit,
			offset: this.state.fetchParams.offset,
			...filters
		})

		this.setState({ fetchParams: { ...this.state.fetchParams, ...meta } })

		const pinnedContents = contentList.filter((content) =>
			this.isPinnedContent(content)
		)
		const nonPinnedContents = contentList.filter(
			(content) => !this.isPinnedContent(content)
		)
		const sortedList = [...pinnedContents, ...nonPinnedContents]

		this.setState({
			congressContent:
				this.state.congressContent?.length && !filters?.language
					? [...this.state.congressContent, ...sortedList]
					: sortedList
		})
	}

	async handleFetchNext() {
		this.setState(
			{
				fetchParams: {
					...this.state.fetchParams,
					offset:
						this.state.fetchParams.offset +
						this.state.fetchParams.limit
				}
			},
			() => {
				this.fetchContentCongress()
			}
		)
	}

	async fetchCongressList() {
		const contentLanguage = localStorage.getItem("contentLanguage")
		const congressList = await getCongressList(contentLanguage)
		this.setState({ congressList })
	}

	handleFilterCongress(congress) {
		if (!congress && this.state.selectedCongress) {
			this.setState(
				{
					fetchParams: {
						offset: 0,
						total: 0,
						limit: 10
					},
					congressContent: null,
					selectedCongress: null
				},
				() => this.fetchContentCongress()
			)
		} else if (congress) {
			this.setState(
				{
					fetchParams: {
						offset: 0,
						total: 0,
						limit: 10
					},
					congressContent: null,
					selectedCongress: congress
				},
				() => this.fetchContentCongress(congress._id)
			)
		}
	}

	handleFilterContent(filters) {
		this.fetchContentCongress(
			null,
			filters
				? {
						medical_specialties: [filters.medicalSpecialty._id],
						tags: filters.medicalTags.length
							? filters.medicalTags?.map((tag) => tag._id)
							: null
				  }
				: null
		)
	}

	switchLanguage(contentLanguage) {
		this.setState(
			{
				contentLanguage
			},
			() => {
				localStorage.setItem("contentLanguage", contentLanguage)
				this.fetchContentCongress(null, { language: contentLanguage })
				this.fetchCongressList()
			}
		)
	}

	isPinnedContent(content) {
		const userOrganisation = store.getState().user.user.organisations?.[0]
		const isPinned = !!userOrganisation?.pinnedCongressContents?.some(
			(el) => el.content === content._id
		)
		return isPinned
	}

	renderContent(content) {
		if (content.content_format === ContentFormatsEnum.VIDEO) {
			const youtubeId = content?.sourceURL?.split("v=")[1]
			const youtubeThumbnail = `https://i3.ytimg.com/vi/${
				youtubeId?.split("&")[0]
			}/hqdefault.jpg`

			return (
				<VideoCard
					key={content._id + "render-video-congress"}
					isPinned={this.isPinnedContent(content)}
					video={{
						...content,
						thumbnail: !!content.apiVideo
							? content.apiVideo.thumbnail
							: youtubeThumbnail
					}}
				/>
			)
		}

		if (content.content_format === ContentFormatsEnum.INFOGRAPHIC)
			return (
				<InfographicCard
					key={content._id + "render-graphic-congress"}
					infographic={content}
					isPinned={this.isPinnedContent(content)}
				/>
			)
	}

	render() {
		if (store.getState().user.isUninitialized) return <Loader />

		const userOrganisation = store.getState().user.user.organisations?.[0]

		return (
			<StyledCongressPage style={{ background: getPageBackground()[1] }}>
				<CustomNavbar
					{...this.props}
					organisation={userOrganisation}
					onSwitchLanguage={(newLanguage) =>
						this.switchLanguage(newLanguage)
					}
				/>

				<HomeNavigation />
				<div className="filter-bar">
					<CongressFilterButton
						congressList={this.state.congressList}
						onChange={this.handleFilterCongress.bind(this)}
					/>

					{userOrganisation.organisationSettings?.discoveryFilter ? (
						<FilterSheet
							isOpen={this.state.openFilterSheet}
							currentSpecialty={null}
							onOpenSheet={() =>
								this.setState({
									openFilterSheet: true
								})
							}
							onCloseSheet={() =>
								this.setState({
									openFilterSheet: false
								})
							}
							onConfirmFilters={this.handleFilterContent.bind(
								this
							)}
						/>
					) : null}
				</div>

				<FadeIn>
					{this.state.congressContent ? (
						<InfiniteScroll
							dataLength={this.state.congressContent.length}
							next={this.handleFetchNext.bind(this)}
							hasMore={
								this.state.congressContent.length <
								this.state.fetchParams.total
							}
							loader={<Loader loaderOnly />}
							pullDownToRefresh={false}
							className="congress-page-content"
						>
							{this.state.congressContent.map((content) =>
								this.renderContent(content)
							)}
						</InfiniteScroll>
					) : (
						<FadeIn>
							<div className="congress-page-content">
								{Array(10)
									.fill()
									.map((_, index) => (
										<SkeletonCard
											key={`skeleton-card-${index}`}
										/>
									))}
							</div>
						</FadeIn>
					)}
					{this.state.congressContent?.length === 0 ? (
						<div className="empty-content">
							<img src={NoResultDraw} alt="" />
							<div>{t("content.congress.empty")}</div>
						</div>
					) : null}
				</FadeIn>
			</StyledCongressPage>
		)
	}
}

export default CongressPage

const CongressFilterButton = ({ congressList, onChange }) => {
	const [selectedCongress, setSelectedCongress] = useState(null)
	const [showDropdown, setShowDropdown] = useState(false)

	const handleChangeCongress = () => {
		onChange && onChange(selectedCongress)
		setShowDropdown(false)
	}

	useEffect(() => {
		handleChangeCongress()
	}, [selectedCongress])

	return (
		<StyledCongressFilterButton
			id="congress-filter-btn"
			onClick={() => setShowDropdown(!showDropdown)}
		>
			<div className="activator">
				{selectedCongress?.name || "All congress"}
			</div>
			<CustomIcon iconName="arrow-down" />

			<div
				className="dropdown"
				style={{
					display: showDropdown ? "flex" : "none",
					top:
						(document
							.getElementById("congress-filter-btn")
							?.getBoundingClientRect().bottom || 0) + 10
				}}
			>
				<button onClick={() => setSelectedCongress(null)}>
					{"All congress"}
				</button>
				{congressList?.map((congress) => (
					<button
						key={congress._id}
						onClick={() => setSelectedCongress(congress)}
					>
						{congress.name}
					</button>
				))}
			</div>
		</StyledCongressFilterButton>
	)
}

const StyledCongressFilterButton = styled.div`
	font-family: Inter;
	font-size: 14px;
	font-weight: 700;
	line-height: 17px;
	letter-spacing: -0.02em;
	text-align: left;

	background: #ffffff;
	padding: 6px 21px;
	min-width: 50%;
	max-width: 80%;
	height: 32px;
	border-radius: 100px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 12px;

	.activator {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		width: 100%;
	}

	.--custom-icon {
		height: 16px;
		width: auto;
	}

	.dropdown {
		background: #ffffff;
		position: absolute;
		left: 0;
		right: 0;
		z-index: 10;
		padding: 8px;
		box-sizing: border-box;
		flex-direction: column;
		gap: 8px;
		width: 100%;

		button {
			outline: none;
			border: none;
			background: #fff;
			color: #313b42;
			padding: 8px 16px;
			border-radius: 4px;
			font-family: Inter;
			font-size: 14px;
			font-weight: 700;
			line-height: 17px;
			letter-spacing: -0.02em;
			text-align: left;

			&:active {
				color: #fff;
				background: #313b42;
			}
		}
	}
`

const StyledCongressPage = styled(StyledHomePage)`
	.filter-bar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 10px 0;
	}

	.congress-page-content {
		box-sizing: border-box;
		padding: 21px 8px;

		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	.empty-content {
		font-size: 14px;
		font-weight: 500;
		font-family: "Poppins";
		color: #ffffff;
		text-align: center;

		img {
			display: block;
			margin: 24px auto;
		}
	}
`
