import { Col, Row } from "react-grid-system";
import toast from "react-hot-toast";
import { Avatar } from "./global";
import JuisciLogo from "../assets/core/icon-square.svg";

export default function customToast(content, type, id) {
  const duration = 3000;

  const defaultStyle = {
    fontFamily: "Poppins",
    fontWeight: "400",
    color: "#212121",
    width: "100%",
    background: "#F2F2F2",
    borderRadius: "0px",
    zIndex: 99,
  };

  switch (type) {
    default:
      const toastId = toast(
        () => (
          <Row
            onClick={() => toast.dismiss(toastId)}
            align='center'
            style={{ width: "100%" }}
          >
            <Col xs={1} style={{ padding: 0, marginRight: 5 }}>
              <Avatar src={JuisciLogo} height='30px' />
            </Col>
            <Col xs={10}>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  color: "#212121",
                  fontSize: "14px",
                  padding: 0,
                  margin: 0,
                }}
              >
                {content}
              </p>
            </Col>
          </Row>
        ),
        { style: defaultStyle, duration, id }
      );

      return toastId;
  }
}
