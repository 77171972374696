import { Component } from "react"
import {
	ProfileContent,
	ProfileHeader,
	MessageInput,
	UserButton,
	Spacer
} from "../../components/global"
import Loader from "../../components/Loader"
import MessageBar from "../../components/MessageBar"
import {
	getListFollowers,
	getListFollowings,
	getUser
} from "../../services/user.services"

import Fuse from "fuse.js"
import { NoResultText } from "../searchPage/searchPage"
import { withTranslation } from "react-i18next"
import { t } from "i18next"
import customToast from "../../components/CustomToast"

class NewMessage extends Component {
	state = {
		usersList: [],
		loading: true,
		searchValue: ""
	}

	async componentDidMount() {
		try {
			const { connected, connectSocket, refreshConversations } =
				this.props.socketHook

			if (!connected) connectSocket()

			refreshConversations()

			// this.props.socketHook.on("newConversation", (conversation) => {
			//   this.props.history.push(
			//     `/profile/messages/conversation/${conversation?._id}`
			//   );
			// });

			this.props.socketHook.newConversation()

			const [authUser, followings, followers] = await Promise.all([
				getUser(),
				getListFollowings(),
				getListFollowers()
			])

			// Remove duplicates from followers
			const followersNotFollowing = followers.filter((follower) => {
				return !followings.find(
					(following) => following.uid === follower.uid
				)
			})

			const usersList = [...followings, ...followersNotFollowing]

			this.setState({ authUser, usersList, loading: false })
		} catch (error) {
			console.error("Err NewMessage didMount", error.message)
			customToast(t("toast.error.global"))
			this.props.history.replace("/profile/messages")
		}
	}

	createConversation(user) {
		const { authUser } = this.state
		const other_uid = user?.uid
		const users = [authUser.uid, other_uid]

		this.props.socketHook.refreshConversations()

		const conversations = this.props.socketHook.conversationsList

		for (var i = 0; i < conversations.length; i++) {
			for (var j = 0; j < conversations[i].users.length; j++) {
				if (conversations[i].users[j]?.uid === other_uid) {
					this.props.history.push(
						`/profile/messages/conversation/${conversations[i]?._id}`
					)
					return
				}
			}
		}
		this.props.socketHook.createConversation(users)

		// this.setState({ loading: true });
	}

	searchUsers() {
		const { usersList, loading, searchValue } = this.state

		const fuse = new Fuse(usersList, {
			keys: [
				"fullname",
				"firstname",
				"lastname",
				"username",
				"primary_author",
				"profession",
				"main_specialty"
			],
			includeScore: true,
			findAllMatches: true,
			threshold: 0.3
		})

		var results = fuse.search(this.state.searchValue)

		var usersResults = results.map((result) => {
			return result.item
		})

		if (loading) {
			return <Loader />
		} else {
			if (searchValue.length > 0) {
				if (usersResults.length > 0) {
					return usersResults.map((user) => {
						return (
							<UserButton
								key={user.uid}
								className="newMessage"
								user={user}
								onClick={() => this.createConversation(user)}
							/>
						)
					})
				} else {
					return (
						<center>
							<Spacer height="15px" />
							<NoResultText color="#212121">
								No users found...
							</NoResultText>
						</center>
					)
				}
			} else {
				return (
					<center>
						<Spacer height="15px" />
						<NoResultText color="#212121">
							Search for a user
						</NoResultText>
					</center>
				)
			}
		}
	}

	render() {
		const { loading, usersList, searchValue } = this.state
		return (
			<>
				<MessageBar
					{...this.props}
					hideNewMsgIcon={true}
					page="new_conv"
				/>
				{loading ? (
					<Loader />
				) : (
					<>
						<ProfileHeader>
							<MessageInput
								placeholder={this.props.t(
									"messages.searchUsers"
								)}
								onInput={(e) =>
									this.setState({
										searchValue: e.target.value
									})
								}
							/>
						</ProfileHeader>
						<ProfileContent
							style={{
								padding: "0px 21px 50px",
								background: "transparent"
							}}
						>
							{searchValue.length > 0 ? (
								this.searchUsers()
							) : (
								<>
									{usersList.length > 0 ? (
										usersList.map((user) => {
											return (
												<UserButton
													key={user.uid}
													style={{
														background:
															"transparent"
													}}
													className="newMessage"
													onClick={() =>
														this.createConversation(
															user
														)
													}
													user={user}
													btnText="Message"
												/>
											)
										})
									) : (
										<p style={{ textAlign: "center" }}>
											{t("messages.noUsersFound")}
										</p>
									)}
								</>
							)}
						</ProfileContent>
					</>
				)}
			</>
		)
	}
}

export default withTranslation()(NewMessage)
