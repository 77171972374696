import React, { Component } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { Spacer } from "../../../components/global"
import Loader from "../../../components/Loader"
import SimpleNavbar from "../../../components/SimpleNavbar"
import { t } from "i18next"
import i18n from "../../../config/i18n"
import { userApi } from "../../../redux/user/user.service"
import { getProfessionsList } from "../../../services/professions.services"
import customToast from "../../../components/CustomToast"
import { getFirebaseToken } from "../../../services/firebase"
import { store } from "../../../redux"
import { GTMBasicEvent, OrganisationUid } from "../../../interfaces"
import { gtmBasicEvent } from "../../../tools/reactgaEvents"

export const MOST_POPULAR = [
	"physician",
	"medical-resident",
	"medical-student",
	"nurse-practitioner",
	"registered-nurse",
	"physican-assistant",
	"pharmacist"
]

const ROYAL_CANIN_PROFESSIONS = ["veterinarian", "veterinarian-technician"]

class OnboardingProfession extends Component {
	state = {
		professions: undefined,
		loaded: false
	}

	async componentDidMount() {
		try {
			const isAuthentified = await getFirebaseToken()

			if (!isAuthentified) {
				customToast(t("toast.error.notLogin"))
				this.props.history.replace("/login")
				return
			}

			/** Getting user status before redirecting or not */
			this.getUserStatus()

			/** Get professions */
			getProfessionsList().then((professions) => {
				this.setState({ professions })
			})

			window.scrollTo(0, 0)
		} catch (error) {
			console.error("Err componentDidMount", error.message)
		}
	}

	/** On profession selected */
	handleSelectProfession(profession) {
		this.setState(
			{
				selection: profession
			},
			() =>
				setTimeout(() => {
					this.props.history.push({
						pathname: "/signup/onboarding/specialty",
						state: {
							profession,
							specialties: profession.medical_specialties,
							...this.props.location.state
						}
					})
				}, 300)
		)
	}

	async getUserStatus() {
		if (!this.props.user.user) await this.props.getUser()

		if (this.props.user.user.profession)
			this.props.history.replace("/profile")
		else if (!this.props.user.user.country && !this.props.location.state)
			this.props.history.replace({
				pathname: "/signup/onboarding/profile",
				state: {
					user: this.props.user.user
				}
			})
		else {
			// view_onboarding event
			gtmBasicEvent(GTMBasicEvent.VIEW_ONBOARDING)
			this.setState({ loaded: true })
		}
	}

	filterProfessionsByOrganisation(prof, reverse = false) {
		const settings =
			this.props.user?.user?.organisations?.[0]?.organisationSettings

		if (!settings?.professions?.length)
			return reverse
				? !MOST_POPULAR.includes(prof.uid)
				: MOST_POPULAR.includes(prof.uid)

		return reverse
			? !settings.professions.includes(prof._id)
			: settings.professions.includes(prof._id)
	}

	render() {
		const { professions, selection, loaded } = this.state
		const userOrganisation = this.props.user?.user?.organisations?.[0]?.uid
		const isRoyalCanin = userOrganisation === OrganisationUid.ROYAL_CANIN

		if (!professions || !loaded) return <Loader />

		return (
			<StyledOnboarding>
				<SimpleNavbar preventGoBack />
				<div className="header">
					<h2>{t("signup.onboarding.h2.profession")}</h2>
					<h5>{t("signup.onboarding.h5.profession")}</h5>
				</div>
				<div
					className={
						isRoyalCanin
							? "selection-rows"
							: "selection-rows popular"
					}
				>
					<h5>{t("signup.onboarding.indicate.profession")}:</h5>
					<Spacer />
					<h3>
						{isRoyalCanin
							? t("common.professions")
							: t("common.mostPopular")}
					</h3>
					{professions
						.filter((prof) =>
							this.filterProfessionsByOrganisation(prof)
						)
						.map((profession, index) => (
							<div
								className="row"
								key={profession + "prfsrw" + index}
							>
								<p>
									{
										profession.translations[
											i18n.resolvedLanguage
										]
									}
								</p>
								<button
									className={
										selection?.uid === profession?.uid
											? "selected"
											: ""
									}
									onClick={() =>
										this.handleSelectProfession(profession)
									}
								>
									{t("common.select")}
								</button>
							</div>
						))}
				</div>
				{isRoyalCanin ? null : (
					<div className="selection-rows">
						<h3>{t("common.otherProfessions")}</h3>
						{professions
							.filter((prof) =>
								this.filterProfessionsByOrganisation(prof, true)
							)
							.sort((a, b) =>
								a.translations[
									i18n.resolvedLanguage
								].localeCompare(
									b.translations[i18n.resolvedLanguage]
								)
							)
							.map((profession, index) => (
								<div
									className="row"
									key={profession + "prfsrw" + index}
								>
									<p>
										{
											profession.translations[
												i18n.resolvedLanguage
											]
										}
									</p>
									<button
										className={
											selection?.uid === profession?.uid
												? "selected"
												: ""
										}
										onClick={() =>
											this.handleSelectProfession(
												profession
											)
										}
									>
										{t("common.select")}
									</button>
								</div>
							))}
					</div>
				)}
			</StyledOnboarding>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(OnboardingProfession)

export const StyledOnboarding = styled.div`
	padding: 80px 0;
	background: #fff;
	min-height: 100vh;
	max-height: 100%;
	box-sizing: border-box;

	h5 {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 110%;
		text-align: center;
		letter-spacing: 0.02em;
		color: #212121;
		margin: 0;
		padding: 0 15px;
	}

	h2 {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 100%;
		text-align: center;
		color: #ce0868;
		margin: 0 0 8px;
	}

	h3 {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 700;
		font-size: 21px;
		line-height: 100%;
		color: #ce0868;
	}

	.header {
		padding: 0 60px;
		margin-bottom: 24px;
		margin-top: 16px;
	}

	.selection-rows {
		padding: 30px 22px;

		&.popular {
			background-color: #fff9e6;
		}

		h3,
		h5 {
			text-align: left;
			margin: 0;
			padding: 0;
		}

		.row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 20px;
			padding: 16px 0;
			border-bottom: 1px solid #212121;

			&.border-none {
				border: none;
				padding-bottom: 0px;
			}

			p {
				font-family: "Poppins";
				font-style: normal;
				font-weight: 700;
				font-size: 15px;
				line-height: 130%;
				display: flex;
				align-items: center;
				letter-spacing: 0.02em;
				color: #212121;
			}

			button {
				outline: none;
				border: 1px solid #d2dce2;
				box-shadow: none;
				background-color: #fff;
				padding: 12px 16px;
				border-radius: 100px;
				/** Font style */
				font-family: "Poppins";
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 100%;
				text-align: right;
				letter-spacing: 0.02em;
				color: #212121;
				transition: all ease-out 0.3s;

				&.selected {
					background-color: #fff3ce;
					border-color: #ff8800;
					color: #ff8800;
				}
			}
		}
	}

	.content-section {
		padding: 10px 22px 50px;

		.avatar-image {
			display: block;
			margin: auto auto 16px;
			width: 200px;
			height: 200px;
			object-fit: cover;
			border-radius: 200px;
			background-color: #f9f9f9;
			border: 1px solid #d2dce2;
		}

		.upload-button {
			display: block;
			margin: auto;
			border: 1px solid #d2dce2;
			border-radius: 100px;

			& > * {
				border-radius: 100px;
				background: transparent !important;
				& > * {
					border-radius: 100px;
					background: transparent !important;
				}
			}

			.btn-text {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 8px;
				font-weight: 700;
				text-align: center;
				padding: 4px 0;
			}
		}

		.default-grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			align-items: center;
			justify-content: center;
			justify-items: center;
			grid-column-gap: 12px;
			grid-row-gap: 24px;

			img {
				width: 81px;
				height: 81px;
				border-radius: 100px;
				border: 3px solid #fff;
				transition: all ease-out 0.3s;
			}

			img.picked {
				border: 3px solid #212121;
				border-radius: 100px;
			}
		}

		.tags-flex {
			display: flex;
			flex-wrap: wrap;
			gap: 8px;
			justify-content: start;
			align-items: center;
		}

		.tag {
			border: 1px solid #d2dce2;
			padding: 10px;
			border-radius: 100px;
			font-family: "Roboto";
			font-style: normal;
			font-weight: 400;
			font-size: 15px;
			line-height: 100%;
			color: #212121;
			transition: all ease-out 0.3s;

			&.selected {
				border-color: #fff3ce;
				background-color: #fff3ce;
				color: #ff8800;
			}
		}
	}

	.footer {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 21px 22px 50px;
		background-color: #fff;
		transform: translateY(0px);
		transition: transform ease-out 0.3s;

		&.hide {
			transform: translateY(100px);
		}

		& :nth-child(2) {
			margin-top: 10px;
		}
	}

	p.empty-text {
		margin: 30px auto;
	}
`
