import { Component } from "react"
import styled from "styled-components"
import {
	CustomContainer,
	Spacer,
	Divider,
	CustomButton
} from "../../components/global"
import { CustomInput } from "../../components/CustomInput"
import disableScroll from "disable-scroll"
import {
	deletePlaylist,
	getPlaylist,
	removeFromPlaylist,
	renamePlaylist,
	sharePlaylist
} from "../../services/playlists.services"
import SettingsNavbar from "../../components/SettingsNavbar"
import { Row, Col } from "react-grid-system"
import CustomIcon from "../../components/CustomIcon"
import { SingleLoader } from "../../components/Loader"
import { withTranslation } from "react-i18next"
import customToast from "../../components/CustomToast"
import { connect } from "react-redux"
import { userApi } from "../../redux/user/user.service"
import i18n from "../../config/i18n"
import { BottomSheet } from "react-spring-bottom-sheet"
import { kycMiddleware } from "../../tools/utils"
import {
	convertContentToItemData,
	gtmItemData,
	gtmItemsData
} from "../../tools/reactgaEvents"
import { t } from "i18next"
import { getFirebaseToken } from "../../services/firebase"
import { iosGenerateHaptic, iosPlaySound } from "../../tools/ios"
import {
	HapticEffect,
	ItemDataEventListName,
	ItemDataVariant,
	ItemsDataEvent,
	SoundEffect
} from "../../interfaces"
import ShareSheet from "../../components/ShareSheet"
import PreviewCard from "../../components/PreviewCard"

class PlaylistPage extends Component {
	state = {
		articles: undefined,
		showSheet: false,
		editSheet: false,
		title: "",
		inputTitle: undefined,
		btnLoading: false
	}

	async componentDidMount() {
		disableScroll.off()
		window.scrollTo(0, 0)
		const { isMe } = this.props
		const playlistId = this.props.match.params.id

		const isAuthenticated = await getFirebaseToken()

		if (isMe && !isAuthenticated) {
			this.props.history.replace("/login")
		} else {
			// Get playlist informations
			getPlaylist(playlistId)
				.then((res) => {
					this.setState({
						...res,
						inputTitle: res.title,
						articles: res.playlist
					})

					gtmItemsData(
						ItemsDataEvent.VIEW_ITEM_LIST,
						convertContentToItemData(
							res.playlist,
							ItemDataEventListName.PLAYLIST_PAGE,
							ItemDataVariant.ARTICLE
						)
					)
				})
				.catch((error) => {
					console.error("Err PlaylistPage getPlaylist", error.message)
					customToast(t("toast.error.global"))
					// this.props.history.replace("/home");
				})
		}
	}

	componentDidUpdate() {
		const { userLoaded, articles } = this.state

		if (!!articles && userLoaded) {
			disableScroll.off()
		}
	}

	async deleteFromPlaylist(slug) {
		const playlistId = this.props.match.params.id

		await removeFromPlaylist(slug, playlistId)
		this.props.getPlaylists()
		this.setState({ articles: undefined })
		getPlaylist(playlistId).then((res) => {
			this.setState({ articles: res.playlist })
		})
		customToast(t("toast.success.unsavedArticle"))
	}

	getAllArticles() {
		return this.state.articles.map((article) => (
			<PreviewCard
				key={article._id + "--profile-card-key"}
				disabled={!this.props.isMe}
				content={article}
				itemListName={ItemDataEventListName.PLAYLIST_PAGE}
				onRemove={() => this.deleteFromPlaylist(article?.slug)}
			/>
		))
	}

	async handleSharePlaylist() {
		const { user, title } = this.state
		const { isMe } = this.props
		const _id = this.props.match.params.id
		const myUid = this.props.user?.user?.uid
		const isAuthenticated = await getFirebaseToken()

		if (!!isAuthenticated && !this.props.user.user.profession)
			kycMiddleware(this, t("toast.error.notOnboarded.sharePlaylist"))
		else if (navigator.share) {
			navigator
				.share({
					title: document.title,
					text:
						i18n.language === "fr"
							? `Découvrez ma playlist ${title} sur Juisci !`
							: `Discover my playlist ${title} on Juisci now!`,
					url: `/profile/user/${
						isMe ? myUid : user?.uid
					}/playlist/${_id}?shared=${myUid ?? "null"}`
				})
				.then(() => {
					customToast(t("toast.success.sharedPlaylist"))
					iosGenerateHaptic(HapticEffect.SUCCESS)
					iosPlaySound(SoundEffect.SHARE)
					sharePlaylist(_id)
					gtmItemData({
						event: "article_share",
						item_name: title,
						item_list_name: window.location.pathname,
						item_id: _id,
						item_category3: "playlist"
					})
				})
				.catch((err) => {
					throw err
				})
		} else {
			customToast(t("toast.error.browser"))
		}
	}

	async editPlaylist() {
		this.setState({ btnLoading: true })

		const playlist_id = this.props.match.params.id
		const { isMe } = this.props

		if (isMe) {
			await renamePlaylist(playlist_id, this.state.inputTitle)
			customToast(t("toast.success.updatedPlaylist"))
			this.setState({
				showSheet: false,
				editSheet: false,
				btnLoading: false,
				title: this.state.inputTitle
			})
			this.props.getPlaylists()
		}
	}

	deletePlaylist() {
		const playlist_id = this.props.match.params.id
		const { isMe } = this.props

		if (isMe) {
			deletePlaylist(playlist_id).then(() =>
				this.props.getPlaylists().then(() => {
					this.props.getSaves().then(() => {
						this.props.history.replace("/profile")
						customToast(
							this.props.t("toast.success.deletedPlaylist"),
							"success"
						)
					})
				})
			)
		}
	}

	render() {
		const { articles, showSheet, editSheet, title, btnLoading } = this.state

		const { isMe, t } = this.props

		return (
			<>
				<SettingsNavbar
					{...this.props}
					goBackPlaylist={
						this.props.history.location.search === "?shared"
					}
					goBack={() =>
						!!this.props.location.key
							? this.props.history.goBack()
							: this.props.history.replace("/home")
					}
					shareBtn
					shareFunction={() => {
						this.setState({ showSheet: true })
					}}
					pageTitle={
						<div>
							<span
								style={{
									fontSize: "12px",
									fontWeight: "normal"
								}}
							>
								Playlist
							</span>
							<br />
							<span>{title}</span>
						</div>
					}
				/>
				<Spacer height="67px" />

				<ShareSheet type="playlist" />

				<CustomContainer
					style={{
						background: "#F7F8FC",
						padding: "20px 20px 50px 20px"
					}}
				>
					{!articles ? (
						<SingleLoader />
					) : articles.length === 0 ? (
						<p className="not-found-text">
							{this.props.t("playlist.findPublications")}
						</p>
					) : (
						<>{this.getAllArticles()}</>
					)}
				</CustomContainer>

				<BottomSheet
					expandOnContentDrag
					open={showSheet}
					onDismiss={() => this.setState({ showSheet: false })}
				>
					<SheetWrapper>
						{/* EDIT */}
						{isMe && (
							<Row
								style={{ margin: 0 }}
								gutterWidth={45}
								onClick={() =>
									this.setState({ editSheet: true })
								}
							>
								<Col xs={1}>
									<CustomIcon
										iconName="edit_pen"
										color="#212121"
									/>
								</Col>
								<Col xs={10}>
									<p
										style={{
											fontSize: "17px",
											fontWeight: 600,
											margin: 0,
											marginTop: -5
										}}
									>
										{t("sheet.playlist.edit.title")}
									</p>
									<p
										style={{
											fontSize: "15px",
											fontWeight: 400,
											margin: 0
										}}
									>
										{t("sheet.playlist.edit.subline")}
									</p>
								</Col>
							</Row>
						)}

						<Spacer />

						{/* SHARE */}
						<Row
							style={{ margin: 0 }}
							gutterWidth={45}
							onClick={this.handleSharePlaylist.bind(this)}
						>
							<Col xs={1}>
								<CustomIcon
									iconName="share-alt"
									scale={0.8}
									color="#212121"
								/>
							</Col>
							<Col xs={10}>
								<p
									style={{
										fontSize: "17px",
										fontWeight: 600,
										margin: 0,
										marginTop: -5
									}}
								>
									{t("sheet.playlist.share.title")}
								</p>
								<p
									style={{
										fontSize: "15px",
										fontWeight: 400,
										margin: 0
									}}
								>
									{t("sheet.playlist.share.subtitle")}
								</p>
							</Col>
						</Row>

						<Spacer />

						{/* DELETE */}
						{isMe && (
							<Row
								style={{ margin: 0 }}
								gutterWidth={45}
								onClick={this.deletePlaylist.bind(this)}
							>
								<Col xs={1}>
									<CustomIcon
										iconName="trash"
										color="#212121"
										increase
									/>
								</Col>
								<Col xs={10}>
									<p
										style={{
											fontSize: "17px",
											fontWeight: 600,
											margin: 0,
											marginTop: -5
										}}
									>
										{t("sheet.playlist.delete.title")}
									</p>
									<p
										style={{
											fontSize: "15px",
											fontWeight: 400,
											margin: 0
										}}
									>
										{t("sheet.playlist.delete.subtitle")}
									</p>
								</Col>
							</Row>
						)}
					</SheetWrapper>
				</BottomSheet>

				<BottomSheet
					expandOnContentDrag
					open={editSheet}
					onDismiss={() => this.setState({ showSheet: false })}
				>
					<SaveWrapper>
						<div
							style={{
								paddingBottom: "200px",
								paddingTop: "7px"
							}}
						>
							<div className="edit-title-header">
								<div style={{ width: 13 }} />
								<h5>{t("sheet.playlist.edit.title")}</h5>
								<CustomIcon
									iconName="times"
									onClick={() =>
										this.setState({
											editSheet: false,
											inputTitle: title
										})
									}
								/>
							</div>

							<Divider margin="25px 0 40px 0" />
							<span>{t("playlist.create.name")}</span>
							<Spacer hieght="5px" />
							<CustomInput
								placeholder={t("common.title")}
								style={{ fontSize: "21px" }}
								value={this.state.inputTitle}
								onInput={(e) =>
									this.setState({
										inputTitle: e.target.value
									})
								}
							/>
							<Divider />
							<CustomButton
								className={
									btnLoading
										? "loading disabled"
										: "purple-gradient"
								}
								onClick={this.editPlaylist.bind(this)}
							>
								{t("common.save")}
							</CustomButton>
						</div>
					</SaveWrapper>
				</BottomSheet>
			</>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}
const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate,
	getPlaylists: userApi.endpoints.getPlaylists.initiate,
	getSaves: userApi.endpoints.getSaves.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(PlaylistPage))

const SheetWrapper = styled.div`
	padding: 55px 5px;
	min-height: ${window.innerHeight / 3}px;
`

const SaveWrapper = styled.div`
	padding: 30px 25px;
	position: relative;

	.edit-title-header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		h5 {
			margin: 0;
		}
	}

	h5 {
		font-family: "Poppins";
		font-weight: 600;
		font-size: 16px;
		line-height: 120%;
		color: #212121;
		margin: 0;
	}

	p {
		font-family: "Poppins";
		font-weight: 600;
		font-size: 18px;
		line-height: 120%;
		margin: 0;
		margin-top: 2px;
		margin-left: 5px;
		color: #212121;
	}

	span {
		font-family: "Poppins";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 120%;
		color: #212121;
	}
`
