import React, { Component } from "react"
import { t } from "i18next"
import FadeIn from "react-fade-in/lib/FadeIn"
import { connect } from "react-redux"
import styled from "styled-components"
import customToast from "../../components/CustomToast"
import EditProfileBar from "../../components/EditProfileBar"
import Loader from "../../components/Loader"
import i18n from "../../config/i18n"
import { userApi } from "../../redux/user/user.service"
import { patchUser } from "../../services/user.services"
import { FORMAT_PREFERENCES } from "../signupPage/onboarding/onboardingFormat"
import { StyledOnboarding } from "../signupPage/onboarding/onboardingProfession"
import { store } from "../../redux"
import { setUser } from "../../redux/user/user.reducer"
import { getFirebaseToken } from "../../services/firebase"

class EditPreferredContentPage extends Component {
	state = {
		isEdited: false,
		formats: []
	}

	async componentDidMount() {
		const { user } = this.props.user
		const isAuthentified = await getFirebaseToken()
		if (!isAuthentified) {
			this.props.history.replace("/login")
			return
		}

		if (!user) this.props.getUser()

		this.setState({
			formats: user.preferredFormats
		})
	}

	handleSubmit(event) {
		event.preventDefault()
		const { formats } = this.state

		customToast(t("toast.success.editedPreferredContent"), "success")

		patchUser({ preferredFormats: formats }).then(async (updatedUser) => {
			store.dispatch(
				setUser({
					...this.props.user.user,
					preferredFormats: updatedUser.preferredFormats
				})
			)
			setTimeout(() => this.props.history.replace("/settings"), 1500)
		})
	}

	handleSelect(pref) {
		const { formats, isEdited } = this.state
		if (!isEdited) this.setState({ isEdited: true })
		if (formats.includes(pref))
			this.setState({ formats: formats.filter((el) => el !== pref) })
		else this.setState({ formats: [...formats, pref] })
	}

	render() {
		const { isEdited, formats } = this.state

		return (
			<form onSubmit={this.handleSubmit.bind(this)} action="#">
				<EditProfileBar
					{...this.props}
					title={t("profile.editProfile.preferredContent")}
					edited={isEdited}
					save
				/>
				{!!formats ? (
					<PageContainer>
						<h2>{t("profile.editProfile.content.h2")}</h2>
						<h5>{t("profile.editProfile.content.h5")}</h5>
						<div className="selection-rows">
							<FadeIn>
								{FORMAT_PREFERENCES.map((pref) => (
									<div
										className="row"
										key={pref.value + "--key"}
									>
										<p>{pref[i18n.resolvedLanguage]}</p>
										<button
											type="button"
											className={
												formats?.includes(pref.value)
													? "selected"
													: ""
											}
											onClick={() =>
												this.handleSelect(pref.value)
											}
										>
											{formats?.includes(pref.value)
												? t("common.selected")
												: t("common.select")}
										</button>
									</div>
								))}
							</FadeIn>
						</div>
					</PageContainer>
				) : (
					<Loader />
				)}
			</form>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}
const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(EditPreferredContentPage)

const PageContainer = styled(StyledOnboarding)`
	background: #ecf0f5;
`
