import React, { Component } from "react"
import {
	CustomButton,
	CustomContainer,
	CustomInput,
	CustomTextarea,
	DisabledCustomButton,
	Divider,
	Spacer
} from "../../components/global"
import styled from "styled-components"
import { WhiteSpace, Toast } from "antd-mobile"
import {
	addUserAward,
	deleteUserAward,
	getUser
} from "../../services/user.services"
import Loader from "../../components/Loader"
import disableScroll from "disable-scroll"
import EditProfileBar from "../../components/EditProfileBar"
import CustomIcon from "../../components/CustomIcon"
import { Row, Col } from "react-grid-system"
import CustomSelect from "../../components/customSelect"
import { withTranslation } from "react-i18next"
import customToast from "../../components/CustomToast"
import { connect } from "react-redux"
import { userApi } from "../../redux/user/user.service"
import { getFirebaseToken } from "../../services/firebase"

class EditAwardsPage extends Component {
	state = {
		userInfos: {},
		loading: true,
		currentAwards: [],
		addingAwards: [],
		deletingAwards: [],
		currentLabel: "2022",
		currentContent: "",
		years: [],
		isEdited: false
	}

	async componentDidMount() {
		window.scrollTo(0, 0)
		disableScroll.off() // Disable Scroll

		// Selectable date init...
		let { years } = this.state
		for (let i = new Date().getFullYear(); i >= 1950; i--) {
			years.push(i)
		}
		this.setState({ years })

		const isAuthentified = await getFirebaseToken()
		if (isAuthentified === null) {
			// Redirect home if not found
			this.props.history.push("/login")
		} else {
			this.loadAwards()
		}
	}

	loadAwards = () => {
		let { currentAwards } = this.state
		getUser().then((res) => {
			currentAwards = res?.awards
			this.setState({ currentAwards, loading: false })
		})
	}

	handleSubmit = async (e) => {
		e.preventDefault()
		let { addingAwards, deletingAwards } = this.state

		if (addingAwards.length > 0)
			await addUserAward(addingAwards).then(async (_) => {
				if (deletingAwards.length > 0)
					await deleteUserAward(deletingAwards)
			})
		else if (deletingAwards.length > 0)
			await deleteUserAward(deletingAwards)

		customToast(this.props.t("toast.success.awards"), "success")

		await this.props.getUser().then(() => this.props.history.goBack())
	}

	handleAdd() {
		const { currentContent, currentLabel, addingAwards, currentAwards } =
			this.state

		let data = {
			label: currentLabel,
			content: currentContent
		}

		addingAwards.push(data)
		currentAwards.push(data)
		this.setState({ addingAwards, currentAwards })
		this.setState({
			currentContent: "",
			currentLabel: "2021",
			isEdited: true
		})
	}

	handleDelete(award) {
		let { currentAwards, deletingAwards } = this.state
		let i = currentAwards.indexOf(award)
		currentAwards.splice(i, 1)
		deletingAwards.push(award)
		deletingAwards.forEach(function (v) {
			delete v._id
		})

		this.setState({ currentAwards, deletingAwards, isEdited: true })
	}

	render() {
		const { currentAwards, currentContent, loading, years, isEdited } =
			this.state
		const { t } = this.props
		return (
			<>
				<form onSubmit={this.handleSubmit.bind(this)} action="#">
					<EditProfileBar
						{...this.props}
						title={t("profile.editProfile.editAwards.title")}
						isEdited={isEdited}
						save
					/>
					{loading ? (
						<Loader />
					) : (
						<CustomContainer
							style={{ padding: "10px 30px 100px 30px" }}
						>
							<Spacer height="20px" />
							<CustomInputLabel for="label">
								{t("profile.editProfile.awards")}
							</CustomInputLabel>
							{currentAwards?.map((award, index) => {
								return (
									<div>
										<Row
											key={`${index}-award`}
											justify="start"
											align="center"
										>
											<Col xs={2}>
												<p
													style={{
														fontSize: "12px",
														fontWeight: 700,
														color: "#FE5763"
													}}
												>
													{award.label}
												</p>
											</Col>
											<Col xs={7}>
												<p
													style={{
														fontSize: "14px",
														fontWeight: 400,
														lineHeight: "18px",
														color: "#212121"
													}}
												>
													{award.content}
												</p>
											</Col>
											<Col xs={3}>
												<Row
													style={{ margin: 0 }}
													align="middle"
													justify="end"
												>
													<div
														style={{
															transform:
																"scale(0.8)"
														}}
														onClick={() =>
															this.handleDelete(
																award
															)
														}
													>
														<CustomIcon
															iconName="trash"
															color="#81939C"
														/>
													</div>
												</Row>
											</Col>
										</Row>
										<Divider style={{ margin: "5px 0" }} />
									</div>
								)
							})}
							<Spacer height="40px" />
							<CustomInputLabel for="label">
								{t("profile.editProfile.editAwards.add")}
							</CustomInputLabel>
							<CustomSelect
								defaultValue={years[0]}
								options={years}
								placeholder="Year"
								name="label"
								onChange={(e) =>
									this.setState({
										currentLabel: e.target.value,
										isEdited: true
									})
								}
								value={this.state.currentLabel}
								required={false}
							/>

							<WhiteSpace size="sm" />
							<CustomTextarea
								style={{ fontSize: "16px", fontWeight: 600 }}
								rows="5"
								placeholder={t("common.award")}
								name="content"
								onInput={(e) =>
									this.setState({
										currentContent: e.target.value,
										isEdited: true
									})
								}
								value={this.state.currentContent}
								required={false}
							/>
							<WhiteSpace size="sm" />

							{currentContent.length > 0 &&
							currentContent.length > 0 ? (
								<CustomButton
									type="button"
									onClick={this.handleAdd.bind(this)}
								>
									{t("common.add")}
								</CustomButton>
							) : (
								<DisabledCustomButton type="button">
									{t("common.add")}
								</DisabledCustomButton>
							)}
						</CustomContainer>
					)}
				</form>
			</>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(EditAwardsPage))

const CustomInputLabel = styled.div`
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 900;
	font-size: 21px;
	line-height: 30px;
	letter-spacing: 0.02em;
	color: #212121;
	margin-bottom: 12px;
`
