import React, { Component } from "react"
import {
	CustomContainer,
	CustomInput,
	CustomTextarea,
	Spacer
} from "../../components/global"
import styled from "styled-components"
import { Toast } from "antd-mobile"
import Loader from "../../components/Loader"
import disableScroll from "disable-scroll"
import EditProfileBar from "../../components/EditProfileBar"
import { getUser, patchUser } from "../../services/user.services"
import { getProfessionSpecialization } from "../../services/professions.services"
import CustomIcon from "../../components/CustomIcon"
import { withTranslation } from "react-i18next"
import customToast from "../../components/CustomToast"
import { connect } from "react-redux"
import { userApi } from "../../redux/user/user.service"
import { getFirebaseToken } from "../../services/firebase"

class EditSpecialtiesPage extends Component {
	state = {
		userInfos: {},
		loading: true,
		specialties: [],
		otherSpecialties: []
	}

	async componentDidMount() {
		window.scrollTo(0, 0)

		disableScroll.off() // Disable Scroll

		const isAuthentified = await getFirebaseToken()
		if (!isAuthentified) {
			this.props.history.push("/login")
		}

		getUser().then((user) => {
			this.setState(
				{
					userInfos: user,
					specialties: user?.medical_subspecialty ?? []
				},
				() =>
					getProfessionSpecialization(user?.profession).then(
						(specialties) => {
							console.log("result ->", specialties)
							if (!specialties.length)
								this.setState({ loading: false })
							Object.keys(specialties).map((specialty) => {
								if (specialty === user?.medical_specialty) {
									var final = specialties[specialty]

									final.map((f) => {
										if (
											user?.medical_subspecialty.includes(
												f
											)
										) {
											var i = final.indexOf(f)
											final.splice(i, 1)
										}
									})
									this.setState({
										otherSpecialties: final,
										loading: false
									})
								}
							})
						}
					)
			)
		})
	}

	handleSubmit(event) {
		event.preventDefault()

		const { specialties } = this.state

		var data = {
			medical_subspecialty: specialties
		}

		customToast(
			this.props.t("toast.success.editedSubpecialties"),
			"success"
		)
		patchUser(data).then((_) => {
			this.props.getUser().then(() => this.props.history.goBack())
		})
	}

	removeTag(removed) {
		const { specialties, otherSpecialties } = this.state
		var i = specialties.indexOf(removed)
		specialties.splice(i, 1)
		otherSpecialties.push(removed)
		this.setState({ specialties, otherSpecialties })
	}

	addTag(added) {
		const { specialties, otherSpecialties } = this.state
		var i = otherSpecialties.indexOf(added)
		otherSpecialties.splice(i, 1)
		specialties.push(added)
		this.setState({ specialties, otherSpecialties })
	}

	render() {
		const { loading, otherSpecialties, specialties } = this.state
		const { t } = this.props
		return (
			<>
				<form onSubmit={this.handleSubmit.bind(this)} action="#">
					<EditProfileBar
						{...this.props}
						title={t(
							"profile.editProfile.editSubspecialties.title"
						)}
						save
					/>
					{loading ? (
						<Loader />
					) : (
						<CustomContainer
							style={{
								padding: "10px 0px 100px 0px",
								background: "#dfe6ec"
							}}
						>
							<CustomContent>
								{specialties.length > 1 &&
									specialties[0]?.length >= 1 && (
										<CustomInputLabel>
											{t(
												"profile.editProfile.editSubspecialties.my"
											)}
										</CustomInputLabel>
									)}

								{!specialties.length
									? t(
											"profile.editProfile.editSubspecialties.empty"
									  )
									: specialties.map((specialty) => {
											if (specialty.length > 0)
												return (
													<RemovableTag
														onClick={() =>
															this.removeTag(
																specialty
															)
														}
													>
														{specialty}
													</RemovableTag>
												)
									  })}
							</CustomContent>

							<Spacer
								height="10px"
								style={{ background: "white" }}
							/>

							<CustomContent>
								<CustomInputLabel>
									{t(
										"profile.editProfile.editSubspecialties.add"
									)}
								</CustomInputLabel>
								{!otherSpecialties.length
									? t(
											"profile.editProfile.editSubspecialties.empty"
									  )
									: otherSpecialties.map((specialty) => {
											if (
												!specialties.includes(specialty)
											) {
												return (
													<AddableTag
														onClick={() =>
															this.addTag(
																specialty
															)
														}
													>
														{specialty}
													</AddableTag>
												)
											}
									  })}
							</CustomContent>
						</CustomContainer>
					)}
				</form>
			</>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(EditSpecialtiesPage))

export const RemovableTag = (props) => {
	return (
		<div
			onClick={props.onClick}
			style={{
				position: "relative",
				display: "inline-block",
				marginTop: "19px",
				marginRight: "19px"
			}}
		>
			<SpecialtyTag>{props.children}</SpecialtyTag>
			<div style={{ position: "absolute", top: -6, right: -6 }}>
				<CustomIcon iconName="cross_circle" />
			</div>
		</div>
	)
}

export const AddableTag = (props) => {
	return (
		<div
			onClick={props.onClick}
			style={{
				position: "relative",
				display: "inline-block",
				marginTop: "19px",
				marginRight: "19px"
			}}
		>
			<SpecialtyTag style={{ background: "#81939C" }}>
				{props.children}
			</SpecialtyTag>
			<div style={{ position: "absolute", top: -6, right: -6 }}>
				<CustomIcon iconName="add_circle" />
			</div>
		</div>
	)
}

const CustomContent = styled.div`
	padding: 20px 30px;
	background: #dfe6ec;
`

const SpecialtyTag = styled.div`
	font-family: "Poppins";
	background-color: #ffc408;
	border-radius: 5px;
	padding: 6px;
	display: inline-block;

	color: #212121;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
`

const CustomInputLabel = styled.div`
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 900;
	font-size: 21px;
	line-height: 30px;
	letter-spacing: 0.02em;
	color: #212121;
	margin-bottom: 5px;
`
