import CustomIcon from "../../components/CustomIcon";
import i18n from "../../config/i18n";

export default function NavCategories({
  categories,
  selectedCategory,
  onSelect = null,
}) {
  return (
    <div
      // className={`category-scroll scrollbar-hidden ${entry || !searchValue ? "hide" : ""}`}
      className={`category-scroll scrollbar-hidden`}
    >
      {categories.map((category) => (
        <div
          key={category.en + "srchctgr"}
          // ${selectedCategories.includes(category.en) ? "selected" : ""}
          // ${state[category.slug]?.total ? "" : "hide"}
          className={`
                        card
                        ${selectedCategory === category.slug ? "selected" : ""}
                    `}
          onClick={() => onSelect && onSelect(category)}
        >
          <div className='card-content'>
            <CustomIcon
              iconName={category.icon}
              color='#212121'
              width={category?.width}
              height={category?.height}
            />
            <span className='category-title'>
              {`${category[i18n.resolvedLanguage]}`}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}
