import React, { Component } from "react"
import styled from "styled-components"
import { Col, Row } from "react-grid-system"
import { Link, withRouter } from "react-router-dom"
import CustomIcon from "./CustomIcon"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { userApi } from "../redux/user/user.service"
import customToast from "./CustomToast"
import { BottomSheet } from "react-spring-bottom-sheet"
import { savePlaylist, unsavePlaylist } from "../services/playlists.services"
import { t } from "i18next"
import { SpecialtyTag } from "./global"
import i18n from "../config/i18n"
import {
	convertContentToItemData,
	gtmItemData,
	gtmItemsData
} from "../tools/reactgaEvents"
import { kycMiddleware } from "../tools/utils"
import { getFirebaseToken } from "../services/firebase"
import {
	ItemDataEventListName,
	ItemDataVariant,
	ItemsDataEvent
} from "../interfaces"

/** Playlist Card */

class PlaylistCard extends Component {
	state = {
		currentIndex: 0,
		showSheet: false
	}

	componentDidMount() {
		this.setState({
			innerHeight: window.innerHeight
		})
	}

	async sharePlaylist() {
		const my_uid = this.props.user?.user?.uid
		const { title, _id, company, medical_specialties } = this.props.playlist
		const isAuthenticated = await getFirebaseToken()

		if (!!isAuthenticated && !this.props.user.user.profession)
			kycMiddleware(this, t("toast.error.notOnboarded.sharePlaylist"))
		else if (navigator.share) {
			navigator
				.share({
					title: document.title,
					text: i18n.language.includes("fr")
						? `Découvrez la playlist ${title} sur Juisci !`
						: `Discover the playlist ${title} on Juisci now!`,
					url: `/playlist/public/${_id}?shared=${my_uid ?? "null"}`
				})
				.then(() => {
					customToast(t("toast.success.sharedPlaylist"), "success")
					gtmItemData({
						event: "article_share",
						item_name: title,
						item_list_name: window.location.pathname,
						item_id: _id,
						item_brand: company?.name,
						item_category: medical_specialties[0].uid,
						item_category3: "playlist"
					})
				})
		} else {
			customToast(t("toast.error.browser"))
		}
	}

	async handleSavePlaylist() {
		const playlistSaved =
			this.props.user.user &&
			this.props.user.user?.playlists.find(
				(el) => el._id === this.props.playlist._id
			)

		const isAuthenticated = await getFirebaseToken()

		if (!!isAuthenticated && !this.props.user.user.profession)
			kycMiddleware(this, t("toast.error.notOnboarded.savePlaylist"))

		if (!!isAuthenticated) {
			if (!playlistSaved)
				savePlaylist(this.props.playlist._id).then(() => {
					this.props.getUser()
					customToast(t("toast.success.savedPlaylist"))
				})
			else
				unsavePlaylist(this.props.playlist._id).then(() => {
					this.props.getUser()
					customToast(t("toast.success.unsavedPlaylist"))
				})
		} else {
			window.location.href = "/login"
		}
	}

	handleSelectItem() {
		gtmItemsData(
			ItemsDataEvent.SELECT_ITEM,
			convertContentToItemData(
				this.props.playlist.playlist,
				ItemDataEventListName.HOME,
				ItemDataVariant.PLAYLIST
			)
		)
	}

	render() {
		const { playlist, onClick } = this.props
		const { showSheet } = this.state

		const playlistSaved =
			this.props.user.user &&
			this.props.user.user?.playlists.find(
				(el) => el._id === this.props.playlist?._id
			)

		const path = {
			pathname: `/playlist/public/${playlist?._id}`,
			state: {
				playlist
			}
		}

		const contentLanguage = localStorage.getItem("contentLanguage")

		return (
			<>
				<CustomWrapper
					style={this.props.style}
					onClick={(e) => {
						onClick && onClick(e)
						this.handleSelectItem()
					}}
				>
					{/* Card Header - Date / Category / Ellpsis */}
					<div className="card-header">
						<div>
							<Link
								to={path}
								style={{ color: "#212121", margin: 0 }}
							>
								{this.props.playlist?.medical_specialties
									.slice(0, 4)
									.map((specialty) => (
										<SpecialtyTag key={specialty.uid}>
											{
												specialty.translations[
													localStorage.getItem(
														"contentLanguage"
													)
												]
											}
										</SpecialtyTag>
									))}
							</Link>
						</div>
					</div>

					{/* Card Body - Carousel */}
					<div>
						<Link to={path} style={{ color: "#212121", margin: 0 }}>
							<MetaTitle>
								Playlist by <b>{playlist?.company?.name}</b>
								<div className="company-description">
									{playlist?.company?.type}.
								</div>
							</MetaTitle>

							<p
								style={{
									fontWeight: 700,
									fontSize: "16px",
									margin: 0,
									marginTop: 20,
									textAlign: "left"
								}}
							>
								{playlist?.title.length > 0
									? playlist?.title
									: playlist?.description}
							</p>
							<Spacer />
						</Link>
						<div className="footer-card">
							{!!playlist?.company && (
								<div>
									<Link
										to={{
											pathname: `/company/${playlist?.company?._id}`,
											state: {
												company: playlist?.company
											}
										}}
									>
										<div className="title-metric">
											<span>
												{contentLanguage === "fr"
													? "Organisation"
													: "Organization"}{" "}
												<CustomIcon
													iconName="link"
													scale={0.7}
												/>
											</span>
										</div>
										<img
											src={
												playlist?.images[
													playlist?.images?.length - 1
												]?.url
											}
											alt="..."
											className="company-img"
										/>
									</Link>
								</div>
							)}

							<div>
								<Link
									to={path}
									style={{ color: "#212121", margin: 0 }}
								>
									<p className="title-metric">Articles</p>
									<p className="circle-metric">
										{playlist?.metrics?.articles}
									</p>
								</Link>
							</div>

							<div>
								<Link
									to={path}
									style={{ color: "#212121", margin: 0 }}
								>
									<p className="title-metric">
										{contentLanguage === "fr"
											? "Journaux"
											: "Journals"}
									</p>
									<p className="circle-metric">
										{playlist?.metrics?.journals}
									</p>
								</Link>
							</div>
						</div>
						<Spacer height="10px" />
					</div>
				</CustomWrapper>
				<BottomSheet
					expandOnContentDrag
					open={showSheet}
					onDismiss={() =>
						setTimeout(
							() => this.setState({ showSheet: false }),
							100
						)
					}
				>
					<div
						style={{ float: "right", marginRight: "20px" }}
						onClick={() =>
							setTimeout(
								() => this.setState({ showSheet: false }),
								100
							)
						}
					>
						<CustomIcon iconName="close_alt" />
					</div>
					<SheetWrapper
						style={{ minHeight: window.innerHeight * 0.3 }}
					>
						<Row
							style={{ margin: 0 }}
							justify="around"
							align="center"
							gutterWidth={16}
							onClick={() => this.handleSavePlaylist()}
						>
							<Col xs={1}>
								<Spacer height="5px" />
								<CustomIcon
									iconName={
										playlistSaved ? "save.fill" : "save"
									}
									color="#212121"
									style={{ marginLeft: -3 }}
								/>
							</Col>
							<Col
								xs={10}
								style={{
									color: playlistSaved
										? "#FF699C"
										: "#212121",
									marginLeft: -12
								}}
							>
								<p
									style={{
										fontSize: "17px",
										fontWeight: 600,
										margin: 0
									}}
								>
									{playlistSaved
										? this.props.t(
												"sheet.playlist.unsave.title"
										  )
										: this.props.t(
												"sheet.playlist.save.title"
										  )}
								</p>
								<p
									style={{
										fontSize: "15px",
										fontWeight: 400,
										width: "100%",
										margin: 0
									}}
								>
									{playlistSaved
										? this.props.t(
												"sheet.playlist.unsave.subtitle"
										  )
										: this.props.t(
												"sheet.playlist.save.subtitle"
										  )}
								</p>
							</Col>
						</Row>
						<Spacer height="30px" />

						<Row
							justify="around"
							align="center"
							gutterWidth={20}
							onClick={this.sharePlaylist.bind(this)}
						>
							<Col xs={1}>
								<Spacer height="5px" />

								<CustomIcon
									iconName="share-alt"
									scale={0.8}
									color="#212121"
									style={{
										marginLeft: 5
									}}
								/>
							</Col>
							<Col xs={10}>
								<p
									style={{
										fontSize: "17px",
										fontWeight: 600,
										margin: 0
									}}
								>
									{this.props.t("sheet.playlist.share.title")}
								</p>
								<p
									style={{
										fontSize: "15px",
										fontWeight: 400,
										margin: 0
									}}
								>
									{this.props.t(
										"sheet.playlist.share.subtitle"
									)}
								</p>
							</Col>
						</Row>
					</SheetWrapper>
				</BottomSheet>
			</>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate,
	getPlaylists: userApi.endpoints.getPlaylists.initiate,
	getSaves: userApi.endpoints.getSaves.initiate
}
const connector = connect(mapState, mapDispatch)

export default connector(withRouter(withTranslation()(PlaylistCard)))

const SheetWrapper = styled.div`
	padding: 55px 15px;
	min-height: ${window.innerHeight / 3}px;
`

const Spacer = styled.div`
	height: ${(props) => props.height ?? "34px"};
`

const CustomWrapper = styled.div`
	/* background: linear-gradient(
    144.76deg,
    #ffffff 0%,
    #ecf0f5 70.37%,
    #fcc408 139.87%
  ); */
	/* background: linear-gradient(
      0deg,
      rgba(2, 131, 206, 0.25),
      rgba(2, 131, 206, 0.25)
    ),
    linear-gradient(0deg, #ffffff, #ffffff); */

	background: rgba(255, 249, 230, 255);
	box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
	border-radius: 15px;

	position: relative;
	padding: 20px;
	margin: 15px 10px;

	.footer-card {
		display: flex;
		justify-content: space-between;
		align-items: start;
		margin-top: 20px;
	}

	.card-header {
		display: block;
		margin-bottom: 24px;

		& .company_badge {
			display: inline-flex;
			gap: 5px;
			align-items: center;

			font-family: "Poppins";
			background: #ffc408;
			color: #212121 !important;
			font-size: 12px;
			line-height: 100%;
			white-space: nowrap;
			border-radius: 5rem;
			margin: 8px 0 4px;
			padding: 2px 10px 2px 8px;
		}
	}

	h5 {
		color: #212121;
		font-weight: 800;
		font-size: 21px;
		margin: 0;
		margin-bottom: 10px;
		margin-top: 20px;
	}

	small {
		color: #ffaa00;
		font-size: 11px;
	}

	p.circle-metric {
		background: white;
		padding: 20px;
		box-sizing: border-box;
		border-radius: 5em;
		font-size: 16px;
		line-height: 100%;
		font-family: "Oswald" !important;
		margin: 10px;
		width: 56px;
		height: 56px;
		text-align: center;
		font-weight: 600;
		box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
	}

	p.title-metric,
	div.title-metric {
		font-size: 12px;
		color: #212121;
		font-weight: 300;
		margin: 0;
		text-align: center;

		span {
			display: flex;
			gap: 2px;
		}
	}

	img.company-img {
		object-fit: contain;
		object-position: left;
		max-height: 40px;
		width: 120px;
		float: left;
		text-align: left;
		margin: 0;
		margin-top: 10px;
	}
`

const MetaTitle = styled.div`
	color: #ff699c;
	font-weight: 400;
	font-size: 12px;
	margin: 0;
	text-transform: uppercase;
	text-align: left;
	margin-bottom: 8px;
	letter-spacing: 0.06em;

	b {
		font-weight: 700;
		color: #212121;
		text-transform: none;
		margin-left: 3px;
	}

	.company-description {
		font-weight: 300;
		font-size: 11px;
		letter-spacing: 0.02em;
		font-family: "Roboto";
		text-transform: none;
		color: #212121;
		margin: 0;
		margin-top: 3px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`
