import React, { Component } from "react"
import styled from "styled-components"
import { CustomContainer, Spacer } from "../../components/global"
import { CustomInput } from "../../components/CustomInput"
import SettingsNavbar from "../../components/SettingsNavbar"
import { WhiteSpace } from "antd-mobile"
import { patchUser } from "../../services/user.services"
import { RemoveScrollBar } from "react-remove-scroll-bar"
import { push } from "react-router-redirect"
import { SingleLoader } from "../../components/Loader"
import { withTranslation } from "react-i18next"
import customToast from "../../components/CustomToast"
import { userApi } from "../../redux/user/user.service"
import { connect } from "react-redux"
import { iosSetBackgroundColor, isNativeIOS } from "../../tools/ios"
import disableScroll from "disable-scroll"
import { changePassword } from "../../services/auth.services"
import { getFirebaseToken } from "../../services/firebase"

class EditSettings extends Component {
	state = {
		selectedRadio: "",
		constEmail: "",
		email: "",
		password: "",
		newPassword: "",
		confirmNewPassword: "",
		loaded: false,
		edit: false
	}

	fetchProfile = async () => {
		await this.props.getUser().then((_) => {
			// Getting user lang...
			const user = this.props.user.user

			this.setState({
				email: user.email,
				constEmail: user.email,
				loadingLang: true,
				loaded: true
			})
		})
	}

	async componentDidMount() {
		disableScroll.on()
		const isAuthentified = await getFirebaseToken()

		if (!isAuthentified) {
			push("/home")
		}

		if (isNativeIOS) {
			iosSetBackgroundColor("#FFFFFF")
		}

		this.fetchProfile()
	}

	async handleSubmit(event) {
		event.preventDefault()

		customToast(this.props.t("toast.success.editedSettings"), "success")

		const { email, constEmail, password, newPassword, confirmNewPassword } =
			this.state

		if (email !== "" && email !== constEmail) {
			const data = {
				email: email
			}

			patchUser(data).then((res) => {
				if (res.status === 404)
					this.setState({ errorMessage: res.message })
				else if (res.status === 500)
					this.setState({ errorMessage: res.message })
			})
		}

		if (
			password !== "" &&
			newPassword !== "" &&
			newPassword === confirmNewPassword
		) {
			changePassword({ password })
			customToast(this.props.t("toast.success.editedPassword"))
		}
	}

	render() {
		const { email, loaded, loadingLang, edited } = this.state
		const { t } = this.props
		return (
			<>
				{loaded && loadingLang ? (
					<>
						<form onSubmit={this.handleSubmit.bind(this)}>
							<RemoveScrollBar />
							<SettingsNavbar
								link="/settings"
								pageTitle={t("settings.editSettings")}
								{...this.props}
								onSubmit
								edited={edited}
							/>
							<Spacer height="60px" />
							<CustomContainer
								style={{ padding: "40px 30px 100px 30px" }}
							>
								<CustomInputLabel for="email">
									{t("signup.email")}
								</CustomInputLabel>
								<CustomInput
									type="email"
									placeholder={t("signup.email")}
									name="email"
									value={email}
									onInput={(e) =>
										this.setState({
											email: e.target.value,
											edited: true
										})
									}
								/>
								<WhiteSpace size="sm" />

								<CustomInputLabel for="password">
									{t("signup.password")}
								</CustomInputLabel>
								<CustomInput
									type="password"
									placeholder={t("signup.password")}
									name="password"
									onInput={(e) =>
										this.setState({
											password: e.target.value,
											edited: true
										})
									}
								/>
								<WhiteSpace size="sm" />
								<CustomInputLabel for="newPassword">
									{t("signup.newPassword")}
								</CustomInputLabel>
								<CustomInput
									type="password"
									placeholder={t("signup.newPassword")}
									name="newPassword"
									onInput={(e) =>
										this.setState({
											newPassword: e.target.value,
											edited: true
										})
									}
								/>
								<WhiteSpace size="sm" />

								<CustomInputLabel for="confirmNewPassword">
									{t("signup.confirmNewPassword")}
								</CustomInputLabel>
								<CustomInput
									type="password"
									placeholder={t("signup.newPassword")}
									name="confirmNewPassword"
									onInput={(e) =>
										this.setState({
											confirmNewPassword: e.target.value,
											edited: true
										})
									}
								/>
								<WhiteSpace size="xl" />
							</CustomContainer>
						</form>
					</>
				) : (
					<CustomContainer
						style={{
							background: "#F7F8FC",
							padding: "50vh 20px 50px 20px",
							minHeight: "100vh",
							maxHeight: "100vh"
						}}
					>
						<RemoveScrollBar />
						<center>
							<SingleLoader />
						</center>
					</CustomContainer>
				)}
			</>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}
const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(EditSettings))

const CustomInputLabel = styled.label`
	font-size: 12px;
	font-weight: 400;
	color: #212121;
	line-height: 30px;
	font-family: "Poppins";
`
