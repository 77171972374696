import axios from "axios";
import { API_URL } from "../config";
import i18n from "../config/i18n";
import { catchError } from "./response";
import { getApiRequestService } from "./index.services";

export const getOrganisationById = async (orgId: string) => {
  try {
    const apiInstance = await getApiRequestService();

    if (!orgId) {
      throw new Error("Organisation is undefined");
    }

    const res = await apiInstance.get(`/organisation/${orgId}`);
    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const getOrganisationSharingCode = async () => {
  try {
    const apiInstance = await getApiRequestService();

    const res = await apiInstance.get("organisation/sharing-qr-code", {
      params: {
        format: "png",
      },
      responseType: "blob",
    });

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};
