import React, { Component } from "react";
import styled from "styled-components";
import { OrganisationUid } from "../interfaces";
import { store } from "../redux";

class CustomSwitch extends Component {
  getSwitcherColors = () => {
    const userOrganisation =
      store.getState().user?.user?.organisations?.[0]?.uid;

    switch (userOrganisation) {
      default:
        return {
          default: { background: "#313b42", color: "#FFFFFF" },
          selected: { background: "#ffffff", color: "#212121" },
        };
    }
  };

  render() {
    return (
      <StyledSwitch
        onClick={this.props.onClick}
        colors={this.getSwitcherColors()}
      >
        <button className={!this.props.checked ? "left checked" : "left"}>
          {this.props.offText ?? "LT"}
        </button>
        <button className={this.props.checked ? "right checked" : "right"}>
          {this.props.onText ?? "RT"}
        </button>
      </StyledSwitch>
    );
  }
}

export default CustomSwitch;

const StyledSwitch = styled.div`
  background: ${(props) => props.colors.default.background};
  padding: 2.5px;
  border-radius: 100px;

  button {
    padding: 5px;
    outline: none;
    border: none;
    box-shadow: none;
    font-family: "Poppins";
    font-weight: 700;
    background: ${(props) => props.colors.default.background};
    color: ${(props) => props.colors.default.color};

    &.left {
      border-radius: 100px 20px 20px 100px;
      padding-left: 7px;
    }

    &.right {
      border-radius: 20px 100px 100px 20px;
      padding-right: 7px;
    }

    &.checked {
      background: ${(props) => props.colors.selected.background};
      color: ${(props) => props.colors.selected.color};
    }
  }
`;
