import React, { Component } from "react"
import styled from "styled-components"
import { Row, Col } from "react-grid-system"
import BackIcon from "../assets/icons/back-orange.svg"
import CustomIcon from "./CustomIcon"
import { iosSetBackgroundColor, isNativeIOS } from "../tools/ios"
import { gtmPageView } from "../tools/reactgaEvents"
import { userApi } from "../redux/user/user.service"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { SaveButton } from "./global"
import { t } from "i18next"

class SettingsNavbar extends Component {
	componentDidMount() {
		// IOS BACKGROUND COLOR
		if (isNativeIOS) {
			iosSetBackgroundColor("#FFFFFF")
		}
		gtmPageView(this.props.user.user?._id)
	}

	render() {
		const { pageTitle, shareBtn, goBack, onSubmit, edited, transparent } =
			this.props
		const pathname = window.location.search

		return (
			<>
				<NavbarContainer transparent={transparent}>
					<Row justify="center" align="center">
						<Col
							xs={2}
							onClick={
								pathname.includes("?shared") || !!goBack
									? () => goBack()
									: () => this.props.history.goBack()
							}
						>
							<StyledIcons src={BackIcon} alt="" />
						</Col>
						<Col>
							<PageTitle>{pageTitle}</PageTitle>
						</Col>
						{!shareBtn ? (
							onSubmit ? (
								<Col xs={2 + 1 / 2} style={{ padding: 0 }}>
									<SaveButton
										disabled={!edited}
										type="submit"
									>
										{t("common.save")}
									</SaveButton>
								</Col>
							) : (
								<Col xs={2}></Col>
							)
						) : (
							<Col xs={2} onClick={this.props.shareFunction}>
								<CustomIcon iconName="share_playlist" />
							</Col>
						)}
					</Row>
				</NavbarContainer>
			</>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(SettingsNavbar))

const NavbarContainer = styled.div`
	background: ${(props) => (props.transparent ? "transparent" : "white")};
	box-shadow: ${(props) =>
		props.transparent ? "none" : "0px 2px 2px rgba(0, 0, 0, 0.1)"};
	padding: 10px 22px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 90 !important;
	transition: all ease-in-out 0.3s;
`

const StyledIcons = styled.img`
	height: 32px;
	width: auto;
`

const PageTitle = styled.div`
	font-family: "Poppins";
	color: #212121;
	font-size: 16px;
	font-weight: 700;
	line-height: 23px;
	margin: 0;
	padding: 0;
	text-align: center;
`
