import React, { Component } from "react"
import { t } from "i18next"
import FadeIn from "react-fade-in/lib/FadeIn"
import { connect } from "react-redux"
import styled from "styled-components"
import customToast from "../../components/CustomToast"
import EditProfileBar from "../../components/EditProfileBar"
import Loader from "../../components/Loader"
import i18n from "../../config/i18n"
import { userApi } from "../../redux/user/user.service"
import { patchUser } from "../../services/user.services"
import { StyledOnboarding } from "../signupPage/onboarding/onboardingProfession"
import CustomSelect from "../../components/customSelect"
import { getArticleTags } from "../../services/articles.services"
import { CustomButton, Divider } from "../../components/global"
import CustomIcon from "../../components/CustomIcon"
import { Modal } from "antd-mobile"
import { getFirebaseToken } from "../../services/firebase"
import { gtmAdvancedEvent } from "../../tools/reactgaEvents"
import { GTMBasicEvent } from "../../interfaces"

class EditSpecialtyPage extends Component {
	state = {
		isEdited: false,
		selectedTags: []
	}

	async componentDidMount() {
		const { user, current, isMainSpecialty, isNew } =
			this.props.location.state

		window.scrollTo(0, 0)

		const isAuthentified = await getFirebaseToken()

		if (!isAuthentified) {
			this.props.history.replace("/login")
			return
		} else if (!user) {
			this.props.replace("/settings/edit/profile")
		}

		getArticleTags(current.uid).then((articleTags) =>
			this.setState({
				articleTags,
				user,
				current,
				isMainSpecialty,
				isNew,

				selectedTags:
					user.tags?.filter((el) =>
						articleTags.find((v) => v.uid === el.uid)
					) || []
			})
		)
	}

	handleSubmit(event) {
		event.preventDefault()
		const { user, selectedTags, isMainSpecialty, current, isNew } =
			this.state

		const tags = [
			...new Set([
				...user.tags.map((el) => el.uid),
				...selectedTags.map((el) => el.uid)
			])
		]

		const medical_specialties = [
			...user.medical_specialties.map((el) => el.uid),
			isNew && current.uid
		]

		if (isNew && !!current)
			gtmAdvancedEvent(GTMBasicEvent.UPDATE_USER_INTERESTS, {
				user: {
					interests: current?.uid || null
				}
			})

		if (selectedTags.length)
			gtmAdvancedEvent(GTMBasicEvent.UPDATE_USER_INTERESTS, {
				user: {
					interests:
						selectedTags?.map((el) => el.uid)?.toString() || null
				}
			})

		let data = {
			tags
		}

		if (isMainSpecialty) data = { ...data, main_specialty: current.uid }
		else data = { ...data, medical_specialties }

		customToast(t("toast.success.editedSpecialty"), "success")
		patchUser(data).then(async () => {
			this.props.getUser()
			setTimeout(() => this.props.history.goBack(), 1000)
		})
	}

	handleChangeSpecialty(e) {
		e.preventDefault()

		const { user, current, isEdited, articleTags } = this.state

		if (!isEdited) this.setState({ isEdited: true })

		const specialty = user.profession.medical_specialties.find(
			(el) => el.uid === e.target.value
		)

		const index = !!user.medical_specialties
			? user.medical_specialties.map((el) => el.uid).indexOf(current.uid)
			: 0

		const medical_specialties = !!user.medical_specialties
			? [...user.medical_specialties]
			: []
		medical_specialties[index] = specialty

		const tags = !!user.tags
			? [
					...user.tags.filter(
						(el) => !articleTags.find((t) => t.uid === el.uid)
					)
			  ]
			: []

		getArticleTags(specialty.uid).then((articleTags) =>
			this.setState({
				articleTags,
				selectedTags: articleTags,
				user: { ...user, medical_specialties, tags },
				current: specialty
			})
		)
	}

	handleAddTag(tag) {
		const { selectedTags, isEdited, user, articleTags } = this.state

		if (!isEdited) this.setState({ isEdited: true })

		if (selectedTags.find((el) => el.uid === tag.uid))
			this.setState({
				selectedTags: [
					...selectedTags.filter((el) => el.uid !== tag.uid)
				],
				user: {
					...user,
					tags: [...user.tags.filter((el) => el.uid !== tag.uid)]
				}
			})
		else
			this.setState({
				selectedTags: [...selectedTags, tag]
			})
	}

	handleSelectAllTags() {
		const { articleTags, isEdited, selectedTags, user } = this.state

		if (!isEdited) this.setState({ isEdited: true })
		if (selectedTags.length === articleTags.length)
			this.setState({
				selectedTags: [],
				user: {
					...user,
					tags: [
						...user.tags.filter(
							(el) => !articleTags.find((v) => v.uid === el.uid)
						)
					]
				}
			})
		else
			this.setState({
				selectedTags: articleTags
			})
	}

	handleDeleteSpecialty() {
		const { user, current, articleTags } = this.state

		this.setState({
			showModal: false,
			isDeleting: true
		})

		const medical_specialties = [
			...user.medical_specialties
				.map((el) => el.uid)
				.filter((v) => v !== current.uid)
		]

		const tags = [
			...user.tags
				.filter((el) => !articleTags.find((t) => t.uid === el.uid))
				.map((v) => v.uid)
		]

		patchUser({ medical_specialties, tags }).then(async () => {
			customToast(t("toast.success.deletedSpecialty"), "success")
			this.props.getUser()
			setTimeout(
				() => this.props.history.replace("/settings/edit/profile"),
				1500
			)
		})
	}

	render() {
		const {
			isEdited,
			user,
			current,
			articleTags,
			selectedTags,
			isMainSpecialty,
			showModal,
			isDeleting,
			isNew
		} = this.state

		return (
			<form onSubmit={this.handleSubmit.bind(this)} action="#">
				<EditProfileBar
					{...this.props}
					title={t("profile.editProfile.preferredContent")}
					edited={isEdited}
					save
				/>
				{!!user && !!current && !!articleTags ? (
					<PageContainer>
						<Modal
							wrapClassName="--page-modal journal-modal"
							visible={showModal}
							transparent
							maskClosable={false}
							onClose={() => this.setState({ showModal: false })}
						>
							<div
								style={{
									position: "absolute",
									right: 40,
									top: 10,
									transform: "translateX(30px)",
									zIndex: 99,
									opacity: 0.6
								}}
							>
								<CustomIcon iconName="close_alt" />
							</div>
							<div
								style={{
									margin: "50px 0 20px",
									fontFamily: "Roboto",
									fontWeight: 600,
									fontSize: "16px",
									lineHeight: "140%",
									textAlign: "center",
									color: "#212121"
								}}
							>
								{t("profile.editProfile.specialty.modal")}
							</div>

							<CustomButton
								onClick={() =>
									this.setState({ showModal: false })
								}
								className="small"
								style={{ marginBottom: 10 }}
							>
								{t("common.cancel")}
							</CustomButton>
							<CustomButton
								onClick={this.handleDeleteSpecialty.bind(this)}
								className="secondary small"
							>
								{t("common.yes")}
							</CustomButton>
						</Modal>
						{isMainSpecialty || isNew ? (
							<>
								<CustomSelect
									label={t("common.secondarySpecialty")}
									defaultValue={current.uid}
									renderOptions={[
										...user.profession.medical_specialties
									]
										.sort((a, b) =>
											a.translations[
												i18n.resolvedLanguage
											].localeCompare(
												b.translations[
													i18n.resolvedLanguage
												]
											)
										)
										.filter((el) =>
											isMainSpecialty
												? el
												: el.uid !==
												  user.main_specialty.uid
										)
										.filter((el) =>
											!!user.medical_specialties
												? el.uid === current.uid ||
												  ![
														...user.medical_specialties?.map(
															(spe) => spe?.uid
														)
												  ].includes(el.uid)
												: el
										)
										.map((el, index) => (
											<option
												key={el._id + index}
												value={el.uid}
											>
												{
													el.translations[
														i18n.resolvedLanguage
													]
												}
											</option>
										))}
									onChange={(e) =>
										this.handleChangeSpecialty(e)
									}
								/>
								<p className="description">
									{t(
										"profile.editProfile.specialty.description"
									)}
								</p>
							</>
						) : (
							<div>
								<h2>
									{
										current.translations[
											i18n.resolvedLanguage
										]
									}
								</h2>
								<p
									style={{ marginTop: 0 }}
									className="description"
								>
									{t(
										"profile.editProfile.specialtySecondary.description"
									)}
								</p>
							</div>
						)}

						<div className="content-section">
							<FadeIn>
								<div className="tags-flex">
									{articleTags.length ? (
										articleTags?.map((tag, index) => (
											<div
												key={tag._id + "--key" + index}
												className={`tag ${
													selectedTags?.find(
														(el) =>
															el.uid === tag.uid
													)
														? "selected"
														: ""
												}`}
												onClick={() =>
													this.handleAddTag(tag)
												}
											>
												{
													tag.translations[
														i18n.resolvedLanguage
													]
												}
											</div>
										))
									) : (
										<p className="description">
											{t(
												"profile.editProfile.specialty.empty"
											)}
										</p>
									)}
								</div>

								{articleTags.length ? (
									<CustomButton
										type="button"
										className="secondary small"
										style={{
											fontWeight: 600,
											marginTop: 80
										}}
										onClick={this.handleSelectAllTags.bind(
											this
										)}
									>
										{selectedTags.length ===
										articleTags.length
											? t("common.unselectAll")
											: t("common.selectAll")}
									</CustomButton>
								) : null}
								{!isMainSpecialty && !isNew && (
									<>
										<Divider />
										<p className="description">
											{t(
												"profile.editProfile.deleteButton"
											)}
										</p>
										<CustomButton
											type="button"
											className={`${
												isDeleting
													? "disabled loading"
													: "danger"
											} small icon`}
											onClick={() =>
												this.setState({
													showModal: true
												})
											}
										>
											<CustomIcon
												color={
													isDeleting
														? "#81939c"
														: "#fff"
												}
												iconName="trash"
											/>{" "}
											{t("common.delete")}
										</CustomButton>
									</>
								)}
							</FadeIn>
						</div>
					</PageContainer>
				) : (
					<Loader />
				)}
			</form>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}
const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(EditSpecialtyPage)

const PageContainer = styled(StyledOnboarding)`
	background: #ecf0f5;
	padding: 80px 30px 100px 30px;

	p {
		&.description {
			font-family: "Roboto";
			font-size: 16px;
			line-height: 140%;
			text-align: center;
		}
	}

	.content-section {
		padding: 10px 0;
	}

	.tag {
		background: #fff;
	}

	.--page-modal {
		position: relative;
	}
`
