import React, { Component } from "react"
import styled from "styled-components"
import { CustomContainer, Spacer } from "../../components/global"
import SettingsNavbar from "../../components/SettingsNavbar"
import { updateUserLanguage } from "../../services/user.services"
import { RemoveScrollBar } from "react-remove-scroll-bar"
import { push } from "react-router-redirect"
import { SingleLoader } from "../../components/Loader"
import { withTranslation } from "react-i18next"
import CustomSelect from "../../components/customSelect"
import customToast from "../../components/CustomToast"
import { userApi } from "../../redux/user/user.service"
import { connect } from "react-redux"
import { iosSetBackgroundColor, isNativeIOS } from "../../tools/ios"
import disableScroll from "disable-scroll"
import i18n from "../../config/i18n"
import { store } from "../../redux"
import { setUser } from "../../redux/user/user.reducer"
import { t } from "i18next"
import { getFirebaseToken } from "../../services/firebase"

const languages = ["English", "Français"]

class EditLanguage extends Component {
	state = {
		selectedRadio: "",
		loaded: false,
		language: "en",
		defaultLang: "",
		edited: false
	}

	fetchProfile = async () => {
		await this.props.getUser()

		const user = this.props.user.user
		const user_lang = user?.language

		i18n.changeLanguage(user_lang)

		this.setState({
			defaultLang: user_lang === "fr" ? "Français" : "English",
			loadingLang: true,
			loaded: true
		})
	}

	async componentDidMount() {
		disableScroll.on()
		const isAuthentified = await getFirebaseToken()
		if (!isAuthentified) {
			push("/home")
		}

		if (isNativeIOS) {
			iosSetBackgroundColor("#FFFFFF")
		}

		this.fetchProfile()
	}

	async handleSubmit(event) {
		event.preventDefault()

		const { language } = this.state

		if (language.length > 0) {
			const updatedUser = await updateUserLanguage(language)

			if (!!updatedUser)
				store.dispatch(
					setUser({
						...store.getState().user.user,
						language: updatedUser.language
					})
				)
			else this.props.getUser()

			i18n.changeLanguage(language).then(() => {
				customToast(t("toast.success.editedSettings"))
			})
		}
	}

	// Changing the state
	changeLang(language) {
		var iso = language.substring(0, 2)
		iso = iso.toLowerCase()
		this.setState({ language: iso, edited: true })
	}

	render() {
		const { loaded, loadingLang, defaultLang, edited } = this.state
		const { t } = this.props
		return (
			<>
				{loaded && loadingLang ? (
					<>
						<form onSubmit={this.handleSubmit.bind(this)}>
							<RemoveScrollBar />
							<SettingsNavbar
								link="/settings"
								pageTitle={t(
									"settings.languageDrawer.application.title"
								)}
								{...this.props}
								onSubmit
								edited={edited}
							/>
							<Spacer height="60px" />
							<CustomContainer
								style={{ padding: "30px 30px 100px 30px" }}
							>
								<CustomSelect
									key="lang-input"
									defaultValue={defaultLang}
									options={languages}
									label={t("common.language")}
									name="language"
									onChange={(e) =>
										this.changeLang(e.target.value)
									}
									required
								/>
								<Spacer height="15px" />
							</CustomContainer>
						</form>
					</>
				) : (
					<CustomContainer
						style={{
							background: "#F7F8FC",
							padding: "50vh 20px 50px 20px",
							minHeight: "100vh",
							maxHeight: "100vh"
						}}
					>
						<RemoveScrollBar />
						<center>
							<SingleLoader />
						</center>
					</CustomContainer>
				)}
			</>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}
const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(EditLanguage))

const CustomInputLabel = styled.label`
	font-size: 12px;
	font-weight: 400;
	color: #212121;
	line-height: 30px;
	font-family: "Poppins";
`

// const TermsLink = styled.p`
//   font-size: 13px;
//   color: #212121;
//   font-weight: 600;
//   margin: 0;
//   margin-top: 3px;
//   margin-left: 5px;
// `;
