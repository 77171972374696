import React, { Component } from "react"
import { t } from "i18next"
import SimpleNavbar from "../../../components/SimpleNavbar"
import { StyledOnboarding } from "./onboardingProfession"
import Loader from "../../../components/Loader"
import FadeIn from "react-fade-in/lib/FadeIn"
import { CustomButton, Spacer } from "../../../components/global"
import i18n from "../../../config/i18n"

class OnboardingOtherSpecialties extends Component {
	state = {
		otherSpecialties: []
	}

	componentDidMount() {
		const { state } = this.props.location
		const { specialties, specialty } = state

		window.scrollTo(0, 0)
		this.setState({
			...state,
			specialties: specialties.filter((el) => el.uid !== specialty.uid)
		})
	}

	handleAddSpecialty(specialty) {
		const { otherSpecialties } = this.state
		if (otherSpecialties.includes(specialty))
			this.setState({
				otherSpecialties: otherSpecialties.filter(
					(el) => el !== specialty
				)
			})
		else
			this.setState({
				otherSpecialties: [...otherSpecialties, specialty]
			})
	}

	handleNext() {
		const { state } = this
		this.props.history.push({
			pathname: "/signup/onboarding/avatar",
			state
		})
	}

	render() {
		const { specialties, otherSpecialties } = this.state

		if (!specialties) return <Loader />
		return (
			<StyledOnboarding>
				<SimpleNavbar />
				<div className="header">
					<h2>{t("signup.onboarding.h2.otherSpecialty")}</h2>
					<h5>{t("signup.onboarding.h5.otherSpecialty")}</h5>
				</div>
				<div className="content-section">
					<FadeIn>
						<div className="tags-flex">
							{specialties
								?.sort((a, b) =>
									a.translations[
										i18n.resolvedLanguage
									].localeCompare(
										b.translations[i18n.resolvedLanguage]
									)
								)
								.map((specialty, index) => (
									<div
										key={specialty._id + "--key" + index}
										className={`tag ${
											otherSpecialties.includes(specialty)
												? "selected"
												: ""
										}`}
										onClick={() =>
											this.handleAddSpecialty(specialty)
										}
									>
										{
											specialty.translations[
												i18n.resolvedLanguage
											]
										}
									</div>
								))}
						</div>
					</FadeIn>
					<Spacer height="100px" />
				</div>
				<div className="footer">
					<CustomButton
						className={otherSpecialties.length ? "" : "disabled"}
						onClick={() =>
							otherSpecialties.length && this.handleNext()
						}
					>
						{t("common.next")}
					</CustomButton>
					<CustomButton
						onClick={() => this.handleNext()}
						className="secondary"
					>
						{t("common.lemonSkip")}
					</CustomButton>
				</div>
			</StyledOnboarding>
		)
	}
}

export default OnboardingOtherSpecialties
