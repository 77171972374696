//
// All iOS JS Functions here...
//

import { SoundEffect } from "../interfaces";

export const iosSetBackgroundColor = (color) => {
  let request = {
    method: "setBackgroundColor",
    color: color,
  };
  if (isNativeIOS) window.webkit.messageHandlers.services.postMessage(request);
};

export let isNativeIOS =
  window.webkit != null && window.webkit.messageHandlers.services != null;

export const iosRequestPushAuthorization = () => {
  let request = {
    method: "requestPushAuthorization",
    callback: "iosRequestPushAuthorizationCallback",
  };
  window.webkit.messageHandlers.services.postMessage(request);
};

export function iosRequestPushAuthorizationCallback(granted) {
  localStorage.setItem("ios_granted", granted);
}

export const iosGetFirebaseToken = () => {
  let request = {
    method: "getFirebaseToken",
    callback: "iosGetFirebaseTokenCallback",
  };
  window.webkit.messageHandlers.services.postMessage(request);
};

export const iosDeleteCookies = () => {
  let request = {
    method: "deleteCookies",
  };
  if (isNativeIOS) window.webkit.messageHandlers.services.postMessage(request);
};

export const iosOpenNewTab = (url) => {
  let request = {
    method: "open",
    url,
  };
  window.webkit.messageHandlers.services.postMessage(request);
};

export const iosAppleSignIn = async () => {
  let request = {
    method: "startSignInFlow",
    authProvider: "apple",
    callback: "iosSignInCallback",
  };
  await window.webkit.messageHandlers.services.postMessage(request);
};

export const iosGoogleSignIn = async () => {
  let request = {
    method: "startSignInFlow",
    authProvider: "google",
    callback: "iosSignInCallback",
  };
  await window.webkit.messageHandlers.services.postMessage(request);
};

export const iosAuthSignOut = async () => {
  let request = {
    method: "signOut",
  };
  if (isNativeIOS)
    await window.webkit.messageHandlers.services.postMessage(request);
};

export const iosRequestReview = async () => {
  let request = {
    method: "requestReview",
  };

  if (isNativeIOS)
    await window.webkit.messageHandlers.services.postMessage(request);
};

export const iosGenerateHaptic = (name = "selection") => {
  const request = {
    method: "generateHapticFeedback",
    name, // selection, success, error, blender
  };
  if (isNativeIOS && localStorage.getItem("disableHaptic") !== "true")
    window.webkit.messageHandlers.services.postMessage(request);
};

export const iosStopHaptic = () => {
  const request = {
    method: "stopHapticFeedback",
  };
  if (isNativeIOS) window.webkit.messageHandlers.services.postMessage(request);
};

export const iosPlaySound = (name = SoundEffect.LIKE) => {
  const request = {
    method: "playSoundEffect",
    name,
  };
  if (isNativeIOS && localStorage.getItem("disableSound") !== "true")
    window.webkit.messageHandlers.services.postMessage(request);
};

export const iosSetAppUrl = async (url) => {
  const request = {
    method: "setAppUrl",
    url,
  };
  if (isNativeIOS)
    await window.webkit.messageHandlers.services.postMessage(request);
};

export const iosGetAppUrl = async () => {
  const request = {
    method: "getAppUrl",
    callback: "testGetAppUrlCallback",
  };
  window.webkit.messageHandlers.services.postMessage(request);
};
