import React, { Component } from "react"
import styled from "styled-components"
import { CustomInput } from "../../components/CustomInput"
import customToast from "../../components/CustomToast"
import { CustomButton, Spacer } from "../../components/global"
import PatternBackground from "../../assets/images/bg_pattern.png"
import JuisciLogo from "../../assets/core/pipe-icon.svg"
import { t } from "i18next"

const _VALID_PASSWORDS = ["loreal2022", "juisci@2022"]

class MaintenancePage extends Component {
	state = {
		password: undefined
	}

	componentDidMount() {
		// localStorage.clear();
		localStorage.removeItem("_accessAuthorized")
	}

	handleGetAuthorized() {
		if (_VALID_PASSWORDS.includes(this.state.password)) {
			localStorage.setItem("_accessAuthorized", "1")
			window.location.reload()
		} else customToast("Oops! It seems to be the wrong password 🤭")
	}

	render() {
		return (
			<PageContainer>
				<div className="form">
					<img src={JuisciLogo} alt="" />
					<h4 className="loading">{t("desktop.technical.title")}</h4>
					<p>{t("desktop.technical.description")}</p>
					<Spacer />
				</div>
			</PageContainer>
		)
	}
}

export default MaintenancePage

const PageContainer = styled.div`
	position: relative;
	box-sizing: border-box;
	overflow: hidden;
	height: ${window.innerHeight}px;
	background: linear-gradient(
			rgba(255, 196, 8, 0.75),
			rgba(255, 196, 8, 0.75)
		),
		url(${PatternBackground});

	.form {
		position: absolute;
		left: 20px;
		right: 20px;
		top: 45%;
		transform: translateY(-45%);
		background-color: #fff;
		border-radius: 20px;
		padding: 20px;

		img {
			margin: auto;
			display: block;
			height: 70px;
			width: 70px;
		}

		h4 {
			font-family: "Poppins";
			font-weight: 600;
			font-size: 24px;
			text-align: center;
			margin-top: 20px;
			margin-bottom: 0px;
		}

		p {
			text-align: center;
			font-size: 18px;
		}
	}
`
