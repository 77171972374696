import { Component } from "react"
import { Avatar, CustomContainer, Spacer } from "../../components/global"
import styled from "styled-components"
import Loader from "../../components/Loader"
import disableScroll from "disable-scroll"
import EditProfileBar from "../../components/EditProfileBar"
import CustomIcon from "../../components/CustomIcon"
import { getUser } from "../../services/user.services"
import {
	DefaultProfilePicture,
	defaultAvatarImages,
	uploadAvatar
} from "../../services/image.services"
import { Row, Col } from "react-grid-system"
import { withTranslation } from "react-i18next"
import customToast from "../../components/CustomToast"
import { connect } from "react-redux"
import { userApi } from "../../redux/user/user.service"
import { getFirebaseToken } from "../../services/firebase"
import { t } from "i18next"
import { store } from "../../redux"
import { setUser } from "../../redux/user/user.reducer"

class EditAvatarPage extends Component {
	state = {
		loading: true,
		customAvatarFile: "",
		selectedDefaultAvatar: "",
		isEdited: false
	}

	async componentDidMount() {
		window.scrollTo(0, 0)

		disableScroll.off() // Disable Scroll

		const isAuthentified = await getFirebaseToken()
		if (isAuthentified === null) {
			// Redirect home if not found
			this.props.history.push("/login")
			return
		}

		getUser().then((res) => {
			if (res?.profilePicture?.name) {
				this.setState({
					selectedDefaultAvatar: res?.profilePicture?.name,
					loading: false
				})
			} else if (res?.profilePicture?.url) {
				this.setState({
					customAvatarFile: res?.profilePicture?.url,
					loading: false
				})
			}
		})
	}

	handleChangeAvatarCustomPreprocess = (e) => {
		const file = e.target.files[0]
		const blob = URL.createObjectURL(file)

		setTimeout(() => this.compressImage(file, blob), 500)

		// Reset image when switching to default then back to upload the same image
		e.target.value = ""
	}

	async compressImage(file, blob) {
		const reader = new FileReader()
		reader.readAsDataURL(file)

		reader.onload = async () => {
			const img = document.createElement("img")
			img.src = blob

			img.onload = () => {
				const canvas = document.createElement("canvas")

				// Avatar dimensions
				const MAX_WIDTH = 600
				const MAX_HEIGHT = 600
				let width = img.width
				let height = img.height

				if (width > height) {
					if (width > MAX_WIDTH) {
						height *= MAX_WIDTH / width
						width = MAX_WIDTH
					}
				} else {
					if (height > MAX_HEIGHT) {
						width *= MAX_HEIGHT / height
						height = MAX_HEIGHT
					}
				}
				canvas.width = width
				canvas.height = height
				const ctx = canvas.getContext("2d")
				ctx.drawImage(img, 0, 0, width, height)

				const dataurl = canvas.toDataURL("image/jpeg")
				this.handleChangeAvatarCustom(dataurl)
			}
		}
	}

	handleChangeAvatarCustom = (url) => {
		this.setState({
			selectedDefaultAvatar: "",
			customAvatarFile: url,
			isEdited: true
		})
	}

	handleChangeAvatarDefault = (slug) => {
		this.setState({
			selectedDefaultAvatar: slug,
			customAvatarFile: null,
			isEdited: true
		})
	}

	handleClearAvatar = () => {
		if (
			this.state.selectedDefaultAvatar != DefaultProfilePicture.Pineapple
		) {
			this.setState({
				customAvatarFile: "",
				selectedDefaultAvatar: DefaultProfilePicture.Pineapple
			})
		}
	}

	handleSubmit = async (event) => {
		event.preventDefault()

		const { customAvatarFile, selectedDefaultAvatar } = this.state

		const formData = new FormData()

		let file
		if (selectedDefaultAvatar) {
			formData.set("pictureName", selectedDefaultAvatar)
		} else {
			await fetch(customAvatarFile)
				.then((res) => res.blob())
				.then(
					(blob) =>
						(file = new File([blob], "avatar", {
							type: "image/jpeg"
						}))
				)
			formData.set("file", file)
		}

		if (selectedDefaultAvatar || file) {
			uploadAvatar(formData)
				.then((newUser) => {
					customToast(t("toast.success.avatar"), "success")
					store.dispatch(
						setUser({
							...store.getState().user.user,
							profilePicture: newUser.profilePicture
						})
					)
					this.props.history.goBack()
				})
				.catch((error) => {
					customToast(t("toast.error.global"))
					console.error("Couldn't upload avatar", error.message)
					this.props.history.goBack()
				})
		}
	}

	render() {
		const { loading, customAvatarFile, selectedDefaultAvatar, isEdited } =
			this.state

		return (
			<>
				<form onSubmit={this.handleSubmit.bind(this)} action="#">
					<EditProfileBar
						{...this.props}
						title={t("profile.editProfile.avatar.title")}
						edited={isEdited}
						save
					/>
					{loading ? (
						<Loader />
					) : (
						<CustomContainer
							style={{ padding: "10px 30px 100px 30px" }}
						>
							<Spacer height="100px" />
							<center>
								<div style={{ position: "relative" }}>
									<ProfilePicture
										height="200px"
										src={
											customAvatarFile
												? customAvatarFile
												: defaultAvatarImages[
														selectedDefaultAvatar
												  ]?.default
										}
									/>
									<div
										style={{
											position: "absolute",
											top: 10,
											right: "25%"
										}}
										onClick={this.handleClearAvatar}
									>
										<CustomIcon
											iconName="cross_circle_big"
											increase
										/>
									</div>
								</div>
								<Spacer height="20px" />
								<UploadAvatarButton
									onChange={
										this.handleChangeAvatarCustomPreprocess
									}
								>
									{t("profile.editProfile.avatar.title")}
								</UploadAvatarButton>
							</center>
							<Spacer />

							<SmallTitle>
								{t("profile.editProfile.avatar.select")}
							</SmallTitle>
							<Row justify="around">
								{Object.entries(defaultAvatarImages).map(
									([slug, imgSet]) => {
										return (
											<Col xs={4} key={slug + "keyarr"}>
												<SampleAvatar
													src={imgSet.default}
													alt=""
													onClick={() =>
														this.handleChangeAvatarDefault(
															slug
														)
													}
													className={
														selectedDefaultAvatar ===
														slug
															? "picked"
															: ""
													}
												/>
											</Col>
										)
									}
								)}
							</Row>
						</CustomContainer>
					)}
				</form>
			</>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)
// type ArticlesListProps = ConnectedProps<typeof connector>

export default connector(withTranslation()(EditAvatarPage))

export const UploadAvatarButton = (props) => {
	return (
		<div style={{ position: "relative" }}>
			<input
				onChange={props.onChange}
				type="file"
				accept="image/*"
				style={{
					opacity: 0,
					position: "absolute",
					bottom: 0,
					left: 0,
					right: 0,
					width: "100%",
					height: "100%"
				}}
			/>
			<UploadButton>{props.children}</UploadButton>
		</div>
	)
}

const SmallTitle = styled.h4`
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 30px;
	/* identical to box height, or 187% */

	letter-spacing: 0.02em;

	/* JUISCI Black Matters */

	color: #212121;
`

const SampleAvatar = styled.img`
	height: 81px;
	width: 81px;
	border-radius: 50%;
	border: ${(props) => (props.selected ? "3px solid white" : " none")};
	margin-bottom: 25px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
`

const UploadButton = styled.div`
	font-size: 16px;
	font-weight: 400;
	background: white;
	border-radius: 6px;
	padding: 10px 18px;
	color: #212121;
	font-family: "Poppins";
`

const ProfilePicture = styled(Avatar)`
	border: none;
	box-shadow: none;
`
