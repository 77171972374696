import axios from "axios";
import { API_URL } from "../config";
import { catchError, handleError } from "./response";
import { getApiRequestService } from "./index.services";

export const getAllPlaylists = async () => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/playlist/me`);
    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const getPlaylist = async (title: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/playlist/${title}`);
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserPlaylists = async (user_ID: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`playlist/user/${user_ID}`);
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createPlaylist = async (data: any) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post("/playlist", data);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const deletePlaylist = async (playlist_id: any) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete(`/playlist/${playlist_id}`);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const renamePlaylist = async (playlistId: string, name: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/playlist/${playlistId}/rename`, {
      name,
    });

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const addToPlaylist = async (articleSlug: any, playlist_id: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/playlist/${playlist_id}/article`, {
      slug: articleSlug,
    });

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const removeFromPlaylist = async (slug: any, playlist_id: string) => {
  try {
    const body: any = { slug };

    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete(`/playlist/${playlist_id}/article`, {
      data: body,
    });

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const getSavedArticles = async () => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/playlist/me/saved/articles`);
    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const getPublicPlaylist = async (params?: Record<string, unknown>) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get("/playlist/public", {
      params: {
        ...params,
        status: "published",
        language: localStorage.getItem("contentLanguage") ?? "en",
      },
    });
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const savePlaylist = async (playlistId: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post("/user/me/playlist", {
      playlistId,
    });

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const unsavePlaylist = async (playlistId: string) => {
  try {
    const data = { playlistId };
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete("/user/me/playlist", {
      data,
    });

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const sharePlaylist = async (playlistId: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/playlist/${playlistId}/share`);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const addContentToPlaylist = async (
  playlistId: String,
  contentId: String
) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/playlist/${playlistId}/content`, {
      contentId,
    });

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const removeContentFromPlaylist = async (
  playlistId: String,
  contentId: String
) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete(`/playlist/${playlistId}/content`, {
      data: { contentId },
    });

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};
