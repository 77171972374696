import { useEffect, useState } from "react"
import { Drawer } from "@mui/material"
import styled from "styled-components"
import CustomIcon from "./CustomIcon"
import CustomRadio from "./CustomRadio"
import CustomTabs from "./CustomTabs"
import { t } from "i18next"
import { patchUser } from "../services/user.services"
import { getFirebaseToken } from "../services/firebase"
import customToast from "./CustomToast"
import i18n from "../config/i18n"
import { store } from "../redux"
import { userApi } from "../redux/user/user.service"
import { CustomButton } from "./global"
import { setUser } from "../redux/user/user.reducer"
import {
	interfaceLanguages,
	getOrganisationLanguages,
	renderContentLanguage
} from "../tools/utils"

export const LanguageDrawer = ({
	showDrawer,
	contentLanguage,
	onDrawerClose,
	handleChangeLanguage,
	handleSwitchLanguage
}) => {
	const [language, setLanguage] = useState(contentLanguage)
	const [interfaceLanguage, setInterfaceLanguage] = useState(
		i18n.resolvedLanguage
	)
	const [tabValue, setTabValue] = useState(0)

	useEffect(() => {
		setInterfaceLanguage(i18n.resolvedLanguage)
	}, [i18n.resolvedLanguage])

	const switchLanguage = async () => {
		const isLoggedIn = !!(await getFirebaseToken())

		handleSwitchLanguage(language)

		if (contentLanguage !== language) handleChangeLanguage(language)
		if (interfaceLanguage === i18n.resolvedLanguage) return onDrawerClose()

		if (isLoggedIn) {
			const updatedUser = await patchUser({
				language: interfaceLanguage
			})
			if (!!updatedUser) store.dispatch(setUser(updatedUser))
			else store.dispatch(userApi.endpoints.getUser.initiate())
		}

		onDrawerClose()

		i18n.changeLanguage(interfaceLanguage).then(() => {
			customToast(t("toast.success.editedSettings"))
		})
	}

	const isChecked = (lang) => {
		if (tabValue === 0) return language === lang
		return interfaceLanguage === lang
	}

	const onRadioChange = (lang) => {
		if (tabValue === 0) setLanguage(lang)
		else setInterfaceLanguage(lang)
	}

	return (
		<StyledDrawer
			anchor="left"
			open={showDrawer}
			onClose={() => {
				console.log("CLOSE")
			}}
		>
			<div className="header">
				<div />
				<h5>{t("settings.languageDrawer.title")}</h5>
				<CustomIcon
					onClick={switchLanguage}
					color="#FFFFFF"
					color2="#212121"
					iconName="close_alt"
				/>
			</div>
			<div
				className={tabValue > 0 ? "tabs-section dark" : "tabs-section"}
			>
				<CustomTabs
					tabs={[t("common.content"), "Interface"]}
					onChange={(newValue) => setTabValue(newValue)}
					value={tabValue}
				/>
			</div>
			<div className={tabValue > 0 ? "content dark" : "content"}>
				{tabValue === 0 ? (
					<div>
						<div className="title">
							{t("settings.languageDrawer.content.title")}
						</div>
						<div className="subtitle">
							{t("settings.languageDrawer.content.subtitle")}
						</div>
					</div>
				) : (
					<div>
						<div className="title">
							{t("settings.languageDrawer.application.title")}
						</div>
						<div className="subtitle">
							{t("settings.languageDrawer.application.subtitle")}
						</div>
					</div>
				)}

				{(tabValue
					? interfaceLanguages
					: getOrganisationLanguages()
				).map((lang) => (
					<CustomRadio
						key={lang.key}
						selectColor={tabValue > 0 ? "#FFC408" : "#000"}
						textStyle={{
							color: tabValue > 0 ? "#fff" : "#000",
							fontWeight: 600
						}}
						onChange={() => onRadioChange(lang.value)}
						checked={isChecked(lang.value)}
						title={renderContentLanguage(lang.value)}
					/>
				))}
			</div>

			<div className="footer">
				<CustomButton onClick={switchLanguage}>
					{t("common.save")}
				</CustomButton>
			</div>
		</StyledDrawer>
	)
}

export const StyledDrawer = styled(Drawer)`
	width: 100%;

	.MuiDrawer-paper {
		width: 100%;
		box-sizing: border-box;
	}

	.tabs-section {
		background: #fff;

		.MuiTabs-indicator {
			transform: translateY(2px);
		}

		&.dark {
			.MuiTabs-indicator {
				background-color: #313b42 !important;
			}
		}
	}

	.content {
		background: #ffc408;
		padding: 30px 24px;
		height: 100%;

		&.dark {
			background: #313b42;

			.MuiTabs-indicator {
				background-color: #313b42 !important;
			}

			.title,
			.subtitle {
				color: #fff;
			}
		}

		.title {
			font-family: Inter;
			font-size: 16px;
			font-weight: 600;
			line-height: 22px;
			letter-spacing: -0.0001em;
			text-align: left;
			color: #212121;
			margin-bottom: 4px;
		}

		.subtitle {
			font-family: Inter;
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			letter-spacing: -0.0001em;
			text-align: left;
			color: #212121;
			margin-bottom: 16px;
		}
	}

	.header {
		padding: 71px 24px;
		background: #ce0868;

		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #fff;
		padding-bottom: 27px;

		& :nth-child(1) {
			width: 30px;
			height: 30px;
		}

		h5 {
			font-family: "Inter";
			font-weight: 600;
			font-size: 16px;
			line-height: 140%;
			text-align: center;
			letter-spacing: -0.00011em;
			color: #ffffff;
			margin: 0;
		}
	}

	.footer {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;

		background-color: #ecf0f5;
		border-radius: 20px 20px 0px 0px;
		box-shadow: 0px -4px 6px 0px rgba(33, 33, 33, 0.1);
		padding: 32px 30px 48px;
	}
`
