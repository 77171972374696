import React, { Component } from "react"
import journalStyles from "./journalPage.css"
import { Modal } from "antd-mobile"
import { Row } from "react-grid-system"
import { connect } from "react-redux"
import styled, { keyframes } from "styled-components"
import ArticleCard from "../../components/ArticleCard"
import CustomNavbar from "../../components/CustomNavbar"
import { CustomButton, Spacer } from "../../components/global"
import { userApi } from "../../redux/user/user.service"
import { getArticlesList } from "../../services/articles.services"
import {
	followJournal,
	getJournalInfos,
	unfollowJournal
} from "../../services/journals.services"
import customToast from "../../components/CustomToast"
import Loader from "../../components/Loader"
import CustomIcon from "../../components/CustomIcon"
import { t } from "i18next"
import LinesEllipsis from "react-lines-ellipsis"
import InfiniteScroll from "react-infinite-scroll-component"
import { kycMiddleware } from "../../tools/utils"
import { store } from "../../redux"
import { setUser } from "../../redux/user/user.reducer"
import { getFirebaseToken } from "../../services/firebase"
import {
	convertContentToItemData,
	gtmBasicEvent,
	gtmItemsData
} from "../../tools/reactgaEvents"
import {
	GTMBasicEvent,
	ItemDataEventListName,
	ItemDataVariant,
	ItemsDataEvent
} from "../../interfaces"
import LanguageBar from "../../components/home/LanguageBar"

const maxScroll = 10

class JournalPage extends Component {
	scrollRef = React.createRef()

	state = {
		journal: {},
		articles: [],
		offset: 0,
		scrolled: false,
		scrollPosition: 0,
		visible: false,
		loaded: false,
		isFollowed: undefined,
		descriptionClamp: true
	}

	async componentDidMount() {
		window.scrollTo(0, 0)

		// Removing page 'cache'
		localStorage.removeItem("_last_art")

		// Getting journal informations
		this.fetchJournal()

		// Getting articles related to the journal
		this.fetchArticles()

		// Getting user followed journals list
		if (await getFirebaseToken()) this.props.getUser()

		// Listen to scroll on page
		this.listenToScrollEvent()
	}

	componentWillUnmount() {
		this.props.getUser()
	}

	fetchJournal = async () => {
		const journal = this.props?.location?.state?.journal
		if (!!journal) {
			this.setState({ journal })
			return
		}

		try {
			const { uid } = this.props.match.params
			const journal = await getJournalInfos(uid)
			this.setState({ journal })
		} catch (error) {
			console.error("Err fetchJournal", error.message)
			customToast(t("toast.error.global"))
			this.props.history.replace("/home")
		}
	}

	async fetchArticles() {
		try {
			const { uid } = this.props.match.params
			const { docs: articles, meta } = await getArticlesList({
				journal: uid
			})
			this.setState({ articles, loaded: true, total: meta.total })

			gtmItemsData(
				ItemsDataEvent.VIEW_ITEM_LIST,
				convertContentToItemData(
					articles,
					ItemDataEventListName.JOURNAL_PAGE,
					ItemDataVariant.ARTICLE
				)
			)
		} catch (error) {
			console.error("Err fetchArticles", error.message)
			customToast(t("toast.error.global"))
			this.props.history.replace("/home")
		}
	}

	switchLanguage(newLanguage) {
		this.setState({ contentLanguage: newLanguage }, () => {
			localStorage.setItem("contentLanguage", newLanguage)
			this.fetchArticles()
		})
	}

	listenToScrollEvent = () => {
		document.addEventListener("scroll", () => {
			requestAnimationFrame(() => {
				this.calculateScrollDistance()
			})
		})
	}

	calculateScrollDistance = () => {
		const scrollTop = window.pageYOffset // how much the user has scrolled by
		const winHeight = window.innerHeight
		const docHeight = this.getDocHeight()

		const totalDocScrollLength = docHeight - winHeight
		const scrollPosition = Math.floor(
			(scrollTop / totalDocScrollLength) * 100
		)

		this.setState({
			scrollPosition
		})
	}

	getDocHeight = () => {
		return Math.max(
			document.body.scrollHeight,
			document.documentElement.scrollHeight,
			document.body.offsetHeight,
			document.documentElement.offsetHeight,
			document.body.clientHeight,
			document.documentElement.clientHeight
		)
	}

	handleFollow = async () => {
		const { journal } = this.state
		const isAuthentified = await getFirebaseToken()

		if (!isAuthentified) {
			localStorage.setItem("_last_art", window.location.pathname)
			this.props.history.replace("/login")
		} else if (!!isAuthentified && !this.props.user.user.profession)
			kycMiddleware(this, t("toast.error.notOnboarded.followJournal"))
		else {
			const isFollowed = this.props.user?.user?.journals?.find(
				(el) => el.uid === journal.uid
			)
			if (isFollowed) {
				customToast(
					t("toast.success.unfollowedJournal") + " " + journal?.name
				)
				const { journals } = unfollowJournal(journal._id)
				store.dispatch(setUser({ ...this.props.user.user, journals }))
			} else {
				customToast(
					t("toast.success.followedJournal") + " " + journal?.name
				)
				gtmBasicEvent(GTMBasicEvent.JOURNAL_FOLLOW)
				followJournal(journal._id)
			}
		}
	}

	handleFetchNext = async () => {
		await getArticlesList(
			this.state.offset + 10,
			undefined,
			undefined,
			undefined,
			undefined,
			this.props.match.params.uid
		).then(({ docs }) =>
			this.setState({
				articles: [...this.state.articles, ...docs],
				offset: this.state.offset + 1
			})
		)
	}

	render() {
		const { journal, articles, scrollPosition, descriptionClamp, loaded } =
			this.state

		const isFollowed = this.props.user.user?.journals?.find(
			(el) => el?.uid === journal?.uid
		)

		return (
			<div className={journalStyles}>
				<Modal
					wrapClassName="journal-modal"
					visible={false}
					transparent
					maskTransitionName
					maskClosable={true}
					onClose={() => this.setState({ visible: false })}
				>
					<p
						style={{
							color: "#212121",
							fontWeight: 600,
							fontFamily: "Poppins",
							textAlign: "left",
							marginBottom: 25,
							marginTop: 25
						}}
					>
						{t("modal.journal.title")}
					</p>
					<CustomButton
						style={{
							padding: "10px",
							fontSize: "15px",
							marginBottom: 10
						}}
						onClick={this.handleFollow.bind(this)}
					>
						{t("common.yesPlease")}
					</CustomButton>
					<CustomButton
						style={{
							padding: "10px",
							fontSize: "15px",
							marginBottom: 15,
							background: "white",
							border: "1px solid #212121"
						}}
						onClick={() => this.setState({ visible: false })}
					>
						{t("common.noThanks")}
					</CustomButton>
				</Modal>
				<CustomNavbar {...this.props} />

				{loaded ? (
					<>
						<JournalWrapper style={{ padding: 0 }}>
							{scrollPosition > maxScroll && articles?.length && (
								<Row
									justify="between"
									align="center"
									style={{
										margin: 0
									}}
									className={
										scrollPosition > maxScroll &&
										"fixed-infos"
									}
								>
									<Row
										align="center"
										style={{ margin: 0, width: "80%" }}
									>
										{journal?.image ? (
											<img
												src={journal?.image?.url}
												alt={journal?.name}
											/>
										) : (
											<span
												style={{
													margin: 0,
													fontWeight: 600,
													fontFamily: "Poppins",
													lineHeight: "110%",
													display: "table",
													maxWidth: "50%"
												}}
											>
												{journal?.name}
											</span>
										)}
										<button
											className={
												isFollowed && "followed-journal"
											}
											onClick={() => this.handleFollow()}
										>
											{isFollowed
												? t("common.unfollow")
												: t("common.follow")}
										</button>
									</Row>
									<div
										onClick={() =>
											this.props.history.goBack()
										}
									>
										<CustomIcon iconName="close_alt" />
									</div>
								</Row>
							)}
						</JournalWrapper>
						<div style={{ height: 30 }} />
						<LanguageBar
							onChange={this.switchLanguage.bind(this)}
						/>

						<JournalWrapper
							style={{ padding: 30 }}
							scrollPosition={false}
						>
							<Row
								justify="between"
								align="center"
								style={{ margin: 0 }}
							>
								<Row
									align="center"
									style={{ margin: 0, width: "80%" }}
								>
									{journal?.image ? (
										<img
											src={journal?.image?.url}
											alt={journal?.name}
										/>
									) : (
										<p
											style={{
												display: "table",
												maxWidth: "50%",
												padding: 0,
												margin: 0,
												fontWeight: 600,
												fontFamily: "Poppins",
												lineHeight: "110%"
											}}
										>
											{journal?.name}
										</p>
									)}

									<button
										className={
											isFollowed && "followed-journal"
										}
										onClick={() => this.handleFollow()}
									>
										{isFollowed
											? t("common.unfollow")
											: t("common.follow")}
									</button>
								</Row>
								<div
									onClick={() => this.props.history.goBack()}
								>
									<CustomIcon iconName="close_alt" />
								</div>
							</Row>

							<Spacer height="17px" />

							<LinesEllipsis
								onClick={() =>
									this.setState({
										descriptionClamp: !descriptionClamp
									})
								}
								text={journal?.description}
								className="line-clamp-text"
								maxLine={descriptionClamp ? "10" : "1000"}
								ellipsis={
									<span>
										...
										<br />
										<button
											className="clamp-lines__button"
											onClick={() =>
												this.setState({
													descriptionClamp:
														!descriptionClamp
												})
											}
										>
											Read more
										</button>
									</span>
								}
								trimRight
								basedOn="letters"
							/>
							{!descriptionClamp && (
								<button
									aria-expanded="true"
									className="clamp-lines__button"
									onClick={() =>
										this.setState({
											descriptionClamp: !descriptionClamp
										})
									}
								>
									{t("common.readLess")}
								</button>
							)}
							<Spacer height="20px" />
							<Row
								style={{ margin: 0, marginBottom: -50 }}
								justify="start"
								align="center"
							>
								<MetricBadge
									value={journal?.impact_factor}
									type={
										<span>
											{"impact"}
											<br />
											{"factor"}
										</span>
									}
								/>
								<MetricBadge
									value={journal?.h_index}
									type="h-index"
								/>
								<MetricBadge value={journal?.sjr} type="sjr" />
							</Row>
						</JournalWrapper>
						<ArticleWrapper>
							<div style={{ height: 16 }} />

							<InfiniteScroll
								dataLength={articles.length}
								next={this.handleFetchNext.bind(this)}
								hasMore={
									this.state.articles.length <
									this.state.total
								}
							>
								{articles.map((article) => (
									<ArticleCard
										itemListName={
											ItemDataEventListName.JOURNAL_PAGE
										}
										currentPath="/journal"
										key={article?._id}
										article={article}
										user={this.props.user.user}
										link={{
											pathname: `/post/${article.slug}`,
											updateArticles: () =>
												console.log("no_update_func"),
											state: {
												article
											}
										}}
									/>
								))}
							</InfiniteScroll>
							{articles?.length === 0 && (
								<center>
									<p
										style={{
											fontSize: 16,
											fontWeight: 600,
											fontFamily: "Poppins",
											color: "#FFF",
											marginTop: 100
										}}
									>
										{t("content.journal.empty")}
									</p>
								</center>
							)}
						</ArticleWrapper>
					</>
				) : (
					<Loader />
				)}
			</div>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(JournalPage)

const MetricBadge = (props) => {
	let color = "#FFC408"
	let bgColor = "#FFC408"

	switch (props.type) {
		case "h-index":
			color = "#FFFFFF"
			bgColor = "#90A4AE"
			break
		case "sjr":
			color = "#FFFFFF"
			bgColor = "#4C5861"
			break

		default:
			color = "#212121"
			bgColor = "#FFC408"
			break
	}

	return (
		<div
			style={{
				height: 50,
				width: 50,
				borderRadius: "200px",
				padding: "18px",
				background: bgColor,
				position: "relative",
				boxShadow: "2px 2px 4px rgba(33, 33, 33, 0.1)",
				marginRight: 8,
				display: props.value > 0 ? "block" : "none"
			}}
		>
			<div
				style={{
					position: "absolute",
					left: 0,
					right: 0,
					top: "50%",
					transform: "translateY(-50%)"
				}}
			>
				<p
					style={{
						color: color,
						fontFamily: "Poppins",
						fontWeight: 700,
						textTransform: "uppercase",
						letterSpacing: "0.02em",
						textAlign: "center",
						fontSize: "8px",
						lineHeight: "8px",
						margin: 0
					}}
				>
					{props.type}
				</p>
				<p
					className="journal-metrics-value"
					style={{
						color: color
					}}
				>
					{props.value}
				</p>
			</div>
		</div>
	)
}

const slideTop = keyframes`
    from {
        top: 0;
    }
    to {
        top: 53px;
    }
`

const JournalWrapper = styled.div`
	padding: 30px;
	padding-top: 90px;

	img {
		height: 35px;
		width: auto;
		max-width: 100px;
		object-fit: contain;
	}

	button {
		outline: none;
		box-shadow: none;
		border: none;
		padding: 8px 16px;
		font-size: 16px;
		font-family: "Poppins";
		font-weight: 700;
		// color: white;
		// background: linear-gradient(180deg, #ff699c 0%, #fe5763 100%);
		color: #212121;
		background: #ffc408;
		border-radius: 100px;
		margin-left: 10px;
		max-width: 150px;
	}

	button.followed-journal {
		// background: #fff3ce;
		// color: #ffc408;
		background: #fff3ce;
		color: #ffc408;
	}

	button.clamp-lines__button {
		background: transparent;
		font-size: 14px;
		font-weight: 400;
		font-style: normal;
		margin: 0;
		padding: 0;
		margin-left: 20px;
		margin-top: 15px;

		&::before {
			content: " ";
			position: absolute;
			border-color: #f42cab !important;
			border-style: solid;
			border-width: 0 0.2em 0.2em 0;
			height: 0.4em;
			left: 30px;
			transform: rotate(45deg);
			width: 0.4em;
		}

		&[aria-expanded="true"]::before {
			content: " ";
			position: absolute;
			border-color: #f42cab !important;
			border-style: solid;
			border-width: 0 0.2em 0.2em 0;
			height: 0.4em;
			left: 30px;
			margin-top: 7px !important;
			transform: rotate(-135deg);
			width: 0.4em;
		}
	}

	p,
	.line-clamp-text {
		font-family: "Poppins";
		font-weight: 400;
		font-size: 14px;
		line-height: 22.4px;
		margin: 0;
		color: #212121;
	}

	p.journal-metrics-value {
		font-family: "Oswald" !important;
		font-weight: 700;
		font-size: 20px;
		text-align: center;
	}

	.fixed-infos {
		z-index: 3;
		position: fixed;
		top: 53px;
		background: #f2f2f2;
		width: 100%;
		padding: 15px 30px;
		box-sizing: border-box;
		box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
		opacity: 1;
		animation: ${slideTop} 0.1s linear;
	}
`

const ArticleWrapper = styled.div`
	background: linear-gradient(
		180deg,
		#ff699c 0%,
		#fe5763 26.99%,
		#ff8a00 51.99%,
		#fdb955 77.51%,
		#ffc408 100%
	);

	min-height: ${window.innerHeight * 0.7}px;
	max-height: 100%;
	width: 100%;
	overflow-y: hidden;
	padding-top: 10px;

	.journal-switch-btn {
		display: flex;
		justify-content: flex-end;
		padding: 10px 10px 0;
	}

	h3 {
		font-family: "Poppins" !important;
		font-size: 16px;
		font-weight: semibold !important;
		color: #212121;
		text-align: center;
	}
`
