import { Toast } from "antd-mobile"
import axios from "axios"
import { API_URL } from "../config"
import { catchError } from "./response"
import { getApiRequestService } from "./index.services"
import jwtDecode from "jwt-decode"
import { SupportedLanguagesEnum } from "../interfaces"

export const loginUser = async (data: any) => {
	try {
		const body: any = {}
		for (const [key, value] of Object.entries(data)) {
			body[key] = value
		}
		const apiInstance = await getApiRequestService()

		const res = await apiInstance.post("/auth/authenticate", body)

		localStorage.setItem(
			"refreshToken",
			res.data.user.stsTokenManager.refreshToken
		)

		return res.data
	} catch (error: any) {
		localStorage.removeItem("authData")
		return error.response
	}
}

export const signUpUser = async (data: any) => {
	try {
		const body: any = {}
		for (const [key, value] of Object.entries(data)) {
			body[key] = value
		}
		const apiInstance = await getApiRequestService()

		const res = await apiInstance.post("/auth/register", body)

		return res.data
	} catch (error) {
		return catchError(error)
	}
}

export const resetPassword = async (data: any) => {
	try {
		const body: any = {}
		for (const [key, value] of Object.entries(data)) {
			body[key] = value
		}
		const apiInstance = await getApiRequestService()

		const res = await apiInstance.post("/auth/resetpassword", body)

		return res.data
	} catch (error) {
		return catchError(error)
	}
}

export const checkEmail = async (email: string) => {
	try {
		const apiInstance = await getApiRequestService()
		const res = await apiInstance.post("/auth/checkemail", {
			email
		})

		return res.data
	} catch (error) {
		return catchError(error)
	}
}

export const changePassword = async (body: { password: string }) => {
	try {
		const apiInstance = await getApiRequestService()
		const res = await apiInstance.post("/auth/changepassword", {
			...body
		})

		return res.data
	} catch (error) {
		return catchError(error)
	}
}

export const setPassword = async (body: { password: string }) => {
	try {
		const apiInstance = await getApiRequestService()
		const res = await apiInstance.post("/auth/set-password", {
			...body
		})

		return res.data
	} catch (error) {
		return catchError(error)
	}
}

export const getOrganisationFromInviteCode = async (inviteCode: string) => {
	try {
		const apiInstance = await getApiRequestService()
		const res = await apiInstance.get(`/auth/invite/verify/${inviteCode}`)

		return res.data
	} catch (error) {
		return catchError(error)
	}
}

export const getMagicLink = async ({
	email,
	language
}: {
	email: string
	language: SupportedLanguagesEnum
}) => {
	try {
		const apiInstance = await getApiRequestService()
		const res = await apiInstance.post("/auth/magic-link", {
			email,
			language
		})

		return res.data
	} catch (error) {
		return catchError(error)
	}
}
