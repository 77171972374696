import { useSelector } from "react-redux";
import { pickUserImage } from "../services/image.services";
import { pickGradient } from "../tools/utils";
import { Avatar } from "./global";

export default function ProfilePicture({ user, height, style, onClick }) {
  const storeUser = useSelector((state) => state.user.user);
  if (!user) {
    user = storeUser;
  }

  return (
    <Avatar
      onClick={onClick}
      src={pickUserImage(user)}
      height={height ? parseInt(height) + "px" : null}
      style={{
        background: pickGradient(user?.firstname?.length % 5),
        ...style,
      }}
    />
  );
}
