import React, { Component } from "react"
import styled from "styled-components"
import { Row, Col } from "react-grid-system"
import CustomNavbar from "../../components/CustomNavbar"
import CustomIcon from "../../components/CustomIcon"
import PostTitle from "../../components/PostTitle"
import { Icon } from "antd-mobile"
import {
	articleSpeech,
	getArticle,
	getRelatedArticles,
	patchViewSourceArticle,
	sharedArticle
} from "../../services/articles.services"
import disableScroll from "disable-scroll"
import { push } from "react-router-redirect"
import CloseAlt from "../../assets/icons/close_alt.svg"
import { Carousel } from "react-responsive-carousel"
import LinesEllipsis from "react-lines-ellipsis"
import { Flex, ShareText, Spacer, SpecialtyTag } from "../../components/global"
import Loader, { SingleLoader } from "../../components/Loader"
import {
	iosGenerateHaptic,
	iosPlaySound,
	iosSetBackgroundColor,
	isNativeIOS
} from "../../tools/ios"
import { Trans, withTranslation } from "react-i18next"
import {
	convertContentToItemData,
	gtmItemsData
} from "../../tools/reactgaEvents"
import customToast from "../../components/CustomToast"
import { connect } from "react-redux"
import { articlesApi } from "../../redux/articles/articles.service"
import { Link } from "react-router-dom"
import { userApi } from "../../redux/user/user.service"
import JuisciIcon from "../../assets/core/pipe-icon.svg"
import EditoIcon from "../../assets/images/edito-icon.png"
import NextColor from "../../assets/icons/next-color.svg"
import NextColorYellow from "../../assets/icons/next-color-yellow.svg"
import { likeArticle, unlikeArticle } from "../../services/user.services"
import ImageViewer from "../../components/post/ImageViewer"
import PlaylistSheet from "../../components/playlists/PlaylistSheet"
import ArticleCard from "../../components/ArticleCard"
import { t } from "i18next"
import { BottomSheet } from "react-spring-bottom-sheet"
import { isAndroid } from "react-device-detect"
import { androidShare } from "../../tools/android"
import { kycMiddleware, renderPublicationDate } from "../../tools/utils"
import { store } from "../../redux"
import { setUser } from "../../redux/user/user.reducer"
import i18n from "../../config/i18n"
import { getFirebaseToken } from "../../services/firebase"
import {
	HapticEffect,
	ItemDataEventListName,
	ItemDataVariant,
	ItemsDataEvent,
	OrganisationUid,
	SoundEffect
} from "../../interfaces"
import CustomAudioPlayer from "../../components/audioplayer/AudioPlayer"

class PostPage extends Component {
	listener = null

	myRef = React.createRef()
	bottomPageRef = React.createRef()
	player = React.createRef()

	state = {
		article: undefined,
		articles: [],
		relatedArticles: [],
		loaded: false,
		scrolled: false,
		scrollPostion: 0,
		articlesLoaded: true,
		isShareOpen: false,
		shareClosed: false,
		methodologyClamp: true,
		limitationsClamp: true,
		backgroundClamp: true,

		// Playlist :
		showSheet: false, // First sheet
		showSave: false,
		wasSaved: false,

		// Audio
		isPlayed: false,
		audioLink: "",
		audioReady: false,
		audioPlayer: undefined,

		// Image viewer
		isViewerOpen: false,
		imageIndex: 0,

		// Likes
		liked: false,
		iconSize: 0,

		// Saves
		isMarked:
			!!this.props?.user?.saves &&
			this.props?.user?.saves?.find(
				(el) => el.slug === this.props.match.params.id
			)
	}

	async componentDidMount() {
		try {
			const isAuthentified = await getFirebaseToken()
			this.setState({ isAuthentified })

			if (localStorage.getItem("_nb_views_tm") === null) {
				localStorage.setItem("numberViews", 0)
			}
			localStorage.setItem("_nb_views_tm", new Date())

			if (!isAuthentified) {
				const redirected = this.preflightGuest()
				if (redirected) return
			}

			window.scrollTo(0, 0)
			setTimeout(() => window.scrollTo(0, 0), 100)

			if (this.props.user?.user?.likes) {
				this.setState({
					isLiked: this.props.user.user?.likes?.some(
						(article) =>
							article?.slug === this.props.match.params.id
					)
				})
			}

			if (isAuthentified) await this.fetchProfile()

			await this.fetchArticle()
			this.setLocalStorageLanguage()

			localStorage.setItem(
				"gtm_audio_percent_targets",
				JSON.stringify([3, 25, 50, 75, 80]) // Defining gtm audio percentages that trigger the event...
			)

			const searchParams = new URLSearchParams(this.props.location.search)

			if (searchParams.get("shared") !== null) {
				localStorage.setItem("isShared", true)
			}

			if (searchParams.get("userShared") !== null) {
				localStorage.setItem(
					"userShared",
					searchParams.get("userShared")
				)
			}

			if (this.props.articles?.length > 0)
				this.setState({ articles: this.props.articles })

			window.addEventListener("scroll", this.handleScroll)
			this.listenToScrollEvent()

			if (
				this.props?.user?.saves &&
				this.props?.user?.saves?.includes(this.props.match.params.id)
			)
				this.setState({ wasSaved: true })
		} catch (error) {
			console.error("Err componentDidMount", error.message)
		}
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.listener)
	}

	preflightGuest() {
		let redirected = false

		// Guests
		const numberViews = localStorage.getItem("numberViews")

		if (parseInt(numberViews) >= 1) {
			// NOTE : MAX NUMBER VIEWS
			localStorage.setItem(
				"authRedirectPath",
				this.props.location.pathname
			)
			this.props.history.replace("/login/prev")
			redirected = true
		} else if (numberViews === null) {
			localStorage.setItem("numberViews", 1)
		} else {
			localStorage.setItem("numberViews", parseInt(numberViews) + 1)
		}

		return redirected
	}

	// All functions to get scroll position in page

	listenToScrollEvent = () => {
		document.addEventListener("scroll", () => {
			requestAnimationFrame(() => {
				this.calculateScrollDistance()
			})
		})
	}

	calculateScrollDistance = () => {
		const scrollTop = window.pageYOffset // how much the user has scrolled by
		const scrollPostion = Math.floor(
			(scrollTop / this.bottomPageRef?.current?.offsetTop) * 85
		)

		this.setState({
			scrollPostion
		})
	}

	// On vérifie ici que ce ne soit pas un article provenant d'une interface dédiée
	checkAccessArticleOrganisation = (article) => {
		const userOrganisation =
			store.getState().user.user?.organisations?.[0]?.uid

		// Condition 1: ne possède pas d'organisation (utilisateur normal) et l'article possède une autre organisation que simplement JUISCI.
		const condition1 =
			!userOrganisation &&
			!article.organisations.length === 1 &&
			!article.organisations?.[0]?.uid !== OrganisationUid.JUISCI

		// Condition 2: possède une organisation (utilisateur d'une organisation) et l'article possède une autre organisation que celle de l'utilisateur.
		const condition2 =
			userOrganisation &&
			article.organisations.length > 1 &&
			!article.organisations.some((el) => el.uid === userOrganisation)

		if (condition1 || condition2) {
			customToast(t("toast.error.notAllowed"))
			return this.props.history.push("/home")
		}
	}

	fetchArticles = async () => {
		await this.props
			.getArticlesList({
				offset: 0
			})
			.then((res) => {
				/** Formatting items data for GTM */
				const _itemsData = [...res.data?.docs].map(
					({ title, _id, journal, medical_specialties }) => ({
						item_name: title,
						item_list_name: window.location.pathname,
						item_id: _id,
						item_brand: journal?.name,
						item_category:
							medical_specialties[0]?.translations[
								localStorage.getItem("contentLanguage")
							],
						item_category3: "article",
						item_category2: "standard",
						quantity: 1
					})
				)
			})
	}

	fetchArticle = async () => {
		try {
			const currentIndex = this.handleGetIndex()
			const article = await getArticle(this.props.match.params.id)
			this.checkAccessArticleOrganisation(article)
			this.setState({ currentIndex, loaded: true, article })

			const res = await articleSpeech({
				slug: article?.slug,
				language: article?.language
			})

			this.setState({
				audioLink: res?.speech?.url,
				audioReady: true
				// audioPlayer: new Audio(res?.speech?.url),
			})

			if (this.props.articles.isUninitialized) await this.fetchArticles()
		} catch (error) {
			console.error("Err fetchArticle", error.message)
			const query = new URLSearchParams(window.location.search)

			// Redirect on home page
			if (error.status === 404) {
				setTimeout(
					() => customToast(t("toast.error.notFoundArticle")),
					1000
				)
			}
			if (query.get("from") === "notification")
				this.props.history.replace(`/journal/${query.get("journal")}`)
			else {
				customToast(t("toast.error.global"))
				this.props.history.replace("/home")
			}

			throw error
		}

		// Related articles
		if (this.props?.location?.state?.sponsorised)
			this.setState({
				relatedArticles:
					this.props.location?.state?.playlist?.playlist.filter(
						(el) => el._id !== this.state.article._id
					)
			})
		else {
			const relatedArticles = await getRelatedArticles(
				this.state.article._id,
				this.state.article?.medical_specialties[0]?.uid
			)

			gtmItemsData(
				ItemsDataEvent.VIEW_ITEM_LIST,
				convertContentToItemData(
					relatedArticles,
					ItemDataEventListName.ARTICLE_PAGE,
					ItemDataVariant.ARTICLE
				)
			)

			this.setState({ relatedArticles })

			if (this.state.article) {
				gtmItemsData(
					ItemsDataEvent.VIEW_ITEM,
					convertContentToItemData(
						[this.state.article],
						ItemDataEventListName.ARTICLE_PAGE,
						ItemDataVariant.ARTICLE
					)
				)
			}
		}
	}

	fetchProfile = async () => {
		if (!this.props.user?.user) {
			await this.props.getUser()
			this.setState({
				userInfos: this.props?.user?.user
			})
		}

		if (!this.props.user?.playlists || !this.props.user?.playlists?.length)
			await this.props.getPlaylists()

		if (!this.props.user.saves || !this.props.user.saves?.length) {
			await this.props.getSaves()
			this.setState({
				isMarked:
					!!this.props?.user?.saves &&
					this.props?.user?.saves?.find(
						(el) => el.slug === this.props.match.params.id
					)
			})
		}
	}

	handlePrevNext(destination) {
		const redux_articles = this.props.articles.list

		const currentIndex = redux_articles.indexOf(
			redux_articles.find(
				(article) => article?.slug === this.props.match.params.id
			)
		)

		switch (destination) {
			case "next":
				if (
					currentIndex > -1 &&
					currentIndex < this.props.articles.meta.offset + 9
				)
					return redux_articles[currentIndex + 1]?.slug
				else return redux_articles[0]?.slug

			default:
				if (currentIndex > 0)
					return redux_articles[currentIndex - 1]?.slug
				else return redux_articles[0]?.slug
		}
	}

	handlePrevTrack() {
		if (this.state.audioReady) {
			localStorage.setItem("prevPath", "/post")
			this.props.history.replace({
				pathname: `/post/${this.handlePrevNext("prev")}`,
				state: {
					article: this.props.articles.list[this.handleGetIndex() - 1]
				}
			})
			this.setState({ audioReady: false, isPlayed: false })
		}
	}

	handleNextTrack() {
		this.props.history.replace({
			pathname: `/post/${this.handlePrevNext("next")}`,
			state: {
				article: this.props.articles.list[this.handleGetIndex() + 1]
			}
		})
		this.setState({ audioReady: false, isPlayed: false })
	}

	handleGetIndex() {
		const redux_articles = this.props.articles.list
		const index = redux_articles.indexOf(
			redux_articles.find(
				(article) => article?.slug === this.props.match.params.id
			)
		)
		return index
	}

	// On scrolled, set the state to true
	handleScroll = () => {
		if (window.pageYOffset > this.myRef + 30) {
			if (!this.state.scrolled) {
				this.setState({ scrolled: true })
			}
		} else {
			if (this.state.scrolled) {
				this.setState({ scrolled: false })
			}
		}
	}

	async shareArticle() {
		const { userInfos, article } = this.state
		const { title, journal } = this.state.article
		const location =
			window.location.protocol +
			"//" +
			window.location.host +
			window.location.pathname

		const isAuthentified = await getFirebaseToken()
		const organisationUid =
			this.props.user.user?.organisations?.[0]?.uid || "null"

		const shareURL = `${location}?shared=${
			userInfos?.uid ?? "null"
		}&org=${organisationUid}`

		/** If login, verify if user has been onboarded */
		if (!!isAuthentified && !this.props.user.user.profession) {
			kycMiddleware(this, t("toast.error.notOnboarded.shareArticle"))
		} else if (isAndroid) {
			androidShare(shareURL, ShareText(title, journal?.name))
		} else if (navigator.share) {
			navigator
				.share({
					title: document.title,
					text: ShareText(title, journal?.name),
					url: shareURL
				})
				.then(async () => {
					this.setState({
						article: {
							...this.state.article,
							metrics: {
								...this.state.article.metrics,
								shares: this.state.article.metrics.shares + 1
							}
						}
					})
					iosPlaySound(SoundEffect.SHARE)
					customToast(
						this.props.t("toast.success.sharedArticle"),
						"success"
					)
					await sharedArticle(article?.slug)
					// GTM SHARE CTA
					this.fetchArticles()
					gtmItemsData(
						ItemsDataEvent.ARTICLE_SHARE,
						convertContentToItemData(
							[this.state.article],
							ItemDataEventListName.ARTICLE_PAGE,
							ItemDataVariant.ARTICLE
						)
					)
				})
		} else {
			customToast(t("toast.error.browser"))
		}
	}

	handleSaveArticle() {
		if (!this.props.user.user.profession)
			kycMiddleware(this, t("toast.error.notOnboarded.saveArticle"))
		else if (!!store.getState().user?.user) {
			this.setState({ showSave: true, showSheet: false })
		} else {
			this.props.history.push("/login")
		}
	}

	async likeArticle() {
		const isAuthentified = await getFirebaseToken()
		const slug = this.props.match.params.id
		const isLiked = this.props.user.user?.likes?.find(
			(article) => article?.slug === this.props.match.params.id
		)

		if (!isAuthentified) this.props.history.push("/login")
		else if (!!isAuthentified && !this.props.user.user.profession)
			kycMiddleware(this, t("toast.error.notOnboarded.likeArticle"))
		else {
			if (!isLiked) {
				this.setState({
					article: {
						...this.state.article,
						metrics: {
							...this.state.article.metrics,
							likes: this.state.article.metrics.likes + 1
						}
					}
				})

				store.dispatch(
					setUser({
						...this.props.user.user,
						likes: [
							...this.props.user.user.likes,
							this.state.article
						]
					})
				)

				customToast(this.props.t("toast.success.liked"))

				iosGenerateHaptic(HapticEffect.SUCCESS)
				iosPlaySound(SoundEffect.LIKE)

				setTimeout(
					() =>
						this.setState({ iconSize: 1.6 }, () =>
							setTimeout(
								() => this.setState({ iconSize: 1.3 }),
								500
							)
						),
					500
				)

				await likeArticle(this.state.article._id)

				gtmItemsData(
					ItemsDataEvent.ARTICLE_SAVE,
					convertContentToItemData(
						[this.state.article],
						ItemDataEventListName.ARTICLE_PAGE,
						ItemDataVariant.ARTICLE
					)
				)

				!!this.props.location?.updateArticles &&
					this.props.location?.updateArticles()
			} else {
				this.setState({
					article: {
						...this.state.article,
						metrics: {
							...this.state.article.metrics,
							likes: this.state.article.metrics.likes - 1
						}
					}
				})
				iosGenerateHaptic(HapticEffect.SUCCESS)
				iosPlaySound(SoundEffect.LIKE)
				customToast(this.props.t("toast.success.unliked"))
				store.dispatch(
					setUser({
						...this.props.user.user,
						likes: [
							...this.props.user.user.likes.filter(
								(el) => el._id !== this.state.article._id
							)
						]
					})
				)
				await unlikeArticle(this.state.article._id)
				!!this.props.location?.updateArticles &&
					this.props.location?.updateArticles()
			}
		}
	}

	addPlaylistSuccess = async ({ contentIsInPlaylist }) => {
		const metricIncrement = contentIsInPlaylist
			? 1
			: !contentIsInPlaylist
			? -1
			: 0

		if (this.state.iconSize <= 0)
			setTimeout(
				() =>
					this.setState({ iconSize: 1.8 }, () =>
						setTimeout(() => this.setState({ iconSize: 1.3 }), 500)
					),
				500
			)

		this.setState({
			article: {
				...this.state.article,
				metrics: {
					...this.state.article.metrics,
					saveds: this.state.article.metrics.saveds + metricIncrement
				}
			},
			isMarked: contentIsInPlaylist
		})
	}

	setLocalStorageLanguage = () => {
		if (!localStorage.getItem("contentLanguage")) {
			localStorage.setItem("contentLanguage", this.state.article.language)
			i18n.changeLanguage(i18n.resolvedLanguage)
		}
	}

	handlePlayPause() {
		iosGenerateHaptic(HapticEffect.SELECTION)

		if (!this.state.audioLink || !this.state.audioReady)
			return customToast(this.props.t("toast.error.audioNotReady"))

		this.setState({ isPlayed: !this.state.isPlayed }, () => {
			if (this.state.isPlayed)
				this.player?.current?.audio?.current?.play().catch(() => {
					customToast(this.props.t("toast.error.audioUnreadable"))

					this.setState(
						{
							audioReady: false,
							isPlayed: false
						},
						() => {
							setTimeout(() => {
								this.setState({ audioReady: true })
							}, 1000)
						}
					)
				})
			else this.player?.current?.audio?.current?.pause()
		})
	}

	render() {
		const {
			scrolled,
			scrollPostion,
			isPlayed,
			article,
			loaded,
			isViewerOpen,
			methodologyClamp,
			limitationsClamp,
			backgroundClamp,
			audioReady,
			showSheet
		} = this.state

		const contentLanguage = localStorage.getItem("contentLanguage")

		const isMarked = this.state.isMarked

		const isLiked = this.props.user.user?.likes?.find(
			(article) => article?.slug === this.props.match.params.id
		)

		if (!article) return <Loader />

		return (
			<>
				<CustomNavbar hideShareBar />
				{/* Loader */}
				<div
					style={{
						paddingTop: "80%",
						display: this.state.loaded ? "none" : "block",
						height: "70vh",
						background: "#fff"
					}}
				>
					<center>
						<SingleLoader />
					</center>
				</div>

				<PostWrapper style={{ display: loaded ? "block" : "none" }}>
					{/* Information on the top of the view, will be overlayed by the white card */}
					<PostInfos>
						<div
							ref={(el) => {
								if (!el) return
								this.myRef = el.clientHeight
							}}
						>
							<Row
								align="center"
								justify="between"
								style={{ padding: "0px" }}
							>
								<Col>
									<p>
										{renderPublicationDate(
											article?.publication_date
										)}
									</p>
									<div style={{ marginTop: "10px" }}>
										{article?.medical_specialties
											?.slice(0, 4)
											.map((specialty) => (
												<SpecialtyTag
													key={
														specialty._id +
														"--specialty-tag"
													}
												>
													{
														specialty
															?.translations?.[
															article.language
														]
													}
												</SpecialtyTag>
											))}
									</div>
								</Col>
								<img
									src={CloseAlt}
									alt=""
									style={{ marginRight: "15px" }}
									onClick={() => {
										iosGenerateHaptic(
											HapticEffect.SELECTION
										)
										this.props.history.goBack()
									}}
								/>
							</Row>
							<h3>{article?.title ?? ""}</h3>
						</div>
					</PostInfos>

					<FixedController
						className={scrolled && "controllerScrolled"}
					>
						{this.state.scrolled ? (
							<StyledTube scroll={scrollPostion + "%"} />
						) : null}

						<Flex
							justify="space-between"
							align="start"
							style={{ margin: "0 20px" }}
						>
							<Flex gap={scrolled ? 8 : 16} align="center">
								{/* Save Button */}
								<Flex
									align="center"
									gap={scrolled ? 0 : 4}
									direction={scrolled ? "column" : "row"}
									onClick={this.handleSaveArticle.bind(this)}
								>
									<IconWrapper
										style={{
											paddingTop: "9px",
											background: isMarked
												? "linear-gradient(180deg, #FFD000 0%, #FFA100 100%)"
												: "#90A4AE",
											transform: scrolled
												? "scale(0.7)"
												: "scale(0.8)",
											transition: "all ease-in-out 0.3s"
										}}
									>
										<CustomIcon
											iconName="marker"
											color="#fff"
										/>
									</IconWrapper>
									<div
										style={{
											fontSize: scrolled
												? "12px"
												: "14px",
											color: "#90A4AE"
										}}
									>
										{this.state.article?.metrics.saveds > 0
											? this.state.article?.metrics.saveds
											: 0}
									</div>
								</Flex>

								{/* Like Button */}
								<Flex
									align="center"
									gap={scrolled ? 0 : 4}
									direction={scrolled ? "column" : "row"}
									onClick={this.likeArticle.bind(this)}
								>
									<IconWrapper
										className="iconWrapperShare"
										style={{
											paddingTop: "9px",
											background: isLiked
												? "#ff8800"
												: "#90A4AE",
											transform: scrolled
												? "scale(0.7)"
												: "scale(0.8)",
											transition: "all ease-in-out 0.3s"
										}}
									>
										<CustomIcon
											iconName="heart"
											scale={1.25}
											color="#fff"
											style={{ marginBottom: -5 }}
										/>
									</IconWrapper>

									<div
										style={{
											fontSize: scrolled
												? "12px"
												: "14px",
											color: "#90A4AE"
										}}
									>
										{article?.metrics?.likes > 0
											? article?.metrics?.likes
											: 0}
									</div>
								</Flex>

								{/* Share Button */}
								<Flex
									align="center"
									gap={scrolled ? 0 : 4}
									direction={scrolled ? "column" : "row"}
									onClick={this.shareArticle.bind(this)}
								>
									<IconWrapper
										className="iconWrapperShare"
										style={{
											paddingTop: "9px",
											background: this.state.isShareOpen
												? "#ff8800"
												: this.state.iconSize > 0
												? "#1fb557"
												: "#90A4AE",
											transform: scrolled
												? "scale(0.7)"
												: "scale(0.8)",
											transition: "all ease-in-out 0.3s"
										}}
									>
										{this.state.iconSize > 0 ? (
											<CustomIcon
												iconName="whatsapp"
												scale={this.state.iconSize}
												color="#fff"
												style={{
													transition:
														"all ease-in-out 0.3s"
												}}
											/>
										) : (
											<CustomIcon
												scale={1.2}
												iconName="share_plane"
												color="#fff"
											/>
										)}
									</IconWrapper>

									<div
										style={{
											fontSize: scrolled
												? "12px"
												: "14px",
											color: "#90A4AE"
										}}
									>
										{article?.metrics?.shares &&
											(article.metrics.shares +
												(this.state.hasBeenShared
													? 1
													: 0) ??
												0)}
									</div>
								</Flex>
							</Flex>

							<Flex
								align="center"
								gap={scrolled ? 0 : 12}
								style={{
									transform: scrolled
										? undefined
										: "translateY(75%)",
									zIndex: 99,
									height: 48
								}}
							>
								<IconWrapper
									onClick={this.handlePlayPause.bind(this)}
									style={{
										zIndex: "8",
										background:
											"linear-gradient(180deg, #F42CAC 0%, #F1353B 100%)",
										padding: scrolled ? "14px" : "12px",
										transform: scrolled
											? "scale(0.6)"
											: "scale(1)"
									}}
								>
									{!audioReady ? (
										<Icon
											type="loading"
											size="xl"
											color="red"
											style={{
												marginLeft: -7,
												marginTop: -5,
												borderColor: "white"
											}}
										/>
									) : (
										<CustomIcon
											iconName={
												isPlayed ? "pause" : "play"
											}
											style={{
												marginTop: 4,
												marginLeft: 4
											}}
											color="#fff"
										/>
									)}
								</IconWrapper>

								{/* VIDEO PUBLICATION ICON */}
								{article?.video ? (
									<IconWrapper
										onClick={() =>
											this.props.history.push(
												`/video/${article.video.slug}`,
												{
													story: article.video
												}
											)
										}
										style={{
											zIndex: "8",
											background:
												"linear-gradient(180deg, #FFD000 0%, #FFA100 100%)",
											padding: scrolled ? "14px" : "12px",
											transform: scrolled
												? "scale(0.6)"
												: "scale(1)"
										}}
									>
										<CustomIcon
											iconName="videoPublication"
											color="#fff"
											scale={1.3}
											style={{ marginTop: 4 }}
										/>
									</IconWrapper>
								) : null}
							</Flex>

							{scrolled && (
								<div
									onClick={() => {
										this.props.history.goBack()
										iosGenerateHaptic(
											HapticEffect.SELECTION
										)
									}}
								>
									<CustomIcon
										style={{ marginTop: 9 }}
										iconName="close_alt"
									/>
								</div>
							)}
						</Flex>
					</FixedController>

					{this.state.audioReady ? (
						<CustomAudioPlayer
							article={this.state.article}
							toPrevTrack={this.handlePrevTrack.bind(this)}
							toNextTrack={() => this.handleNextTrack()}
							audioSrc={this.state.audioLink}
							visible={this.state.isPlayed}
							onPlayPauseClick={(isPlayed) =>
								this.setState({ isPlayed })
							}
						/>
					) : null}

					{/* All the post content in the overlayed white card */}
					<PostCard>
						<Row style={{ marginTop: "10px" }}>
							<Col xs={12}>
								<Link
									to={"/journal/" + article?.journal?.uid}
									style={{
										color: "#4C5861",
										fontSize: "13px",
										fontWeight: "300"
									}}
									onClick={() => {
										gtmItemsData(
											ItemsDataEvent.VIEW_ORIGINAL_PAPER,
											convertContentToItemData(
												[article],
												ItemDataEventListName.ARTICLE_PAGE,
												ItemDataVariant.ARTICLE
											)
										)
									}}
								>
									{this.props.t(
										"content.articles.originalPublication"
									)}
									<br />

									<span
										style={{
											fontWeight: "700",
											color: "#212121",
											marginRight: 5
										}}
									>
										{article?.journal?.name ?? "..."}
									</span>
									<CustomIcon
										style={{ display: "inline" }}
										iconName="link"
									/>
								</Link>

								<Spacer />
								<PostTitle title="conclusion" />
								<PostText>
									{article?.conclusion
										?.filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later
										.map((sentence, index) => {
											return (
												<span
													key={"conclusion" + index}
												>
													<span
														style={{
															color: "#F42CAB",
															fontSize: "24px",
															verticalAlign: "top"
														}}
													>
														•{" "}
													</span>
													{`${sentence}`}
													<br />
													<br />
												</span>
											)
										})}
								</PostText>

								{article?.conclusion && <Divider />}

								<PostTitle title="background" />
								<PostText>
									<LinesEllipsis
										text={article?.background ?? ""}
										maxLine={
											backgroundClamp ? "10" : "1000"
										}
										ellipsis={
											<span>
												...
												<br />
												<button
													className="clamp-lines__button"
													onClick={() =>
														this.setState({
															backgroundClamp:
																!backgroundClamp
														})
													}
												>
													Read more
												</button>
											</span>
										}
										trimRight
										basedOn="letters"
									/>
									{!backgroundClamp && (
										<button
											aria-expanded="true"
											className="clamp-lines__button"
											onClick={() =>
												this.setState({
													backgroundClamp:
														!backgroundClamp
												})
											}
										>
											Read Less
										</button>
									)}
								</PostText>

								{article?.objectives?.length > 0 && (
									<>
										<Divider />
										<PostTitle title="objectives" />
										<PostText>
											{article?.objectives
												?.filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later
												.map((sentence, index) => {
													if (sentence?.length > 0)
														return (
															<span
																key={
																	"objectives" +
																	index
																}
															>
																<span
																	style={{
																		color: "#F42CAB",
																		fontSize:
																			"24px",
																		verticalAlign:
																			"top"
																	}}
																>
																	•{" "}
																</span>
																{`${sentence}`}
																<br />
																<br />
															</span>
														)
												})}
										</PostText>
									</>
								)}

								{/* Detect if Figures image array is empty */}
								<div
									style={{
										display:
											article?.images?.length > 0
												? "block"
												: "none"
									}}
								>
									<Divider />

									<PostTitle title="figures" />
								</div>
							</Col>
						</Row>
					</PostCard>

					<PostText
						style={{
							display:
								article?.images?.length > 0 ? "block" : "none",
							margin: 0,
							padding: 0
						}}
					>
						{article?.images?.length > 0 && (
							<>
								<div
									onClick={() =>
										this.setState(
											{ isViewerOpen: true },
											() => {
												disableScroll.on()
												if (isNativeIOS)
													iosSetBackgroundColor(
														"#191919"
													)
											}
										)
									}
								>
									<Carousel
										autoFocus={false}
										autoPlay={false}
										preventMovementUntilSwipeScrollTolerance
										swipeScrollTolerance={30}
										interval={1e12}
										stopOnHover={true}
										infiniteLoop={true}
										showIndicators={false}
										showStatus={false}
										showThumbs={false}
										showArrows={false}
										centerMode={false}
										selectedItem={this.state.imageIndex}
										onChange={(to) =>
											this.setState({ imageIndex: to })
										}
										style={{
											margin: 0
										}}
									>
										{article?.images
											?.filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later
											?.sort((a, b) => a.order - b.order)
											.map((figure, index) => (
												<img
													key={figure?._id + index}
													src={figure?.url}
													style={{
														width: "100%",
														height: "200px",
														objectFit: "cover"
													}}
												/>
											))}
									</Carousel>
								</div>
								<ImageViewer
									open={isViewerOpen}
									imgs={article?.images
										?.filter((el) => !!el)
										?.sort((a, b) => a.order - b.order)}
									imageIndex={this.state.imageIndex}
									updateIndex={(to) =>
										this.setState({ imageIndex: to })
									}
									onClose={() =>
										this.setState({ isViewerOpen: false })
									}
								/>
								<Spacer height="15px" />
								<center>
									{article?.images?.map(
										(_, index) =>
											index < 14 && (
												<CustomIndicators
													key={
														"indicatorsImageView" +
														index
													}
													active={
														this.state
															.imageIndex ===
														index
													}
												/>
											)
									)}
								</center>
							</>
						)}
					</PostText>

					<PostCard style={{ paddingTop: 0 }}>
						<Row>
							{/* Content of the post below */}

							<Col xs={12}>
								<Divider />
								<PostTitle title="methodology" />
								<PostText>
									<LinesEllipsis
										text={article?.methodology ?? ""}
										maxLine={
											methodologyClamp ? "10" : "1000"
										}
										ellipsis={
											<span>
												...
												<br />
												<button
													className="clamp-lines__button"
													onClick={() =>
														this.setState({
															methodologyClamp:
																!methodologyClamp
														})
													}
												>
													{t("common.readMore")}
												</button>
											</span>
										}
										trimRight
										basedOn="letters"
									/>
									{!methodologyClamp && (
										<button
											aria-expanded="true"
											className="clamp-lines__button"
											onClick={() =>
												this.setState({
													methodologyClamp:
														!methodologyClamp
												})
											}
										>
											{t("common.readLess")}
										</button>
									)}
								</PostText>

								{article?.methodology && <Divider />}

								<PostTitle title="results" />
								<PostText>
									{article?.results
										?.filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later
										.map((sentence, index) => {
											return (
												<span key={`results${index}`}>
													<span
														style={{
															color: "#F42CAB",
															fontSize: "24px",
															verticalAlign: "top"
														}}
													>
														•{" "}
													</span>
													{`${sentence}`}
													<br />
													<br />
												</span>
											)
										})}
								</PostText>

								{article?.results && <Divider />}

								<div
									style={{
										display: article?.limitations
											? "block"
											: "none"
									}}
								>
									<PostTitle title="limitations" />
									<PostText>
										<LinesEllipsis
											text={article?.limitations ?? ""}
											maxLine={
												limitationsClamp ? "10" : "1000"
											}
											ellipsis={
												<span>
													...
													<br />
													<button
														className="clamp-lines__button"
														onClick={() =>
															this.setState({
																limitationsClamp:
																	!limitationsClamp
															})
														}
													>
														Read more
													</button>
												</span>
											}
											trimRight
											basedOn="letters"
										/>
										{!limitationsClamp && (
											<button
												aria-expanded="true"
												className="clamp-lines__button"
												onClick={() =>
													this.setState({
														limitationsClamp:
															!limitationsClamp
													})
												}
											>
												Read Less
											</button>
										)}
									</PostText>

									<Divider />
								</div>

								<div
									style={{
										display:
											article?.authors.length > 0
												? "block"
												: "none"
									}}
								>
									<PostTitle title="authors" />
									{[
										article.primary_author,
										...article.authors
									]
										.filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later
										.map((author, index) => {
											return (
												<Row
													key={`authors${index}`}
													align="center"
												>
													<Col xs={2}>
														<AvatarPlaceholder />
													</Col>
													<Col>
														<AuthorName>
															{author}
														</AuthorName>
													</Col>
												</Row>
											)
										})}

									<Divider />
								</div>
								<div
									style={{
										display: article?.keywords
											? "block"
											: "none"
									}}
								>
									<PostTitle title="keywords" />
									<PostText
										style={{
											fontSize: "12px",
											lineHeight: "18px"
										}}
									>
										{article?.keywords
											.filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later
											.map((keyword, index) => (
												<span
													key={
														"keyword" +
														keyword +
														index
													}
												>
													{keyword}
													{index !==
														article?.keywords
															?.length -
															1 && ", "}
												</span>
											))}
									</PostText>
									<Divider />
								</div>

								<div
									style={{
										display: article?.disclosure
											? "block"
											: "none"
									}}
								>
									<PostTitle title="disclosure" />
									<PostText
										style={{
											fontSize: "12px",
											lineHeight: "18px"
										}}
									>
										{article?.disclosure ?? ""}
									</PostText>
								</div>
							</Col>
						</Row>
						{article?.disclosure && <Divider />}
						{article?.edito && (
							<EditoLinkButton href={article.edito} />
						)}
						<OriginalLinkButton
							article={article}
							href={`${article?.doi ?? ""}`}
						/>

						{/* Bottom Navigation */}
						{!this.props.location.search.startsWith("?shared=") &&
							false && (
								<Row
									align="center"
									justify={
										this.props.match.path ===
										"/post/push/:id"
											? "start"
											: "end"
									}
									style={{
										padding: "40px 20px 30px 20px"
										// borderBottom: "1px solid #90A4AE",
									}}
								>
									<>
										<Spacer />
										<Col
											xs={6}
											style={{
												textAlign: "left",
												display:
													this.state.currentIndex ===
													0
														? "none"
														: "block"
											}}
										>
											<Link
												to={{
													pathname: `/post/${this.handlePrevNext(
														"prev"
													)}`,
													key: 0,
													state: {
														article:
															this.props.articles
																.list[
																this.handleGetIndex() -
																	1
															]
													}
												}}
											>
												<Row
													align="center"
													justify="start"
												>
													<CustomIcon
														iconName="back-arrow"
														color="white"
													/>
													<p
														style={{
															textTransform:
																"uppercase",
															color: "#81939C",
															marginTop: "4px",
															fontSize: "15px",
															marginRight: "10px"
														}}
													>
														back
													</p>
												</Row>
											</Link>
										</Col>

										<Col
											xs={6}
											style={{ textAlign: "right" }}
										>
											<Link
												to={{
													pathname: `/post/${this.handlePrevNext(
														"next"
													)}`,
													state: {
														article:
															this.props.articles
																.list[
																this.handleGetIndex() +
																	1
															]
													}
												}}
											>
												<Row
													align="center"
													justify="end"
												>
													<p
														style={{
															textTransform:
																"uppercase",
															color: "#81939C",
															marginTop: "4px",
															fontSize: "15px",
															marginRight: "10px"
														}}
													>
														next
													</p>
													<CustomIcon
														iconName="next-arrow"
														color="white"
													/>
												</Row>
											</Link>
										</Col>
									</>
								</Row>
							)}
						<Spacer height="100px" />
						<div ref={this.bottomPageRef} />
					</PostCard>

					<ArticlesWrapper
						style={{
							display: this.state.relatedArticles?.length
								? "block"
								: "none"
						}}
					>
						{this.props.location?.state?.sponsorised ? (
							<h3>
								{contentLanguage === "fr"
									? "Playlist " +
									  this.props.location?.state?.playlist
											?.company?.name
									: this.props.location?.state?.playlist
											?.company?.name + "'s playlist"}
							</h3>
						) : (
							<h3>
								{contentLanguage === "fr"
									? "Un autre concentré "
									: "Refill about "}
								<br />
								{contentLanguage === "fr"
									? "aeouiAEOUI".includes(
											article?.medical_specialties[0]
												?.translations?.[
												contentLanguage
											][0]
									  )
										? "d'"
										: "de "
									: " "}
								{article?.medical_specialties[0]
									?.translations?.[contentLanguage] ?? "en"}
							</h3>
						)}
						{this.state.relatedArticles.length &&
							this.state.relatedArticles
								.filter((el) => !!el) // NOTE: hotfix for corrupted data, remove later
								.map((article) => {
									return (
										<ArticleCard
											itemListName={
												ItemDataEventListName.ARTICLE_PAGE
											}
											currentPath="/post"
											key={article?._id}
											article={article}
											user={this.props.user.user}
											link={{
												pathname: `/post/${article.slug}`,
												state: {
													article
												}
											}}
										/>
									)
								})}
					</ArticlesWrapper>
				</PostWrapper>

				{/* PLAYLIST */}
				<>
					{this.state.showSave && (
						<PlaylistSheet
							playlists={this.props.user?.playlists?.docs}
							showSheet={this.state.showSave}
							onClose={() => this.setState({ showSave: false })}
							article={this.state?.article}
							getPlaylists={() => this.props.getPlaylists()}
							getSaves={() => this.props.getSaves()}
							getUser={() => this.props.getUser()}
							onComplete={this.addPlaylistSuccess.bind(this)}
						/>
					)}

					<BottomSheet
						expandOnContentDrag
						open={showSheet}
						onDismiss={() =>
							setTimeout(
								() => this.setState({ showSheet: false }),
								100
							)
						}
					>
						<SheetWrapper>
							<Row
								style={{ margin: 0 }}
								align="center"
								gutterWidth={45}
								onClick={() => {
									if (!this.props.user.user.profession)
										kycMiddleware(
											this,
											t(
												"toast.error.notOnboarded.saveArticle"
											)
										)
									else if (!!store.getState().user?.user) {
										this.setState({
											showSave: true,
											showSheet: false
										})
									} else {
										push("/login")
									}
								}}
							>
								<Col xs={1}>
									<Spacer height="5px" />
									<CustomIcon
										iconName={
											isMarked ? "save.fill" : "save"
										}
										color="#212121"
									/>
								</Col>
								<Col
									xs={10}
									style={{
										color: isMarked ? "#FF699C" : "#212121"
									}}
								>
									<p
										style={{
											fontSize: "17px",
											fontWeight: 600,
											margin: 0
										}}
									>
										{isMarked ? "Modify" : "Save"} article
									</p>
									<p
										style={{
											fontSize: "15px",
											fontWeight: 400,
											margin: 0
										}}
									>
										{isMarked
											? this.props.t(
													"sheet.article.unsave"
											  )
											: this.props.t(
													"sheet.article.save"
											  )}
									</p>
								</Col>
							</Row>
							<Spacer height="30px" />
						</SheetWrapper>
					</BottomSheet>
				</>
			</>
		)
	}
}

function mapState(state) {
	const articles = state.articles
	const user = state.user
	return {
		articles,
		user
	}
}

const mapDispatch = {
	getArticlesList: articlesApi.endpoints.getArticlesList.initiate,
	getUser: userApi.endpoints.getUser.initiate,
	getSaves: userApi.endpoints.getSaves.initiate,
	getPlaylists: userApi.endpoints.getPlaylists.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(PostPage))

export const AudioController = (props) => {
	return (
		<ControllerWrapper
			className={props.visible ? "" : "hide"}
			isNative={props.isNative}
		>
			{props.children}
		</ControllerWrapper>
	)
}

export const OriginalLinkButton = (props) => {
	const handleClickDOI = async () => {
		gtmItemsData(
			ItemsDataEvent.VIEW_ORIGINAL_PAPER,
			convertContentToItemData(
				[props.article],
				ItemDataEventListName.ARTICLE_PAGE,
				ItemDataVariant.ARTICLE
			)
		)

		// Increment "view_source" metric
		await patchViewSourceArticle(props.article._id)
	}
	return (
		<a href={props.href} target="_blank" onClick={() => handleClickDOI()}>
			<div
				style={{
					position: "relative",
					background: "#DFE6EC",
					borderRadius: "8px",
					padding: 20,
					...props.style
				}}
			>
				<img
					src={JuisciIcon}
					style={{ position: "absolute", top: -25, height: "45px" }}
					alt=""
				/>
				<Row style={{ margin: 0 }} align="center">
					<Col xs={10}>
						<p
							style={{
								fontFamily: "Poppins",
								fontWeight: 700,
								color: "#4C5861",
								lineHeight: "100%",
								fontSize: "16px",
								marginBottom: 0,
								whiteSpace: "pre-wrap"
							}}
						>
							{t("content.articles.originalLinkButton.title")}
						</p>
						<p
							style={{
								fontFamily: "Roboto",
								fontWeight: 400,
								color: "#4C5861",
								lineHeight: "16px",
								fontSize: "14px",
								marginTop: "6px"
							}}
						>
							{t("content.articles.originalLinkButton.subtitle")}
						</p>
					</Col>
					<Col xs={2} style={{ padding: 0 }}>
						<img
							src={NextColor}
							alt=""
							style={{ height: 50, padding: 0, margin: 0 }}
						/>
					</Col>
				</Row>
			</div>
		</a>
	)
}

export const EditoLinkButton = (props) => {
	return (
		<a href={props.href} target="_blank">
			<div
				style={{
					position: "relative",
					background: "#DFE6EC",
					borderRadius: "8px",
					padding: 20,
					marginBottom: 50,
					...props.style
				}}
			>
				<img
					src={EditoIcon}
					style={{
						position: "absolute",
						top: -25,
						left: 15,
						height: "45px"
					}}
					alt=""
				/>
				<Row style={{ margin: 0 }} align="center">
					<Col xs={10}>
						<p
							style={{
								fontFamily: "Poppins",
								fontWeight: 700,
								color: "#4C5861",
								lineHeight: "100%",
								fontSize: "16px",
								marginBottom: 0
							}}
						>
							{t("content.articles.editoButton.title")}
						</p>
						<p
							style={{
								fontFamily: "Roboto",
								fontWeight: 400,
								color: "#4C5861",
								lineHeight: "16px",
								fontSize: "14px",
								marginTop: "6px"
							}}
						>
							{t("content.articles.editoButton.subtitle")}
						</p>
					</Col>
					<Col xs={2} style={{ padding: 0 }}>
						<img
							src={NextColorYellow}
							alt=""
							style={{ height: 50, padding: 0, margin: 0 }}
						/>
					</Col>
				</Row>
			</div>
		</a>
	)
}

export const CustomIndicators = (props) => (
	<span
		style={{
			fontSize: "50px",
			color: props.active
				? props.primaryColor ?? "#FFC408"
				: props.secondary ?? "#90A4AE"
		}}
	>
		•
	</span>
)

const ControllerWrapper = styled.div`
	background: linear-gradient(180deg, #f42cab 0%, #f1353a 100%);
	border-radius: 8px;
	padding: 30px 20px 16px;
	margin: 20px;
	position: fixed;
	left: 0;
	right: 0;
	bottom: ${(props) => (props.isNative ? "70px" : "15px")};
	z-index: 10;
	animation: slideIn 0.2s ease-in;

	&.hide {
		opacity: 0;
		display: none;
		animation: slideOut 0.2s ease-out;
	}

	@keyframes slideIn {
		0% {
			opacity: 0;
			bottom: -10px;
		}
		100% {
			opacity: 1;
			bottom: ${(props) => (props.isNative ? "70px" : "15px")};
		}
	}

	@keyframes slideOut {
		0% {
			opacity: 1;
			bottom: ${(props) => (props.isNative ? "70px" : "15px")};
		}
		100% {
			opacity: 0;
			bottom: -10px;
		}
	}

	color: white;
	font-weight: 800;

	button {
		margin-top: -3px;
		outline: none;
		border: none;
		background: white;
		width: 27px;
		height: 27px;
		line-height: 27px;
		border-radius: 50%;
		font-size: 10px;
		color: #f42cab;
		font-weight: 500;
		font-family: "Poppins";
		text-align: center;
		padding: 0;
	}
`

const FixedController = styled.div`
	background: #ecf0f5;
	border-radius: 0 0 20px 20px;
	padding-bottom: 16px;
`

const AvatarPlaceholder = styled.div`
	background: linear-gradient(
		180deg,
		#ffd3a6 0%,
		#ff9c96 51.56%,
		#fc6f89 100%
	);
	height: 27px;
	width: 27px;
	border-radius: 50%;
`

const AuthorName = styled.p`
	font-size: 14px;
	font-family: Poppins;
	font-weight: 400;
	color: #212121;

	&:hover,
	&:focus {
		color: #212121;
	}
`

const Divider = styled.div`
	// border-top: 0.5px solid #d2dce2;
	// background: #d2dce2;
	// height: 0.5px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	margin: 40px 0;
`

const PostText = styled.div`
	font-size: 17px;
	line-height: 26px;
	color: #212121;
	font-weight: 400;
	margin: 0;
	padding: 0;
	word-break: break-word !important;
	font-family: "Poppins" !important;
`

const PostCard = styled.div`
	background: #fff;
	min-height: 50vh;
	z-index: 2;
	position: relative;
	top: 0px;
	padding: 30px 30px 0px 30px;
`

const PostWrapper = styled.div`
	min-height: 85vh;
	max-height: 100%;
	background: white;
	margin-bottom: 0;
	font-family: "Poppins" !important;
`

const ArticlesWrapper = styled.div`
	padding: 10px;
	background: linear-gradient(
		180deg,
		#ff699c 0%,
		#fe5763 26.99%,
		#ff8a00 51.99%,
		#fdb955 77.51%,
		#ffc408 100%
	);

	h3 {
		font-family: "Work Sans";
		font-weight: 900;
		font-size: 24px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.02em;
		color: #ffffff;
	}
`

const PostInfos = styled.div`
	// background: linear-gradient(180deg, #ffd3a6 0%, #ff9c96 51.56%, #fc6f89 100%);
	background: #ecf0f5;
	padding: 100px 22px 16px 22px;
	z-index: 0;
	top: 50px;
	max-width: 100vw;
	overflow: hidden;

	p {
		font-size: 14px;
		// font-weight: 600;
		// color: #81939c;
		font-weight: 400;
		margin: 0;
		color: #212121;
	}

	span {
		font-size: 11px;
		font-weight: 500;
		margin: 0;
	}

	h3 {
		font-size: 25px;
		font-weight: 700;
		line-height: 29px;
		text-align: left;
		width: 100%;
		line-break: normal;
		overflow: clip;
	}
`

const StyledTube = styled.div`
	background: linear-gradient(
		to right,
		#ffc408 0 ${(props) => props.scroll},
		#fff 0
	);
	width: 100%;
	height: 13px;
	margin-bottom: 10px;
`

export const IconWrapper = styled.div`
	padding: 8px;
	width: 30px !important;
	height: 30px !important;
	border-radius: 50%;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
`

// Playlist components...

const SheetWrapper = styled.div`
	padding: 55px 5px;
	min-height: ${window.innerHeight / 3}px;
`
