import React, { Component } from "react"
import styled from "styled-components"
import JuisciLogo from "../../assets/core/juisci-logo.svg"
import { Spacer } from "../../components/global"
import JuisciPattern from "../../assets/images/mobile-pattern.svg"
import { t } from "i18next"
import CustomNavbar from "../../components/CustomNavbar"
import CloseAlt from "../../assets/icons/close_alt.svg"
import { isNativeIOS } from "../../tools/ios"
import { RemoveScrollBar } from "react-remove-scroll-bar"
import { RemoveScroll } from "react-remove-scroll"

class PrevLoginPage extends Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render() {
		return (
			<div>
				<CustomNavbar {...this.props} />
				<RemoveScrollBar />
				<RemoveScroll />
				<PrevLoginWrapper isNative={isNativeIOS}>
					<img
						src={CloseAlt}
						alt=""
						style={{
							position: "absolute",
							right: "30px",
							top: "-50px"
						}}
						onClick={() => {
							if (this.props.history.action === "PUSH") {
								this.props.history.goBack()
							} else {
								this.props.history.push("/home")
							}
						}}
					/>
					<center>
						<img src={JuisciLogo} alt="" />
						<SignupTitle>
							<h1>{t("sheet.login.title")}</h1>
							<p>
								<b>{t("sheet.login.subtitle")}</b>
								<br />
								{t("sheet.login.description")}
							</p>
						</SignupTitle>
						<Spacer height="30px" />
						<button
							onClick={() =>
								this.props.history.replace("/signup/prev")
							}
						>
							{t("common.register")}
						</button>
						<button
							onClick={() => {
								this.props.history.replace("/login")
							}}
							style={{ background: "#FFC408" }}
						>
							{t("common.login")}
						</button>
					</center>
					<img className="img-pattern" src={JuisciPattern} alt="" />
				</PrevLoginWrapper>
			</div>
		)
	}
}

export default PrevLoginPage

const PrevLoginWrapper = styled.div`
	height: ${window.innerHeight + 100}px;
	overscroll-behavior: contain;
	padding: 0 40px;
	z-index: 97 !important;
	font-family: "Poppins";
	position: relative;
	background: #ffffff;

	button {
		font-family: "Poppins";
		width: 100%;
		outline: none;
		border: none;
		box-shadow: none;
		align-items: center;
		padding: 16px 24px;
		background: #ecf0f5;
		border-radius: 100px;
		color: #212121;
		font-size: 16px;
		line-height: 16px;
		font-weight: 700;
		margin: 5px 0;
	}

	img {
		margin-bottom: 10px;
		margin-top: ${window.innerHeight * 0.17}px;
	}

	img.img-pattern {
		margin: 0;
		padding: 0;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		object-fit: ${(props) => (props.isNative ? "fill" : "cover")};
		height: ${(props) =>
			props.isNative
				? window.innerHeight * 0.35
				: window.innerHeight * 0.2}px;
	}

	p {
		font-size: 16px;
		line-height: 22.4px;
		text-align: center;
	}

	b {
		font-weight: 700;
	}
`

const SignupTitle = styled.div`
	color: #212121;
	text-align: center;

	h1 {
		font-family: "Poppins";
		font-size: 24px;
		line-height: 33.6px;
		margin-bottom: 0px;
	}

	p {
		font-family: Roboto !important;
		font-size: 16px;
		margin-top: 5px;
		margin-bottom: 35px;
	}
`
