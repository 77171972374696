import { Tab, Tabs } from "@mui/material";
import React from "react";
import styled from "styled-components";

type CustomTabsProps = {
  value: number;
  tabs: string[];
  onChange: (newValue: number, event: React.ChangeEvent<{}>) => void;
};

const CustomTabs = ({ value, tabs, onChange }: CustomTabsProps) => {
  return (
    <StyledTabs
      centered
      value={value}
      onChange={(evt, newValue) => onChange(newValue, evt)}
    >
      {tabs.map((tab, index) => (
        <Tab key={index} label={tab} />
      ))}
    </StyledTabs>
  );
};

export default CustomTabs;

const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    background-color: #ffc408;
    border-radius: 20px;
    height: 6px;
  }

  & .MuiTab-root {
    text-transform: none;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: -0.0001em;
    text-align: center;
    color: #90a4ae;

    opacity: 1;
    padding: 6px 2rem;
    min-width: 0;
    margin-right: 1rem;
    transition: opacity 0.3s ease-in-out;

    &.Mui-selected {
      color: #212121;
    }

    &:hover {
      opacity: 1;
    }
  }
`;
