import { Component } from "react"
import { BottomSheet } from "react-spring-bottom-sheet"
import CustomIcon from "../CustomIcon"
import { kycMiddleware } from "../../tools/utils"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { userApi } from "../../redux/user/user.service"
import customToast from "../CustomToast"
import { ShareText, Spacer } from "../global"
import { Col, Row } from "react-grid-system"
import { t } from "i18next"
import { gtmItemData } from "../../tools/reactgaEvents"
import { sharedArticle } from "../../services/articles.services"
import { androidShare } from "../../tools/android"
import { isAndroid } from "react-device-detect"
import styled from "styled-components"
import PlaylistSheet from "../playlists/PlaylistSheet"
import { iosPlaySound } from "../../tools/ios"
import { SoundEffect } from "../../interfaces"
import { getFirebaseToken } from "../../services/firebase"

class OptionSheet extends Component {
	state = {
		isAuthentified: false,
		showSave: false,
		isMarked: false
	}

	async componentDidMount() {
		const isAuthentified = await getFirebaseToken()
		this.setState({ isAuthentified })
		if (isAuthentified) {
			if (!this.props.user.playlists) await this.props.getPlaylists()
		}

		this.setState({
			isMarked:
				!!this.props?.user?.saves &&
				this.props.user.saves.find(
					(article) => article?._id === this.props.article._id
				)
		})
	}

	addPlaylistSuccess = async ({ contentIsInPlaylist }) => {
		this.setState({ isMarked: contentIsInPlaylist })
		await this.props.getUser()
		await this.props.getPlaylists()
	}

	async shareArticle() {
		const { title, _id, journal, medical_specialties } = this.props.article

		const isAuthentified = await getFirebaseToken()

		const shareQueries = `?shared=${
			this.props.user?.user?.uid ?? "null"
		}&org=${this.props.user.user?.organisations?.[0]?.uid || "null"}`

		if (isAuthentified && !this.props.user.user.profession) {
			/** If login, verify if user has been onboarded */
			kycMiddleware(this, t("toast.error.notOnboarded.shareArticle"))
		} else if (isAndroid) {
			androidShare(
				`${window.location.href}${shareQueries}`,
				ShareText(title, journal?.name)
			)
		} else if (navigator.share) {
			navigator
				.share({
					title: document.title,
					text: ShareText(title, journal?.name),
					url: `/post/${this.props.article.slug}${shareQueries}`
				})
				.then(async () => {
					await sharedArticle(this.props.article?.slug)
					customToast(t("toast.success.sharedArticle"), "success")
					iosPlaySound(SoundEffect.SHARE)
					gtmItemData({
						event: "article_share",
						item_name: title,
						item_list_name: window.location.pathname,
						item_id: _id,
						item_brand: journal?.name,
						item_category: medical_specialties[0].uid,
						item_category3: "article"
					})
				})
		} else {
			customToast(t("toast.error.browser"))
		}
	}

	render() {
		const { isAuthentified } = this.state
		return (
			<div>
				{this.state.showSave && (
					<PlaylistSheet
						playlists={this.props.user?.playlists?.docs}
						showSheet={this.state.showSave}
						onClose={() => this.setState({ showSave: false })}
						article={this.props.article}
						getUser={() => this.props.getUser()}
						getPlaylists={() => this.props.getPlaylists()}
						onComplete={this.addPlaylistSuccess.bind(this)}
						itemListName={this.props.itemListName}
					/>
				)}

				<BottomSheet
					expandOnContentDrag
					open={this.props.showSheet}
					onDismiss={this.props.handleDismiss}
				>
					<div
						style={{ float: "right", marginRight: "20px" }}
						onClick={this.props.handleDismiss}
					>
						<CustomIcon iconName="close_alt" />
					</div>
					<SheetWrapper>
						<Row
							style={{ margin: 0 }}
							justify="around"
							align="center"
							gutterWidth={16}
							onClick={() => {
								if (
									isAuthentified &&
									!this.props.user.user.profession
								)
									kycMiddleware(
										this,
										t(
											"toast.error.notOnboarded.saveArticle"
										)
									)
								else if (isAuthentified) {
									this.setState({
										showSave: true,
										showSheet: false
									})
								} else {
									this.props.history.push("/login")
								}
							}}
						>
							<Col xs={1}>
								<Spacer height="5px" />
								<CustomIcon
									iconName={
										this.state.isMarked
											? "save.fill"
											: "save"
									}
									color="#212121"
									style={{ marginLeft: -3 }}
								/>
							</Col>
							<Col
								xs={10}
								style={{
									color: this.state.isMarked
										? "#FF699C"
										: "#212121",
									marginLeft: -12
								}}
							>
								<p
									style={{
										fontSize: "17px",
										fontWeight: 600,
										margin: 0
									}}
								>
									{this.state.isMarked
										? t("common.modify")
										: t("common.save")}
								</p>
								<p
									style={{
										fontSize: "15px",
										fontWeight: 400,
										width: "100%",
										margin: 0
									}}
								>
									{this.state.isMarked
										? t("sheet.article.unsave")
										: t("sheet.article.save")}
								</p>
							</Col>
						</Row>
						<Spacer height="30px" />
						<Row
							justify="around"
							align="center"
							gutterWidth={20}
							onClick={this.props.handleLike}
						>
							<Col xs={1}>
								<Spacer height="10px" />
								<CustomIcon
									scale={1.5}
									style={{ marginTop: -5, marginLeft: 7 }}
									iconName={
										this.props.isLiked
											? "heart"
											: "heart_outlined"
									}
									color={
										this.props.isLiked
											? "#FF699C"
											: "#212121"
									}
								/>
							</Col>
							<Col
								xs={10}
								style={{
									color: this.props.isLiked
										? "#FF699C"
										: "#212121"
								}}
							>
								<p
									style={{
										fontSize: "17px",
										fontWeight: 600,
										margin: 0
									}}
								>
									{this.props.isLiked
										? t("common.unlike")
										: t("common.like")}
								</p>
								<p
									style={{
										fontSize: "15px",
										fontWeight: 400,
										margin: 0
									}}
								>
									{t("sheet.article.like")}
								</p>
							</Col>
						</Row>
						<Spacer height="30px" />
						<Row
							justify="around"
							align="center"
							gutterWidth={20}
							onClick={this.shareArticle.bind(this)}
						>
							<Col xs={1}>
								<Spacer height="5px" />

								<CustomIcon
									iconName="share-alt"
									scale={0.8}
									color="#212121"
									style={{
										marginLeft: 5
									}}
								/>
							</Col>
							<Col xs={10}>
								<p
									style={{
										fontSize: "17px",
										fontWeight: 600,
										margin: 0
									}}
								>
									{t("common.share")}
								</p>
								<p
									style={{
										fontSize: "15px",
										fontWeight: 400,
										margin: 0
									}}
								>
									{t("sheet.article.share")}
								</p>
							</Col>
						</Row>
					</SheetWrapper>
				</BottomSheet>
			</div>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate,
	getPlaylists: userApi.endpoints.getPlaylists.initiate
}
const connector = connect(mapState, mapDispatch)

export default connector(withRouter(OptionSheet))

const SheetWrapper = styled.div`
	padding: 55px 15px;
	min-height: ${window.innerHeight / 3}px;
`
