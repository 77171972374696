import { useEffect, useState } from "react";
import { useLocation } from "react-router";

export default function RouteChecker() {
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState(location.pathname);
  
    useEffect(() => {
      const nextPath = location.pathname;
      
      if (
        // If none of the paths is the search page
        ![currentPath, nextPath].some(path => path.startsWith("/search")) ||
        // If none of the paths is a search result path
        ![
          "/post/",
          "/journal/",
          "/playlist", // V1
          "/profile/user",
        ].some((prefix) => {
          return [currentPath, nextPath].some(path => path.startsWith(prefix));
        })
      ) {
        console.debug("CLEAR SEARCH PARAMS");
        localStorage.removeItem("_search_params");
      }
  
      setCurrentPath(location.pathname);
    }, [location.pathname]);
  
    return null;
  }