import axios from "axios";
import { API_URL } from "../config";
import { catchError, handleError } from "./response";
import i18n from "../config/i18n";
import { getApiRequestService } from "./index.services";

export const getArticlesList = async ({
  offset,
  medical_specialties,
  tags,
  publication_date,
  limit,
  journal,
}: Record<string, any>) => {
  try {
    const language = !!localStorage.getItem("contentLanguage")
      ? localStorage.getItem("contentLanguage")
      : i18n.resolvedLanguage;

    let params: Record<string, unknown> = {
      offset,
      publication_date,
      limit,
      language,
      journal,
      ts: new Date().getTime(),
      status: "published",
      visibility: "public",
    };

    if (!!medical_specialties && medical_specialties?.length)
      params.medical_specialties = medical_specialties;

    if (!!tags && tags?.length) params.tags = tags;

    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get("/article", { params });

    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const recommanderMultiple = async (params: Record<string, number>) => {
  try {
    const language = localStorage.getItem("contentLanguage") ?? "en";
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/recommendations/forYouMultiple`, {
      ...params,
      language,
    });

    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const searchArticlesList = async (keywords: any) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/article?keywords=${keywords}`);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

// Recherche classique
export const searchArticles = async (
  offset?: number,
  search?: string,
  limit?: number
) => {
  try {
    const apiInstance = await getApiRequestService();

    const res = await apiInstance.get(`/article/search`, {
      params: {
        offset,
        limit,
        search,
        language: localStorage.getItem("contentLanguage"),
      },
    });

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const getArticle = async (slug: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/article/${slug}`);
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const sharedArticle = async (slug: any) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/article/${slug}/share`);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const getFigureImage = async (image_path: any) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`article/image/${image_path}`);
    //
    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const articleSpeech = async ({
  slug,
  language,
}: {
  slug: string;
  language: string;
}) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(
      `/speech/synthesize?language=${language}`,
      { slug }
    );

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const audioArticle = async (auth: any, data: any, url: any) => {
  try {
    const res = await axios.post(url, data, {
      auth: auth,
      responseType: "arraybuffer",
    });

    var blob = new Blob([res.data], { type: "audio/wav" });

    var blobUrl = URL.createObjectURL(blob);

    return blobUrl;
  } catch (error) {
    return catchError(error);
  }
};

export const getArticleSpecialties = async () => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get("/tag/parents");
    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const getArticleTags = async (specialty_uid: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`tag/parent/${specialty_uid}`);
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

// Récupérer le Last Article...
export const getLastArticle = async () => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get("history/me/lastArticle");
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserLastArticle = async (user_uid: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`history/${user_uid}/lastArticle`);
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getRelatedArticles = async (
  slug: string,
  medical_specialties: string,
  tags: string
) => {
  try {
    const language = localStorage.getItem("contentLanguage");

    const params = {
      language,
      medical_specialties,
      tags,
    };
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`article/${slug}/related`, {
      params,
    });
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getContentRelatedArticles = async (slug: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get("content/relatedArticles/" + slug);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const patchViewSourceArticle = async (articleId: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.patch(`/article/view-source/${articleId}`);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};
