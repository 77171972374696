import React, { Component } from "react";
import styled from "styled-components";
import { WingBlank } from "antd-mobile";
import Logo from "../../assets/core/juisci-logo.svg";
// import LogoAnimated from "../../assets/core/juisci-loop-1.gif";
import disableScroll from "disable-scroll";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import { push } from "react-router-redirect";

class WelcomePage extends Component {
  componentDidMount() {
    disableScroll.on();
    setTimeout(() => {
      push("/home");
    }, 3000);
  }

  render() {
    return (
      <div style={{ minHeight: "100vh", background: "#F7F8FC" }}>
        <RemoveScrollBar />
        <WingBlank>
          <center>
            <LogoBrand src={Logo} alt="JUICI" />
            <TextContent>
              <p
                style={{
                  fontWeight: "bold",
                }}
              >
                Juisci’s current focus is across topics in cardiology
              </p>
              <p>More specialties to come, stay tuned</p>
              <p
                className="loading"
                style={{
                  marginTop: "30px",
                  color: "#81939C",
                }}
              >
                Preparing shots
              </p>
            </TextContent>
          </center>
        </WingBlank>
      </div>
    );
  }
}

export default WelcomePage;

const LogoBrand = styled.img`
  width: 150px;
  height: auto;
  padding-top: 150px;
  padding-bottom: 70px;
  background: transparent !important;
`;

const TextContent = styled.div`
  padding: 0 5%;
  p {
    font-size: 18px;
  }
`;
