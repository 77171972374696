import React, { Component, useState } from "react"
import styled from "styled-components"
import { Avatar, ProfileContent, Spacer } from "../../components/global"
import MessageBar from "../../components/MessageBar"
import TextareaAutosize from "react-textarea-autosize"
import { Col, Row } from "react-grid-system"
import Loader from "../../components/Loader"
import Moment from "react-moment"
import FadeIn from "react-fade-in"
import CustomIcon from "../../components/CustomIcon"
import { withTranslation } from "react-i18next"
import "moment/locale/fr"
import { isNativeIOS } from "../../tools/ios"
import {
	deleteNotification,
	getNotifications
} from "../../services/notifications.services"
import { gtmBasicEvent } from "../../tools/reactgaEvents"
import { pickGradient } from "../../tools/utils"
import { getUser } from "../../services/user.services"
import customToast from "../../components/CustomToast"
import { t } from "i18next"
import ProfilePicture from "../../components/ProfilePicture"

class DirectMessage extends Component {
	socketConInterval = null
	socketConvInterval = null

	constructor(props) {
		super(props)
		this.messagesEnd = React.createRef(null)

		this.state = {
			conversation: {},
			messageValue: "",
			loading: true,
			authUser: null
		}
	}

	async componentDidMount() {
		try {
			const conversation_id = this.props.match.params.id

			// Reading all related notifications...
			getNotifications().then((res) => {
				var notifications = res.filter((v) => !v.deleted)

				notifications.map((notification) => {
					if (
						notification?.action?.notificationType ===
						"DirectMessage"
					)
						if (
							notification?.action?.conversation?._id ===
							conversation_id
						)
							deleteNotification(notification?._id)
				})
			})

			const authUser = await getUser()
			this.setState({ authUser })

			this.socketConInterval = setInterval(() => {
				if (this.props.socketHook.connected) {
					clearInterval(this.socketConInterval)
					this.socketConvInterval = setInterval(() => {
						if (
							this.props.socketHook.conversationsList &&
							this.props.socketHook.conversationsList.length > 0
						) {
							clearInterval(this.socketConvInterval)

							var conversation =
								this.props.socketHook.conversationsList.find(
									(conv) => conv._id === conversation_id
								)
							var otherUser =
								conversation?.users[1]?.uid !== authUser.uid
									? conversation?.users[1]
									: conversation?.users[0]

							this.setState({
								conversation,
								loading: false,
								otherUser
							})

							this.props.socketHook.markConversationAsRead(
								conversation_id
							)

							this.props.socketHook.socket?.on(
								"newMessage",
								(message) => {
									var conversation = this.state.conversation

									var new_message = message
									new_message.isMe =
										message?.sender?.uid === authUser.uid
									conversation?.messages?.push(new_message)
									this.setState({ conversation })
								}
							)
						}
					}, 100)
				} else {
					this.props.socketHook.connectSocket()
				}
			}, 100)
		} catch (error) {
			console.error("Err DirectMessage didMount", error.message)
			customToast(t("toast.error.global"))
			this.props.history.replace("/profile/messages")
		}
	}

	componentDidUpdate() {
		if (this.state.loading === false) {
			this.scrollToBottom()
		}
	}

	handleSendMessage() {
		const { messageValue } = this.state
		const conversation_id = this.props.match.params.id

		if (messageValue.length > 0) {
			gtmBasicEvent("message_sent")
			this.props.socketHook.sendMessage(conversation_id, messageValue)

			this.scrollToBottom()
			this.setState({ messageValue: "" })
		}
	}

	scrollToBottom = () => {
		var objDiv = document.getElementById("dmcontainer")
		objDiv.scrollTop = objDiv.scrollHeight
	}

	componentWillUnmount() {
		const conversation_id = this.props.match.params.id
		this.props.socketHook.markConversationAsRead(conversation_id)
	}

	render() {
		const { conversation, messageValue, loading, otherUser, authUser } =
			this.state
		const { t } = this.props

		return (
			<>
				{loading ? (
					<Loader />
				) : (
					<div>
						<HeaderMessage>
							<MessageBar
								{...this.props}
								prevState={{
									firstname: otherUser?.firstname,
									lastname: otherUser?.lastname,
									uid: otherUser?.uid
								}}
								isDM
								page="DM"
							/>
						</HeaderMessage>
						<DMContainer id="dmcontainer">
							<DMContent>
								<center
									onClick={() =>
										this.props.history.push(
											`/profile/user/${otherUser?.uid}`
										)
									}
								>
									<ProfilePicture
										user={otherUser}
										height={100}
										onClick={() => push("/profile/edit")}
									/>
									<Spacer height="10px" />
									<h4>
										{otherUser?.firstname}{" "}
										<b>{otherUser?.lastname}</b>
									</h4>
									<h5>{t("messages.dm.welcome")}</h5>
									<Spacer />
								</center>

								{conversation?.messages?.map(
									(message, index) => {
										var sender_uid = message?.sender?.uid
										var showDate =
											conversation?.messages[index - 1]
												?.sender?.uid === sender_uid

										return (
											<MessageContent
												key={index}
												showDate={!showDate}
												date={
													message?.meta?.creationDate
												}
												message={message?.message}
												isMe={
													message?.sender?.uid ===
														authUser.uid ||
													message?.isMe
												}
											/>
										)
									}
								)}
							</DMContent>
						</DMContainer>

						<MessageAreaWrapper isNative={isNativeIOS}>
							<Row
								style={{ margin: 0 }}
								align="center"
								justify="between"
								gutterWidth={40}
							>
								<Col xs={19 / 2}>
									<MessageArea
										value={messageValue}
										placeholder={t("messages.dm.write")}
										onInput={(e) => {
											window.scroll({
												top: 1e7,
												left: 0,
												behavior: "smooth"
											})
											this.setState({
												messageValue: e.target.value
											})
										}}
									/>
								</Col>
								<Col xs={3 / 2}>
									<div
										onClick={this.handleSendMessage.bind(
											this
										)}
										style={{ marginLeft: -10 }}
									>
										<CustomIcon
											iconName="send_message"
											color={
												messageValue.length > 0
													? "#FFC408"
													: "#4C5861"
											}
										/>
									</div>
								</Col>
							</Row>
						</MessageAreaWrapper>
					</div>
				)}
			</>
		)
	}
}

export default withTranslation()(DirectMessage)

export const MessageContent = (props) => {
	const [showDate, setShowDate] = useState(false)
	var app_lang = localStorage.getItem("i18nextLng")

	return (
		<FadeIn
			onComplete={() => {
				window.scroll({
					top: 100000000,
					left: 0,
					behavior: "smooth"
				})
			}}
		>
			<div onClick={() => setShowDate(!showDate)}>
				{showDate && (
					<DateText isMe={props.isMe}>
						<Moment
							locale={app_lang === "fr" ? "fr" : "en"}
							fromNow
						>
							{props.date}
						</Moment>
					</DateText>
				)}
				<MessageWrapper isMe={props.isMe}>
					{props.message.split("\n").map((msg, index) => {
						return (
							<span key={index}>
								{msg}
								<br />
							</span>
						)
					})}
				</MessageWrapper>
			</div>
		</FadeIn>
	)
}

const DMContainer = styled.div`
	position: absolute;
	top: 55px;
	bottom: 60px;
	left: 0;
	right: 0;
	overflow: auto;
	background: #f7f8fc;
`

const HeaderMessage = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
`

const MessageArea = styled(TextareaAutosize)`
	margin: 0;
	border-radius: 6px;
	font-weight: 300;
	font-family: Poppins;
	font-size: 16px;
	padding: 10px 20px;
	resize: none;
	width: 100%;
	border: none;
	overflow-y: hidden;
`

const MessageAreaWrapper = styled.div`
	background: #dfe6ec;
	padding: 20px 10px 25px 10px;
	padding-bottom: ${(props) => props.isNative && "40px"};
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
`

const DMContent = styled(ProfileContent)`
	overflow-y: auto;
	padding: 60px 20px;

	h4 {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 300;
		font-size: 17px;
		line-height: 100%;
		color: #212121;
		margin: 0;
	}

	h5 {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 300;
		font-size: 12px;
		line-height: 15px;
		text-align: center;
		color: #81939c;
		margin: 0;
		margin-top: 6px;
	}
`

const MessageWrapper = styled.div`
	background: ${(props) => (props.isMe ? "#FFC408" : "#DFE6EC")};
	padding: 10px 15px;
	border-radius: 12px;
	margin-top: 2px;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 140%;
	letter-spacing: 0.02em;
	color: #000000;
	width: auto;
	max-width: 50%;

	margin-left: ${(props) => props.isMe && "auto"};
	margin-right: ${(props) => !props.isMe && "auto"};

	display: table;
`

const DateText = styled.p`
	font-family: Poppins;
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 15px;
	margin-bottom: 10px;
	color: #81939c;
	text-align: ${(props) => (props.isMe ? "right" : "left")};
	text-transform: capitalize;
`
