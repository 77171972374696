import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IArticle } from "../../interfaces/article.interface";
import { articlesApi } from "./articles.service";

export interface ArticlesState {
  list: IArticle[];
  suggested: IArticle[];
  sponsored: any[];
  sponsoredFiltered: any[];
  specialties: {
    list: String[];
    isUninitialized: boolean;
  };
  meta: { total: number; limit: number; offset: number };
  isUninitialized: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const initialState: ArticlesState = {
  list: [],
  suggested: [],
  sponsored: [],
  sponsoredFiltered: [],
  specialties: {
    list: [],
    isUninitialized: true,
  },
  meta: { total: 0, limit: 0, offset: 0 },
  isUninitialized: true,
  isLoading: false,
  isFetching: false,
  isError: false,
  isSuccess: false,
};

export const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    setArticles: (state, action: PayloadAction<IArticle[]>) => {
      state.list = action.payload;
    },
    resetArticles: (state) => {
      state.list = [];
    },
    setSuggestedArticles: (state, action: PayloadAction<IArticle[]>) => {
      state.suggested = action.payload;
    },
    appendArticles: (state, action: PayloadAction<IArticle[]>) => {
      state.list.push(...action.payload);
    },
    setArticlesSpecialties: (state, action: any) => {
      state.specialties.list = action.payload;
    },
    setPublicPlaylists: (state, action: any) => {
      state.sponsored = action.payload;
    },
    setPublicPlaylistsFiltered: (state, action: any) => {
      state.sponsoredFiltered = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      articlesApi.endpoints.getArticlesList.matchFulfilled,
      (state, { payload }) => {
        if (state.list.length !== payload.meta.total) {
          state.list = new Array(payload.meta.total);
        }

        state.meta = payload.meta;

        state.list.splice(
          payload.meta.offset,
          payload.meta.limit,
          ...payload.docs
        );

        state.isUninitialized = false;
        state.isLoading = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.isError = false;
      }
    );
    builder.addMatcher(
      articlesApi.endpoints.getArticlesList.matchPending,
      (state) => {
        state.isLoading = state.isUninitialized;
        state.isFetching = true;
      }
    );
    builder.addMatcher(
      articlesApi.endpoints.getArticlesList.matchRejected,
      (state) => {
        state.isLoading = false;
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
      }
    );

    builder.addMatcher(
      articlesApi.endpoints.getArticleSpecialties.matchFulfilled,
      (state, { payload }) => {
        state.specialties.list = !!payload.length
          ? payload
          : payload.medical_specialties; // if is logged in we receive the profession object instead of array of specialties directly
        state.specialties.isUninitialized = false;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  setArticles,
  resetArticles,
  appendArticles,
  setSuggestedArticles,
  setPublicPlaylists,
  setPublicPlaylistsFiltered,
} = articlesSlice.actions;

export default articlesSlice.reducer;
