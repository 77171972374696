import { Component } from "react"
import styled from "styled-components"
import { t } from "i18next"
import CustomIcon from "../CustomIcon"
import { Drawer } from "@mui/material"
import customToast from "../CustomToast"
import { iosGenerateHaptic } from "../../tools/ios"
import { HapticEffect, SupportedLanguagesEnum } from "../../interfaces"
import CustomRadio from "../CustomRadio"
import { connect } from "react-redux"
import {
	getOrganisationLanguages,
	languageTranslation,
	renderContentLanguage
} from "../../tools/utils"
import { setContentLanguage } from "../../redux/global/global.reducer"
import { store } from "../../redux"

class LanguageBar extends Component {
	state = {
		showDrawer: false,
		contentLanguage: localStorage.getItem("contentLanguage")
	}

	handleChangeLanguage = () => {
		this.setState({ showDrawer: false })

		let flag = ""
		let newLanguage = t("languages.english")

		switch (this.state.contentLanguage) {
			case SupportedLanguagesEnum.FR:
				newLanguage = t("languages.french")
				break

			case SupportedLanguagesEnum.IT:
				newLanguage = t("languages.italian")
				break

			case SupportedLanguagesEnum.ES:
				newLanguage = t("languages.spanish")
				break

			default:
				newLanguage = t("languages.english")
		}

		switch (this.state.contentLanguage) {
			case SupportedLanguagesEnum.FR:
				flag = "🇫🇷"
				break

			case SupportedLanguagesEnum.IT:
				flag = "🇮🇹"
				break

			case SupportedLanguagesEnum.ES:
				flag = "🇪🇸"
				break

			default:
				flag = "🇬🇧"
		}

		if (this.state.edited)
			customToast(
				`${flag} ${t("toast.success.switchLanguage")} ${newLanguage}`
			)

		this.setState({ edited: false })
	}

	handleSwitchLanguage = (lang) => {
		this.setState({
			contentLanguage: lang,
			edited: true
		})

		store.dispatch(setContentLanguage(lang))

		iosGenerateHaptic(HapticEffect.SELECTION)

		this.props.onChange && this.props.onChange(lang)
	}

	render() {
		return (
			<>
				{this.props.customTrigger ? (
					<div onClick={() => this.setState({ showDrawer: true })}>
						{this.props.customTrigger}
					</div>
				) : (
					<StyledBar style={this.props.style}>
						<div
							className="lang-bar"
							onClick={() => this.setState({ showDrawer: true })}
						>
							<div className="left-section">
								{renderContentLanguage(
									this.state.contentLanguage
								)}
							</div>
							<div className="right-section">
								<div>
									{t("settings.languageBar.setContentLang")}
								</div>
								<CustomIcon iconName="arrow-right" />
							</div>
						</div>
					</StyledBar>
				)}

				<StyledDrawer
					anchor="left"
					open={this.state.showDrawer}
					onClose={this.handleChangeLanguage.bind(this)}
				>
					<div className="header">
						<div />
						<h5>{t("settings.languageBar.chooseLang")}</h5>
						<CustomIcon
							onClick={this.handleChangeLanguage.bind(this)}
							color="#FFFFFF"
							color2="#212121"
							iconName="close_alt"
						/>
					</div>

					{getOrganisationLanguages().map((lang) => (
						<CustomRadio
							key={lang.key}
							selectColor="#FFC408"
							onChange={() =>
								this.handleSwitchLanguage(lang.value)
							}
							checked={this.state.contentLanguage === lang.value}
							title={languageTranslation(lang.value)}
						/>
					))}
				</StyledDrawer>
			</>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {}

const connector = connect(mapState, mapDispatch)

export default connector(LanguageBar)

const StyledBar = styled.div`
	margin-top: 24px;

	.lang-bar {
		background: #ce0868;
		padding: 12px 21px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 8px;

		.right-section {
			display: flex;
			justify-content: end;
			align-items: center;
			gap: 8px;
			font-weight: 400;
		}

		.left-section {
			font-weight: 700;
		}

		/** Text style */
		color: #fff;
		font-family: "Roboto";
		font-style: normal;
		font-size: 14px;
		text-align: right;
		letter-spacing: 0.02em;

		.--custom-icon {
			transform: translateY(2px) !important;
		}
	}
`

export const StyledDrawer = styled(Drawer)`
	width: 100%;

	.MuiDrawer-paper {
		background: #ce0868;
		width: 100%;
		padding: 71px 24px;
		box-sizing: border-box;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #fff;
		padding-bottom: 27px;

		& :nth-child(1) {
			width: 30px;
			height: 30px;
		}

		h5 {
			font-family: "Inter";
			font-weight: 600;
			font-size: 16px;
			line-height: 140%;
			text-align: center;
			letter-spacing: -0.00011em;
			color: #ffffff;
			margin: 0;
		}
	}
`
