import React, { Component } from "react"
import styled from "styled-components"
import { t } from "i18next"
import { CustomInput } from "../../../components/CustomInput"
import { CustomButton, Spacer } from "../../../components/global"
import i18n from "../../../config/i18n"
import SimpleNavbar from "../../../components/SimpleNavbar"
import { withRouter } from "react-router-dom"
import customToast from "../../../components/CustomToast"
import CustomDatePicker from "../../../components/CustomDatePicker"
import moment from "moment"
import ComboBox from "../../../components/skeleton/ComboBox"
import { store } from "../../../redux"
import { userApi } from "../../../redux/user/user.service"
import { getFirebaseToken } from "../../../services/firebase"
import { getCountryList, POPULAR_COUNTRY_CODES } from "../countryList"

const countryList = getCountryList(i18n.resolvedLanguage)

class OnboardingProfile extends Component {
	state = {
		firstname: "",
		lastname: "",
		birthdate: "",
		country: ""
	}

	async componentDidMount() {
		try {
			const isAuthentified = await getFirebaseToken()
			if (!isAuthentified) {
				customToast(t("toast.error.notLogin"))
				this.props.history.replace("/login")
				return
			}

			/** If user has already some infodata */
			if (!!this.props.location.state?.user) {
				const { firstname, lastname, birthdate, country } =
					this.props.location.state.user

				this.setState({
					firstname,
					lastname,
					birthdate,
					country
				})
			} else {
				await store.dispatch(userApi.endpoints.getUser.initiate())
				const { firstname, lastname, birthdate, country } =
					store.getState().user.data

				this.setState({
					firstname,
					lastname,
					birthdate,
					country
				})
			}
		} catch (error) {
			console.error("Err componentDidMount", error.message)
		}
	}

	handleNext = () => {
		if (
			this.state.firstname?.length &&
			this.state.lastname?.length &&
			this.state.country?.length
		)
			this.props.history.push({
				pathname: "/signup/onboarding",
				state: {
					...this.state
				}
			})
		else customToast(t("signup.error.fieldsNotFilled"))
	}

	render() {
		const countryOptions = Object.keys(countryList)
			.map((key) => ({
				value: key,
				label: countryList[key]
			}))
			.sort((a, b) => {
				// Si 'a' est favori, il vient avant 'b'
				if (
					POPULAR_COUNTRY_CODES.includes(a.value) &&
					!POPULAR_COUNTRY_CODES.includes(b.value)
				) {
					return -1
				}
				// Si 'b' est favori, il vient après 'a'
				if (
					!POPULAR_COUNTRY_CODES.includes(a.value) &&
					POPULAR_COUNTRY_CODES.includes(b.value)
				) {
					return 1
				}
				// Trier les non favoris alphabétiquement
				if (
					!POPULAR_COUNTRY_CODES.includes(a.value) &&
					!POPULAR_COUNTRY_CODES.includes(b.value)
				) {
					return a.label.localeCompare(b.label)
				}
				// Si les deux sont favoris, on les laisse dans leur ordre original dans 'POPULAR_COUNTRY_CODES'
				return (
					POPULAR_COUNTRY_CODES.indexOf(a.value) -
					POPULAR_COUNTRY_CODES.indexOf(b.value)
				)
			})

		return (
			<CustomWrapper>
				<SimpleNavbar preventGoBack />
				<h1>{t("signup.onboarding.profileTitle")}</h1>
				<p>{t("signup.subtitle")}</p>
				<CustomInput
					type="text"
					name="firstname"
					placeholder={t("signup.firstname")}
					value={this.state.firstname}
					onInput={(e) => {
						this.setState({ firstname: e.target.value })
					}}
					style={{ marginBottom: "20px" }}
					required
				/>
				<Spacer height="20px" />
				<CustomInput
					type="text"
					name="lastname"
					placeholder={t("signup.lastname")}
					value={this.state.lastname}
					onInput={(e) => this.setState({ lastname: e.target.value })}
					style={{ marginBottom: "20px" }}
					required
				/>
				<Spacer height="20px" />
				<CustomDatePicker
					defaultValue={this.state.birthdate}
					onChange={(date) => {
						this.setState({
							birthdate: moment(date).format("YYYY-MM-DD")
						})
					}}
				/>
				<Spacer height="20px" />
				<ComboBox
					options={countryOptions}
					placeholder={t("signup.country")}
					groupBy={(option) =>
						POPULAR_COUNTRY_CODES.includes(option.value)
							? t("common.mostSelected")
							: t("signup.otherCountries")
					}
					onChange={(country) =>
						!!country && this.setState({ country: country.value })
					}
					required
				/>
				<Spacer height="20px" />
				<CustomButton
					className="primary"
					onClick={() => this.handleNext()}
				>
					{t("common.next")}
				</CustomButton>
			</CustomWrapper>
		)
	}
}

export default withRouter(OnboardingProfile)

const CustomWrapper = styled.div`
	background: #ecf0f5;
	min-height: 80vh;
	max-height: 100%;
	padding: 80px 30px 0px;
	padding-bottom: 100px;

	h1,
	p {
		color: #212121;
		text-align: center;
		margin: 5px 0;
	}

	p {
		margin-bottom: 24px;
	}
`
