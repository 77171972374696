import React, { Component } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { Row } from "react-grid-system"
import { Avatar, CustomContainer, Spacer } from "../../components/global"
import SettingsNavbar from "../../components/SettingsNavbar"
import disableScroll from "disable-scroll"
import { iosSetBackgroundColor, isNativeIOS } from "../../tools/ios"
import { withTranslation } from "react-i18next"
import CustomIcon from "../../components/CustomIcon"
import { t } from "i18next"
import { connect } from "react-redux"
import { userApi } from "../../redux/user/user.service"
import { logoutUserFromApp } from "../../tools/utils"
import CustomSwitch from "../../components/CustomSwitch"
import { getActiveSession } from "../../services/user.services"
import { OrganisationUid } from "../../interfaces"
import ProfilePicture from "../../components/ProfilePicture"
import Loader from "../../components/Loader"
import { getFirebaseToken } from "../../services/firebase"

class SettingsPage extends Component {
	state = {
		loadingLogout: false
	}

	async componentDidMount() {
		window.scrollTo(0, 0)
		disableScroll.off()

		if (!(await getFirebaseToken()))
			return this.props.history.replace("/login")

		getActiveSession().then((res) => {
			console.log("getActiveSession", res)
		})

		document.body.style.background = "#ecf0f5"

		if (this.props.user?.isUninitialized) this.props.getUser()

		if (isNativeIOS) {
			iosSetBackgroundColor("#FFFFFF")
		}
	}

	render() {
		const user = this.props.user?.user
		const isBiocodex =
			user?.organisations?.[0]?.uid === OrganisationUid.BIOCODEX

		if (this.props.user?.isUninitialized) return <Loader />

		return (
			<>
				<SettingsNavbar
					link="/profile"
					pageTitle={this.props.t("navigation.settings")}
					{...this.props}
				/>
				<Spacer height="60px" />
				<SettingsContainer>
					<h2>{t("navigation.profile")}</h2>
					<Row
						style={{ margin: "0 20px" }}
						onClick={() =>
							this.props.history.push("/settings/edit/profile")
						}
						justify="start"
					>
						<ProfilePicture user={user} height={64} />
						<p
							style={{
								fontSize: "17px",
								fontFamily: "Poppins",
								fontWeight: 700,
								marginLeft: 15
							}}
						>
							<span style={{ fontWeight: 400 }}>
								{user?.firstname}
							</span>
							<br />
							{user?.lastname}
						</p>
					</Row>

					<Spacer height="10px" />

					<SettingButton
						icon="settings_qrcode"
						to="/settings/qr-code"
					>
						{this.props.t("settings.shareQrcode")}
					</SettingButton>

					<SettingButton
						icon="settings_edit"
						to="/settings/edit/profile"
					>
						{this.props.t("settings.editProfile")}
					</SettingButton>

					<SettingButton
						icon="settings_preferred"
						to="/settings/edit/profile/preferredContent"
					>
						{this.props.t("settings.prefered_content")}
					</SettingButton>

					<Spacer />

					<h2>{t("navigation.settings")}</h2>

					<SettingButton icon="settings_security" to="/settings/edit">
						{t("settings.passwordSecurity")}
					</SettingButton>

					<SettingButton
						icon="settings_lang"
						to="/settings/edit/language"
					>
						{t("settings.languageDrawer.application.title")}
					</SettingButton>

					<SettingButton
						icon="settings_terms"
						to={{
							pathname: `/terms`,
							search: isBiocodex ? "?version=biocodex" : null,
							state: { isPush: true }
						}}
					>
						{this.props.t("settings.terms")}
					</SettingButton>

					<SettingButton
						icon="settings_policy"
						to={{
							pathname: `/policy`,
							search: isBiocodex ? "?version=biocodex" : null,
							state: { isPush: true }
						}}
					>
						{this.props.t("settings.policies")}
					</SettingButton>

					<SettingButton
						icon="settings_account"
						to="/settings/account"
					>
						{this.props.t("settings.account")}
					</SettingButton>

					<SettingLink
						onClick={() =>
							logoutUserFromApp(() =>
								this.setState({ loadingLogout: true })
							)
						}
					>
						<p
							style={{
								color: this.state.loadingLogout
									? "#81939c"
									: "#FF4300"
							}}
							className={
								this.state.loadingLogout ? "loading" : ""
							}
						>
							{this.props.t("settings.logout")}
						</p>
					</SettingLink>
				</SettingsContainer>
			</>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(SettingsPage))

export const SettingButton = (props) => {
	const content = (
		<div className="setting-button">
			<div className="left-part">
				<CustomIcon
					style={{
						marginLeft: props.icon === "settings_account" ? -4 : 0,
						float: "left"
					}}
					color="#000000"
					scale={props.icon === "bell" ? 0.8 : props.scale ?? 1}
					iconName={props.icon}
				/>
				<div className="button-title">{props.children}</div>
			</div>

			{props.mode === "switch" ? (
				<div>
					<CustomSwitch checked={props.checked} />
				</div>
			) : (
				<div>
					<svg
						width="8"
						height="15"
						viewBox="0 0 8 15"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M1 1.32031L7 7.32031L1 13.3203"
							stroke="#90A4AE"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
			)}
		</div>
	)

	if (!props.to) return <div onClick={props.onClick}>{content}</div>
	return <Link to={props.to ?? ""}>{content}</Link>
}

const SettingLink = styled.div`
	color: #212121;
	font-size: 16px;
	line-height: 32px;
	font-weight: 600;
	width: 100%;

	margin: 36px 0px;
	text-align: center;
`

export const SettingsContainer = styled(CustomContainer)`
	padding: 20px 0px;

	h2 {
		font-family: "Work Sans";
		font-size: 30px;
		font-weight: 900;
		color: #212121;
		margin: 0 25px;
		margin-bottom: 16px;
	}

	.--custom-icon {
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: start;
	}

	.setting-button {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #ffffff;
		padding: 12px 25px;
		box-sizing: border-box;
		width: 100%;
		margin: 8px 0;

		.left-part {
			display: flex;
			align-items: center;
			gap: 16px;
		}

		.button-title {
			font-size: 15px;
			color: #212121;
			font-family: "Poppins";
			font-weight: 300;
		}
	}
`
