import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IArticle } from "../../interfaces/article.interface";
import { IFollowPending } from "../../interfaces/follow.interface";
import { IPlaylist } from "../../interfaces/playlist.interface";
import { IUser } from "../../interfaces/user.interface";
import { getCurrentOS } from "../../tools/utils";
import { patchUser } from "../../services/user.services";
import { userApi } from "./user.service";
import i18n from "../../config/i18n";
import { IMeta } from "../../interfaces";

export interface UserState {
  user: IUser | Boolean;
  saves: string[] | Boolean;
  likes: IArticle[] | Boolean;
  playlists: {
    docs: Boolean | IPlaylist[];
    meta: {
      total: number;
      limit: number;
      offset: number;
    };
  };
  followers: {
    pending: IFollowPending[];
    active: IUser[];
    isUninitialized: boolean;
  };
  following: {
    pending: IFollowPending[];
    active: IUser[];
    isUninitialized: boolean;
  };
  readAnnouncements: string[];
  isUninitialized: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const initialState: UserState = {
  user: false,
  saves: false,
  likes: false,
  playlists: {
    docs: false,
    meta: { total: 0, limit: 0, offset: 0 },
  },
  followers: {
    pending: [],
    active: [],
    isUninitialized: true,
  },
  following: {
    pending: [],
    active: [],
    isUninitialized: true,
  },
  readAnnouncements: [],
  isUninitialized: true,
  isLoading: false,
  isFetching: false,
  isError: false,
  isSuccess: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setSaves: (state, action: any) => {
      state.saves = action.payload;
    },
    setLikes: (state, action: any) => {
      state.likes = action.payload;
    },
    setPlaylists: (state, action: PayloadAction<IPlaylist[]>) => {
      state.playlists.docs = action.payload;
    },
    setFollowers: (state, action: any) => {
      state.followers = action.payload;
    },
    setFollowing: (state, action: any) => {
      state.following = action.payload;
    },
    flagAnnouncementAsRead: (state, action: PayloadAction<string>) => {
      if (!state.readAnnouncements.includes(action.payload)) {
        state.readAnnouncements = [...state.readAnnouncements, action.payload];
      }
    },
  },
  extraReducers: async (builder) => {
    builder.addMatcher(
      userApi.endpoints.getUser.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
        state.isUninitialized = false;
        state.isLoading = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.isError = false;

        // Detect if user language is different from app
        if (
          payload.language !== i18n.language.slice(0, 2) &&
          payload?.profession?.length
        )
          setTimeout(() => i18n.changeLanguage(payload.language), 100);

        // Detect if user has 'device' attribute empty
        if (!payload?.device?.length) patchUser({ device: getCurrentOS() });
      }
    );
    builder.addMatcher(userApi.endpoints.getUser.matchPending, (state) => {
      state.isLoading = state.isUninitialized;
      state.isFetching = true;
    });

    builder.addMatcher(userApi.endpoints.getUser.matchRejected, (state) => {
      state.isLoading = false;
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
    });

    builder.addMatcher(
      userApi.endpoints.getSaves.matchFulfilled,
      (state, { payload }) => {
        state.saves = payload;
      }
    );

    builder.addMatcher(
      userApi.endpoints.getPlaylists.matchFulfilled,
      (state, { payload }) => {
        state.playlists = payload;
      }
    );

    // FOLLOWERS
    builder.addMatcher(
      userApi.endpoints.getFollowers.matchFulfilled,
      (state, { payload }) => {
        state.followers.active = payload;
        state.followers.isUninitialized = false;
      }
    );
    builder.addMatcher(
      userApi.endpoints.getPendingFollowers.matchFulfilled,
      (state, { payload }) => {
        state.followers.pending = payload;
        state.followers.isUninitialized = false;
      }
    );

    // FOLLOWING
    builder.addMatcher(
      userApi.endpoints.getFollowing.matchFulfilled,
      (state, { payload }) => {
        state.following.active = payload;
        state.following.isUninitialized = false;
      }
    );
    builder.addMatcher(
      userApi.endpoints.getPendingFollowing.matchFulfilled,
      (state, { payload }) => {
        state.following.pending = payload;
        state.following.isUninitialized = false;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  setUser,
  setSaves,
  setLikes,
  setPlaylists,
  setFollowers,
  setFollowing,
  flagAnnouncementAsRead,
} = userSlice.actions;

export default userSlice.reducer;
