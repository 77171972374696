import React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

// https://mui.com/material-ui/api/input-label/

export const CustomInput = (props) => (
  <StyledInput
    inputRef={props.ref}
    ref={props.ref}
    key={props.key}
    InputProps={{
      ...props.InputProps,
      disableUnderline: true,
      startAdornment: props.prefix && (
        <InputAdornment position='end'>
          <IconButton onClick={props.prefixClick} edge='start'>
            {props.prefix}
          </IconButton>
        </InputAdornment>
      ),
      endAdornment: props.suffix && (
        <InputAdornment position='end'>
          <IconButton
            aria-label='toggle password visibility'
            onClick={props.suffixClick}
            edge='end'
          >
            {props.suffix}
          </IconButton>
        </InputAdornment>
      ),
    }}
    inputProps={props.inputProps}
    InputLabelProps={{ shrink: props.shrink }}
    variant='filled'
    style={{ width: "100%" }}
    label={props.placeholder}
    value={props.value}
    type={props.type ?? "text"}
    onInput={props.onInput}
    required={props.required}
    multiline={props.multiline}
    rows={5}
    background={props.background}
  />
);

const StyledInput = styled(TextField)((props) => ({
  "& .MuiFilledInput-root": {
    color: "#212121",
    fontFamily: "Poppins",
    fontWeight: 500,
    background: props.background ?? "white",
    border: "none",
    borderRadius: "5px",

    "& ::-webkit-calendar-picker-indicator": {
      display: "none",
      webkitAppearance: "none",
    },
  },

  "& .MuiFilledInput-root.Mui-focused": {
    background: props.background ?? "white",
  },

  "& .MuiInputBase-root.MuiFilledInput-root:hover": {
    background: props.background ?? "white !important",
  },

  "& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused": {
    color: "#81939c",
    fontFamily: "Poppins",
    fontWeight: 500,
  },
}));
