import React, { Component } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { articlesApi } from "../../redux/articles/articles.service"
import { searchApi } from "../../redux/search/search.service"
import { connect } from "react-redux"
import { userApi } from "../../redux/user/user.service"
import CustomSearchInput from "../../components/CustomSearchInput"
import { JournalButton, Spacer, UserButton } from "../../components/global"
import CustomIcon from "../../components/CustomIcon"
import i18n from "../../config/i18n"
import { t } from "i18next"
import Loader from "../../components/Loader"
import { followStatus, kycMiddleware } from "../../tools/utils"
import customToast from "../../components/CustomToast"
import {
	createFollowRequest,
	unfollowRequest
} from "../../services/user.services"
import {
	convertContentToItemData,
	gtmBasicEvent,
	gtmItemsData,
	gtmSearchView
} from "../../tools/reactgaEvents"
import InfiniteScroll from "react-infinite-scroll-component"
import { getPublicPlaylist } from "../../services/playlists.services"
import NoResultDraw from "../../assets/images/illustrations/search_no_results.svg"
import { store } from "../../redux"
import {
	followJournal,
	getJournalsList,
	unfollowJournal
} from "../../services/journals.services"
import BackIcon from "../../assets/icons/back-orange.svg"
import { setFollowing, setUser } from "../../redux/user/user.reducer"
import { setPublicPlaylists } from "../../redux/articles/articles.reducer"
import NavCategories from "./NavCategories"
import FadeIn from "react-fade-in/lib/FadeIn"
import { setSearchJournals } from "../../redux/search/search.reducer"
import PlaylistPost from "../../components/playlists/PlaylistPost"
import SkeletonPostCard from "../../components/skeleton/SkeletonPostCard"
import { getFirebaseToken } from "../../services/firebase"
import {
	GTMBasicEvent,
	ItemDataEventListName,
	ItemDataVariant,
	ItemsDataEvent,
	OrganisationUid
} from "../../interfaces"
import PreviewCard from "../../components/PreviewCard"
import LanguageBar from "../../components/home/LanguageBar"

const SLUGS = {
	GLOBAL: "global",
	ARTICLES: "articles",
	JOURNALS: "journals",
	PLAYLISTS: "playlists",
	USERS: "users"
}

const searchCategories = [
	{
		slug: SLUGS.ARTICLES,
		en: "Publications",
		fr: "Publications",
		icon: "paperscroll",
		width: "19",
		height: "19"
	},
	// {
	//   en: "Medical Guidelines",
	//   fr: "Guides médical",
	//   icon: "medical_paper",
	// },
	{
		slug: SLUGS.JOURNALS,
		en: "Journals",
		fr: "Journaux",
		icon: "documents",
		width: "16",
		height: "18"
	},
	{
		slug: SLUGS.PLAYLISTS,
		en: "Playlists",
		fr: "Playlists",
		icon: "playlist",
		width: "16",
		height: "16"
	},
	{
		slug: SLUGS.USERS,
		en: "Users",
		fr: "Utilisateurs",
		icon: "user",
		width: "16",
		height: "16"
	}
	// {
	//   en: "Companies",
	//   fr: "Entreprises",
	//   icon: "company",
	//   width: "16",
	//   height: "16",
	// },
]

const DEFAULT_LIMIT = 5

class SearchPage extends Component {
	contentRef = React.createRef()
	searchTimer

	state = {
		selectedCategory: SLUGS.GLOBAL,
		displaySingleCategory: false,
		categoryBadgeSelected: false,
		searchValue: "",
		searchResults: {}
	}

	/** When component mounts */
	async componentDidMount() {
		try {
			const isAuthentified = await getFirebaseToken()

			if (!!isAuthentified) {
				if (this.props.user.following.isUninitialized) {
					this.props.getFollowing()
					this.props.getPendingFollowing()
				}
			}

			this.getQueryURL()
			this.getLocalState()

			if (this.props.user.isUninitialized) {
				this.props.getUser()
				this.props.getPendingFollowing()
				this.props.getFollowing()
			}

			localStorage.removeItem("_last_search")

			if (!this.props.articles.list.length) {
				this.props.getArticlesList({
					offset: 0,
					limit: 10,
					lang:
						localStorage.getItem("contentLanguage").slice(0, 2) ??
						this.props.i18n.resolvedLanguage
				})
			}

			// V1 journals
			if (!this.props.search.journals.length) {
				getJournalsList({
					sortImpactFactor: "desc",
					limit: 50
				})
					.then((list) => store.dispatch(setSearchJournals(list)))
					.catch((error) => {
						console.error(
							"SearchPage componentDidMount",
							error.message
						)
					})
			}

			if (!this.props.articles.sponsored.length)
				getPublicPlaylist({ limit: 20 })
					.then(({ docs }) =>
						store.dispatch(setPublicPlaylists(docs))
					)
					.catch((error) => {
						console.error(
							"SearchPage getPublicPlaylist",
							error.message
						)
					})

			this.props.getUsersList({
				offset: 0,
				limit: 40,
				relevance: "desc"
			})

			document.addEventListener(
				"keydown",
				this.handleBackSpace.bind(this),
				false
			)
		} catch (error) {
			console.error("SearchPage didMount", error.message)
			customToast(t("toast.error.global"))
			this.props.history.replace("/home")
		}
	}

	/** When component unmounts */
	componentWillUnmount() {
		clearTimeout(this.searchTimer)

		document.removeEventListener(
			"keydown",
			this.handleBackSpace.bind(this),
			false
		)

		this.props.getUser()
	}

	/** Get params from URL query */
	getQueryURL = () => {
		const query = new URLSearchParams(this.props.location.search)

		if (query.get("select") === "journals")
			this.handleEnterCategory(SLUGS.JOURNALS)
		if (query.get("select") === "users")
			this.handleEnterCategory(SLUGS.USERS)
	}

	/** Set state params in localstorage */
	setLocalState() {
		console.debug("Set state params in localstorage")
		const { searchValue, selectedCategory, displaySingleCategory } =
			this.state
		const searchParams = {
			searchValue,
			selectedCategory,
			displaySingleCategory
		}
		localStorage.setItem("_search_params", JSON.stringify(searchParams))
	}

	/** Get state params in localstorage */
	getLocalState() {
		const searchParams = localStorage.getItem("_search_params") ?? false
		if (searchParams) {
			console.log("Get state params in localstorage", searchParams)
			this.setState(
				JSON.parse(searchParams),
				() => {
					const {
						selectedCategory,
						displaySingleCategory,
						searchValue
					} = this.state

					if (displaySingleCategory) {
						this.handleEnterCategory(selectedCategory)
					} else {
						this.selectCategory(selectedCategory)
						this.getSearchGTM()
					}
					this.handleSearch(searchValue)
				},
				() => {
					localStorage.removeItem("_search_params")
				}
			)
		}
	}

	setSearchResults(key, list = [], total = 0, offset = null, cb = null) {
		if (!total) total = list.length
		this.setState(
			{
				searchResults: {
					...this.state.searchResults,
					[key]: { list, total, offset }
				}
			},
			() => {
				if (cb) cb()
			}
		)
	}

	/** When keyword tapped and filtered by category */
	filterCategory(slug) {
		if (slug === this.state.selectedCategory) {
			slug = SLUGS.GLOBAL
		}
		this.setState({ selectedCategory: slug }, () => this.getSearchGTM())
	}

	/** When user use directly category entries to search */
	async selectCategory(slug) {
		this.setState(
			{
				selectedCategory: slug
			},
			async () => {
				if (slug === SLUGS.ARTICLES) {
					this.setSearchResults(
						SLUGS.ARTICLES,
						this.props.articles.list.slice(0, 10),
						10
					)
				}
				if (slug === SLUGS.JOURNALS) {
					this.setSearchResults(
						SLUGS.JOURNALS,
						this.props.search.journals
					)
				}
			}
		)
		if (slug === SLUGS.PLAYLISTS) {
			if (!this.props.articles.sponsored.length) {
				try {
					const { docs: sponsored } = await getPublicPlaylist({
						limit: 20
					})
					store.dispatch(setPublicPlaylists(sponsored))
				} catch (error) {
					console.error("SearchPage getPublicPlaylist", error.message)
					customToast(t("toast.error.global"))
					this.setSearchResults(SLUGS.PLAYLISTS)
				}
			}
			this.setSearchResults(
				SLUGS.PLAYLISTS,
				this.props.articles.sponsored
			)
		}
		if (slug === SLUGS.USERS) {
			this.setSearchResults(SLUGS.USERS, this.props.search.users)
		}
	}

	/** Hide/show content based on selected filters */
	makeResultsSectionClasses(slug) {
		let classes = ["result-section"]
		if (![SLUGS.GLOBAL, slug].includes(this.state.selectedCategory)) {
			classes.push("hide")
		}
		if (this.state.displaySingleCategory) classes.push("borderless")

		return classes.join(" ")
	}

	/** Results title component */
	renderResultsTitle(slug) {
		return (
			<h3 id={`result-title-${slug}`}>
				{
					searchCategories.find((cat) => cat.slug === slug)[
						i18n.resolvedLanguage
					]
				}
			</h3>
		)
	}

	/** Get sponsored playlists prioritized in results */
	getPrioritizedPublicPlaylists() {
		// Copy frozen array
		const list = [...this.state.searchResults.playlists.list]
		list.sort((a, b) => {
			if (a._source.filters.sponsored && !b._source.filters.sponsored) {
				return -1
			} else if (
				!a._source.filters.sponsored &&
				b._source.filters.sponsored
			) {
				return 1
			} else {
				return 0
			}
		})
		return list
	}

	/** When keyword tapped and filtered by category */
	handleFilterCategory(slug) {
		if (slug === this.state.selectedCategory) {
			slug = SLUGS.GLOBAL
		}
		this.setState({ selectedCategory: slug }, () => this.getSearchGTM())
	}

	/** When user use directly category entries to search */
	handleEnterCategory(slug) {
		gtmBasicEvent(GTMBasicEvent.SEARCH_SECTION_SELECTED)
		this.setState({ displaySingleCategory: true })
		this.selectCategory(slug)
	}

	// Cooldown between each keyboard hits (buffer)
	handleSearchDelay(searchValue, timeout = 300) {
		// Reset timer
		clearTimeout(this.searchTimer)
		this.searchTimer = setTimeout(() => {
			this.handleSearch(searchValue)
		}, timeout)
	}

	/** Global search function */
	handleSearch(searchValue = "") {
		this.setState({ searchValue }, () => {
			const { selectedCategory, displaySingleCategory, searchValue } =
				this.state
			if (searchValue.length > 0)
				this.setState({ categoryBadgeSelected: false })

			if (!displaySingleCategory || selectedCategory === SLUGS.ARTICLES) {
				this.handleSearchArticles()
			}

			if (!displaySingleCategory || selectedCategory === SLUGS.JOURNALS) {
				this.handleSearchJournals()
			}

			if (
				!displaySingleCategory ||
				selectedCategory === SLUGS.PLAYLISTS
			) {
				this.handleSearchPlaylists()
			}

			if (!displaySingleCategory || selectedCategory === SLUGS.USERS) {
				this.handleSearchUsers()
			}
		})
	}

	handleExitSingleView() {
		// const categoryBeforeExit = this.state.selectedCategory;
		this.setState(
			{
				selectedCategory: SLUGS.GLOBAL,
				displaySingleCategory: false,
				categoryBadgeSelected: false
			},
			() => {
				// Reset lists to default limit
				for (const key of [
					SLUGS.ARTICLES,
					SLUGS.JOURNALS,
					SLUGS.PLAYLISTS,
					SLUGS.USERS
				]) {
					if (this.state.searchResults[key]) {
						this.setState({
							searchResults: {
								...this.state.searchResults,
								[key]: {
									...this.state.searchResults[key],
									list: this.state.searchResults[
										key
									].list.slice(0, DEFAULT_LIMIT)
								}
							}
						})
					}
				}
				this.contentRef.current.scrollTo(0, 0)
			}
		)
	}

	/** Search articles function */
	handleSearchArticles(fetchMore = false) {
		const {
			selectedCategory,
			displaySingleCategory,
			searchValue,
			searchResults
		} = this.state
		const limit = selectedCategory === SLUGS.ARTICLES ? 20 : DEFAULT_LIMIT

		if (searchValue.length) {
			this.props
				.searchArticles({
					offset: fetchMore
						? searchResults.articles?.offset ?? limit
						: 0,
					limit,
					search: searchValue
				})
				.then(({ data }) => {
					if (!!data) {
						const { hits, total } = data.hits
						this.setSearchResults(
							SLUGS.ARTICLES,
							searchResults.articles && fetchMore
								? searchResults.articles.list.concat(hits)
								: hits,
							total,
							fetchMore
								? (searchResults.articles?.offset ?? limit) +
										limit
								: limit
						)
					}
				})
		} else if (displaySingleCategory) {
			// Bring suggested articles when search value empty
			this.setSearchResults(
				SLUGS.ARTICLES,
				this.props.articles.list.slice(0, 10)
			)

			gtmItemsData(
				ItemsDataEvent.VIEW_ITEM_LIST,
				convertContentToItemData(
					this.props.articles.list.slice(0, 10),
					ItemDataEventListName.SEARCH,
					ItemDataVariant.ARTICLE
				)
			)
		}
	}

	/** Search journals function */
	handleSearchJournals(fetchMore = false) {
		const {
			selectedCategory,
			displaySingleCategory,
			searchValue,
			searchResults
		} = this.state
		const limit = selectedCategory === SLUGS.JOURNALS ? 20 : DEFAULT_LIMIT

		if (searchValue.length) {
			this.props
				.searchJournals({
					offset: fetchMore
						? searchResults.journals?.offset ?? limit
						: 0,
					limit,
					search: searchValue
				})
				.then(({ data }) => {
					if (!!data) {
						const { hits, total } = data.hits
						this.setSearchResults(
							SLUGS.JOURNALS,
							searchResults.journals && fetchMore
								? searchResults.journals.list.concat(hits)
								: hits,
							total,
							fetchMore
								? (searchResults.journals?.offset ?? limit) +
										limit
								: limit
						)
					}
				})
		} else if (displaySingleCategory) {
			// Bring suggested journals when search value empty
			this.setSearchResults(SLUGS.JOURNALS, this.props.search.journals)

			gtmItemsData(
				ItemsDataEvent.VIEW_ITEM_LIST,
				convertContentToItemData(
					this.props.search.journals,
					ItemDataEventListName.SEARCH,
					ItemDataVariant.JOURNALS
				)
			)
		}
	}

	/** Search playlist function */
	async handleSearchPlaylists(fetchMore = false) {
		const {
			selectedCategory,
			displaySingleCategory,
			searchValue,
			searchResults
		} = this.state
		const limit = selectedCategory === SLUGS.PLAYLISTS ? 20 : DEFAULT_LIMIT

		if (searchValue.length) {
			this.props
				.searchPlaylists({
					search: searchValue,
					offset: fetchMore
						? searchResults.playlists?.offset ?? limit
						: 0,
					limit,
					language:
						localStorage.getItem("contentLanguage").slice(0, 2) ??
						this.props.i18n.resolvedLanguage
				})
				.then(({ data }) => {
					if (!!data) {
						const { hits, total } = data.hits
						this.setSearchResults(
							SLUGS.PLAYLISTS,
							searchResults.playlists && fetchMore
								? searchResults.playlists.list.concat(hits)
								: hits,
							total,
							fetchMore
								? (searchResults.playlists?.offset ?? limit) +
										limit
								: limit
						)
					}
				})
		} else if (displaySingleCategory) {
			// Bring suggested playlists when search value empty
			if (!this.props.articles.sponsored.length) {
				const sponsored = await getPublicPlaylist({ limit: 20 })
				store.dispatch(setPublicPlaylists(sponsored))
			}
			this.setSearchResults(
				SLUGS.PLAYLISTS,
				this.props.articles.sponsored
			)

			gtmItemsData(
				ItemsDataEvent.VIEW_ITEM_LIST,
				convertContentToItemData(
					this.props.articles.sponsored,
					ItemDataEventListName.SEARCH,
					ItemDataVariant.PLAYLIST
				)
			)
		}
	}

	/** Search users function */
	handleSearchUsers(fetchMore = false) {
		const {
			selectedCategory,
			displaySingleCategory,
			searchValue,
			searchResults
		} = this.state
		const limit = selectedCategory === SLUGS.USERS ? 20 : DEFAULT_LIMIT

		if (searchValue.length) {
			this.props
				.searchUsers({
					offset: fetchMore
						? searchResults.users?.offset ?? limit
						: 0,
					limit,
					search: searchValue
				})
				.then(({ data }) => {
					if (data) {
						const { docs, meta } = data
						this.setSearchResults(
							SLUGS.USERS,
							fetchMore
								? searchResults.users.list.concat(docs)
								: docs,
							meta.total,
							fetchMore
								? (searchResults.users?.offset ?? limit) + limit
								: limit
						)
					}
				})
		} else if (displaySingleCategory) {
			// Bring suggested users when search value empty
			this.setSearchResults(SLUGS.USERS, this.props.users)
		}
	}

	/** Handle Backspace on input */
	handleBackSpace(key) {
		if (key.code !== "Backspace") return

		if (
			this.state.displaySingleCategory &&
			this.state.searchValue.length === 0
		) {
			// Select category badge when search value is empty
			if (!this.state.categoryBadgeSelected) {
				this.setState({ categoryBadgeSelected: true })
			} else {
				// Remove category badge (exit single view)
				this.handleExitSingleView()
			}
		}
	}

	/** Switch global article language */
	async switchLanguage(newLanguage) {
		localStorage.setItem("contentLanguage", newLanguage)

		const { selectedCategory, searchValue, searchResults } = this.state

		this.props.getArticlesList({
			offset: 0,
			limit: 10,
			lang: newLanguage
		})

		if (searchValue.length) this.handleSearch(searchValue)

		await getPublicPlaylist({
			limit: 20,
			offset: 0
		}).then((sponsored) => store.dispatch(setPublicPlaylists(sponsored)))

		if (
			!searchResults.playlists?.offset &&
			selectedCategory === SLUGS.PLAYLISTS
		) {
			this.setSearchResults(
				SLUGS.PLAYLISTS,
				this.props.articles.sponsored
			)
			gtmItemsData(
				ItemsDataEvent.VIEW_ITEM_LIST,
				convertContentToItemData(
					this.props.articles.sponsored,
					ItemDataEventListName.SEARCH,
					ItemDataVariant.PLAYLIST
				)
			)
		}
	}

	/** Handling search event for GTM */
	getSearchGTM() {
		const { searchValue, selectedCategory } = this.state
		if (searchValue.length) {
			const _searchItemData = {
				term: searchValue,
				results_count: this.getResultsCount() || 0,
				type: selectedCategory,
				account_id: this.props.user.user.uid ?? undefined
			}
			gtmSearchView(_searchItemData)
		}
	}

	/** Get results count */
	getResultsCount() {
		const { selectedCategory, searchResults } = this.state
		const { articles, journals, playlists } = searchResults

		if (selectedCategory !== SLUGS.GLOBAL) {
			return searchResults[selectedCategory]?.list?.length
		} else {
			return (
				articles?.list?.length +
				journals?.list?.length +
				playlists?.list?.length
			)
		}
	}

	/** Follow user function */
	handleFollowUser = async (user) => {
		const isAuthentified = await getFirebaseToken()

		if (!isAuthentified) {
			this.props.history.push({
				pathname: "/login",
				state: { previousPath: window.location.pathname }
			})
		} else {
			if (followStatus(this.props.user, user?.uid, "followedByMe")) {
				store.dispatch(
					setFollowing({
						...this.props.user.following,
						active: [
							...this.props.user.following.active.filter(
								(el) => el?.uid !== user.uid
							)
						]
					})
				)
				customToast(t("toast.success.unfollowedUser"), "success")
				unfollowRequest(user?.uid)
			} else if (
				followStatus(this.props.user, user?.uid, "pendingByMe")
			) {
				customToast(t("toast.error.requestAlready"), "error")
			} else {
				store.dispatch(
					setFollowing({
						...this.props.user.following,
						pending: [
							...this.props.user.following.pending,
							{ following: user }
						]
					})
				)
				customToast(t("toast.success.requestSent"), "success")
				gtmBasicEvent("user_search_follow")
				createFollowRequest(user?.uid)
			}
		}
	}

	/** Follow journal function */
	handleFollowJournal = async (journal) => {
		const isAuthentified = await getFirebaseToken()
		if (!isAuthentified)
			this.props.history.push({
				pathname: "/login",
				state: { previousPath: window.location.pathname }
			})
		else if (!!isAuthentified && !this.props.user.user.profession)
			kycMiddleware(this, t("toast.error.notOnboarded.followJournal"))
		else {
			const isFollowed = this.props.user?.user?.journals?.find(
				(el) => el.uid === journal.uid
			)
			if (isFollowed) {
				store.dispatch(
					setUser({
						...this.props.user.user,
						journals: this.props.user.user.journals.filter(
							(el) => el.uid !== journal.uid
						)
					})
				)
				customToast(
					t("toast.success.unfollowedJournal") + " " + journal?.name
				)
				const { journals } = await unfollowJournal(journal._id)
				store.dispatch(setUser({ ...this.props.user.user, journals }))
			} else {
				store.dispatch(
					setUser({
						...this.props.user.user,
						journals: [...this.props.user.user.journals, journal]
					})
				)
				customToast(
					t("toast.success.followedJournal") + " " + journal?.name
				)
				gtmBasicEvent(GTMBasicEvent.JOURNAL_FOLLOW)
				const { journals } = await followJournal(journal._id)
				store.dispatch(setUser({ ...this.props.user.user, journals }))
			}
		}
	}

	// Seulement pour Takeda, de manière temporaire...
	filterSearchCategoriesForTakeda = (cat) => {
		const userOrganisation = this.props.user.user.organisations?.[0]?.uid

		return (
			(cat.en !== "Users" && userOrganisation?.includes("takeda")) ||
			!userOrganisation?.includes("takeda")
		)
	}

	render() {
		const {
			selectedCategory,
			displaySingleCategory,
			searchValue,
			searchResults,
			categoryBadgeSelected
		} = this.state

		const { articles, journals, playlists, users } = searchResults

		return (
			<PageContainer>
				<SearchNavbar>
					<img
						src={BackIcon}
						alt=""
						onClick={() => this.props.history.replace("/home")}
					/>
					<h4>{t("search.input.placholder")}</h4>
					<div style={{ width: 30 }} />
				</SearchNavbar>
				<LanguageBar
					style={{ marginTop: 55 }}
					onChange={this.switchLanguage.bind(this)}
				/>

				<div className="header" onTouchMove={(e) => e.preventDefault()}>
					<CustomSearchInput
						placeholder={t("search.input.placholder")}
						prefix={
							displaySingleCategory
								? searchCategories.find(
										(cat) => cat.slug === selectedCategory
								  )?.[i18n.resolvedLanguage] ?? ""
								: ""
						}
						entrySelected={categoryBadgeSelected}
						onBlur={() => this.getSearchGTM()}
						onPrefixClick={this.handleExitSingleView.bind(this)}
						onClear={() => this.handleSearchDelay("")}
						onSearch={this.handleSearchDelay.bind(this)}
						onCancel={() => {
							this.handleSearch("")
							this.handleExitSingleView()
						}}
					/>
				</div>

				{displaySingleCategory === false && searchValue !== "" && (
					<NavCategories
						// Display only categories with results
						categories={searchCategories.filter(
							(cat) => searchResults[cat.slug]?.total > 0
						)}
						selectedCategory={selectedCategory}
						onSelect={(category) =>
							this.handleFilterCategory(category.slug)
						}
					/>
				)}

				<div
					ref={this.contentRef}
					id="content-ref"
					className={
						this.state.displaySingleCategory ||
						(!this.state.displaySingleCategory &&
							!this.state.searchValue.length)
							? "content entry-mode"
							: "content"
					}
				>
					{/* Category card list before searching */}
					{displaySingleCategory === false && searchValue === "" && (
						<div>
							{searchCategories
								.filter(
									this.filterSearchCategoriesForTakeda.bind(
										this
									)
								)
								.map((category) => (
									<div
										key={category.en + "srchntry"}
										className="entry-row"
										onClick={() =>
											this.handleEnterCategory(
												category.slug
											)
										}
									>
										<CustomIcon
											iconName={category.icon}
											color="#212121"
											width={category.width}
											height={category.height}
										/>
										<span className="category-title">
											{category[i18n.resolvedLanguage]}
										</span>
										<CustomIcon
											iconName="external"
											className="external-icon"
										/>
									</div>
								))}
						</div>
					)}

					{/* Search results */}
					{(searchValue.length > 0 || displaySingleCategory) && (
						<div className="results-section">
							{/* Publication results */}
							<div
								className={this.makeResultsSectionClasses(
									SLUGS.ARTICLES
								)}
							>
								{!articles ? (
									<Loader loaderOnly />
								) : articles.offset && searchValue.length ? (
									<>
										{this.renderResultsTitle(
											SLUGS.ARTICLES
										)}
										{articles.list.length ? (
											<>
												<InfiniteScroll
													dataLength={
														articles.list.length
													}
													next={() => {
														if (
															displaySingleCategory
														) {
															this.handleSearchArticles(
																true
															)
															this.getSearchGTM()
														}
													}}
													hasMore={
														articles.offset <
														articles.total
													}
													pullDownToRefresh={false}
													scrollableTarget="content-ref"
												>
													<FadeIn>
														{articles.list.map(
															(
																{ _source },
																index
															) => {
																const data = {
																	..._source?.core,
																	publication_date:
																		_source?.publication_date,
																	journal: {
																		name: _source
																			?.core
																			.journal
																	},
																	_id: _source.id
																}

																return (
																	<PreviewCard
																		key={
																			data._id +
																			"--profile-card-key" +
																			index
																		}
																		disabled={
																			true
																		}
																		content={
																			data
																		}
																		itemListName={
																			ItemDataEventListName.SEARCH
																		}
																		onClick={() => {
																			this.setLocalState()
																			this.props.history.push(
																				`/post/${data.slug}`
																			)
																		}}
																	/>
																)
															}
														)}
													</FadeIn>
												</InfiniteScroll>
												{articles.list.length <
													articles.total &&
													!displaySingleCategory && (
														<ViewButtonContainer>
															<button
																onClick={() => {
																	this.handleEnterCategory(
																		SLUGS.ARTICLES
																	)
																	this.handleSearchArticles(
																		true
																	)
																}}
															>
																{t(
																	"common.viewAll"
																)}
															</button>
														</ViewButtonContainer>
													)}
												{!displaySingleCategory &&
													selectedCategory !==
														SLUGS.GLOBAL && (
														<Spacer />
													)}
											</>
										) : (
											<>
												{displaySingleCategory ? (
													<div className="no-results-section">
														<img
															src={NoResultDraw}
															alt=""
														/>
														<p>
															{t(
																"search.noResultsFoundRequest"
															)}
														</p>
														<p>
															{t(
																"search.suggestSummerize"
															)}
															<br />
															<a
																className="link"
																target="_blank"
																href={
																	i18n.resolvedLanguage ===
																	"fr"
																		? "https://www.juisci.com/fr/mixez"
																		: "https://www.juisci.com/blend"
																}
															>
																{t(
																	"search.discoverMore"
																)}
															</a>
														</p>
													</div>
												) : (
													<NoResultText>
														{t(
															"search.noResultsFound"
														)}
													</NoResultText>
												)}
											</>
										)}
									</>
								) : !searchValue.length ? (
									<div>
										<h3>{t("search.recentArticles")}</h3>
										{this.props.articles.isFetching
											? [...Array(10)].map((_, index) => (
													<FadeIn
														delay={100 * index}
														key={`${index}--skeleton-card`}
														onComplete={() =>
															this.setState({
																skeletonLoaded: true
															})
														}
													>
														<SkeletonPostCard />
													</FadeIn>
											  ))
											: this.props.articles.list.map(
													(article, index) => (
														<FadeIn
															key={
																article._id +
																"srchpstprfl" +
																index
															}
															delay={
																index > 4
																	? 250
																	: 50 * index
															} // 100ms delay between each element
														>
															<PreviewCard
																itemListName={
																	ItemDataEventListName.SEARCH
																}
																key={
																	article._id +
																	"--profile-card-key-default"
																}
																disabled={true}
																content={
																	article
																}
																onClick={() => {
																	this.setLocalState()
																	this.props.history.push(
																		{
																			pathname: `/post/${article.slug}`,
																			state: {
																				article
																			}
																		}
																	)
																}}
															/>
														</FadeIn>
													)
											  )}
									</div>
								) : (
									<Loader loaderOnly />
								)}
							</div>

							{/* Journal results */}
							<div
								className={`
                ${this.makeResultsSectionClasses(SLUGS.JOURNALS)}
                ${displaySingleCategory ? "last-step" : ""}
              `}
							>
								{!journals ? (
									<Loader loaderOnly />
								) : journals?.offset && searchValue.length ? (
									<>
										{this.renderResultsTitle(
											SLUGS.JOURNALS
										)}
										{journals.list.length ? (
											<>
												<InfiniteScroll
													dataLength={
														journals?.list
															?.length ?? 0
													}
													next={() => {
														if (
															displaySingleCategory
														) {
															this.handleSearchJournals(
																true
															)
														}
													}}
													hasMore={
														journals.offset <
															journals.total &&
														searchValue.length > 0
													}
													pullDownToRefresh={false}
													scrollableTarget="content-ref"
												>
													{journals.list.map(
														({
															_source: journal
														}) => (
															<JournalButton
																key={journal.id}
																journal={
																	journal.core
																}
																isFollowed={this.props.user?.user?.journals?.find(
																	(el) =>
																		el.uid ===
																		journal
																			.core
																			.uid
																)}
																onClick={() => {
																	this.setLocalState()
																	this.props.history.push(
																		`/journal/${journal.core.uid}`
																	)
																}}
																handleFollow={() =>
																	this.handleFollowJournal(
																		{
																			...journal.core,
																			_id: journal.id
																		}
																	)
																}
															/>
														)
													)}
												</InfiniteScroll>
												{journals.list.length <
													journals.total &&
													!displaySingleCategory && (
														<ViewButtonContainer>
															<button
																onClick={() => {
																	this.handleEnterCategory(
																		SLUGS.JOURNALS
																	)
																	this.handleSearchJournals(
																		true
																	)
																}}
															>
																{t(
																	"common.viewAll"
																)}
															</button>
														</ViewButtonContainer>
													)}
												{!displaySingleCategory &&
													selectedCategory !==
														SLUGS.GLOBAL && (
														<Spacer />
													)}
											</>
										) : (
											<NoResultText>
												{t("search.noResultsFound")}
											</NoResultText>
										)}
									</>
								) : !searchValue.length ? (
									<div>
										<h3>{t("search.suggestedJournals")}</h3>
										{this.props.search.journals.map(
											(journal) => {
												return (
													<FadeIn key={journal._id}>
														<JournalButton
															journal={{
																...journal,
																image: journal
																	?.image?.url
															}}
															isFollowed={this.props.user?.user?.journals?.find(
																(el) =>
																	el.uid ===
																	journal.uid
															)}
															onClick={() => {
																this.setLocalState()
																this.props.history.push(
																	`/journal/${journal.uid}`
																)
															}}
															handleFollow={() =>
																this.handleFollowJournal(
																	journal
																)
															}
														/>
													</FadeIn>
												)
											}
										)}
									</div>
								) : (
									<Loader loaderOnly />
								)}
							</div>

							{/* Playlists results */}
							<div
								className={`
                ${this.makeResultsSectionClasses(SLUGS.PLAYLISTS)}
                ${displaySingleCategory ? "last-step" : ""}
              `}
							>
								{!playlists ? (
									<Loader loaderOnly />
								) : playlists.offset && searchValue.length ? (
									<>
										{this.renderResultsTitle(
											SLUGS.PLAYLISTS
										)}
										{playlists.list.length ? (
											<>
												<InfiniteScroll
													dataLength={
														playlists?.list
															?.length ?? 0
													}
													next={() => {
														if (
															displaySingleCategory
														) {
															this.handleSearchPlaylists(
																true
															)
														}
													}}
													hasMore={
														playlists.offset <
															playlists.total &&
														searchValue.length > 0
													}
													pullDownToRefresh={false}
													scrollableTarget="content-ref"
												>
													{this.getPrioritizedPublicPlaylists().map(
														({ _source }) => {
															return (
																<PlaylistPost
																	key={
																		_source.id +
																		"playlistpostsearch"
																	}
																	onClick={() =>
																		this.setLocalState()
																	}
																	playlist={{
																		..._source.core,
																		sponsored:
																			_source
																				.filters
																				.sponsored,
																		company:
																			{
																				..._source
																					.core
																					.company,
																				images: [
																					_source
																						.core
																						.company
																						?.image
																				]
																			},
																		_id: _source.id
																	}}
																/>
															)
														}
													)}
												</InfiniteScroll>
												{playlists.list.length <
													playlists.total &&
													!displaySingleCategory && (
														<ViewButtonContainer>
															<button
																onClick={() => {
																	this.handleEnterCategory(
																		SLUGS.PLAYLISTS
																	)
																	this.handleSearchPlaylists(
																		true
																	)
																}}
															>
																{t(
																	"common.viewAll"
																)}
															</button>
														</ViewButtonContainer>
													)}
												{!displaySingleCategory &&
													selectedCategory !==
														SLUGS.GLOBAL && (
														<Spacer />
													)}
											</>
										) : (
											<NoResultText>
												{t("search.noResultsFound")}
											</NoResultText>
										)}
									</>
								) : !searchValue.length ? (
									<div>
										<h3>
											{t("search.suggestedPlaylists")}
										</h3>

										{playlists?.list.map(
											(playlist) =>
												playlist?._id && (
													<FadeIn
														key={
															playlist._id +
															"playlist"
														}
													>
														<PlaylistPost
															onClick={() =>
																this.setLocalState()
															}
															playlist={{
																...playlist,
																sponsored: true
															}}
														/>
													</FadeIn>
												)
										)}
									</div>
								) : (
									<Loader loaderOnly />
								)}
							</div>

							{/* Users results */}
							<div
								className={`
                ${this.makeResultsSectionClasses(SLUGS.USERS)}
                ${displaySingleCategory ? "last-step" : ""}
              `}
							>
								{!users ? (
									<Loader loaderOnly />
								) : users.offset && searchValue.length ? (
									<>
										{this.renderResultsTitle(SLUGS.USERS)}
										{users.list.length ? (
											<>
												<InfiniteScroll
													dataLength={
														users?.list?.length ?? 0
													}
													next={() => {
														if (
															displaySingleCategory
														) {
															this.handleSearchUsers(
																true
															)
														}
													}}
													hasMore={
														users.offset <
														users.total
													}
													pullDownToRefresh={false}
													scrollableTarget="content-ref"
												>
													{users.list.map(
														(user, index) => (
															<UserButton
																key={
																	user?._id +
																	"srchusrbtn" +
																	index
																}
																className="fullWidth"
																background="transparent"
																user={user}
																onClick={() => {
																	this.setLocalState()
																	this.props.history.push(
																		`/profile/user/${user?.uid}?search=1`
																	)
																}}
																isFollowed={followStatus(
																	this.props
																		.user,
																	user?.uid,
																	"followedByMe"
																)}
																isSent={followStatus(
																	this.props
																		.user,
																	user?.uid,
																	"pendingByMe"
																)}
																handleFollow={() =>
																	this.handleFollowUser(
																		user
																	)
																}
															/>
														)
													)}
												</InfiniteScroll>
												{users.list.length <
													users.total &&
													!displaySingleCategory && (
														<ViewButtonContainer>
															<button
																onClick={() => {
																	this.handleEnterCategory(
																		SLUGS.USERS
																	)
																	this.handleSearchUsers(
																		true
																	)
																}}
															>
																{t(
																	"common.viewAll"
																)}
															</button>
														</ViewButtonContainer>
													)}
												{/* Spacer for the last item */}
												<Spacer />
											</>
										) : (
											<NoResultText>
												{t("search.noResultsFound")}
											</NoResultText>
										)}
									</>
								) : !searchValue.length ? (
									<>
										<h3>{t("search.suggestedUsers")}</h3>
										{this.props.search.users.map(
											(user, index) => (
												<FadeIn
													key={
														user?._id +
														"srchusrbtn" +
														index
													}
												>
													<UserButton
														className="fullWidth"
														background="#fff"
														user={user}
														onClick={() => {
															this.setLocalState()
															this.props.history.push(
																`/profile/user/${user?.uid}`
															)
														}}
														isFollowed={followStatus(
															this.props.user,
															user?.uid,
															"followedByMe"
														)}
														isSent={followStatus(
															this.props.user,
															user?.uid,
															"pendingByMe"
														)}
														handleFollow={() =>
															this.handleFollowUser(
																user
															)
														}
														noFollow={
															user?.uid ===
															this.props.user.user
																.uid
														}
													/>
												</FadeIn>
											)
										)}
									</>
								) : (
									<Loader loaderOnly />
								)}
							</div>
						</div>
					)}
				</div>
			</PageContainer>
		)
	}
}

function mapState(state) {
	const articles = state.articles
	const search = state.search
	const user = state.user
	const journals = state.journals
	return {
		articles,
		search,
		user,
		journals
	}
}

const mapDispatch = {
	getArticlesList: articlesApi.endpoints.getArticlesList.initiate,
	searchArticles: articlesApi.endpoints.searchArticles.initiate,
	getUsersList: searchApi.endpoints.getUsersList.initiate,
	searchUsers: searchApi.endpoints.searchUsers.initiate,
	searchJournals: searchApi.endpoints.searchJournals.initiate,
	searchPlaylists: searchApi.endpoints.searchPlaylists.initiate,
	getUser: userApi.endpoints.getUser.initiate,
	getFollowing: userApi.endpoints.getFollowing.initiate,
	getPendingFollowing: userApi.endpoints.getPendingFollowing.initiate
	// getJournalsList: journalsApi.endpoints.getJournalsList.initiate,
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(SearchPage))

export const UserWrapper = styled.div`
	background: #ecf0f5;
	margin: 0;
	padding: 0px 23px;
	border: none;
	p {
		margin: 0;
		margin-left: 10px;
		color: #212121;
		font-weight: 500;
		font-family: "Poppins";
		font-size: 14px;
		line-height: 21px;
	}
	span {
		margin: 0;
		margin-left: 10px;
		color: #212121;
		font-weight: 300;
		font-size: 10px;
		line-height: 15px;
		font-family: "Poppins";
		text-transform: uppercase;
	}
`

const PageContainer = styled.div`
	font-family: "Poppins" !important;
	background-color: #fff;
	overflow: hidden !important;
	height: ${window.innerHeight}px;

	.loader {
		width: ${window.innerWidth - 40}px;
		display: block;
		margin: auto;

		h5 {
			font-size: 17px;
			font-weight: 400;
			font-family: "Poppins";
			text-align: center;
			opacity: 0;
			margin-top: 50px;
			animation: fadeIn 1.4s 3 forwards;
			-webkit-animation: fadeIn 1.4s 3 forwards;
		}
	}

	div.header {
		padding: 20px 10px;
		background: #f7f8fc;
		touch-action: none;
	}

	.category-title {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 70%;
		letter-spacing: 0.02em;
		color: #212121;
	}

	div.category-scroll {
		margin: 0;
		overflow-x: auto;
		overflow-y: hidden;
		white-space: nowrap;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		width: 100%;
		padding: 0 10px;
		box-sizing: border-box;

		.card {
			flex: 0 0 auto;
			margin: 0px 10px;
			padding: 20px 0px;
			align-items: center;
			opacity: 0.3;
			transition: opacity ease-in-out 0.3s;

			&.selected {
				opacity: 1;
			}

			.card-content {
				display: flex;
				align-items: center;
				gap: 0 8px;
			}
		}
	}

	.hide {
		display: none !important;
	}

	div.content {
		overflow-y: scroll;
		overflow-x: hidden;
		/* position: relative; */
		padding: 0px;
		background: white;
		width: 100%;
		height: ${window.innerHeight - 200}px;

		&.entry-mode {
			height: calc(100% - 195px) !important;
			padding-bottom: 50px;
		}

		div.entry-row {
			display: grid;
			grid-template-columns: 0.4fr 3fr 0.3fr;
			grid-template-rows: 1fr;
			gap: 0px 0px;
			grid-template-areas: ". . .";
			align-items: center;
			padding: 20px 0px;
			margin: 0 20px;
			border-bottom: 1px solid rgba(210, 220, 226, 1);

			& .external-icon {
				text-align: right;
			}
		}
	}

	div.results-section {
		.result-section {
			padding: 0 20px 40px;
			border-bottom: 1px solid #90a4ae;

			&.last-step {
				padding-bottom: 100px;
			}

			&.borderless {
				border-bottom: none;
			}

			.no-results-section {
				padding-top: 50px;

				img {
					display: block;
					margin: auto;
				}
				p {
					font-family: "Poppins";
					font-style: normal;
					font-weight: 400;
					font-size: 15px;
					line-height: 150%;
					text-align: center;
					color: #212121;
					& .link {
						color: #ff8800;
						font-weight: 500;
						text-decoration: underline;
					}
				}
			}
		}

		& .hide {
			display: none;
		}

		h3 {
			font-family: "Poppins";
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 27px;
			color: #212121;
		}
	}

	.suggested-foryou {
		padding: 0 20px;
		box-sizing: border-box;

		&.no-padding {
			padding: 0;
		}

		&.noauth {
			img {
				display: block;
				margin: auto;
			}
			p.noauth-foryou {
				font-family: "Poppins";
				font-style: normal;
				font-weight: 400;
				font-size: 15px;
				line-height: 150%;
				text-align: center;

				& .link {
					text-decoration-line: underline;
					color: #ce0868;
				}
			}
		}

		.suggested-card {
			background: linear-gradient(180deg, #f9fcfc 0%, #e0e7eb 100%);
			border-radius: 10px;
			padding: 28px 24px;
			box-sizing: border-box;
			font-weight: 700;
			font-size: 11px;
			line-height: 120%;
			text-align: center;
		}

		h4 {
			font-weight: 600;
			font-size: 18px;
			line-height: 27px;
			margin: 50px 0px 24px;
		}
	}
`

export const NoResultText = styled.p`
	color: #212121;
	font-family: "Poppins";
	font-weight: 600;
	font-size: 15px;
	text-align: center;
	margin-top: 50px;
`

const SearchNavbar = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 99;
	margin: 0 auto;
	padding: 0px 20px;

	/** Flex */
	display: flex;
	justify-content: space-between;
	align-items: center;

	/** Style */
	background: #fff;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

	.toggle-lang {
		background: #313b42;
		border-radius: 100px;
		padding: 3px;

		button {
			padding: 5px;
			outline: none;
			border: none;
			box-shadow: none;
			font-family: "Poppins";
			font-weight: 700;
			background: white;
		}
	}
`

const ViewButtonContainer = styled.div`
	button {
		outline: none;
		box-shadow: none;
		background: #ffffff;
		color: #212121;
		border: 1px solid #d2dce2;
		border-radius: 100px;
		font-family: "Poppins";
		font-weight: 600;
		font-size: 16px;
		line-height: 100%;
		text-align: center;
		letter-spacing: 0.02em;
		padding: 12px 16px;
		text-transform: capitalize;
		margin: 40px auto 0px;
		display: block;
	}
`
