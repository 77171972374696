// Theme

const theme = {
  color: {
    primary: "#5F27CD",
    secondary: "#FFF",
    accent: "#0055FF",
    background: {
      white: "#ffffff",
      light: "#f8f8f8",
    },
    white: {
      regular: "#ffffff",
      lessdark: "#faf9f8",
      dark: "#f6f6f6",
      darker: "#eeeeee",
    },
    black: {
      lightest: "#ABA8AF",
      light: "#564F62",
      regular: "#071435",
    },
  },
  screen: {
    xs: "575px",
    sm: "767px",
    md: "991px",
    lg: "1199px",
  },
};

export default theme;
