import axios from "axios";
import { API_URL } from "../config";
import { catchError, handleError } from "./response";
import { getApiRequestService } from "./index.services";
import { SupportedLanguagesEnum } from "../interfaces";

export const getCongressList = async (language?: SupportedLanguagesEnum) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get("/congress", {
      params: {
        language,
      },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};
