import { createSlice } from "@reduxjs/toolkit";
import { IJournal } from "../../interfaces/journal.interface";
import { IUser } from "../../interfaces/user.interface";
import { searchApi } from "./search.service";

export interface SearchState {
  users: IUser[];
  journals: IJournal[];
  scrollPosition: number;
  searchMode: string;
}

const initialState: SearchState = {
  users: [],
  journals: [],
  scrollPosition: 0,
  searchMode: "articles",
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      const { users, journals, scrollPosition, lastSearch, searchMode } =
        action.payload;
      state.users = users;
      state.journals = journals;
      state.scrollPosition = scrollPosition;
      state.searchMode = searchMode;
    },
    setSearchJournals: (state, action) => {
      state.journals = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      searchApi.endpoints.getUsersList.matchFulfilled,
      (state, { payload }) => {
        state.users = payload;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const { setSearch, setSearchJournals } = searchSlice.actions;

export default searchSlice.reducer;
