// All functions to get scroll position in page

import Moment from "react-moment"
import customToast from "../components/CustomToast"
import { fbLogout } from "../services/firebase"
import { clearLocalStorage, handleError } from "../services/response"
import { iosAuthSignOut, iosDeleteCookies, isNativeIOS } from "./ios"
import { androidSignOut } from "./android"
import { unregisterFirebaseToken } from "../services/notifications.services"
import { updateActiveSession } from "../services/user.services"
import { store } from "../redux"
import { SupportedLanguagesEnum } from "../interfaces"
import { t } from "i18next"

export const listenToScrollEvent = () => {
	document.addEventListener("scroll", () => {
		requestAnimationFrame(() => {
			this.calculateScrollDistance()
		})
	})
}

export const getCurrentOS = () => {
	if (window) {
		let agent = window.navigator.userAgent
		if (agent?.includes("iPhone")) return "ios"
		if (agent?.includes("Android")) return "android"
		return undefined
	}
}

export const preventScroll = (on) => {
	if (on) {
		document.addEventListener("touchmove", preventTouchMove, {
			passive: false
		})
		document.addEventListener("scroll", preventTouchMove, {
			passive: false
		})
	} else {
		document.removeEventListener("touchmove", preventTouchMove, {
			passive: false
		})
		document.removeEventListener("scroll", preventTouchMove, {
			passive: false
		})
	}
}

export const preventTouchMove = (e) => {
	if (
		e.target.className !== "story-text" &&
		e.target.className !== "bullet-text" &&
		e.target.className !== "story-content" &&
		e.target.className !== "sc-fcdeBU jokdJK"
	)
		e.preventDefault()
}

export const pickGradient = (type) => {
	switch (type) {
		case 0:
			return "linear-gradient(#FFD000, #FFA100)"

		case 1:
			return "linear-gradient(180deg, #FFD3A6 0%, #FF9C96 51.56%, #FC6F89 100%)"

		case 2:
			return "linear-gradient(180deg, #FFC408 0%, #FF734B 51.04%, #FF306E 100%)"

		case 3:
			return "linear-gradient(180deg, #FDB955 0%, #FF8A00 100%)"

		case 4:
			return "linear-gradient(180deg, #FF699C 0%, #FE5763 100%)"

		default:
			return "linear-gradient(180deg, #F42CAB 0%, #F1353A 100%)"
	}
}

export const followStatus = (me, userID, useCase) => {
	if (!!me && !!userID)
		switch (useCase) {
			case "followedByMe":
				return (
					me.following?.active?.find((el) => el?.uid === userID) ??
					false
				)

			case "pendingByMe":
				return (
					me.following?.pending?.find(
						({ following }) => following?.uid === userID
					) ?? false
				)

			case "isFollower":
				return (
					me.followers?.active.find((el) => el?.uid === userID) ??
					false
				)

			case "isPendingMe":
				return (
					me.followers?.pending?.find(
						({ follower }) => follower?.uid === userID
					) ?? false
				)

			default:
				console.error("NO_USE_CASE_FOUND")
				break
		}
}

export const renderPublicationDate = (
	date,
	locale = localStorage.getItem("contentLanguage")
) => {
	return (
		<Moment
			locale={locale}
			format={locale === "fr" ? "D MMMM YYYY" : "MMMM D, YYYY"}
			withTitle
			style={{
				textTransform: locale === "fr" ? "lowercase" : "capitalize"
			}}
		>
			{date}
		</Moment>
	)
}

export const kycMiddleware = async (el, toast) => {
	if (el.props.user.isUninitialized) {
		await el.props.getUser()
	}

	if (
		!el.props.user.user.profession ||
		typeof el.props.user.user.profession !== "object"
	) {
		el.props.history.replace("/signup/onboarding?preventGoBack=1")
		customToast(toast)
		return
	}
}

export const logoutUserFromApp = async (callbackFunction) => {
	if (isNativeIOS) {
		unregisterFirebaseToken(localStorage.getItem("ios_fb_token"))
		localStorage.removeItem("ios_fb_token")
	} else {
		unregisterFirebaseToken(localStorage.getItem("fb_token"))
		localStorage.removeItem("fb_token")
	}

	!!callbackFunction && callbackFunction() // Généralement un setState pour afficher un loader
	await updateActiveSession("false")

	fbLogout()
	iosAuthSignOut()
	iosDeleteCookies() // Deleting cookies on iOS for non-auth-persistance
	androidSignOut()
	clearLocalStorage()
}

export const convertSecondsMinutes = (secondes) => {
	const minutes = Math.floor(secondes / 60)
	const resteSecondes = (secondes % 60).toFixed(0)
	const formatMinSeconde =
		minutes + ":" + (resteSecondes < 10 ? "0" : "") + resteSecondes
	return formatMinSeconde
}

export const getPageBackground = (contentLoading) => {
	const organisation = store.getState().user?.user?.organisations?.[0]
	const settings = organisation?.organisationSettings

	// Color: Discovery, Congress
	if (contentLoading) return ["#90A4AE", "#90A4AE"]

	if (settings) return [settings.primaryColor, settings.secondaryColor]

	return [
		"linear-gradient(180deg, #ff699c 0%, #fe5763 26.99%, #ff8a00 51.99%, #fdb955 77.51%, #ffc408 100%)",
		null
	]
}

// #6AA617

export const redirectToStore = () => {
	const isIOS =
		/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
	const isAndroid = /Android/.test(navigator.userAgent) && !window.MSStream

	if (isIOS)
		window.location.href =
			"https://apps.apple.com/fr/app/juisci/id1588725828"
	else if (isAndroid)
		window.location.href = `https://play.google.com/store/apps/details?id=com.juisci.app&hl=us&gl=US`
	else customToast(t("toast.error.global"))
}

export const SPECIALTIES_EXCEPTION = [
	"Acute Care",
	"Administration & Management",
	"Case Management & Discharge Planning",
	"Correctional Health",
	"Cosmetic Dentistry",
	"Endodontics",
	"Faculty & Teaching",
	"General Dentistry",
	"Geriatric Dentistry",
	"Home Care",
	"Hospice & Palliative Medicine",
	"Implant Dentistry",
	"Infusion Therapy",
	"Legal Consulting",
	"Long Term Care",
	"Non-Clinical",
	"Non-Clinical",
	"Occupational Medicine",
	"Oral Microbiology",
	"Oral and Maxillofacial Pathology",
	"Oral and Maxillofacial Radiology",
	"Oral and Maxillofacial Surgery",
	"Orthodontics",
	"Other Clinical",
	"Patient Education",
	"Periodontics",
	"Plastic Surgery",
	"Preventive Medicine",
	"Prosthodontics",
	"Public Health",
	"Public Health Dentistry",
	"Reconstructive Dentistry",
	"Risk Management & Utilization Review",
	"School & College Health",
	"Telemetry",
	"Undersea & Hyperbaric Medicine"
]

export const TAKEDA_TAGS_EXCEPTION = [
	"Cell Transplantation",
	"Leukemia",
	"Myelodysplastic syndromes",
	"Myeloid neoplasms",
	"Myeloma",
	"Thrombocytopenia"
]

export const getOrganisationLanguages = () => {
	const organisation = store.getState().user?.user?.organisations?.[0]

	if (!organisation) return []

	const allowedLanguages =
		organisation?.organisationSettings?.allowedLanguages

	return Object.entries(SupportedLanguagesEnum)
		.filter(([_key, value]) => allowedLanguages.includes(value))
		.map(([key, value]) => ({
			key,
			value
		}))
}

// export const interfaceLanguages = Object.entries(SupportedLanguagesEnum)
// 	.filter(
// 		([_key, value]) =>
// 			value === SupportedLanguagesEnum.EN ||
// 			value === SupportedLanguagesEnum.FR
// 	)
// 	.map(([key, value]) => ({
// 		key,
// 		value
// 	}))

export const interfaceLanguages = [
	{ key: "EN", value: "en" },
	{ key: "FR", value: "fr" }
	// { key: "FRformal", value: "fr-formal" }
]

export const languageTranslation = (lang) => {
	switch (lang) {
		case SupportedLanguagesEnum.ES:
			return t("languages.spanish")
		case SupportedLanguagesEnum.FR:
			return t("languages.french")
		case SupportedLanguagesEnum.IT:
			return t("languages.italian")
		case SupportedLanguagesEnum.DE:
			return t("languages.german")
		default:
			return t("languages.english")
	}
}

export const renderContentLanguage = (contentLanguage) => {
	switch (contentLanguage) {
		case SupportedLanguagesEnum.FR:
			return "🇫🇷 " + t("languages.french")

		case SupportedLanguagesEnum.IT:
			return "🇮🇹 " + t("languages.italian")

		case SupportedLanguagesEnum.ES:
			return "🇪🇸 " + t("languages.spanish")

		case SupportedLanguagesEnum.DE:
			return "🇩🇪" + t("languages.german")

		default:
			return "🇬🇧 " + t("languages.english")
	}
}

export const getEmailFromUrl = () => {
	const url = new URL(window.location.href)

	const query = new URLSearchParams(window.location.search)
	const email = query.get("email")?.replace(" ", "+")

	return email
}
