import axios from "axios";
import { API_URL } from "../config";
import { catchError, handleError } from "./response";
import { getApiRequestService } from "./index.services";
import { IUser } from "../interfaces/user.interface";

import AvatarApple from "../assets/images/default-avatar/default-apple.svg";
import AvatarPineapple from "../assets/images/default-avatar/default-pineapple.svg";
import AvatarPear from "../assets/images/default-avatar/default-pear.svg";
import AvatarLemon from "../assets/images/default-avatar/default-lemon.svg";
import AvatarBanana from "../assets/images/default-avatar/default-banana.svg";
import AvatarAvocado from "../assets/images/default-avatar/default-avocado.svg";
import AvatarAppleGrey from "../assets/images/default-avatar/default-apple-grey.svg";
import AvatarPineappleGrey from "../assets/images/default-avatar/default-pineapple-grey.svg";
import AvatarPearGrey from "../assets/images/default-avatar/default-pear-grey.svg";
import AvatarLemonGrey from "../assets/images/default-avatar/default-lemon-grey.svg";
import AvatarBananaGrey from "../assets/images/default-avatar/default-banana-grey.svg";
import AvatarAvocadoGrey from "../assets/images/default-avatar/default-avocado-grey.svg";

const headers = {
  "Content-Type": "multipart/form-data",
};

export enum DefaultProfilePicture {
  Apple = "apple",
  Avocado = "avocado",
  Pineapple = "pineapple",
  Pear = "pear",
  Lemon = "lemon",
  Banana = "banana",
}

export const defaultAvatarImages = {
  [DefaultProfilePicture.Pear]: {
    default: AvatarPear,
    disabled: AvatarPearGrey,
  },
  [DefaultProfilePicture.Apple]: {
    default: AvatarApple,
    disabled: AvatarAppleGrey,
  },
  [DefaultProfilePicture.Pineapple]: {
    default: AvatarPineapple,
    disabled: AvatarPineappleGrey,
  },
  [DefaultProfilePicture.Lemon]: {
    default: AvatarLemon,
    disabled: AvatarLemonGrey,
  },
  [DefaultProfilePicture.Banana]: {
    default: AvatarBanana,
    disabled: AvatarBananaGrey,
  },
  [DefaultProfilePicture.Avocado]: {
    default: AvatarAvocado,
    disabled: AvatarAvocadoGrey,
  },
};

export function pickUserImage(user: IUser) {
  if (user.profilePicture?.name) {
    return defaultAvatarImages[user.profilePicture.name].default;
  }
  if (user.profilePicture?.url) {
    return user.profilePicture.url;
  }
  // TEMP: Legacy profile pictures on some pages
  if (user?.image?.url) {
    return user?.image.url;
  }
  // Choisir un élement dans l'enum à partir de la longueur du user.fullname
  const randomImage = Object.values(DefaultProfilePicture)[
    user?.fullname?.length % 6
  ];

  return defaultAvatarImages[randomImage || "banana"].default;
}

export const uploadAvatar = async (data: FormData) => {
  try {
    const apiInstance = await getApiRequestService(headers);
    const res = await apiInstance.post("/user/image", data);

    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getAvatar = async (token: string, path: string) => {
  try {
    const apiInstance = await getApiRequestService(headers);

    const res = await apiInstance.get(`${path}`);
    return res.data;
  } catch (error) {
    return catchError(error);
  }
};
