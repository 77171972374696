import { t } from "i18next"
import { Component } from "react"
import { isAndroid } from "react-device-detect"
import customToast from "../../components/CustomToast"
import Loader from "../../components/Loader"
import { getNotification } from "../../services/notifications.services"
import { androidOpen } from "../../tools/android"
import { iosOpenNewTab, isNativeIOS } from "../../tools/ios"

class NotificationRouter extends Component {
	componentDidMount() {
		this.handleReadNotification()
	}

	async handleReadNotification() {
		const notification_id = this.props.match.params.id

		getNotification(notification_id)
			.then((notification) => {
				this.handleNotifications(notification)
			})
			.catch(({ response }) => {
				console.log("error", response)
				switch (response.status) {
					case 404:
						customToast(t("notifications.notFound"))
						this.props.history.replace("/home")
						break

					case 401:
						localStorage.setItem(
							"_cache_notification",
							notification_id
						)
						customToast(t("notifications.notAuthenticated"))
						this.props.history.replace("/login")
						break

					default:
						console.log("not 401 or 403", error.status)
						this.props.history.replace("/profile/notifications")
						break
				}
			})
	}

	handleNotifications(notification) {
		const notif_type = notification?.action?.notificationType
		const emitter = notification.action.emitters[0] ?? ""
		const conversation = notification?.action?.conversation

		switch (notif_type) {
			case "FollowRequestReceived":
				this.props.history.replace(`/profile/user/${emitter?.uid}`)
				break

			case "FollowRequestSent":
				this.props.history.replace(`/profile/user/${emitter?.uid}`)
				break

			case "FollowRequestAccepted":
				this.props.history.replace(`/profile/user/${emitter?.uid}`)
				break

			case "FollowUnfollow":
				this.props.history.replace(`/profile/user/${emitter?.uid}`)
				break

			case "ExternalLink":
				this.props.history.replace("/profile/notifications")

				if (notification?.action?.url.includes("juisci.com")) {
					this.props.history.push(
						notification?.action?.url.split(".com")[1]
					)
				} else if (isNativeIOS) iosOpenNewTab(notification?.action?.url)
				else if (isAndroid && window.services)
					androidOpen(notification?.action?.url)
				else window.open(notification?.action?.url, "_blank")
				break

			case "FollowActivity":
				this.props.history.replace("/home")
				break

			case "DirectMessage":
				this.props.history.replace(
					`/profile/messages/conversation/${conversation?._id}`
				)
				break

			case "ContentNew":
				this.props.history.replace("/home")
				break

			case "ContentRecommended":
				this.props.history.replace("/home")
				break

			case "ContentShare":
				this.props.history.replace("/home")
				break

			case "ArticlePublished":
				const { article, journal } = notification.action
				this.props.history.replace(
					`/post/${article?.slug}?from=notification&journal=${journal?.uid}`
				)
				break

			default:
				this.props.history.replace("/profile/notifications")
				break
		}
	}

	render() {
		return (
			<>
				<Loader />
			</>
		)
	}
}

export default NotificationRouter
