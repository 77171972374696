import React, { Component } from "react"
import styled from "styled-components"
import CustomNavbar from "../../components/CustomNavbar"
import LetterSVG from "../../assets/images/illustrations/letter.svg"
import BrainEyesSVG from "../../assets/images/illustrations/brain-eyes.svg"
import { t } from "i18next"
import { CustomButton } from "../../components/global"
import { completeMagicLink } from "../../services/firebase"
import Loader from "../../components/Loader"
import customToast from "../../components/CustomToast"
import { getMagicLink } from "../../services/auth.services"
import i18n from "../../config/i18n"
import { SupportedLanguagesEnum } from "../../interfaces"
import { getEmailFromUrl } from "../../tools/utils"

class MagicLinkPage extends Component {
	state = {
		btnLoading: false,
		loading: true,
		status: "retry"
	}

	async componentDidMount() {
		window.scrollTo(0, 0)

		const emailState = this.props.location?.state?.email
		const emailFromUrl = getEmailFromUrl()

		const interfaceLanguage =
			i18n.resolvedLanguage || SupportedLanguagesEnum.EN

		if (!!emailState && !emailFromUrl) {
			await getMagicLink({
				email: emailState,
				language: interfaceLanguage
			})
			this.setState({ status: "sent", loading: false })
		} else if (
			window.location.href.includes("apiKey") &&
			window.location.href.includes("oobCode") &&
			emailFromUrl
		) {
			try {
				await completeMagicLink(window.location.href, emailFromUrl)
			} catch (error) {
				this.setState({ loading: false })
			}
		} else {
			customToast(t("toast.error.global"))
			this.props.history.replace("/login")
		}
	}

	handleClick = async () => {
		this.setState({ loading: true, btnLoading: true })
		const emailFromUrl = getEmailFromUrl()

		const interfaceLanguage =
			i18n.resolvedLanguage || SupportedLanguagesEnum.EN

		if (!!emailFromUrl) {
			await getMagicLink({
				email: emailFromUrl,
				language: interfaceLanguage
			})

			setTimeout(() => {
				this.setState({
					status: "sent",
					loading: false,
					btnLoading: false
				})
			}, 500)
		} else {
			this.props.history.replace("/signup/prev")
		}
	}

	render() {
		const emailState =
			this.props.location?.state?.email || getEmailFromUrl()
		const isSent = this.state.status === "sent"

		return (
			<div>
				<CustomNavbar />
				{this.state.loading ? (
					<Loader />
				) : (
					<PageWrapper>
						<img
							src={isSent ? LetterSVG : BrainEyesSVG}
							alt="letter"
						/>
						<h3>
							{isSent ? t("login.magicLink.checkMail") : "Oops!"}
						</h3>
						<p>
							{isSent ? (
								<span>
									{t("")}
									<span className="email-color">{` ${emailState}`}</span>
								</span>
							) : (
								t("login.magicLink.description")
							)}
						</p>
						{this.state.status !== "sent" && (
							<CustomButton
								onClick={() => this.handleClick()}
								className={
									this.state.btnLoading
										? "loading disabled"
										: "pink"
								}
							>
								{t("login.magicLink.button")}
							</CustomButton>
						)}
					</PageWrapper>
				)}
			</div>
		)
	}
}

export default MagicLinkPage

const PageWrapper = styled.div`
	background: #ecf0f5;
	height: 100vh;
	box-sizing: border-box;
	padding: 15vh 40px 21px;
	text-align: center;

	img {
		margin: 24px 0 48px;
	}

	h3 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 120%;
		text-align: center;
		letter-spacing: -0.025em;
		color: #212121;
		margin: 0;
	}

	p {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		color: #212121;
		margin: 8px 0 24px;

		.email-color {
			color: #ce0868;
		}
	}
`
