import { Component } from "react"
import styled from "styled-components"
import { Row, Col } from "react-grid-system"
import { Divider, Spacer } from "../../components/global"
import ProfileBar from "../../components/ProfileBar"
import LinesEllipsis from "react-lines-ellipsis"
import disableScroll from "disable-scroll"
import {
	acceptFollowRequest,
	createFollowRequest,
	deletePublishedArticle,
	getUserById,
	rejectFollowRequest,
	unfollowRequest,
	unlikeArticle
} from "../../services/user.services"
import { getUserPlaylists } from "../../services/playlists.services"
import Loader from "../../components/Loader"
import {
	getLastArticle,
	getUserLastArticle
} from "../../services/articles.services"
import { Link } from "react-router-dom"
import { Trans, withTranslation } from "react-i18next"
import customToast from "../../components/CustomToast"
import { gtmBasicEvent } from "../../tools/reactgaEvents"
import { connect } from "react-redux"
import { userApi } from "../../redux/user/user.service"
import { t } from "i18next"
import CustomIcon from "../../components/CustomIcon"
import { pickGradient } from "../../tools/utils"
import LearningActivity from "../../components/gamification/LearningActivity"
import ShareActivity from "../../components/gamification/ShareActivity"
import Moment from "react-moment"
import i18n from "../../config/i18n"
import { getFirebaseToken } from "../../services/firebase"
import ProfilePicture from "../../components/ProfilePicture"
import PreviewCard from "../../components/PreviewCard"
import { store } from "../../redux"
import { setUser } from "../../redux/user/user.reducer"

class ProfilePage extends Component {
	state = {
		currentContent: localStorage.getItem("profileTab") || 0,
		userLoaded: false,
		user: undefined,
		playlists: [],
		liked_articles: [],
		loadTabs: false,

		// Follow system...
		followedByMe: false,
		isFollower: false,
		pendingByMe: false,
		loadingLastArticle: true,

		// ...
		loadingLikes: false,
		bioClamp: true,
		disableBand: false
	}

	fetchProfile = async () => {
		const { isMe } = this.props
		const userID = this.props.match.params.id
		const isAuthenticated = await getFirebaseToken()

		if (isMe && isAuthenticated) {
			// Run parallel queries
			this.props.getPlaylists()
			this.props.getSaves()

			// Run parallel query (last read article)
			getLastArticle()
				.then((lastReadArticle) =>
					this.setState({
						lastReadArticle,
						loadingLastArticle: false
					})
				)
				.catch((error) => {
					console.error("Err getLastArticle", error.message)
					this.setState({ loadingLastArticle: false })
				})

			// Run mandatory query (user data)
			if (!this.props.user.user || this.props.user.isUninitialized) {
				await this.props.getUser()
			}

			// Check user profile for onboarding
			if (
				!this.props.user.user.profession ||
				typeof this.props.user.user.profession !== "object"
			) {
				this.props.history.replace("/signup/onboarding")
				return
			}

			/** Getting if profile following/followers */
			if (this.props.user.following.isUninitialized) {
				this.props.getFollowing()
				this.props.getPendingFollowing()
			}
			if (this.props.user.followers.isUninitialized) {
				this.props.getFollowers()
				this.props.getPendingFollowers()
			}

			// Update state
			this.setState({
				user: this.props.user.user,
				sentLoader: true
			})

			// For Biocodex custom UI (colors, filter button hidden, ...)
			const organisation =
				!!this.props.user?.user?.organisations &&
				this.props.user?.user?.organisations[0]

			i18n.changeLanguage(this.props.user.user.language)
		} else {
			// Other user profile
			try {
				// Run parallel query (last read article)
				getUserLastArticle(userID)
					.then((lastReadArticle) =>
						this.setState({
							lastReadArticle,
							loadingLastArticle: false
						})
					)
					.catch((error) => {
						console.error("Err getUserLastArticle", error.message)
						this.setState({ loadingLastArticle: false })
					})

				// Run parallel query (playlists)
				getUserPlaylists(userID)
					.then((list) => {
						this.setState({ playlists: list })
					})
					.catch((error) => {
						console.error("Err getUserPlaylists", error.message)
					})

				// Run mandatory queries in parallel
				const [user] = await Promise.all([
					getUserById(userID),
					this.props.getFollowing(),
					this.props.getPendingFollowers(),
					this.props.getPendingFollowing()
				])

				gtmBasicEvent("view_profile")

				// Update state
				this.checkFollowRelationships()
				this.setState({
					user, // Set user informations...
					loadingLikes: false,
					sentLoader: true
				})
			} catch (error) {
				console.error("Err load other user profile", error.message)
				customToast(t("toast.error.global"))
				this.props.history.replace("/home")
			}
		}
	}

	checkFollowRelationships = () => {
		const userID = this.props.match.params.id
		const followedByMe =
			this.props.user.following?.active?.find(
				(user) => user?.uid === userID
			) ?? false
		const isFollower =
			this.props.user.followers?.active.find(
				(user) => user?.uid === userID
			) ?? false

		const pendingByMe =
			this.props.user.following?.pending?.find(
				({ following }) => following?.uid === userID
			) ?? false
		const isPendingMe =
			this.props.user.followers?.pending?.find(
				({ follower }) => follower?.uid === userID
			) ?? false

		this.setState({
			pendingByMe,
			followedByMe,
			isFollower,
			isPendingMe
		})
	}

	async componentDidMount() {
		try {
			const isAuthenticated = await getFirebaseToken()
			localStorage.removeItem("didAuth")
			window.scrollTo(0, 0)
			disableScroll.off()

			if (!isAuthenticated) {
				customToast(t("toast.error.notLogin"))
				this.props.history.replace("/login")
				return
			}

			this.fetchProfile()

			// Setting previous tab...
			if (!localStorage.getItem("profileTab")) {
				this.setState({ currentContent: 0, loadTabs: true })
			} else {
				this.setState({
					currentContent:
						parseInt(localStorage.getItem("profileTab")) ?? 0,
					loadTabs: true
				})
			}
		} catch (error) {
			console.error("Err ProfilePage didMount", error.message)
			customToast(t("toast.error.global"))
			this.props.history.push("/home")
		}
	}

	componentWillUnmount() {
		localStorage.setItem("profileTab", this.state.currentContent)
	}

	unlikeIt = async (articleId) => {
		store.dispatch(
			setUser({
				...this.props.user.user,
				likes: this.props.user.user.likes.filter(
					(article) => article._id !== articleId
				)
			})
		)

		customToast(t("toast.success.unliked"))

		const { likes } = await unlikeArticle(articleId)
		store.dispatch(
			setUser({
				...this.props.user.user,
				likes
			})
		)
	}

	async handleFollow() {
		const userID = this.props.match.params.id
		const { followedByMe, pendingByMe } = this.state
		const isAuthenticated = getFirebaseToken()

		if (!isAuthenticated) {
			this.props.history.push("/login")
		}

		if (!followedByMe && !pendingByMe) {
			createFollowRequest(userID).then((_) => {
				gtmBasicEvent("profile_follow")
				customToast(this.props.t("toast.success.requestSent"))
				this.props.getFollowing()
				this.props.getPendingFollowing()
				this.props.getUser()
				this.setState({ pendingByMe: true })
			})
		} else if (pendingByMe) {
			customToast(this.props.t("toast.error.requestAlready"), "error")
		} else {
			unfollowRequest(userID).then((res) => {
				customToast(this.props.t("toast.success.unfollowedUser"))
				this.setState({ pendingByMe: false, followedByMe: false })
				this.fetchProfile()
				this.props.getUser()
			})
		}
	}

	handleRequest(accept) {
		const userID = this.props.match.params.id
		const request = this.props.user.followers?.pending?.find(
			({ follower }) => follower?.uid === userID
		)
		if (accept)
			acceptFollowRequest(request?._id).then(() => {
				customToast(t("toast.success.acceptedRequest"))
				this.setState({ disableBand: true }, () => this.fetchProfile())
			})
		else
			rejectFollowRequest(request?._id).then(() => {
				customToast(t("toast.error.request"))
				this.setState({ disableBand: true }, () => this.fetchProfile())
			})
	}

	handleShareApp() {
		const location = window.location.protocol + "//" + window.location.host
		const organisationId = this.props.user?.user?.organisations?.[0]?._id

		const shareText = t("sharing.shareAppProfile")

		if (navigator.share) {
			navigator
				.share({
					title: document.title,
					text: shareText,
					url: `${location}/signup?org=${organisationId}`
				})
				.then(() => {
					customToast(
						this.props.t("toast.success.profileSharedMe"),
						"success"
					)
				})
				.catch(() => {})
		} else {
			customToast(this.props.t("toast.error.browser"))
		}
	}

	render() {
		const {
			currentContent,
			sentLoader,
			loadingLikes,
			lastReadArticle,
			followedByMe,
			isFollower,
			pendingByMe,
			isPendingMe,
			loadTabs,
			disableBand
		} = this.state

		const userID = this.props.match.params.id

		const liked_articles = this.props.isMe
			? this.props.user?.user?.likes
			: this.state.user?.likes

		let { isMe, t } = this.props

		if (this.props.match.params.id === this.props.user.user.uid) {
			isMe = true
		}

		const playlists = this.props.isMe
			? this.props?.user?.playlists.docs
			: this.state.playlists

		const user = this.props.isMe ? this.props.user.user : this.state.user

		if (isMe && !this.props.user.user.profession) return <Loader />

		return (
			<>
				<ProfileBar
					{...this.props}
					isMe={isMe}
					user={isMe ? this.props.user.user : user}
					followedByMe={followedByMe}
					isFollower={isFollower}
					me={this.props.user.user}
				/>
				<Spacer height="40px" />
				{(isMe && !!user) || (!isMe && sentLoader) ? (
					<>
						{!isMe && isPendingMe && !disableBand && (
							<AcceptFollowBand>
								<Row
									justify="between"
									style={{ margin: 0 }}
									align="center"
								>
									<Col xs={8}>
										<p>
											<b>
												{user.firstname +
													" " +
													user.lastname[0]}
												.
											</b>{" "}
											souhaite rejoindre votre réseau.
											Accepter ?
										</p>
									</Col>
									<Col xs={4}>
										<Row>
											<button
												onClick={() =>
													this.handleRequest(true)
												}
												style={{
													background: "white",
													color: "black"
												}}
											>
												{t("common.yes")}
											</button>
											<button
												onClick={() =>
													this.handleRequest(false)
												}
												style={{
													background: "black",
													color: "white"
												}}
											>
												{t("common.no")}
											</button>
										</Row>
									</Col>
								</Row>
							</AcceptFollowBand>
						)}
						<ProfileHeader>
							<Row justify="start" gutterWidth={15} align="start">
								<Col xs={5 / 2}>
									<div style={{ position: "relative" }}>
										<ProfilePicture
											user={user}
											height={54}
											style={{
												background: pickGradient(
													user?.firstname?.length % 5
												)
											}}
										/>
										{user?.published_articles?.length >
											0 && (
											<AuthorTag>
												{t("profile.author")}
											</AuthorTag>
										)}
									</div>
								</Col>
								<Col xs={11 / 2}>
									<h3>
										{user?.firstname}
										<br />
										<b>{user?.lastname}</b>
									</h3>
									<h5>
										<span>
											{
												user?.profession?.translations[
													i18n.resolvedLanguage
												]
											}
										</span>
										<br />
										{
											user?.main_specialty?.translations[
												i18n.resolvedLanguage
											]
										}
									</h5>
									<div className="creation-date">
										{t("common.joinedIn") + " "}
										<Moment
											withTitle
											format="MMMM YYYY"
											className="capitalize"
											locale={i18n.resolvedLanguage}
										>
											{user.meta.creationDate}
										</Moment>
									</div>
								</Col>
								{!isMe && (
									<Col>
										<SwitcherButton
											onClick={this.handleFollow.bind(
												this
											)}
											style={{
												width: followedByMe
													? "110px"
													: "90px",
												marginLeft: followedByMe && -15,
												boxShadow:
													"1px 1px 10px rgba(33, 33, 33, 0.05)"
											}}
											selected={
												!followedByMe && !pendingByMe
											}
										>
											{followedByMe
												? "Unfollow"
												: pendingByMe
												? "Sent"
												: "Follow"}
										</SwitcherButton>
									</Col>
								)}
							</Row>
							<Spacer height="30px" />
							<Row justify="start" style={{ margin: 0 }}>
								{user?.published_articles?.length > 0 && (
									<h4>
										<b>
											{user?.published_articles?.length}
										</b>{" "}
										publication
										{user?.published_articles?.length > 1 &&
											"s"}
									</h4>
								)}
								<CustomLink
									to={{
										state: {
											showName: true,
											firstname: user?.firstname,
											lastname: user?.lastname
										},
										pathname: isMe
											? `/profile/following`
											: `/profile/following/${userID}`
									}}
								>
									<h4>
										<b>{user?.metrics?.following}</b>{" "}
										{t("profile.following")}
									</h4>
								</CustomLink>
								<CustomLink
									to={{
										state: {
											showName: true,
											firstname: user?.firstname,
											lastname: user?.lastname
										},
										pathname: isMe
											? `/profile/followers`
											: `/profile/followers/${userID}`
									}}
								>
									<h4>
										<b>{user?.metrics?.followers}</b>{" "}
										{t("profile.followers")}
									</h4>
								</CustomLink>
							</Row>

							{!!user.link && (
								<a
									className="user-link"
									target="blank"
									href={user.link}
								>
									<CustomIcon
										iconName="link"
										color="#CE0868"
									/>
									{user.link}
								</a>
							)}

							<Spacer height="30px" />
							{user?.bio?.length > 0 ? (
								<div
									onClick={() =>
										this.setState({
											bioClamp: !this.state.bioClamp
										})
									}
								>
									{/* <NewlineText expanded={true}>{user?.bio}</NewlineText> */}
									<LinesEllipsis
										className="force-wrap"
										text={user?.bio}
										maxLine={
											this.state.bioClamp ? "7" : "1000"
										}
										style={{ lineHeight: "160%" }}
										ellipsis={
											<span>
												...
												<button className="clamp-lines__button_profile">
													View more
												</button>
											</span>
										}
										trimRight
										basedOn="letters"
									/>
									{!this.state.bioClamp && (
										<button
											aria-expanded="true"
											className="clamp-lines__button_profile"
											style={{ marginLeft: 0 }}
										>
											View Less
										</button>
									)}
								</div>
							) : isMe ? (
								<p
									onClick={() =>
										this.props.history.push(
											"/settings/edit/profile"
										)
									}
									style={{
										margin: 0,
										fontWeight: 500,
										textAlign: "center",
										background: "white",
										padding: "7px 20px",
										borderRadius: "10px",
										boxShadow:
											" 2px 2px 4px rgba(33, 33, 33, 0.05)"
									}}
								>
									{t("profile.addBio")} 📝
								</p>
							) : (
								<p>{t("profile.noBio")}</p>
							)}
							<Spacer height="30px" />
						</ProfileHeader>
						<>
							{!loadTabs ? (
								<Loader />
							) : (
								<>
									<center>
										<ProfileSwitcher>
											<SwitcherButton
												selected={currentContent === 0}
												onClick={() =>
													this.setState({
														currentContent: 0
													})
												}
											>
												{t("profile.information")}
											</SwitcherButton>
											<SwitcherButton
												selected={currentContent === 1}
												onClick={() =>
													this.setState({
														currentContent: 1
													})
												}
											>
												{t("profile.library")}
											</SwitcherButton>
										</ProfileSwitcher>
									</center>
									{currentContent === 0 ? (
										<ProfileContent>
											<LearningActivity
												isMe={isMe}
												onClick={() =>
													isMe
														? this.props.history.push(
																"/profile/game/activity"
														  )
														: this.props.history.push(
																"/user/game/activity/" +
																	user?.uid
														  )
												}
											/>
											<Spacer height="16px" />
											{isMe && (
												<ShareActivity
													onClick={() =>
														this.handleShareApp()
													}
												/>
											)}
											<Divider />

											<h3>
												{t("common.medicalSpecialties")}
											</h3>
											<Spacer height="12px" />
											<SpecialtyTag className="primary">
												{
													user?.main_specialty
														?.translations[
														i18n.resolvedLanguage
													]
												}
											</SpecialtyTag>
											{user?.medical_specialties?.length >
											0 ? (
												user?.medical_specialties?.map(
													(specialty) => (
														<SpecialtyTag
															key={
																specialty._id +
																"mdclspe"
															}
														>
															{
																specialty
																	.translations[
																	i18n
																		.resolvedLanguage
																]
															}
														</SpecialtyTag>
													)
												)
											) : (
												<p>
													{t("profile.noSpecialties")}
												</p>
											)}
											<Divider />

											<h3>{t("profile.workplace")}</h3>
											<h4>
												{!user?.location?.location
													.length &&
													this.props.t(
														"profile.noLocation"
													)}
												<b>
													{
														user?.location?.location?.split(
															","
														)[0]
													}
												</b>
												<br />
												{
													user?.location?.location?.split(
														","
													)[1]
												}{" "}
												<br />
												{
													user?.location?.location?.split(
														","
													)[2]
												}
												{user?.location?.location?.split(
													","
												)[3]?.length > 0 && ","}
												{
													user?.location?.location?.split(
														","
													)[3]
												}
												<br />
											</h4>
											<Divider />

											{user?.published_articles?.length >
												0 && (
												<>
													<h3>
														{t(
															"profile.publishedPapers"
														)}
													</h3>
													<Spacer height="20px" />
													{user?.published_articles?.map(
														(article, index) => {
															return (
																<PreviewCard
																	key={
																		article._id +
																		"profile-published-papers"
																	}
																	content={
																		article
																	}
																	onRemove={() =>
																		deletePublishedArticle(
																			article?.slug
																		)
																	}
																/>
															)
														}
													)}
													<Divider />
												</>
											)}

											<h3>{t("profile.awards")}</h3>
											<Spacer height="21px" />
											{user?.awards?.length === 0 &&
												(!isMe ? (
													<p>
														{t("profile.noAwards")}
													</p>
												) : (
													<>
														<p
															onClick={() =>
																this.props.history.push(
																	"/settings/edit/profile"
																)
															}
															style={{
																margin: 0,
																fontWeight: 500,
																textAlign:
																	"center",
																background:
																	"white",
																padding:
																	"7px 20px",
																borderRadius:
																	"10px",
																boxShadow:
																	" 2px 2px 4px rgba(33, 33, 33, 0.05)"
															}}
														>
															{t(
																"profile.add_award"
															)}{" "}
															🎖
														</p>
													</>
												))}

											{user?.awards?.map((award) => {
												if (award?.content)
													return (
														<AwardGroup
															key={award?._id}
															date={award.label}
															content={
																award.content
															}
														/>
													)
											})}
										</ProfileContent>
									) : (
										<ProfileContent
											style={{ padding: "50px 0" }}
										>
											{/* LAST READ ARTICLE */}
											<div style={{ padding: "0 20px" }}>
												<h3>{t("profile.lastread")}</h3>
												<Spacer height="10px" />
												{this.state
													.loadingLastArticle ? (
													<Loader loaderOnly />
												) : (lastReadArticle?.status ??
														"") === 404 ||
												  !lastReadArticle ? (
													<p>
														{t(
															"profile.noLastReadArticle"
														)}
													</p>
												) : (
													<PreviewCard
														content={
															lastReadArticle
														}
														disabled
														onRemove={() =>
															this.deleteFromPlaylist(
																article?.slug
															).then((res) => {
																return res
															})
														}
													/>
												)}
											</div>
											{/* FAVORITES JOURNALS */}
											<div>
												<div
													style={{
														padding: "0 20px"
													}}
												>
													<Divider />
													<h3>
														{t(
															"profile.myJournals"
														)}
													</h3>
													<Spacer height="20px" />
												</div>
												<HScrollView
													className="scrollbar-hidden"
													style={{ paddingLeft: 20 }}
												>
													{isMe && (
														<PlaylistSquare
															onClick={() =>
																this.props.history.push(
																	"/search?select=journals"
																)
															}
															style={{
																color: "white",
																borderRadius: 10,
																boxSizing:
																	"border-box",
																height: 75,
																background:
																	"linear-gradient(180deg, #FF699C 0%, #FE5763 100%)"
															}}
														>
															<div
																className="content"
																style={{
																	margin: "7px 0"
																}}
															>
																<CustomIcon
																	iconName="search"
																	color="white"
																	style={{
																		marginBottom: 3
																	}}
																/>
																{t(
																	"common.search"
																)}
															</div>
														</PlaylistSquare>
													)}

													{user?.journals?.map(
														(journal, index) => (
															<PlaylistSquare
																key={
																	journal?._id
																}
																style={{
																	height: 75,
																	borderRadius: 10,
																	boxSizing:
																		"border-box",
																	marginRight:
																		index ==
																			user
																				?.journals
																				?.length -
																				1 &&
																		40
																}}
																onClick={() =>
																	this.props.history.push(
																		"/journal/" +
																			journal?.uid
																	)
																}
															>
																<img
																	src={
																		journal
																			?.image
																			?.url
																	}
																	alt=""
																	style={{
																		maxWidth: 80,
																		maxHeight: 70,
																		position:
																			"absolute",
																		top: "50%",
																		left: "50%",
																		transform:
																			"translate(-50%,-50%)"
																	}}
																/>
															</PlaylistSquare>
														)
													)}
													{!user?.journals
														?.length && (
														<p>
															{t(
																"profile.noJournals"
															)}
														</p>
													)}
												</HScrollView>
											</div>

											{/* PLAYLISTS */}
											<div>
												<div
													style={{
														padding: "0 20px"
													}}
												>
													<Divider />
													<h3>
														{isMe ? (
															t(
																"profile.myplaylists"
															)
														) : (
															<>
																<Trans i18nKey="profile.playlists">
																	Playlist de{" "}
																	{
																		user?.firstname
																	}
																</Trans>
																<Spacer height="10px" />
															</>
														)}
													</h3>
													{playlists?.length === 0 &&
													!isMe ? (
														<p>
															{t(
																"profile.noPlaylists"
															)}
														</p>
													) : (
														<div />
													)}
													<Spacer height="20px" />
												</div>

												<HScrollView
													className="scrollbar-hidden"
													style={{ paddingLeft: 20 }}
												>
													{isMe && (
														<PlaylistSquare
															onClick={() =>
																this.props.history.push(
																	"/profile/playlist/create/new"
																)
															}
															style={{
																color: "white",
																background:
																	"linear-gradient(180deg, #FF699C 0%, #FE5763 100%)"
															}}
														>
															<div
																className="content"
																style={{
																	margin: "13px 0"
																}}
															>
																<div>
																	<CustomIcon
																		scale={
																			1.1
																		}
																		iconName="plus"
																	/>
																	<span
																		style={{
																			whiteSpace:
																				"pre-wrap"
																		}}
																	>
																		{t(
																			"profile.createPlaylist"
																		)}
																	</span>
																</div>
															</div>
														</PlaylistSquare>
													)}

													{isMe && (
														<>
															{this.props.user
																.user
																?.playlists &&
																this.props.user.user?.playlists.map(
																	(
																		playlist,
																		index
																	) => {
																		return (
																			<PlaylistSquare
																				className="sponsored"
																				key={
																					playlist?._id
																				}
																				style={{
																					marginRight:
																						index ==
																							this
																								.props
																								.user
																								.user
																								?.playlists
																								?.length +
																								playlists?.length -
																								1 &&
																						40
																				}}
																				onClick={() => {
																					this.props.history.push(
																						`/playlist/public/${playlist._id}`
																					)
																				}}
																			>
																				{/* <img src={playlist.company.image[0]} alt="" /> */}
																				<div className="content">
																					<LinesEllipsis
																						text={
																							playlist.title
																						}
																						maxLine="3"
																						trimRight
																						basedOn="letters"
																					/>
																					<br />
																					<span
																						style={{
																							fontSize:
																								"10px"
																						}}
																					>
																						{
																							playlist
																								.playlist
																								.length
																						}{" "}
																						{playlist
																							.playlist
																							.length >
																						1
																							? "publications"
																							: "publication"}
																					</span>
																				</div>
																			</PlaylistSquare>
																		)
																	}
																)}
														</>
													)}

													{playlists &&
														playlists?.map(
															(
																playlist,
																index
															) => {
																return (
																	<PlaylistSquare
																		key={
																			playlist?._id
																		}
																		style={{
																			marginRight:
																				index ==
																					playlists?.length -
																						1 &&
																				40
																		}}
																		onClick={() => {
																			if (
																				isMe
																			) {
																				this.props.history.push(
																					`/profile/playlist/${playlist._id}`
																				)
																			} else {
																				this.props.history.push(
																					`/profile/user/${user?.uid}/playlist/${playlist._id}`
																				)
																			}
																		}}
																	>
																		<div className="content">
																			<LinesEllipsis
																				text={
																					playlist.title
																				}
																				maxLine="3"
																				trimRight
																				basedOn="letters"
																				style={{
																					margin: "10px 0"
																				}}
																			/>
																			<span
																				style={{
																					fontSize:
																						"10px"
																				}}
																			>
																				{
																					playlist
																						.playlist
																						.length
																				}{" "}
																				{playlist
																					.playlist
																					.length >
																				1
																					? "publications"
																					: "publication"}
																			</span>
																		</div>
																	</PlaylistSquare>
																)
															}
														)}
												</HScrollView>
											</div>

											<div style={{ padding: "0 20px" }}>
												<Divider
													style={{ marginBottom: 10 }}
												/>

												<Spacer height="30px" />

												<h3>
													{isMe ? (
														t("profile.mylikes")
													) : (
														<Trans i18nKey="profile.likes">
															Articles liked by{" "}
															{user?.firstname}
														</Trans>
													)}
												</h3>

												{loadingLikes ? (
													<Loader loaderOnly />
												) : liked_articles?.length >
												  0 ? (
													<div>
														<Spacer height="20px" />
														{liked_articles.map(
															(article) => {
																if (
																	article?.slug !==
																	undefined
																) {
																	return (
																		<PreviewCard
																			key={
																				article._id +
																				"liked-articles-profile"
																			}
																			content={
																				article
																			}
																			onRemove={() =>
																				this.unlikeIt(
																					article?._id
																				)
																			}
																		/>
																	)
																}
															}
														)}
														<Divider />
													</div>
												) : (
													<p
														style={{
															marginBottom: 24
														}}
													>
														{t(
															"profile.noLikedArticles"
														) +
															" " +
															user?.firstname}
													</p>
												)}
											</div>
										</ProfileContent>
									)}
								</>
							)}
						</>
					</>
				) : (
					<Loader />
				)}
			</>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate,
	getSaves: userApi.endpoints.getSaves.initiate,
	getPlaylists: userApi.endpoints.getPlaylists.initiate,
	getFollowers: userApi.endpoints.getFollowers.initiate,
	getPendingFollowers: userApi.endpoints.getPendingFollowers.initiate,
	getFollowing: userApi.endpoints.getFollowing.initiate,
	getPendingFollowing: userApi.endpoints.getPendingFollowing.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(ProfilePage))

export const NewlineText = (props) => {
	const text = props.children
	const newText = text.split("\n").map((str) => (
		<p
			style={{
				overflow: "hidden",
				maxHeight: props.expanded ? "100%" : 70
			}}
			className="force-wrap"
			key={str}
		>
			{str}
		</p>
	))

	return newText
}

export const AwardGroup = (props) => {
	return (
		<Row>
			<Col xs={2}>
				<p
					style={{
						color: "#FF699C",
						fontWeight: 700,
						fontSize: "12px"
					}}
				>
					{props.date}
				</p>
			</Col>
			<Col xs={10}>
				<p
					style={{
						color: "#212121",
						fontWeight: 400,
						fontSize: "12px"
					}}
				>
					{props.content}
				</p>
			</Col>
		</Row>
	)
}

const AcceptFollowBand = styled.div`
	width: 100%;
	background: #ffc501;
	padding: 16px 16px 0;

	b {
		font-weight: 700;
	}

	button {
		outline: none;
		border: none;
		box-shadow: none;
		border-radius: 5px;
		padding: 3px 8px;
		font-family: "Poppins";
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 120%;
		color: #000000;
		text-transform: capitalize;
		margin-left: 5px;
	}

	p {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 120%;
		color: #000000;
	}
`

const AuthorTag = styled.div`
	position: absolute;
	bottom: -7px;
	left: 0;
	right: 0;

	font-size: 11px;
	text-align: center;
	color: #212121;
	font-weight: regular;
	background: white;
	padding: 3px 5px;
	border-radius: 100px;
	box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
`

export const HScrollView = styled.div`
	margin: 0;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	display: inline-block;
	vertical-align: middle;
	width: 100%;
`

const CustomLink = styled(Link)`
	color: #212121;
`

const PlaylistSquare = styled.div`
	background: linear-gradient(360deg, #d2dce2 0%, #ecf0f5 100%);
	color: black;
	box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
	padding: 10px 0;
	width: 120px;
	height: 100px;
	text-align: center;
	display: inline-block;
	white-space: normal;
	margin: 0 6px;
	border-radius: 20px;
	position: relative;

	&.sponsored {
		background: linear-gradient(180.06deg, #fdb955 0.05%, #d9e2e7 326.35%);

		& > .content {
			font-size: 12px;
			top: 50%;
		}
	}

	.content {
		font-family: "Poppins";
		font-weight: 600;
		font-size: 13px;
		position: absolute;
		left: 50%;
		top: 40%;
		width: 100%;
		transform: translate(-50%, -50%);
		width: 90%;
	}

	span {
		font-family: "Poppins";
		font-weight: normal;
		font-size: 10px;
	}
`

const ProfileSwitcher = styled.div`
	display: table;
	position: relative;
	background: #fff3ce;
	border-radius: 20px;
	padding: 3px;
	margin-bottom: -20px !important;
	z-index: 10 !important;
`

const SwitcherButton = styled.button`
	outline: none;
	border: none;
	padding: 4px 13px;
	border-radius: 20px;
	box-shadow: ${(props) =>
		props.selected ? "1px 1px 10px rgba(33, 33, 33, 0.1)" : "none"};

	background: ${(props) => (props.selected ? "#FFC408" : "#fff3ce")};
	color: ${(props) => (props.selected ? "#212121" : "#FFC408")};
	font-family: "Poppins";
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	width: 140px;
`

const SpecialtyTag = styled.div`
	font-family: "Poppins";
	background-color: #ffffff;
	border-radius: 5px;
	border: 1px solid #d2dce2;
	padding: 6px 10px;
	margin-right: 6px;
	margin-top: 6px;
	display: inline-block;

	color: #212121;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;

	&.primary {
		background-color: #ffc408;
		border-color: #ffc408;
	}
`

const ProfileContent = styled.div`
	background: #f7f8fc;
	color: #212121;
	padding: 50px 20px;

	h3 {
		font-size: 21px;
		line-height: 25.2px;
		font-weight: 900;
		margin: 0;
		font-family: "Work Sans" !important;
	}

	h4 {
		margin-top: 15px;
		font-size: 16px;
		line-height: 20.8px;
		font-weight: 400;
		margin-top: 5px;
		font-family: "Poppins";
	}

	h4 b {
		font-weight: 600;
	}
`

const ProfileHeader = styled.div`
	padding: 50px 21px 30px;
	background: #ecf0f5;
	color: #212121;
	font-family: "Poppins";

	.user-link {
		margin-top: 16px;

		display: flex;
		align-items: center;
		gap: 8px;

		font-family: "Inter";
		font-weight: 600;
		font-size: 12px;
		line-height: 160%;
		text-decoration-line: underline;
		color: #ce0868;

		.--custom-icon {
			margin-top: 6px;
		}
	}

	h3 {
		line-height: 17px;
		font-size: 17px;
		font-weight: 300;
		margin: 0 0 10px;
	}

	h3 b {
		font-weight: 700;
	}

	h4 {
		line-height: 15px;
		font-size: 11px;
		font-weight: 300;
		margin: 0;
		margin-right: 20px;
	}

	h4 b {
		font-weight: 600;
		font-size: 14px;
	}

	h5 {
		margin: 0;
		text-transform: capitalize;
		font-size: 11px;
		line-height: 15.4px;
		font-weight: 300;
	}

	h5 span {
		text-transform: uppercase;
	}

	p {
		font-size: 14px;
		line-height: 22.4px;
		font-weight: 400;
		margin: 0;
		padding: 0;
	}

	.creation-date {
		font-style: normal;
		font-weight: 300;
		font-size: 9px;
		line-height: 120%;
		letter-spacing: 0.03em;
		margin-top: 10px;

		.capitalize {
			text-transform: capitalize;
		}
	}
`
