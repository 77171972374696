import { Component } from "react";
import styled from "styled-components";
import { Row, Col } from "react-grid-system";
import BackIcon from "../assets/icons/back-orange.svg";
import CustomIcon from "./CustomIcon";
import { withTranslation } from "react-i18next";
import { getUser } from "../services/user.services";
import { gtmPageView } from "../tools/reactgaEvents";

class MessageBar extends Component {
  state = {
    showSheet: false,
  };

  componentDidMount() {
    getUser().then((res) =>
      gtmPageView(res?._id, this.props.match.url)
    );
  }

  render() {
    const { prevState, isDM, hideNewMsgIcon, page } = this.props;
    return (
      <>
        <BarWrapper>
          <Row justify='between' align='center'>
            <Col
              xs={2}
              onClick={() => {
                switch (page) {
                  case "DM":
                    this.props.history.push("/profile/messages");
                    break;

                  case "new_conv":
                    this.props.history.push("/profile/messages");
                    break;

                  default:
                    this.props.history.push("/profile");
                    break;
                }
              }}
            >
              <StyledIcons src={BackIcon} alt='' />
            </Col>
            {isDM ? (
              <>
                <Col xs={8}>
                  <h3
                    onClick={() =>
                      this.props.history.push(`/profile/user/${prevState.uid}`)
                    }
                    style={{
                      margin: 0,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      textAlign: "center",
                    }}
                  >
                    {prevState?.firstname}&nbsp;
                    <b>{prevState?.lastname}</b>
                  </h3>
                </Col>
                <Col xs={2}>
                  {/* <CustomIcon iconName="share_playlist" /> */}
                </Col>
              </>
            ) : (
              <>
                <Col xs={8}>
                  <h3
                    style={{
                      margin: 0,
                      fontFamily: "Poppins",
                      fontWeight: 400,
                      textAlign: "center",
                    }}
                  >
                    {this.props.t("messages.messaging")}
                  </h3>
                </Col>
                {hideNewMsgIcon ? (
                  <Col xs={2}></Col>
                ) : (
                  <Col
                    xs={2}
                    onClick={() =>
                      this.props.history.push({
                        pathname: "/profile/messages/new",
                        state: {
                          firstname: "Firstname",
                          lastname: "Lastname",
                        },
                      })
                    }
                  >
                    <CustomIcon iconName='dm_icon' />
                  </Col>
                )}
              </>
            )}
          </Row>
        </BarWrapper>
      </>
    );
  }
}

export default withTranslation()(MessageBar);

export const Badge = (props) => {
  return (
    <>
      <div style={{ position: "relative" }}>
        <span
          style={{
            position: "absolute",
            top: -6,
            right: -6,
            color: "#212121",
            background: "#FFC408",
            fontSize: "9px",
            fontWeight: 700,
            fontFamily: "Poppins",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "14px",
            height: "14px",
          }}
        >
          {props.count > 99 ? 99 : props.count}
        </span>
        {props.children}
      </div>
    </>
  );
};

const BarWrapper = styled.div`
  background: white;
  padding: 10px 22px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
`;

const StyledIcons = styled.img`
  height: 32px;
  width: auto;
`;

const SheetWrapper = styled.div`
  padding: 55px 5px;
  min-height: ${window.innerHeight / 8}px;
`;
