import React, { useState, useEffect, useRef } from "react"
import AudioControls from "./AudioControls"
import styled from "styled-components"
import { convertSecondsMinutes } from "../../tools/utils"
import {
	convertContentToItemData,
	gtmItemsData
} from "../../tools/reactgaEvents"
import {
	ItemDataEventListName,
	ItemDataVariant,
	ItemsDataEvent
} from "../../interfaces"
import AudioPlayer from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"
import { useTranslation } from "react-i18next"
import customToast from "../CustomToast"

const CustomAudioPlayer = ({
	audioSrc,
	visible,
	onPlayPauseClick,
	toPrevTrack,
	toNextTrack,
	article
}) => {
	const { t } = useTranslation()

	// State
	const [trackProgress, setTrackProgress] = useState(0)
	const [isPlaying, setIsPlaying] = useState(false)
	const [duration, setDuration] = useState(1)
	const [playbackRate, setPlaybackRate] = useState(0)
	const [remainTime, setRemainTime] = useState(false)

	// Refs

	if (!audioSrc) return <div />

	const player = useRef()

	const currentPercentage = duration
		? `${(trackProgress / duration) * 100}%`
		: "0%"
	const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #212121))
  `

	const onScrub = (value) => {
		// Pause during scrubbing
		if (isPlaying) setIsPlaying(false)

		player.current.audio.current.currentTime = value
		setTrackProgress(value)

		setIsPlaying(true)
	}

	const onScrubEnd = () => {
		// If not already playing, start
		if (!isPlaying) {
			setIsPlaying(true)
		}
	}

	const changePlaybackRate = () => {
		setPlaybackRate(playbackRate + 1)
	}

	const setReplayBack = () => {
		// Play back from 10 seconds
		if (trackProgress > 10) onScrub(trackProgress - 10)
		else onScrub(0)

		setIsPlaying(true)
		startTimer()
	}

	useEffect(() => {
		if (isPlaying) {
			player?.current?.audio?.current?.play().catch(() => {
				customToast(t("toast.error.audioUnreadable"))
				setIsPlaying(false)
			})
		} else if (!isPlaying && !!player?.current?.audio?.current) {
			player?.current?.audio?.current?.pause()
		}
	}, [isPlaying])

	useEffect(() => {
		if (!!player?.current?.audio?.current)
			player.current.audio.current.playbackRate =
				(playbackRate % 3) / 4 + 1
	}, [playbackRate])

	useEffect(() => {
		setIsPlaying(visible)
	}, [visible])

	useEffect(() => {
		if (parseInt(trackProgress) <= 1.5)
			gtmItemsData(
				ItemsDataEvent.AUDIO_PLAYED,
				convertContentToItemData(
					[article],
					ItemDataEventListName.ARTICLE_PAGE,
					ItemDataVariant.ARTICLE
				)
			)

		if (parseInt(trackProgress) === parseInt(duration))
			gtmItemsData(
				ItemsDataEvent.AUDIO_COMPLETE,
				convertContentToItemData(
					[article],
					ItemDataEventListName.ARTICLE_PAGE,
					ItemDataVariant.ARTICLE
				)
			)

		if (parseInt(trackProgress) % 15) {
			const audioPercentage = ((trackProgress / duration) * 100).toFixed(
				1
			)
			gtmItemsData(ItemsDataEvent.AUDIO_PROGREESS, [
				{
					item_list_name: ItemDataEventListName.ARTICLE_PAGE,
					item_variant: ItemDataVariant.ARTICLE,
					item_name: article.title,
					item_id: article._id,
					item_brand: article.journal.name,
					item_category:
						article.medical_specialties?.[0]?.uid || null,
					item_category2: "standard",
					quantity: 1,
					percentage: audioPercentage
				}
			])
		}
	}, [trackProgress])

	return (
		<div>
			<AudioPlayer
				ref={player}
				autoPlay={false}
				src={audioSrc}
				style={{
					display: "none"
				}}
				onCanPlay={(e) => setDuration(e.target.duration)}
				onListen={(e) => setTrackProgress(e.target.currentTime)}
			/>
			{!isPlaying || !duration ? (
				<div />
			) : (
				<StyledAudioPlayer>
					<div className="audio-player">
						<div className="track-info">
							<AudioControls
								playbackRate={(playbackRate % 3) / 4 + 1}
								isPlaying={isPlaying}
								onPrevClick={() =>
									trackProgress < 5
										? toPrevTrack()
										: onScrub(0)
								}
								onNextClick={toNextTrack}
								onPlayPauseClick={() => {
									setIsPlaying(!isPlaying)
									onPlayPauseClick(!isPlaying)
								}}
								onPlaybackRate={changePlaybackRate}
								onReplayBack={setReplayBack}
							/>
							<div className="track-slider">
								<div className="track-time">
									{convertSecondsMinutes(trackProgress)}
								</div>
								<input
									type="range"
									value={trackProgress}
									step="1"
									min="0"
									max={duration ? duration : `${duration}`}
									className="progress"
									onChange={(e) => onScrub(e.target.value)}
									onMouseUp={onScrubEnd}
									onKeyUp={onScrubEnd}
									onTouchEnd={onScrubEnd}
									style={{ background: trackStyling }}
								/>
								<div
									className="track-time"
									onClick={() => setRemainTime(!remainTime)}
								>
									{remainTime
										? `-${convertSecondsMinutes(
												duration - trackProgress
										  )}`
										: convertSecondsMinutes(duration)}
								</div>
							</div>
						</div>
					</div>
				</StyledAudioPlayer>
			)}
		</div>
	)
}

export default CustomAudioPlayer

const StyledAudioPlayer = styled.div`
	background: linear-gradient(180deg, #f42cab 0%, #f1353a 100%);
	border-radius: 8px;
	padding: 28px 24px;
	margin: 20px;
	position: fixed;
	left: 0;
	right: 0;
	bottom: ${(props) => (props.isNative ? "70px" : "15px")};
	z-index: 10;
	animation: slideIn 0.1s ease-in;

	&.hide {
		opacity: 0;
		display: none;
		animation: slideOut 0.1s ease-out;
	}

	@keyframes slideIn {
		0% {
			opacity: 0;
			bottom: -10px;
		}
		100% {
			opacity: 1;
			bottom: ${(props) => (props.isNative ? "70px" : "15px")};
		}
	}

	@keyframes slideOut {
		0% {
			opacity: 1;
			bottom: ${(props) => (props.isNative ? "70px" : "15px")};
		}
		100% {
			opacity: 0;
			bottom: -10px;
		}
	}

	.audio-player {
		max-width: 350px;
		border-radius: 20px;
		margin: auto;
		color: #fff;
	}

	.audio-controls {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin: 0 auto 21px;
	}

	.audio-controls .prev svg,
	.audio-controls .next svg,
	.audio-controls .replay svg {
		width: 20px;
		height: 20px;
	}

	.audio-controls .play svg,
	.audio-controls .pause svg {
		height: 24px;
		width: 24px;
	}

	.track-slider {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 16px;

		.track-time {
			font-family: "Poppins";
			font-weight: 600;
			font-size: 14px;
			width: 36px;
			min-width: 36px;
			text-align: center;
		}
	}

	button {
		background: none;
		border: none;
		cursor: pointer;

		& .playback-rate {
			outline: none;
			background: white;
			width: 23px;
			height: 23px;
			line-height: 23px;
			border-radius: 50%;
			font-size: 9px;
			color: #f42cab;
			font-weight: 500;
			font-family: "Poppins";
			text-align: center;
			padding: 0;
			margin-bottom: 1px;
		}
	}

	input[type="range"] {
		height: 5px;
		appearance: none;
		width: 100%;
		border-radius: 8px;
		background: #212121;
		transition: background 0.2s ease;
		cursor: pointer;
	}

	input[type="range"]::-webkit-slider-thumb {
		height: 16px;
		width: 16px;
		border-radius: 9999px;
		background: #fff;
		cursor: pointer;
		-webkit-appearance: none;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.4);
	}
`
