import { Skeleton } from "@mui/material";
import React, { Component } from "react";
import styled from "styled-components";

class SkeletonPostCard extends Component {
  render() {
    return (
      <StyledCard>
        <Skeleton variant='rounded' className='left-color-bar' />
        <div className='post-card-skeleton'>
          <Skeleton variant='rectangle' width={240} height={10} />
          <Skeleton variant='rectangle' width={210} height={10} />
          <Skeleton variant='rectangle' width={260} height={10} />
          <Skeleton variant='rectangle' width={220} height={10} />
          <div className='footer-card-skeleton'>
            <div className='footer-col'>
              <Skeleton variant='rectangle' width={40} height={6} />
              <Skeleton variant='rectangle' width={60} height={8} />
            </div>
            <div className='footer-col'>
              <Skeleton variant='rectangle' width={40} height={6} />
              <Skeleton variant='rectangle' width={60} height={8} />
            </div>
          </div>
        </div>
      </StyledCard>
    );
  }
}

export default SkeletonPostCard;

const StyledCard = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 160px;
  margin: 16px 0;

  .left-color-bar {
    width: 20px;
    height: 160px;
    border-radius: 10px;
    box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
  }

  .post-card-skeleton {
    background-color: #fff;
    padding: 10px 21px;
    box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
    border-radius: 10px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
  }

  .footer-card-skeleton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    width: 100%;
  }

  .footer-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
  }
`;
