import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col } from "react-grid-system";
import Strawberry from "../assets/icons/straw.svg";
import Grapes from "../assets/icons/grapes.svg";
import Apple from "../assets/icons/apple.svg";
import Rasp from "../assets/icons/rasp.svg";
import Cherry from "../assets/icons/cherry.svg";
import Pineapple from "../assets/icons/pineapple.svg";
import Lemon from "../assets/icons/lemon.svg";
import Blender from "../assets/icons/blender.svg";
import Avocado from "../assets/icons/avocado.svg";
import Peach from "../assets/icons/peach.svg";

/**
 * "conclusion",
  "background",
  "objectives",
  "figures",
  "methodology",
  "results",
  "limitations",
  "authors",
  "disclosure",
 */

const TITLE_TYPE = {
  conclusion: {
    en: "Conclusion",
    fr: "Conclusion",
    it: "Conclusione",
  },
  background: {
    en: "Background",
    fr: "Contexte",
    it: "Premessa",
  },
  objectives: {
    en: "Objectives",
    fr: "Objectifs",
    it: "Obiettivi",
  },
  figures: {
    en: "Figures",
    fr: "Images & tableaux",
    it: "Figure",
  },
  methodology: {
    en: "Methodology",
    fr: "Méthodologie",
    it: "Metodologia",
  },
  results: {
    en: "Results",
    fr: "Résultats",
    it: "Risultati",
  },
  authors: {
    en: "Authors",
    fr: "Auteurs",
    it: "Autori",
  },
  keywords: {
    en: "Keywords",
    fr: "Mots-clés",
    it: "Parole chiave",
  },
  limitations: {
    en: "Limitations",
    fr: "Limites",
    it: "Limitazioni",
  },
  disclosure: {
    en: "Disclosure Statement",
    fr: "Déclaration de liens d’intérêt",
    it: "Dichiarazione di divulgazione",
  },
};

class PostTitle extends Component {
  render() {
    const { title } = this.props;
    return (
      <TitleWrapper>
        <Row align='start' justify='start'>
          <Col xs={1}>{renderIcon(title)}</Col>
          {title === "results" && <div style={{ marginRight: "10px" }}></div>}
          {title === "authors" && <div style={{ marginRight: "5px" }}></div>}
          <Col>
            <StyledTitle>
              {
                TITLE_TYPE[title][
                  localStorage.getItem("contentLanguage") ?? "en"
                ]
              }
            </StyledTitle>
          </Col>
        </Row>
      </TitleWrapper>
    );
  }
}

export default PostTitle;

// In function of which part we are, a unique icon
export const renderIcon = (part) => {
  switch (part) {
    case "conclusion":
      return <StyledIcon src={Lemon} alt='' />;

    case "keywords":
      return <StyledIcon src={Strawberry} alt='' />;

    case "background":
      return <StyledIcon src={Grapes} alt='' />;

    case "objectives":
      return <StyledIcon src={Pineapple} alt='' />;

    case "figures":
      return <StyledIcon src={Apple} alt='' />;

    case "methodology":
      return <StyledIcon src={Rasp} alt='' />;

    case "results":
      return <StyledIcon src={Cherry} alt='' />;

    case "limitations":
      return <StyledIcon src={Avocado} alt='' />;

    case "authors":
      return <StyledIcon src={Blender} alt='' />;

    case "disclosure":
      return <StyledIcon src={Peach} alt='' />;

    default:
      break;
  }
};

const TitleWrapper = styled.div`
  margin-top: 0px;
  // margin-bottom: 10px;
`;

const StyledIcon = styled.div`
  height: 30px;
  // width: auto;
  width: 30px;
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-repeat: no-repeat;
`;

const StyledTitle = styled.h2`
  font-family: "Poppins" !important;
  font-size: 30px;
  line-height: 33px;
  color: #212121;
  margin-top: 0px;
  margin-left: 5px;
  font-weight: 900 !important;
  /* text-transform: capitalize; */
  text-align: left !important;
  font-family: "Work Sans" !important;
`;
