import React, { Component } from "react"
// import SwipeableBottomSheet from "react-swipeable-bottom-sheet";
import styled from "styled-components"
import JuisciLogo from "../../assets/core/juisci-logo.svg"
import { Spacer } from "../global"
import JuisciPattern from "../../assets/images/mobile-pattern.svg"
import disableScroll from "disable-scroll"
import { BottomSheet } from "react-spring-bottom-sheet"

class LoginSheet extends Component {
	componentDidUpdate() {
		if (this.props.showActionSheet) disableScroll.on()
		else disableScroll.off()
	}

	render() {
		const { t, showActionSheet } = this.props

		return (
			<>
				<BottomSheet
					open={showActionSheet}
					onDismiss={() =>
						setTimeout(() => this.props.closeSheet(), 100)
					}
					expandOnContentDrag
				>
					<ActionSheetWrapper>
						<div style={{ padding: 40 }}>
							<center>
								<img src={JuisciLogo} alt="" />
								<p>
									<b>{t("sheet.login.title")}</b>
									<br />
									{t("sheet.login.description")}
								</p>
								<Spacer height="30px" />
								<button
									onClick={() => {
										this.props.closeSheet()
										this.props.history.push("/signup/prev")
									}}
								>
									{t("common.register")}
								</button>
								<button
									onClick={() => {
										this.props.closeSheet()
										this.props.history.push({
											pathname: "/login",
											state: {
												previousPath:
													window.location.pathname
											}
										})
									}}
									style={{ background: "#FFC408" }}
								>
									{t("common.login")}
								</button>
							</center>
						</div>
						<img
							className="img-pattern"
							src={JuisciPattern}
							alt=""
						/>
					</ActionSheetWrapper>
				</BottomSheet>
			</>
		)
	}
}

export default LoginSheet

const ActionSheetWrapper = styled.div`
	min-height: ${window.innerHeight * 0.7}px;
	max-height: 100%;
	padding-bottom: 0px;
	z-index: 97 !important;
	margin: auto !important;
	font-family: "Poppins";
	position: relative;

	button {
		font-family: "Poppins";
		width: 100%;
		outline: none;
		border: none;
		box-shadow: none;
		align-items: center;
		padding: 16px 24px;
		background: #ecf0f5;
		border-radius: 100px;
		color: #212121;
		font-size: 16px;
		line-height: 16px;
		font-weight: 700;
		margin: 5px 0;
	}

	img {
		margin-bottom: 10px;
	}

	img.img-pattern {
		object-fit: cover;
		margin: 0;
		width: 100%;
		margin-bottom: -5px;
	}

	p {
		font-size: 16px;
		line-height: 22.4px;
		text-align: center;
	}

	b {
		font-weight: 700;
	}
`
