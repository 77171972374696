import styled from "styled-components"
import { Link } from "react-router-dom"
import CustomIcon from "./CustomIcon"
import { Col, Row } from "react-grid-system"
import { CustomInput } from "./CustomInput"
import i18n from "../config/i18n"
import { t } from "i18next"
import { pickGradient } from "../tools/utils"

export const Container = styled.div`
	margin: 0 auto;
	padding: 0 20px;
`

export const Spacer = styled.div`
	height: ${(props) => props.height ?? "30px"};
	min-height: ${(props) => props.height ?? "30px"};
`

export const Section = styled.section`
	padding: 80px 0;
	overflow: hidden;
	background-color: ${(props) => {
		switch (props.accent) {
			case "secondary":
				return props.theme.color.white.dark
			case "main":
				return props.theme.color.primary
			default:
				return "white"
		}
	}};

	@media (max-width: ${(props) => props.theme.screen.md}) {
		padding: 80px 0;
	}

	${(props) =>
		props.accent &&
		`background-color: ${
			props.accent === "secondary"
				? props.theme.color.white.dark
				: props.theme.color.primary
		}`};
`

export const LogoBrand = styled.img`
	height: 30px;
`

export const Divider = styled.div`
	box-sizing: border-box;
	width: 100%;
	padding: 0;
	color: #000000d9;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	border-top: ${(props) => (props.text ? "0px" : "1px")} solid
		rgba(0, 0, 0, 0.06);
	margin: ${(props) => props.margin ?? "30px 0"};

	&.divider {
		display: flex;
		align-items: center;
		text-align: center;
		width: 100%;
		font-family: "Poppins" !important;
		font-weight: 500;
		font-size: 16px;
		color: #4c5861;
	}

	&.divider::after,
	&.divider::before {
		content: "";
		border-top: 1px solid #4c5861;
		flex: 1;
	}

	&.divider:not(:empty)::before {
		margin-right: 5px;
	}

	&.divider:not(:empty)::after {
		margin-left: 5px;
	}
`

export const OldCustomInput = styled.input`
	outline: none;
	box-shadow: none;
	border: ${(props) =>
		props.filled || props.value
			? "1.5px solid #FFC408"
			: "1.5px solid #fff"};
	font-size: 16px;
	font-weight: 500 !important;
	font-family: "Poppins" !important;
	background: #fff;
	border-radius: 5px;
	padding: 18px 20px;
	width: 88%;
	margin-bottom: 15px;

	::placeholder {
		color: #81939c;
	}
`

export const SearchInput = styled.input`
	outline: none;
	box-shadow: none;
	border: none;
	font-size: 16px;
	font-weight: 600 !important;
	font-family: "Poppins" !important;
	background: #fff;
	border-radius: 50px;
	padding: 10px 20px;
	width: 85%;
	margin-bottom: 15px;
	::placeholder {
		color: #90a4ae;
	}
`

export const CustomTextarea = styled.textarea`
	outline: none;
	box-shadow: none;
	border: none;
	font-size: 14px;
	line-height: 160%;
	font-weight: normal;
	font-family: "Poppins";
	background: #fff;
	border-radius: 10px;
	padding: 22px 20px;
	width: 85%;
	margin-bottom: 15px;
	resize: none;

	::placeholder {
		color: #90a4ae;
	}
`

export const SiderTitle = styled.h3`
	font-size: 35px;
	line-height: 100%;
	color: black;
	margin: 20px;
	font-family: "Gelion Semibold";
`

export const ContentTitle = styled.h1`
	font-size: 45px;
	line-height: 45px;
	color: black;
	font-family: HKGrotesk Semibold;
`

export const ContentSubTitle = styled.h4`
	font-size: 18px;
	line-height: 21.59px;
	color: #333333;
	font-family: Gelion Light;
`

export const CustomButton = styled.button`
	box-shadow: none;
	outline: none;
	border: 1px solid transparent;
	box-sizing: border-box;
	background-color: #ffc408;
	border-radius: 100px;
	padding: 16px 40px;
	font-size: 16px;
	font-weight: 700;
	color: #212121;
	font-family: "Poppins";
	width: 100%;
	cursor: pointer;
	transition: all ease-out 0.2s;

	&.black {
		color: #fff;
		background: #212121;
		border-color: #212121;
	}

	&.secondary {
		background-color: #fff;
		color: #212121;
		border: 1px solid #d2dce2;
	}

	&.disabled {
		background-color: #d2dce2;
		border-color: #d2dce2;
		color: #81939c;
	}

	&.pink {
		background-color: #ce0868;
		border-color: #ce0868;
		color: #fff;
	}

	&.purple-gradient {
		color: #fff;
		background: linear-gradient(180deg, #ff699c 0%, #fe5763 100%);
	}

	&.danger {
		background: #ff4300;
		border-color: #ff4300;
		color: #fff;
	}

	&.small {
		padding: 12px 24px;
	}

	&.icon {
		display: flex;
		gap: 8px;
		align-items: center;
		justify-content: center;
	}

	&.white {
		background: #fff;
		border: 1px solid #212121;
	}

	&.no-border {
		border: none;
	}
`

export const DisabledCustomButton = styled(CustomButton)`
	color: #81939c;
	background: #d2dce2;
	cursor: not-allowed;
`

export const CustomButtonAlt = styled.button`
	box-shadow: none;
	filter: drop-shadow(1px 2px 8px rgba(0, 0, 0, 0.05));
	outline: none;
	border: 1px solid lightgray;
	box-sizing: border-box;
	background: white;
	border-radius: 3px;
	padding: 8px 40px;
	font-size: 16px;
	color: #000;
	font-family: "HKGrotesk Semibold";
	cursor: pointer;
	transition: all ease-in-out 0.2s;
	&:hover {
		// transform: scale(1.02);
		border-color: #5f27cd;
		color: #5f27cd;
	}
`

export const SaveButton = styled(CustomButton)`
	font-size: 12px;
	text-transform: capitalize;
	padding: 7px;
	text-align: center;
	background: ${(props) => (!props.disabled ? "#FFC408" : "#D2DCE2")};
	color: ${(props) => (!props.disabled ? "#212121" : "#81939C")};
	max-width: 70px;
`

export const CustomLabelInput = styled.span`
	font-size: 18px;
	font-family: "HKGrotesk Regular";
	color: #777;
	margin-right: 0;
`

export const CustomLink = styled(Link)`
	color: #5f27cd;
	transition: all ease-in-out 0.3s;
	&:hover {
		color: #eb4d4b;
	}
`

export const CustomContainer = styled.div`
	min-height: 83vh;
	max-height: 100%;
	background: #ecf0f5;
	padding: 80px 40px;
`

export const Avatar = styled.img`
	height: ${(props) => props.height ?? "44px"};
	width: ${(props) => props.height ?? "44px"};
	background: lightgray;
	border-radius: 50%;
	border: 3px solid white;
	object-fit: cover;
	box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.05);
`

export const ProfileHeader = styled.div`
	padding: 20px 21px;
	background: #ecf0f5;
	color: #212121;
	font-family: "Poppins";

	h3 {
		line-height: 17px;
		font-size: 17px;
		font-weight: 300;
		margin: 10px 0;
	}

	h3 b {
		font-weight: 700;
	}

	h4 {
		line-height: 15px;
		font-size: 11px;
		font-weight: 300;
		margin: 0;
		margin-right: 20px;
	}

	h4 b {
		font-weight: 600;
		font-size: 14px;
	}

	h5 {
		margin: 0;
		text-transform: capitalize;
		font-size: 11px;
		line-height: 15.4px;
		font-weight: 300;
	}

	h5 span {
		text-transform: uppercase;
	}

	p {
		font-size: 14px;
		line-height: 22.4px;
		font-weight: 400;
		margin: 0;
	}
`

export const ProfileContent = styled.div`
	background: #f7f8fc;
	color: #212121;
	padding: 30px 20px;

	h3 {
		font-size: 21px;
		line-height: 25.2px;
		font-weight: 900;
		margin: 0;
		font-family: "Work Sans" !important;
		text-transform: capitalize;
	}

	h4 {
		font-size: 16px;
		line-height: 20.8px;
		font-weight: 400;
		margin-top: 5px;
	}

	h4 b {
		font-weight: 600;
	}
`

export const MessageInput = (props) => {
	return (
		<div style={{ position: "relative" }}>
			<div
				style={{
					position: "absolute",
					left: 15,
					top: 7,
					transform: "scale(0.7)"
				}}
			>
				<CustomIcon iconName="search" color="#90A4AE" />
			</div>

			<CustomInput
				onInput={props.onInput}
				style={{ paddingLeft: 40, margin: 0 }}
				placeholder={props.placeholder ?? "Search..."}
			/>
		</div>
	)
}

export const UserButton = (props) => {
	return (
		<>
			<UserWrapper
				style={{
					display:
						!props.user?.firstname && !props.user?.lastname
							? "none"
							: "block",
					...props.style
				}}
			>
				<Spacer height="20px" />

				<div className="user-row">
					<div className="user-infos" onClick={props.onClick}>
						<Avatar
							style={{
								background: pickGradient(
									props.user?.firstname?.length % 5
								)
							}}
							src={props.user?.image?.url}
							height="30px"
						/>
						<div>
							<p className="fullname">
								{props.user?.firstname +
									" " +
									props.user?.lastname}
							</p>
							<span>
								{!!props.user?.profession?.translations
									? props.user.profession?.translations[
											i18n.resolvedLanguage
									  ]
									: t("common.other")}
							</span>
						</div>
					</div>
					<div className="user-button">
						{!props.noFollow && (
							<CustomButton
								style={{
									display: "table",
									width: "100%",
									padding: "5px 16px",
									width: "auto",
									fontSize: 15,
									float: "right",
									background:
										(props.isFollowed || props.isSent) &&
										"#fff3ce",
									color:
										(props.isFollowed || props.isSent) &&
										"#ffc408"
								}}
								onClick={props.handleFollow ?? props.onClick}
							>
								{!!props.btnText
									? props.btnText
									: props.isFollowed
									? t("common.unfollow")
									: props.isSent
									? t("common.sent")
									: t("common.follow")}
							</CustomButton>
						)}
					</div>
				</div>
				<Spacer height="20px" />
				<Divider style={{ margin: 0 }} />
			</UserWrapper>
		</>
	)
}

export const JournalButton = (props) => {
	return (
		<>
			<UserWrapper>
				<Spacer height="20px" />
				<Row style={{ margin: 0 }} justify="between" align="center">
					<Col xs={8} onClick={props.onClick}>
						<Row
							style={{ margin: 0 }}
							justify="start"
							align="center"
						>
							<Col xs={3} style={{ padding: 0 }}>
								{props.journal?.image?.length > 0 ? (
									<img
										src={props.journal?.image}
										alt=""
										style={{
											height: "64px",
											width: "64px",
											objectFit: "contain",
											margin: 0,
											padding: 0
										}}
									/>
								) : (
									<div
										style={{
											height: 64,
											width: 64,
											borderRadius: 128,
											border: "2px solid white",
											background: pickGradient(
												props.index % 5
											)
										}}
									></div>
								)}
							</Col>
							<Col xs={9}>
								<p
									style={{
										fontSize: 14,
										fontWeight: 600,
										lineHeight: "21px",
										color: "#212121",
										paddingLeft: 5
									}}
								>
									{props.journal?.name}
								</p>
							</Col>
						</Row>
					</Col>
					<Col xs={4}>
						<CustomButton
							style={{
								display: "table",
								padding: "5px 16px",
								width: "auto",
								fontSize: 14,
								float: "right",
								background: props.isFollowed && "#fff3ce",
								color: props.isFollowed && "#ffc408"
							}}
							onClick={props.handleFollow}
						>
							{props.isFollowed
								? t("common.unfollow")
								: t("common.follow")}
						</CustomButton>
					</Col>
				</Row>
				<Spacer height="20px" />
				<Divider style={{ margin: 0 }} />
			</UserWrapper>
		</>
	)
}

export const UserWrapper = styled.div`
	background: #fff;
	margin: 0;
	padding: 0px;
	border: none;

	.user-row {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.user-infos {
			width: 100%;
			display: flex;
			align-items: center;
			gap: 10px;
		}
	}

	.newMessage {
		background: #000;
		padding: 0 22px;
	}

	p {
		margin: 0;
		margin-left: 10px;
		color: #212121;
		font-weight: 500;
		font-family: "Poppins";
		font-size: 14px;
		line-height: 21px;

		&.fullname {
			margin-left: 0px;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
		}
	}

	span {
		margin: 0;
		color: #212121;
		font-weight: 300;
		font-size: 10px;
		line-height: 15px;
		font-family: "Poppins";
		text-transform: uppercase;
	}
`

export const SpecialtyTag = (props) => {
	let backgroundColor = "#FFE5F0"
	let color = "#E6005A"

	switch (props.children) {
		case "COVID-19":
			backgroundColor = "#B7E3E4"
			color = "#076668"
			break

		case "Cardiology":
			backgroundColor = "#FCF8D1"
			color = "#8F8621"
			break

		case "Endocrinology":
			backgroundColor = "#FFF3E5"
			color = "#E67A00"
			break

		case "Gastroenterology":
			backgroundColor = "#F2E6FE"
			color = "#5705AD"
			break

		case "General Medicine":
			backgroundColor = "#E6EEFE"
			color = "#0550E0"
			break

		case "Geriatrics":
			backgroundColor = "#E9FDE7"
			color = "#19A50E"
			break

		case "Gyn & Obs":
			backgroundColor = "#EBF9F4"
			color = "#2A8969"
			break

		case "Immunology":
			backgroundColor = "#FAFDE7"
			color = "#6E7C0F"
			break

		case "Nephrology":
			backgroundColor = "#F8E7FD"
			color = "#830BA7"
			break

		case "Neurology":
			backgroundColor = "#ECF8F9"
			color = "#2B8187"
			break

		case "Oncology":
			backgroundColor = "#FEF9E7"
			color = "#806A0F"
			break

		case "Oncology":
			backgroundColor = "#E6FDFF"
			color = "#02A6B0"
			break

		case "Orthopedics":
			backgroundColor = "#EDEDF8"
			color = "#4045A5"
			break

		case "Pediatrics":
			backgroundColor = "#F7EEF0"
			color = "#9C4A5D"
			break

		case "Pulmonology":
			backgroundColor = "#F2F9EC"
			color = "#5D902B"
			break

		case "Rheumatology":
			backgroundColor = "#FFF0F6"
			color = "#CE0868"
			break

		case "Urology":
			backgroundColor = "#FEF7E6"
			color = "#E09906"
			break

		default:
			backgroundColor = "#FFE5F0"
			color = "#E6005A"
			break
	}

	return (
		<div
			style={{
				margin: "8px 5px 0 0",
				display: props.children ? "inline-block" : "none"
			}}
		>
			<span
				style={{
					fontFamily: "Inter",
					backgroundColor: "#FFF3CE",
					color: "#FF8800",
					padding: "2px 8px",
					borderRadius: "50px",
					fontSize: "11px",
					fontWeight: 400,
					textAlign: "left"
				}}
				className="--specialty-tag"
			>
				{props.children}
			</span>
		</div>
	)
}

export const ShareText = (title, journal_name) =>
	i18n.t("sharing.shareText", { title, journal_name })

export const ShareProfileText = (fullname) =>
	i18n.t("sharing.shareProfileText", { fullname })

export const PolicyText = styled.div`
	color: #212121;
	font-family: "Poppins";

	& :nth-child(2) {
		margin-top: 30px;
	}

	h1 {
		/* margin-top: 0; */
	}

	h2 {
		font-size: 24px;
		font-weight: 700;
		margin-top: 60px;
	}

	ol {
		padding-left: 20px;
	}

	ul {
		padding-left: 15px;
	}

	li {
		font-size: 13px;
		font-weight: 300;
		margin-bottom: 15px;
	}

	b {
		font-weight: 700;
	}

	p {
		font-size: 14px;
		font-weight: 300;
	}

	a {
		color: #ff8800;
		font-weight: 500;
	}

	th,
	td {
		border: 1px solid #212121;
		font-size: 12px;
	}

	td {
		padding: 12px;
		box-sizing: border-box;
	}

	th {
		padding: 8px 0;
	}
`

export const MetaTitle = styled.p`
	font-family: "Inter";
	color: #ce0868;
	font-weight: 600;
	font-size: 12px;
	margin: 5px 0 0;
	text-transform: uppercase;
	text-align: left;
	margin-bottom: 8px;
	letter-spacing: 0.06em;
`

export const Flex = styled.div`
	display: flex;
	flex-direction: ${(props) => props.direction ?? "row"};
	align-items: ${(props) => props.align ?? "center"};
	justify-content: ${(props) => props.justify ?? "start"};
	gap: ${(props) => props.gap ?? 8}px;
`
