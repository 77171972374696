import React, { Component } from "react"
import {
	CustomButton,
	CustomContainer,
	Divider,
	PolicyText,
	Spacer
} from "../../components/global"
import SettingsNavbar from "../../components/SettingsNavbar"
import CustomNavBar from "../../components/CustomNavbar"
import disableScroll from "disable-scroll"
import { iosSetBackgroundColor, isNativeIOS } from "../../tools/ios"
import ReactMarkdown from "react-markdown"
import ToUMarkdown from "../../assets/markdowns/ToU.md"
import BiocodexToUMd from "../../assets/markdowns/biocodex-ToU.md"
import TakedaToUMd from "../../assets/markdowns/takeda-ToU.md"
import TakedaToUMdFr from "../../assets/markdowns/takeda-ToU-fr.md"
import { withRouter } from "react-router-dom"
import Loader from "../../components/Loader"
import i18n from "../../config/i18n"
import { SupportedLanguagesEnum } from "../../interfaces"
import { t } from "i18next"

class TermsPage extends Component {
	state = {
		ToU: ""
	}

	componentDidMount() {
		disableScroll.off()
		window.scrollTo(0, 0)
		const query = new URLSearchParams(window.location.search)

		if (isNativeIOS) {
			iosSetBackgroundColor("#FFFFFF")
		}

		const interfaceLanguage =
			localStorage.getItem("i18nextLng") || i18n.resolvedLanguage

		let mdFile

		switch (query.get("version")) {
			case "biocodex":
				mdFile = BiocodexToUMd
				break
			case "takeda":
				mdFile =
					interfaceLanguage === SupportedLanguagesEnum.FR
						? TakedaToUMdFr
						: TakedaToUMd
				break
			default:
				mdFile = ToUMarkdown
		}

		fetch(mdFile)
			.then((response) => response.text())
			.then((text) => {
				this.setState({ ToU: text })
			})
			.catch((error) => {
				console.error("Err fetch terms", error.message)
			})
	}

	render() {
		const isPush = this.props.location?.state?.isPush
		const hideTitle = this.props.location?.state?.hideTitle
		const query = new URLSearchParams(window.location.search)

		if (!this.state.ToU) return <Loader />

		return (
			<>
				{isPush ? (
					<>
						<SettingsNavbar
							pageTitle={
								hideTitle ? "" : t("settings.editSettings")
							}
							{...this.props}
						/>
						<Spacer height="60px" />
					</>
				) : (
					<CustomNavBar />
				)}
				<CustomContainer
					style={{
						padding: isPush
							? "10px 30px 100px 30px"
							: "80px 30px 100px 30px",
						background: "#F7F8FC"
					}}
				>
					<PolicyText>
						<ReactMarkdown children={this.state.ToU} />
					</PolicyText>
					<Divider />

					<CustomButton
						onClick={() =>
							this.props.history.replace({
								pathname: "/policy",
								search: query.get("version")
									? `?version=${query.get("version")}`
									: "",
								state: { isPush: true }
							})
						}
					>
						{t("content.terms.seePrivacyPolicy")}
					</CustomButton>
				</CustomContainer>
			</>
		)
	}
}

export default withRouter(TermsPage)
