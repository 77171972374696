import { createContext, FunctionComponent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { io, Socket } from "socket.io-client";
import { getFirebaseToken } from "../services/firebase";

// GLOBAL ENABLE SWITCH
const ENABLE_SOCKET = true;

export interface IImage {
  path: string;
  url: string;
  legend?: string;
}

export interface IUser {
  uid: string;
  email: string;
  city: string;
  country: string;
  employer: string;
  firstname: string;
  lastname: string;
  image: IImage;
  main_specialty: {
    uid: string;
    translations: any;
  };
  medical_subspecialty: string;
  profession: string;
  username: string;
  meta: {
    creationDate: Date;
    lastModified: Date;
  };
}

export interface IMessage {
  message: string;
  sender: IUser;
  meta: {
    creationDate: Date;
  };
}

export interface IConversation {
  _id: string;
  users: IUser[];
  messages: IMessage[];
  readStatus: {
    readDate: Date;
    user: IUser;
  }[];
  deleteStatus: {
    deleteDate: Date;
    user: IUser;
  }[];
  meta: {
    creationDate: Date;
    lastModified: Date;
  };
}

export interface IConversationEdit {
  _id: string;
  users: IUser[];
  messages: IMessage[];
  readStatus: {
    readDate: Date;
    user: IUser;
  }[];
  deleteStatus: {
    deleteDate: Date;
    user: IUser;
  }[];
  unreadMessages: number;
  isDeleted: boolean;
  meta: {
    creationDate: Date;
    lastModified: Date;
  };
}

export type SocketContextProps = {
  connected: boolean;
  socket: Socket;
  conversationsList: IConversation[];
  unreadConversations: number;
  sendMessage: (conversationId: string, message: string) => Promise<void>;
  createConversation: (users: string[]) => Promise<void>;
  refreshConversations: () => void;
  markConversationAsRead: (conversationId: string) => void;
  markConversationAsDeleted: (conversationId: string) => void;
  connectSocket: () => void;
  disconnectSocket: () => void;
  newConversation: () => void;
};

export const SocketContext = createContext<SocketContextProps>(
  {} as SocketContextProps
);

export const SocketProvider: FunctionComponent = ({ children }: any) => {
  let location = useLocation();

  const [connect, setConnect] = useState(false);
  const [connected, setConnected] = useState(false);
  const [socket, setSocket] = useState(null as unknown as Socket);
  const [conversationsList, setConversationsList] = useState<
    IConversationEdit[]
  >([]);
  const [unreadConversations, setUnreadConversations] = useState(0);

  const socketUrl = process.env.REACT_APP_SOCKET_URL || "";

  useEffect(() => {
    if (location.pathname.startsWith("/profile")) {
      setConnect(true);
    } else {
      setConnect(false);
    }
    return () => {
      setConnect(false);
    };
  }, [location]);

  const connectSocket = async () => {
    setConnect(true);
  };

  useEffect(() => {
    const setupSocketio = async () => {
      if (socket) {
        socket.disconnect();
      }

      const token = await getFirebaseToken();

      const newSocket = io(socketUrl, {
        query: { token },
        transports: ["websocket", "polling"],
      });
      setSocket(newSocket);

      newSocket.on("connect", () => {
        setConnected(true);
      });

      newSocket.on("disconnect", () => {
        setConnected(false);
      });

      const messageListener = (payload: {
        message?: IMessage;
        conversation: IConversation;
      }) => {
        setConversationsList((conversationsList) => {
          const convIndex = conversationsList.findIndex(
            (c) => c._id === payload.conversation._id
          );

          if (convIndex !== -1) {
            const convs = conversationsList;
            convs.splice(
              convIndex,
              1,
              handleConversation(payload.conversation)
            );
            localStorage.setItem("conversationsList", JSON.stringify(convs));
            return convs;
          }

          localStorage.setItem(
            "conversationsList",
            JSON.stringify(conversationsList)
          );
          return conversationsList;
        });
      };

      newSocket.on("newConversationMessage", messageListener);

      newSocket.on(
        "conversationReadStatusUpdate",
        (conversation: IConversation) => {
          messageListener({ conversation });
        }
      );
      newSocket.on(
        "conversationDeleteStatusUpdate",
        (conversation: IConversation) => {
          messageListener({ conversation });
        }
      );

      const conversationListener = (conversation: IConversation) => {
        setConversationsList((prevConversationsList) => [
          ...prevConversationsList,
          handleConversation(conversation),
        ]);
      };
      newSocket.on("newConversation", conversationListener);

      newSocket.on("allConversations", (list: IConversation[]) => {
        setConversationsList(list.map((conv) => handleConversation(conv)));
      });
    };

    if (connect && ENABLE_SOCKET) {
      setupSocketio();
    } else {
      socket?.disconnect();
      setConnected(false);
    }

    return () => {
      socket?.disconnect();
      setConnected(false);
    };
  }, [connect, socketUrl]);

  useEffect(() => {
    const unread = conversationsList.filter(
      (c) => c.unreadMessages !== 0 && !c.isDeleted
    ).length;
    setUnreadConversations(unread);
  }, [conversationsList]);

  const disconnectSocket = () => {
    setConnect(false);
  };

  const newConversation = () => {
    if (socket?.connected)
      socket.on("newConversation", (conversation) => {
        window.location.href = `/profile/messages/conversation/${conversation?._id}`;
      });
  };

  const handleConversation = (
    conversation: IConversation
  ): IConversationEdit => {
    const newConversation: IConversationEdit = {
      ...conversation,
      unreadMessages: 0,
      isDeleted: false,
    };
    const myUid = localStorage.getItem("my_uid");

    const readStatus = conversation.readStatus.find(
      (rs) => rs.user?.uid === myUid
    );

    const deleteStatus = conversation.deleteStatus.find(
      (ds) => ds.user?.uid === myUid
    );

    if (readStatus) {
      newConversation.unreadMessages = conversation.messages.filter(
        (m) =>
          new Date(m.meta.creationDate).getTime() >
          new Date(readStatus.readDate).getTime()
      ).length;
    }

    if (deleteStatus) {
      newConversation.isDeleted = true;
    }

    return newConversation;
  };

  const refreshConversations = () => {
    if (socket?.connected) {
      socket.emit("getConversations");
    }
  };

  // const readMessage = async (conversationId: string) => {
  //   if (socket?.connected) {
  //     socket.emit("updateConversationReadStatus", {
  //       conversationId,
  //     });

  //     socket.emit("getConversations");

  //     socket.on("allConversations", () =>
  //       localStorage.setItem(
  //         "conversationsList",
  //         JSON.stringify(conversationsList)
  //       )
  //     );
  //   }
  // };

  const markConversationAsRead = (conversationId: string) => {
    if (socket?.connected) {
      socket.emit("updateConversationReadStatus", {
        conversationId,
      });
    }
  };

  const markConversationAsDeleted = (conversationId: string) => {
    if (socket?.connected) {
      socket.emit("updateConversationDeleteStatus", {
        conversationId,
      });
    }
  };

  const sendMessage = async (conversationId: string, message: string) => {
    if (socket?.connected) {
      socket.emit("message", {
        message: message,
        conversationId,
      });
    }
  };

  const createConversation = async (users: string[]) => {
    if (socket?.connected)
      socket.emit(
        "createConversation",
        {
          users,
        },
        // () => console.log("create", users)
      );
  };

  return (
    <SocketContext.Provider
      value={{
        connected,
        socket,
        conversationsList,
        unreadConversations,
        sendMessage,
        createConversation,
        refreshConversations,
        markConversationAsRead,
        markConversationAsDeleted,
        connectSocket,
        disconnectSocket,
        newConversation,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
