// Need to use the React-specific entry point to import createApi
import { createApi } from "@reduxjs/toolkit/query/react";
import { IMeta } from "../../interfaces";
import { IArticle } from "../../interfaces/article.interface";
import { IContent } from "../../interfaces/content.interface";
import { IFollowPending } from "../../interfaces/follow.interface";
import { IPlaylist } from "../../interfaces/playlist.interface";
import { IUser } from "../../interfaces/user.interface";
import { baseQuery } from "../base-query";

export const userApi = createApi({
  reducerPath: "userApi",
  refetchOnMountOrArgChange: true,
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getUser: builder.query<IUser, any>({
      query: (params?) => {
        return "/user/me";
      },
    }),
    getSaves: builder.query<string[], any>({
      query: () => {
        return "/playlist/me/saved/articles";
      },
    }),

    getPlaylists: builder.query<
      {
        docs: IPlaylist[];
        meta: {
          total: number;
          limit: number;
          offset: number;
        };
      },
      any
    >({
      query: () => {
        return "/playlist/me";
      },
    }),

    getFollowers: builder.query<IUser[], any>({
      query: () => {
        return "/user/me/followers";
      },
    }),
    getPendingFollowers: builder.query<IFollowPending[], any>({
      query: () => {
        return "/user/me/followers/pending";
      },
    }),

    getFollowing: builder.query<IUser[], any>({
      query: () => {
        return "/user/me/following";
      },
    }),

    getPendingFollowing: builder.query<IFollowPending[], any>({
      query: () => {
        return "/user/me/following/pending";
      },
    }),

    patchUserContent: builder.query<IContent[], any>({
      query: (body) => ({
        url: "/user/me/content",
        params: { ...body },
        method: "PATCH",
      }),
    }),
  }),
});
