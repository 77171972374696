import { Component } from "react"
import { Row, Col } from "react-grid-system"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { BottomSheet } from "react-spring-bottom-sheet"
import styled from "styled-components"
import CustomIcon from "../../components/CustomIcon"
import { SheetWrapper } from "../../components/CustomSheet"
import customToast from "../../components/CustomToast"
import { Spacer } from "../../components/global"
import Loader from "../../components/Loader"
import SettingsNavbar from "../../components/SettingsNavbar"
import { t } from "i18next"
import i18n from "../../config/i18n"
import { userApi } from "../../redux/user/user.service"
import {
	getPlaylist,
	savePlaylist,
	sharePlaylist,
	unsavePlaylist
} from "../../services/playlists.services"
import {
	iosGenerateHaptic,
	iosPlaySound,
	iosSetBackgroundColor,
	isNativeIOS
} from "../../tools/ios"
import { kycMiddleware } from "../../tools/utils"
import { store } from "../../redux"
import { setUser } from "../../redux/user/user.reducer"
import {
	HapticEffect,
	ItemDataEventListName,
	ItemDataVariant,
	ItemsDataEvent,
	SoundEffect
} from "../../interfaces"
import ShareSheet from "../../components/ShareSheet"
import { getFirebaseToken } from "../../services/firebase"
import {
	convertContentToItemData,
	gtmItemsData
} from "../../tools/reactgaEvents"
import PreviewCard from "../../components/PreviewCard"

class PublicPlaylist extends Component {
	state = {
		playlist: null,
		scrolled: false,
		showSheet: false
	}

	async componentDidMount() {
		this.loadPage()
	}

	loadPage() {
		window.scrollTo(0, 0)

		document.querySelector("html").style.background = "#f7f8fc"
		window.addEventListener("scroll", this.listenScroll)

		if (isNativeIOS) iosSetBackgroundColor("#FAFBFC")

		getPlaylist(this.props.match.params.id)
			.then((playlist) => {
				this.setState({ playlist })
				gtmItemsData(
					ItemsDataEvent.VIEW_ITEM_LIST,
					convertContentToItemData(
						playlist.playlist,
						ItemDataEventListName.PLAYLIST_PAGE,
						ItemDataVariant.ARTICLE
					)
				)
			})
			.catch((error) => {
				console.error("Err PublicPlaylist getPlaylist", error.message)
				customToast(t("toast.error.global"))
				this.props.history.replace("/home")
			})
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.listenScroll)
		if (isNativeIOS) iosSetBackgroundColor("#FFFFFF")
	}

	listenScroll = () => {
		if (window.scrollY > 100)
			this.setState(
				{ scrolled: true },
				() => isNativeIOS && iosSetBackgroundColor("#FFFFFF")
			)
		else this.setState({ scrolled: false })
	}

	async handleSharePlaylist() {
		const my_uid = this.props.user?.user?.uid
		const { title, _id } = this.state.playlist

		const isAuthentified = await getFirebaseToken()

		if (!!isAuthentified && !this.props.user.user.profession)
			kycMiddleware(this, t("toast.error.notOnboarded.sharePlaylist"))
		else if (navigator.share) {
			navigator
				.share({
					title: document.title,
					text: i18n.language.includes("fr")
						? `Découvrez la playlist ${title} sur Juisci !`
						: `Discover the playlist ${title} on Juisci now!`,
					url: `/playlist/public/${_id}?shared=${my_uid ?? "null"}`
				})
				.then(() => {
					iosGenerateHaptic(HapticEffect.SUCCESS)
					iosPlaySound(SoundEffect.SHARE)
					customToast(t("toast.success.sharedPlaylist"), "success")
					sharePlaylist(_id)
				})
		} else {
			customToast(t("toast.error.browser"))
		}
	}

	async handleSavePlaylist() {
		const playlistSaved =
			this.props.user.user &&
			this.props.user.user?.playlists.find(
				(el) => el._id === this.state.playlist._id
			)

		const isAuthentified = await getFirebaseToken()

		if (!!isAuthentified && !this.props.user.user.profession)
			kycMiddleware(this, t("toast.error.notOnboarded.savePlaylist"))
		else if (!!isAuthentified) {
			if (!playlistSaved) {
				customToast(t("toast.success.savedPlaylist"))
				iosGenerateHaptic(HapticEffect.SUCCESS)
				iosPlaySound(SoundEffect.SAVE)

				store.dispatch(
					setUser({
						...this.props.user.user,
						playlists: [
							...this.props.user.user.playlists,
							this.state?.playlist
						]
					})
				)
				savePlaylist(this.state?.playlist._id).then(() =>
					this.props.getUser()
				)
			} else {
				customToast(t("toast.success.unsavedPlaylist"))
				store.dispatch(
					setUser({
						...this.props.user.user,
						playlists: [
							...this.props.user.user.playlists.filter(
								(el) => el._id !== this.state?.playlist._id
							)
						]
					})
				)
				unsavePlaylist(this.state?.playlist._id).then(() =>
					this.props.getUser()
				)
			}
		} else {
			this.props.history.push("/login")
		}
	}

	render() {
		const { playlist, scrolled, showSheet } = this.state
		const contentLanguage = localStorage.getItem("contentLanguage")
		const playlistSaved =
			this.props.user.user &&
			this.props.user.user?.playlists.find(
				(el) => el._id === this.state.playlist?._id
			)

		return (
			<PageWrapper>
				{playlist ? (
					<>
						<SettingsNavbar
							{...this.props}
							goBackPlaylist={
								this.props.history.location.search === "?shared"
							}
							goBack={() =>
								this.props.location.key
									? this.props.history.goBack()
									: this.props.history.replace(`/home`)
							}
							shareBtn
							shareFunction={() => {
								this.setState({ showSheet: true })
							}}
							pageTitle={
								<span className="page-title">
									{t("content.playlists.playlistBy", {
										lng: contentLanguage
									})}{" "}
									{scrolled && (
										<b>{playlist?.company?.name}</b>
									)}
								</span>
							}
							transparent={!scrolled}
						/>

						<ShareSheet type="playlist" />
						<div className="page-header">
							<Link
								to={{
									pathname:
										"/company/" + playlist?.company?._id,
									state: {
										company: playlist?.company
									}
								}}
							>
								{!!playlist.images && (
									<img
										src={
											playlist.images[
												playlist.images.length - 1
											]?.url
										}
										alt=""
									/>
								)}
								<div className="company_link">
									{(contentLanguage === "fr"
										? "Découvrir"
										: "Discover") +
										" " +
										playlist.company.name}
								</div>
								<h5>{playlist.title}</h5>
							</Link>
						</div>
						<div className="container">
							{playlist.playlist?.map((article, index) => (
								<Link
									key={article?._id + "plsp" + index}
									to={{
										pathname: "/post/" + article?.slug,
										state: {
											sponsorised: true,
											playlist: playlist
										}
									}}
								>
									<PreviewCard
										key={article._id + "--profile-card-key"}
										disabled={true}
										content={article}
										itemListName={
											ItemDataEventListName.PLAYLIST_PAGE
										}
									/>
								</Link>
							))}
						</div>
					</>
				) : (
					<Loader />
				)}
				<BottomSheet
					expandOnContentDrag
					open={showSheet}
					onDismiss={() =>
						setTimeout(
							() => this.setState({ showSheet: false }),
							100
						)
					}
				>
					<SheetWrapper style={{ height: window.innerHeight * 0.3 }}>
						<Spacer height="50px" />
						<Row
							style={{ margin: 0 }}
							gutterWidth={45}
							onClick={() => this.handleSavePlaylist()}
						>
							<Col xs={1}>
								<Spacer height="5px" />
								<CustomIcon
									iconName={
										playlistSaved ? "save.fill" : "save"
									}
									color="#212121"
									style={{ marginLeft: 5 }}
								/>
							</Col>
							<Col
								xs={10}
								style={{
									color: playlistSaved ? "#FF699C" : "#212121"
								}}
							>
								<p
									style={{
										fontSize: "17px",
										fontWeight: 600,
										margin: 0
									}}
								>
									{playlistSaved
										? this.props.t(
												"sheet.playlist.unsave.title"
										  )
										: this.props.t(
												"sheet.playlist.save.title"
										  )}
								</p>
								<p
									style={{
										fontSize: "15px",
										fontWeight: 400,
										width: "100%",
										margin: 0
									}}
								>
									{playlistSaved
										? this.props.t(
												"sheet.playlist.unsave.subtitle"
										  )
										: this.props.t(
												"sheet.playlist.unsave.title"
										  )}
								</p>
							</Col>
						</Row>
						<Spacer height="30px" />
						{/* SHARE */}
						<Row
							style={{ margin: 0 }}
							gutterWidth={45}
							onClick={this.handleSharePlaylist.bind(this)}
						>
							<Col xs={1}>
								<CustomIcon
									iconName="share-alt"
									scale={0.8}
									color="#212121"
								/>
							</Col>
							<Col xs={10}>
								<p
									style={{
										fontSize: "17px",
										fontWeight: 600,
										margin: 0,
										marginTop: -5
									}}
								>
									{this.props.t("sheet.playlist.share.title")}
								</p>
								<p
									style={{
										fontSize: "15px",
										fontWeight: 400,
										margin: 0
									}}
								>
									{t("sheet.playlist.share.subtitle")}
								</p>
							</Col>
						</Row>
					</SheetWrapper>
				</BottomSheet>
			</PageWrapper>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(PublicPlaylist))

const PageWrapper = styled.div`
	span.page-title {
		font-size: 12px;
		font-weight: normal;

		b {
			font-weight: 600;
		}
	}

	div.page-header {
		padding: 70px 70px 40px;
		width: 100%;
		box-sizing: border-box;
		background: linear-gradient(
			144.76deg,
			#ffffff 0%,
			#ecf0f5 70.37%,
			#fcc408 139.87%
		);

		.company_link {
			color: #f1353a;
			font-family: "Poppins";
			font-style: normal;
			font-weight: 400;
			font-size: 10px;
			line-height: 120%;
			text-align: center;
			letter-spacing: 0.04em;
			background: #fff;
			border-radius: 5rem;
			padding: 8px 16px;
			box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
			display: table;
			margin: 0px auto 20px;
		}

		img {
			display: block;
			margin: 0px auto 10px;
			/* padding: 10px 16px; */
			width: 120px;
			height: 84px;
			border-radius: 10px;
			/* background: #fff; */
			/* box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1); */
			object-fit: contain;
		}

		h5 {
			font-family: "Poppins";
			font-style: normal;
			font-weight: 600;
			font-size: 26px;
			line-height: 110%;
			text-align: center;
			letter-spacing: 0.02em;
			color: #212121;
			margin: 0 auto;
		}
	}

	div.container {
		/* height: 100%; */
		background: #f7f8fc;
		padding: 20px 20px 50px 20px;
	}
`
