import axios from "axios";
import { API_URL } from "../config";
import { catchError, handleError } from "./response";
import { getApiRequestService } from "./index.services";

export const registerFirebaseToken = async (
  token: string,
  fb_token: string
) => {
  try {
    const data = { registrationId: fb_token };
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/notification/registration`, data);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const unregisterFirebaseToken = async (registrationId: string) => {
  try {
    const data = { registrationId };
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(
      `/notification/registration/delete`,
      data
    );

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const getNotifications = async () => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/notification`);
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const readAllNotifications = async () => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/notification/read-all`);
    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const getNotification = async (notification_id: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/notification/${notification_id}`);
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const readAllNotification = async (token: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.patch(`/notification/read`);
    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const readNotification = async (notification_id: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.patch(
      `/notification/${notification_id}/read`
    );
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteNotification = async (notification_id: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete(
      `/notification/${notification_id}/delete`
    );
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};
