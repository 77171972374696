import { Skeleton } from "@mui/material";
import React, { Component } from "react";
import styled from "styled-components";
import { Spacer } from "../global";

class SkeletonCard extends Component {
  render() {
    return (
      <CustomCard>
        <div className='card-header'>
          <div className='col'>
            <Skeleton variant='rectangular' width='35%' height={8} />
            <div className='specialties'>
              <Skeleton
                variant='rounded'
                className='rectangle-circle'
                width='25%'
                height={19}
              />
              <Skeleton
                variant='rounded'
                className='rectangle-circle'
                width='25%'
                height={19}
              />
            </div>
          </div>
          <Skeleton variant='circular' width={35} height={32} />
        </div>
        <div className='card-content'>
          <Skeleton variant='rectangle' width={"35%"} height={11} />
          <Skeleton variant='rectangle' width={"100%"} height={8} />
          <Skeleton variant='rectangle' width={"100%"} height={8} />
          <Skeleton variant='rectangle' width={"100%"} height={8} />
          <Skeleton variant='rectangle' width={"60%"} height={8} />
        </div>

        <div className='card-footer'>
          <div className='col'>
            <Skeleton variant='rectangle' width={"100%"} height={8} />
            <Skeleton variant='rectangle' width={"100%"} height={32} />
          </div>
          <div className='col'>
            <Skeleton variant='rectangle' width={"100%"} height={8} />
            <Skeleton variant='rectangle' width={"100%"} height={8} />
            <Skeleton variant='rectangle' width={"100%"} height={8} />
          </div>
        </div>

        <div className='card-controls'>
          <Skeleton
            variant='circular'
            width={12}
            height={12}
            sx={{ bgcolor: "grey.400" }}
          />
          <Skeleton variant='circular' width={12} height={12} />
        </div>
      </CustomCard>
    );
  }
}

export default SkeletonCard;

export const CustomCard = styled.div`
  /* background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(360deg, #d2dce2 0%, #ecf0f5 100%); */
  background: #ecf0f5;
  box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
  border-radius: 15px;

  padding: 21px;
  margin: 15px 10px;

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .specialties {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 10px;
    gap: 8px;
  }

  .rectangle-circle {
    border-radius: 100px;
  }

  .col {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    gap: 16px;
  }

  .card-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 28px;
  }
`;
