import { IComment, SupportedLanguagesEnum } from ".";
import { IArticle } from "./article.interface";
import { ICompany } from "./company.interface";
import { IJournal } from "./journal.interface";
import { IOrganisation } from "./organisation.interface";
import { ISite } from "./site.interface";
import { ISpecialty } from "./specialty.interface";
import { ITag } from "./tag.interface";
import { IVideo } from "./video.interface";

export enum ContentFormatsEnum {
  ARTICLE = "article",
  VIDEO = "Video",
  PODCAST = "podcast",
  GUIDELINE = "guideline",
  INFOGRAPHIC = "Infographic",
}

export enum ContentStatusEnum {
  DRAFT = "draft",
  PUBLISHED = "published",
}

export enum ContentVisibilityEnum {
  PUBLIC = "public",
  PRIVATE = "private",
}

export interface IContent {
  readonly content_format: ContentFormatsEnum;
  comments: IComment[];
  company: ICompany;
  journal: IJournal;
  keywords: string[];
  language: SupportedLanguagesEnum;
  medical_specialties: ISpecialty[];
  meta?: {
    creationDate?: Date;
    lastModified?: Date;
    status?: ContentStatusEnum;
  };
  metrics?: {
    saveds: number;
    favorites: number;
    shares: number;
    views: number;
  };
  organisations: IOrganisation[];
  publication_date: Date;
  sites: ISite[];
  slug: string;
  tags: ITag[];
  title: string;
  versions: {
    [key: string]: any;
  };
  visibility: ContentVisibilityEnum;
  _id: string;
}

export type AnyContent = IArticle | IVideo;
