import { Component } from "react"
import { t } from "i18next"
import { Row, Col } from "react-grid-system"
import { Spacer, Avatar, CustomButton } from "../../components/global"
import SettingsNavbar from "../../components/SettingsNavbar"
import { SettingButton, SettingsContainer } from "./settingsPage"
import { connect } from "react-redux"
import { userApi } from "../../redux/user/user.service"
import { withTranslation } from "react-i18next"
import { deleteCurrentUser } from "../../services/user.services"
import customToast from "../../components/CustomToast"
import { Modal } from "antd-mobile"
import { logoutUserFromApp, pickGradient } from "../../tools/utils"
import ProfilePicture from "../../components/ProfilePicture"

class AccountPage extends Component {
	state = {
		visible: false,
		isDeleting: false,
		disableSound: localStorage.getItem("disableSound") === "true",
		disableHaptic: localStorage.getItem("disableHaptic") === "true"
	}

	handleDeleteAccount = async () => {
		this.setState({ isDeleting: true })
		try {
			await deleteCurrentUser()
			customToast(t("toast.success.deletedAccount"))

			logoutUserFromApp()
			window.location.replace("/signup/prev")
		} catch (error) {
			this.setState({ isDeleting: false })
			throw error
		}
	}

	handleUpdateSound = () => {
		localStorage.setItem("disableSound", !this.state.disableSound)
		this.setState({ disableSound: !this.state.disableSound })
	}

	handleUpdateHaptic = () => {
		localStorage.setItem("disableHaptic", !this.state.disableHaptic)
		this.setState({ disableHaptic: !this.state.disableHaptic })
	}

	render() {
		const user = this.props.user?.user
		return (
			<div>
				<Modal
					wrapClassName="journal-modal"
					visible={this.state.visible}
					transparent
					maskTransitionName
					maskClosable={true}
					onClose={() => this.setState({ visible: false })}
				>
					<p
						style={{
							color: "#212121",
							fontWeight: 600,
							fontFamily: "Poppins",
							textAlign: "center",
							marginBottom: 25,
							marginTop: 25
						}}
					>
						{t("modal.deleteAccount")}
					</p>
					<CustomButton
						style={{
							padding: "10px",
							fontSize: "15px",
							marginBottom: 10
						}}
						onClick={() => this.setState({ visible: false })}
					>
						{t("common.cancel")}
					</CustomButton>
					<CustomButton
						style={{
							padding: "10px",
							fontSize: "15px",
							marginBottom: 15,
							background: "white",
							border: "1px solid #212121"
						}}
						onClick={() => this.handleDeleteAccount()}
						className={this.state.isDeleting ? "loading" : ""}
					>
						{t("common.yes")}
					</CustomButton>
				</Modal>
				<SettingsNavbar
					link="/profile"
					pageTitle={t("navigation.settings")}
					{...this.props}
				/>
				<Spacer height="60px" />
				<SettingsContainer>
					<h2>{t("navigation.profile")}</h2>
					<Row
						style={{ margin: "0 5px" }}
						onClick={() =>
							this.props.history.push("/settings/edit/profile")
						}
					>
						<Col xs={5 / 2}>
							<ProfilePicture user={user} height={64} />
						</Col>
						<Col>
							<p
								style={{
									fontSize: "17px",
									fontFamily: "Poppins",
									fontWeight: 700
								}}
							>
								<span style={{ fontWeight: 400 }}>
									{user?.firstname}
								</span>
								<br />
								{user?.lastname}
							</p>
						</Col>
					</Row>
					<Spacer height="30px" />

					<SettingButton
						scale={1.1}
						icon="volume"
						mode="switch"
						checked={!this.state.disableSound}
						onClick={() => this.handleUpdateSound()}
					>
						{t("settings.enableSound")}
					</SettingButton>

					<SettingButton
						scale={1.1}
						icon="haptic"
						mode="switch"
						checked={!this.state.disableHaptic}
						onClick={() => this.handleUpdateHaptic()}
					>
						{t("settings.enableHaptic")}
					</SettingButton>

					<Spacer height={`${window.innerHeight * 0.4}px`} />

					<SettingButton
						icon="delete_account"
						onClick={() =>
							this.setState({
								visible: true
							})
						}
					>
						{t("profile.delete")}
					</SettingButton>
				</SettingsContainer>
			</div>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(AccountPage))
