import React, { Component } from "react"
import { t } from "i18next"
import SimpleNavbar from "../../../components/SimpleNavbar"
import { StyledOnboarding } from "./onboardingProfession"
import FadeIn from "react-fade-in/lib/FadeIn"
import { CustomButton } from "../../../components/global"
import i18n from "../../../config/i18n"
import { PreferedContent } from "../../../interfaces"
import CustomIcon from "../../../components/CustomIcon"

export const FORMAT_PREFERENCES = [
	{
		en: "Scientific publications",
		fr: "Publications scientifiques",
		value: PreferedContent.SCIENTIFIC_PUBLICATIONS
	},
	{
		en: "Medical guidelines",
		fr: "Recommandations médicales",
		value: PreferedContent.MEDICAL_GUIDELINES
	},
	{
		en: "Editorial content",
		fr: "Contenu éditorial",
		value: PreferedContent.EDITORIAL_CONTENT
	},
	{ en: "Videos", fr: "Vidéos", value: PreferedContent.VIDEOS },
	{ en: "Podcasts", fr: "Podcasts", value: PreferedContent.PODCASTS }
]

class OnboardingFormat extends Component {
	state = {
		format: []
	}

	componentDidMount() {
		const { state } = this.props.location
		window.scrollTo(0, 0)

		this.setState({
			...state
		})
	}

	handleSelect(pref) {
		const { format } = this.state
		if (format.includes(pref))
			this.setState({ format: format.filter((el) => el !== pref) })
		else this.setState({ format: [...format, pref] })
	}

	handleNext() {
		const { state } = this
		this.props.history.push({
			pathname: "/signup/onboarding/journals",
			state
		})
	}

	render() {
		const { format } = this.state
		return (
			<StyledOnboarding>
				<SimpleNavbar
					endIcon={
						<CustomIcon
							onClick={() =>
								this.props.history.push({
									pathname: "/signup/success",
									state: this.state
								})
							}
							iconName="close_alt"
						/>
					}
				/>
				<div className="header">
					<h2>{t("signup.onboarding.h2.format")}</h2>
					<h5>{t("signup.onboarding.h5.format")}</h5>
				</div>
				<div className="selection-rows">
					<FadeIn>
						{FORMAT_PREFERENCES.map((pref) => (
							<div className="row" key={pref["en"] + "--key"}>
								<p>{pref[i18n.resolvedLanguage]}</p>
								<button
									className={
										format.includes(pref.value)
											? "selected"
											: ""
									}
									onClick={() =>
										this.handleSelect(pref.value)
									}
								>
									{t(
										format?.includes(pref.value)
											? "Selected"
											: "Select"
									)}
								</button>
							</div>
						))}
					</FadeIn>
				</div>
				<div className="footer">
					<CustomButton
						className={format.length ? "" : "disabled"}
						onClick={this.handleNext.bind(this)}
					>
						{t("common.next")}
					</CustomButton>
					<CustomButton
						className="secondary"
						onClick={this.handleNext.bind(this)}
					>
						{t("common.lemonSkip")}
					</CustomButton>
				</div>
			</StyledOnboarding>
		)
	}
}

export default OnboardingFormat
