import React, { Component } from "react"
import { t } from "i18next"
import SimpleNavbar from "../../../components/SimpleNavbar"
import { StyledOnboarding } from "./onboardingProfession"
import FadeIn from "react-fade-in/lib/FadeIn"
import { CustomButton, JournalButton } from "../../../components/global"
import {
	getJournalsList,
	searchJournal
} from "../../../services/journals.services"
import CustomSearchInput from "../../../components/CustomSearchInput"
import i18n from "../../../config/i18n"

class OnboardingJournals extends Component {
	inputRef = React.createRef()
	state = {
		defaultList: [],
		journals: [],
		selectedJournals: [],
		loading: false
	}

	componentDidMount() {
		const { state } = this.props.location
		window.scrollTo(0, 0)

		this.setState({
			...state
		})

		getJournalsList({
			sortImpactFactor: "desc",
			limit: 40
		}).then((defaultList) => this.setState({ defaultList }))
	}

	handleNext() {
		const { state } = this

		this.setState({ loading: true })

		this.props.history.push({
			pathname: "/signup/success",
			state
		})
	}

	handleSearchJournals(searchValue) {
		this.setState({ searchValue })
		if (searchValue.length)
			searchJournal(searchValue).then(({ hits }) => {
				this.setState({ journals: hits.hits })
			})
	}

	handleFollowJournal(journal) {
		const { selectedJournals } = this.state

		if (selectedJournals.includes(journal._id))
			this.setState({
				selectedJournals: selectedJournals.filter(
					(el) => el !== journal._id
				)
			})
		else
			this.setState({
				selectedJournals: [...selectedJournals, journal._id]
			})
	}

	render() {
		const {
			journals,
			selectedJournals,
			searchValue,
			loading,
			defaultList
		} = this.state

		return (
			<StyledOnboarding>
				<SimpleNavbar />
				<div
					className="header"
					style={{ marginBottom: 0, padding: "0px 30px" }}
				>
					<h2>{t("signup.onboarding.h2.journals")}</h2>
					<h5 style={{ padding: 0 }}>
						{i18n.resolvedLanguage === "en" ? (
							<>
								You may have some favorite journals? Follow them
								to be notified about the latest publications.
							</>
						) : (
							<>
								Tu as peut-être des revues de prédilection? Suis
								les pour être informé des derniers papiers
								publiés.
							</>
						)}
					</h5>
				</div>
				<div className="selection-rows">
					<CustomSearchInput
						placeholder={t("search.search")}
						onSearch={this.handleSearchJournals.bind(this)}
						onClear={() =>
							this.setState({ journals: [], searchValue: "" })
						}
					/>

					{journals.length > 0 ? (
						journals.map(({ _source }) => (
							<JournalButton
								key={_source.id}
								journal={_source.core}
								isFollowed={selectedJournals.includes(
									_source.id
								)}
								handleFollow={() =>
									this.handleFollowJournal({
										..._source.core,
										_id: _source.id,
										slug: _source.core.uid
									})
								}
							/>
						))
					) : searchValue?.length ? (
						<FadeIn delay={750}>
							<p className="empty-text">
								{t("signup.onboarding.emptyText")}
							</p>
						</FadeIn>
					) : (
						defaultList.map((journal) => (
							<JournalButton
								key={journal._id}
								journal={{
									...journal,
									image: journal.image?.url
								}}
								isFollowed={selectedJournals.includes(
									journal._id
								)}
								handleFollow={() =>
									this.handleFollowJournal(journal, true)
								}
							/>
						))
					)}
				</div>
				<div className="footer">
					<CustomButton
						className={loading ? "disabled loading" : "pink"}
						onClick={this.handleNext.bind(this)}
					>
						{t("signup.onboarding.finalPhrase")}
						{loading ? "" : "!"}
					</CustomButton>
				</div>
			</StyledOnboarding>
		)
	}
}

export default OnboardingJournals
