import React, { useEffect, useState } from "react"
import { t } from "i18next"
import { store } from "../redux"
import { isNativeIOS } from "../tools/ios"
import { SPECIALTIES_EXCEPTION } from "../tools/utils"
import CustomCheckbox from "./CustomCheckbox"
import CustomSheet from "./CustomSheet"
import { CustomButton, Spacer } from "./global"
import { useGetArticleSpecialtiesQuery } from "../redux/articles/articles.service"
import { gtmFilter } from "../tools/reactgaEvents"
import { getArticleTags } from "../services/articles.services"
import CustomIcon from "./CustomIcon"
import FadeIn from "react-fade-in/lib/FadeIn"
import FilterButton from "./FilterButton"

const FilterSheet = ({
	isOpen,
	onOpenSheet,
	onCloseSheet,
	onConfirmFilters
}) => {
	const contentLanguage = localStorage.getItem("contentLanguage")
	const userOrganisation = store.getState().user.user.organisations?.[0]

	const { data: profession } = useGetArticleSpecialtiesQuery({
		profession_uid: store.getState().user.user.profession.uid
	})

	const [medicalTags, setMedicalTags] = useState([])
	const [currentTags, setCurrentTags] = useState([])
	const [currentSpecialty, setCurrentSpecialty] = useState(null)

	const filterSpecialty = async (specialty) => {
		gtmFilter({
			filter_value: specialty.uid,
			filter_name: "specialty"
		})

		const articleTags = await getArticleTags(specialty.uid)
		setMedicalTags(articleTags)
		setCurrentSpecialty(specialty)
	}

	const selectTagFilter = (tag) => {
		gtmFilter({
			filter_value: tag.uid,
			filter_name: "subspecialty"
		})

		setCurrentTags((currentTags) => {
			const isTagSelected = currentTags.find((el) => el.uid === tag.uid)
			if (isTagSelected) {
				return currentTags.filter((el) => el.uid !== tag.uid)
			} else {
				return [...currentTags, tag]
			}
		})
	}

	const resetFiltersSpecialties = () => {
		setCurrentTags([])
		setCurrentSpecialty(null)
		setMedicalTags([])
		onConfirmFilters && onConfirmFilters(null)
	}

	useEffect(() => {
		if (onConfirmFilters && (currentSpecialty || currentTags.length))
			onConfirmFilters({
				medicalSpecialty: currentSpecialty,
				medicalTags: currentTags
			})
	}, [currentSpecialty, currentTags])

	return (
		<div>
			<FilterButton active={!!currentSpecialty} onClick={onOpenSheet} />
			<CustomSheet
				show={isOpen}
				title={t("common.filters")}
				bodyStyle={{
					height: isNativeIOS
						? window.innerHeight * 0.6
						: window.innerHeight * 0.8
				}}
				bodyId="top-sheet"
				onChange={onCloseSheet}
				bottomContent={
					!!currentSpecialty ? (
						<>
							<CustomButton
								className="black"
								onClick={onCloseSheet}
							>
								{t("common.confirm")}
							</CustomButton>
							<Spacer height="25px" />
							{isNativeIOS && <Spacer height="40px" />}
						</>
					) : null
				}
			>
				<div>
					<h5 style={{ marginBottom: 10 }}>
						{t("sheet.filters.selectField")}
					</h5>
					{!!currentSpecialty && (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between"
							}}
							onClick={resetFiltersSpecialties}
						>
							{/* Specialty checkbox (checked) */}
							<CustomCheckbox
								checked={true}
								title={
									currentSpecialty.translations[
										contentLanguage
									]
								}
								onChange={resetFiltersSpecialties}
							/>
							<CustomIcon
								style={{ marginBottom: -10 }}
								scale={0.8}
								iconName="close_alt"
							/>
						</div>
					)}
					{!!currentSpecialty && (
						<FadeIn visible={!!currentSpecialty}>
							<h5 style={{ marginBottom: 10 }}>
								{t("sheet.filters.selectTag")}
							</h5>
							{[...medicalTags]
								.sort((a, b) =>
									a.translations[
										contentLanguage
									].localeCompare(
										b.translations[contentLanguage]
									)
								)
								.map((tag) => {
									return (
										<CustomCheckbox
											key={tag?._id}
											onChange={() =>
												selectTagFilter(tag)
											}
											checked={currentTags?.find(
												(el) => el.uid === tag.uid
											)}
											title={
												tag.translations[
													contentLanguage
												]
											}
										/>
									)
								})}
						</FadeIn>
					)}
					<div
						style={{
							opacity: !!currentSpecialty ? 0 : 1,
							transition: "all ease-in-out 0.3s"
						}}
					>
						{profession?.medical_specialties
							?.filter(
								(el) =>
									!SPECIALTIES_EXCEPTION.includes(
										el.translations.en
									)
							)
							?.filter((el) =>
								userOrganisation.organisationSettings?.medicalSpecialties.includes(
									el._id
								)
							)
							.sort((a, b) =>
								a.translations[contentLanguage]?.localeCompare(
									b.translations[contentLanguage]
								)
							)
							?.map((specialty) => (
								// Specialty checkbox (unchecked)
								<CustomCheckbox
									key={specialty?._id}
									onChange={() => {
										filterSpecialty(specialty)
										document
											.getElementById("top-sheet")
											.scrollIntoView({
												behavior: "smooth",
												block: "start",
												inline: "start"
											})
									}}
									checked={false}
									title={
										specialty.translations[contentLanguage]
									}
								/>
							))}
					</div>
				</div>
			</CustomSheet>
		</div>
	)
}

export default FilterSheet
