import axios from "axios";
import { API_URL } from "../config";
import { SupportedLanguagesEnum } from "../interfaces";
import { auth } from "./firebase";
import { getApiRequestService } from "./index.services";
import { catchError, handleError } from "./response";

export const getUserById = async (user_ID: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/user/${user_ID}`);
    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const getUser = async () => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/user/me`);
    localStorage.setItem("my_uid", res.data.uid);
    return res.data;
  } catch (error: any) {
    throw handleError(error);
  }
};

export const getUsers = async () => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/user`);
    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const patchUser = async (data: any) => {
  try {
    const body: any = {};
    for (const [key, value] of Object.entries(data)) {
      body[key] = value;
    }
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.patch("/user/me", body);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const updateUserLanguage = async (language: SupportedLanguagesEnum) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.put("/user/me/language", {
      language,
    });

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const createUser = async (data: any) => {
  try {
    const body: any = {};
    for (const [key, value] of Object.entries(data)) {
      body[key] = value;
    }
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post("/user", body);

    return res.data;
  } catch (error) {
    throw error;
    // return catchError(error);
  }
};

// LIKES

export const likeArticle = async (articleId: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`user/me/like/${articleId}`);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const unlikeArticle = async (articleId: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete(`user/me/like/${articleId}`);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

// PUBLISHED ARTICLES

export const addPublishedArticle = async (slug: string) => {
  try {
    const data = {
      slug: slug,
    };

    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post("user/me/article", data);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const deletePublishedArticle = async (slug: string) => {
  try {
    const data = {
      slug: slug,
    };

    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete("user/me/article", {
      data,
    });

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

// AWARDS

export const addUserAward = async (data: any) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post("user/me/award", data);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const deleteUserAward = async (data: any) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete("user/me/award", {
      data,
    });

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

// FOLLOWING & FOLLOWERS

export const getListPendingFollowers = async () => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/user/me/followers/pending`);

    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getListPendingFollowings = async (token: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/user/me/following/pending`);
    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const getListFollowers = async () => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/user/me/followers`);
    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const getListFollowings = async () => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/user/me/following`);
    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const getUserListFollowers = async (userID: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/user/${userID}/followers`);
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUserListFollowings = async (userID: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/user/${userID}/following`);
    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createFollowRequest = async (userUid: string) => {
  try {
    const data: any = { userUid };
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post("user/me/follow", data);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const unfollowRequest = async (userUid: string) => {
  try {
    const data: any = { userUid };
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post("user/me/unfollow", data);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const acceptFollowRequest = async (requestId: string) => {
  try {
    const data: any = { requestId };

    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post("user/me/follow/accept", data);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const rejectFollowRequest = async (requestId: string) => {
  try {
    const data = { requestId };
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post("user/me/follow/reject", data);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const deleteCurrentUser = async () => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete("user/me");
    return res.data;
  } catch (error) {
    catchError(error);
  }
};

export const getActiveSession = async () => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get("/user/me/session");

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const updateActiveSession = async (activesession: boolean) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.patch(
      `/user/me/session?activesession=${activesession}`
    );

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const incrementUserMetric = async (metric: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post("/user/metric", {
      metric,
    });

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const setFirstLogin = async () => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post("/user/first-login");
  } catch (error) {
    return catchError(error);
  }
};

export const getAnnouncements = async () => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get("/user/me/announcements");

    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteAnnouncement = async (organisationId: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete("/user/announcement", {
      data: { organisationId },
    });

    return res.data;
  } catch (error) {
    throw handleError(error);
  }
};
