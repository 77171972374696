import React, { Component } from "react"
import { t } from "i18next"
import styled from "styled-components"
import CustomIcon from "./CustomIcon"

const SELECTION_COLOR = "#32A0FE"

// TODO: onClear remove localStorage "_search_params"
class CustomSearchInput extends Component {
	state = {
		searchValue: "",
		canceled: true
	}

	componentDidMount() {
		if (localStorage.getItem("_search_params")) {
			const { searchValue } = JSON.parse(
				localStorage.getItem("_search_params")
			)
			if (searchValue) this.setState({ searchValue })
		}
	}

	render() {
		const {
			placeholder,
			name,
			required,
			prefix,
			entrySelected,
			onSearch,
			onCancel,
			onClear,
			onBlur
		} = this.props
		return (
			<SearchContainer
				className={`${
					(this.state.searchValue.length > 0 ||
						!this.state.canceled) &&
					!!onCancel
						? "closable"
						: prefix
						? "entry-mode"
						: ""
				} ${prefix ? "prefix" : ""}`}
			>
				<div
					className={`searchbar ${prefix ? "prefix" : ""} ${
						!!onCancel ? "" : "clear-only"
					}`}
				>
					<CustomIcon
						iconName="search"
						color="#81939C"
						scale={0.8}
						style={{
							marginBottom: -50,
							transform: "translateY(4px)",
							textAlign: "right"
						}}
					/>
					{prefix && (
						<div
							className={`tag ${
								entrySelected ? "entry-selected" : ""
							}`}
							onClick={() => {
								onCancel && onCancel()
								this.setState({
									searchValue: "",
									canceled: true
								})
							}}
						>
							{prefix}
						</div>
					)}
					<input
						value={this.state.searchValue}
						placeholder={placeholder}
						name={name}
						required={required}
						onChange={(e) => {
							onSearch && onSearch(e.target.value)
							this.setState({
								searchValue: e.target.value,
								canceled: false
							})
						}}
						onBlur={() => {
							onBlur && onBlur()
						}}
					/>
					{this.state.searchValue.length > 0 ? (
						<CustomIcon
							iconName="close_alt"
							className="close_icon"
							scale={0.8}
							onClick={() => {
								this.setState({
									searchValue: "",
									canceled: false
								})
								onClear && onClear()
							}}
							onTouchEnd={() => {
								this.setState({
									searchValue: "",
									canceled: false
								})
								onClear && onClear()
							}}
						/>
					) : (
						<div
							style={{
								background: "none",
								width: 34,
								height: 34
							}}
						/>
					)}
				</div>
				{onCancel && (
					<div
						onClick={() => {
							onCancel && onCancel()
							this.setState({ searchValue: "", canceled: true })
						}}
						onTouchEnd={() => {
							onCancel && onCancel()
							this.setState({ searchValue: "", canceled: true })
						}}
						className="cancel"
					>
						{t("common.cancel")}
					</div>
				)}
			</SearchContainer>
		)
	}
}

export default CustomSearchInput

const SearchContainer = styled.div`
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 1fr;
	gap: 0px 0px;
	grid-template-areas: ". . .";
	align-items: center;
	transition: all ease-in-out 0.2s;

	&.closable {
		grid-template-columns: 83% 17%;

		.close_icon,
		.cancel {
			width: 100%;
			opacity: 1;
			animation-name: fadeInOpacity;
			animation-iteration-count: 1;
			animation-timing-function: ease-in;
			animation-duration: 0.2s;
		}
	}

	&.entry-mode {
		grid-template-columns: 83% 17%;

		.cancel {
			opacity: 1;
			width: 100%;
			animation-name: fadeInOpacity;
			animation-iteration-count: 1;
			animation-timing-function: ease-in;
			animation-duration: 0.2s;
		}
	}

	.close_icon {
		opacity: 0;
		text-align: right;
		width: 0px;
	}

	.cancel {
		opacity: 0;
		width: 0px;
		padding: 15px 0px 15px 5px;
		text-align: center;
		font-family: "Roboto";
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 12px;
		color: #ff8800;
	}

	.searchbar {
		display: grid;
		grid-template-columns: 20px auto 30px;
		grid-template-rows: 1fr;
		gap: 0px 0px;
		grid-template-areas: ". . .";
		background-color: #dfe6ec;
		padding: 7px 10px;
		box-sizing: border-box;
		border-radius: 5em;

		&.prefix {
			grid-template-columns: 20px auto auto 30px;
			gap: 0 5px;
		}

		&.clear-only {
			grid-template-columns: 20px auto 60px;

			.close_icon {
				text-align: right;
				box-sizing: content-box;
				width: 100%;
				opacity: 1;
			}
		}

		input {
			outline: none;
			box-shadow: none;
			border: none;
			font-size: 16px;
			font-weight: 600 !important;
			font-family: "Roboto" !important;
			background: #dfe6ec;
			display: table;
			width: 100%;
			box-sizing: border-box;

			::placeholder {
				color: #81939c;
				font-family: "Roboto" !important;
				font-weight: 400 !important;
			}
		}
	}

	div.tag {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 400;
		font-size: 10px;
		line-height: 20px;
		letter-spacing: 0.02em;
		color: #ff8800;
		padding: 1px 5px;
		background: #fff3ce;
		border-radius: 10px;
		text-align: center;
		height: 20px;
		transform: translateY(4px);

		&.entry-selected {
			background: ${SELECTION_COLOR};
			color: white;
			border-radius: 0px;
			position: relative;

			&::before {
				content: "";
				display: block;
				position: absolute;
				left: -5px;
				bottom: -7px;
				width: 10px;
				height: 10px;
				border-radius: 10px;
				background-color: ${SELECTION_COLOR};
			}

			&::after {
				content: "";
				display: block;
				position: absolute;
				right: -5px;
				top: -7px;
				width: 10px;
				height: 10px;
				border-radius: 10px;
				background-color: ${SELECTION_COLOR};
			}
		}
	}

	@keyframes fadeInOpacity {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`
