import { Component } from "react"
import styled from "styled-components"
import { Link, withRouter } from "react-router-dom"
import Clamp from "react-multiline-clamp"
import CustomIcon from "./CustomIcon"
import { SpecialtyTag, MetaTitle } from "./global"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { userApi } from "../redux/user/user.service"
import { kycMiddleware, renderPublicationDate } from "../tools/utils"
import ShareBtn from "../assets/icons/share-btn.svg"
import LikeIcon from "../assets/icons/card_like.svg"
import LikeActiveIcon from "../assets/icons/card_like_active.svg"
import i18n from "../config/i18n"
import { t } from "i18next"
import OptionSheet from "./post/OptionSheet"
import { likeContent } from "../services/contentApi"
import { getFirebaseToken } from "../services/firebase"
import {
	convertContentToItemData,
	gtmItemData,
	gtmItemsData
} from "../tools/reactgaEvents"
import { ItemDataVariant, ItemsDataEvent } from "../interfaces"

class ArticleCard extends Component {
	state = {
		isLiked: this.props.user.user?.likes?.some(
			(article) => article?.slug === this.props.article.slug
		),
		showSheet: false,
		showSave: false
	}

	componentDidMount() {
		this.setState({
			innerHeight: window.innerHeight
		})
	}

	// Likes our article
	async handleLike() {
		const isAuthentified = await getFirebaseToken()
		if (!isAuthentified) {
			this.props.history.push("/login")
			return
		} else if (!this.props.user.user.profession) {
			kycMiddleware(this, t("toast.error.notOnboarded.likeArticle"))
			return
		}

		likeContent(
			this.props.article,
			gtmItemsData(
				ItemsDataEvent.ARTICLE_LIKE,
				convertContentToItemData(
					[this.props.article],
					window.location.pathname,
					ItemDataVariant.ARTICLE
				)
			)
		)
	}

	handleSelectItem() {
		const { title, _id, journal, medical_specialties } = this.props.article

		if (this.props.itemListName)
			gtmItemData({
				event: ItemsDataEvent.SELECT_ITEM,
				item_name: title,
				item_list_name: this.props.itemListName,
				item_id: _id,
				item_brand: journal?.name || null,
				item_category: medical_specialties[0]?.uid,
				item_category2: "standard",
				item_variant: ItemDataVariant.ARTICLE
			})
	}

	/** Show share & save bottom sheet */
	handleShowSheet = () => {
		this.setState({ showSheet: true })
	}

	handleDismiss() {
		setTimeout(() => this.setState({ showSheet: false }), 100)
	}

	render() {
		const {
			slug,
			title,
			primary_author,
			publication_date,
			journal,
			authors,
			article_type,
			medical_specialties
		} = this.props?.article

		const { showSheet } = this.state
		const isLiked = this.props.user.user?.likes?.some(
			(article) => article?.slug === slug
		)

		const contentLanguage = localStorage.getItem("contentLanguage")

		const path_to_redirect = `/post/${slug}`

		return (
			<>
				<CustomWrapper
					style={this.props.style}
					onClick={() => this.handleSelectItem()}
					className={this.props.className}
				>
					{/* Card Header - Date / Category / Ellpsis */}
					<div>
						<div className="card-header">
							<Link
								to={path_to_redirect}
								className="card-header-infos"
							>
								<div className="card-header-date">
									{renderPublicationDate(publication_date)}
								</div>
								<div className="specialties-inline">
									{(this.props.currentField
										? [
												this.props.currentField,
												...medical_specialties?.filter(
													(el) =>
														el.uid !==
														this.props.currentField
															.uid
												)
										  ]
										: medical_specialties
									)
										.slice(0, 4)
										.map((specialty) => (
											<SpecialtyTag
												key={
													specialty._id +
													"articlecardspecialtytag"
												}
											>
												{
													specialty.translations[
														localStorage.getItem(
															"contentLanguage"
														)
													]
												}
											</SpecialtyTag>
										))}
								</div>
							</Link>
							<div className="card-header-icons">
								<div onClick={() => this.handleLike()}>
									<img
										src={
											isLiked ? LikeActiveIcon : LikeIcon
										}
										alt=""
									/>
								</div>
								<div onClick={this.handleShowSheet.bind(this)}>
									<img src={ShareBtn} alt="" />
								</div>
							</div>
						</div>
					</div>
					{/* Card Body  */}
					<div className="card-body">
						<Link
							to={path_to_redirect}
							onDragStart={(e) => e.preventDefault()}
							style={{ color: "#212121", margin: 0 }}
						>
							<MetaTitle>
								{this.props.t(
									article_type ?? t("common.clinicalStudy"),
									{
										lng: contentLanguage
									}
								)}
							</MetaTitle>

							<p
								style={{
									fontWeight: 700,
									fontSize: "16px",
									margin: 0,
									textAlign: "left"
								}}
							>
								<Clamp lines={4}>{title}</Clamp>
							</p>
							<Spacer />
						</Link>
						<div className="bottom-flex">
							<Link
								className="link-div"
								to={`/journal/${journal?.uid}`}
							>
								<div className="journal-flex">
									<p className="bottom-title">Journal</p>{" "}
									<CustomIcon
										style={{ marginTop: -8.5 }}
										iconName="link"
										scale={0.7}
									/>
								</div>
								{journal?.image?.url ? (
									<img
										src={journal?.image?.url}
										alt={journal?.name ?? "Loading..."}
										className="journal-img journal-placeholder"
									/>
								) : (
									<div className="journal-placeholder">
										{journal?.name}
									</div>
								)}
							</Link>

							<div className="authors-div">
								<p className="bottom-title">
									{contentLanguage === "fr"
										? "Auteurs"
										: "Authors"}
								</p>
								<p
									className="bottom-title"
									style={{
										fontWeight: 500,
										margin: 0,
										marginTop: -2,
										lineHeight: "14px"
									}}
								>
									{primary_author} <br />
									{authors.length
										? i18n.resolvedLanguage === "fr"
											? `et ${authors.length} autres auteurs`
											: `and ${authors.length} other authors`
										: ""}
								</p>
							</div>
						</div>
					</div>
				</CustomWrapper>

				<OptionSheet
					isLiked={isLiked}
					showSheet={showSheet}
					handleDismiss={this.handleDismiss.bind(this)}
					article={this.props.article}
					handleLike={this.handleLike.bind(this)}
					itemListName={this.props.itemListName}
				/>
			</>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate,
	getPlaylists: userApi.endpoints.getPlaylists.initiate
}
const connector = connect(mapState, mapDispatch)

export default connector(withRouter(withTranslation()(ArticleCard)))

const Spacer = styled.div`
	height: ${(props) => props.height ?? "34px"};
`

const CustomWrapper = styled.div`
	box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
	border-radius: 15px;
	position: relative;
	padding: 24px 20px 36px;
	margin: 16px 8px;
	background: #ffffff;

	&.fixed-height {
		min-height: 330px;
		padding: 24px 20px;
	}

	&.yellow {
		background: #fff3ce;

		.--specialty-tag {
			background: #ffff !important;
			color: #ff8800 !important;
		}
	}

	.card-header {
		display: flex;
		justify-content: space-between;
		align-items: start;
		margin-bottom: 24px;

		.card-header-infos {
			width: 100%;
			color: #212121;
		}

		.card-header-date {
			font-size: 14px;
			text-transform: capitalize;
			text-align: left;
			line-height: 100%;
			margin: 0;
		}

		.card-header-icons {
			display: flex;
			align-items: center;
			gap: 10px;

			img {
				height: 32px;
				width: 32px;
			}
		}

		.specialties-inline {
			margin-top: 3px;
			float: left;
			text-align: left;
		}
	}

	.card-body {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}

	h5 {
		color: #212121;
		font-weight: 800;
		font-size: 21px;
		margin: 0;
		margin-bottom: 10px;
		margin-top: 20px;
	}

	small {
		color: #ffaa00;
		font-size: 11px;
	}

	.bottom-flex {
		display: flex;
		align-items: flex-start;
		justify-content: start;
		gap: 32px;

		.link-div {
		}
	}

	.journal-flex {
		display: flex;
		align-items: center;
	}

	p {
		&.bottom-title {
			font-family: "Inter";
			color: #212121;
			font-size: 12px;
			font-weight: 300;
			text-align: left;
			margin: 0px 2px 10px 0px;
		}
	}

	img {
		&.journal-img {
			object-fit: contain;
			object-position: 0px;
			max-height: 40px;
			width: 120px;
			margin: 0 5px 0 0;
		}
	}

	.journal-placeholder {
		max-width: 120px;
		font-size: 12px;
		font-weight: 600;
		color: #212121;
	}
`
