import React from "react";
import { ReactComponent as Play } from "../../assets/icons/play_audio.svg";
import { ReactComponent as Pause } from "../../assets/icons/pause_audio.svg";
import { ReactComponent as Next } from "../../assets/icons/next_audio.svg";
import { ReactComponent as Back10Seconds } from "../../assets/icons/back_audio.svg";

const AudioControls = ({
  playbackRate,
  isPlaying,
  onPlayPauseClick,
  onPrevClick,
  onNextClick,
  onPlaybackRate,
  onReplayBack,
}) => (
  <div className='audio-controls'>
    <button
      type='button'
      className='prev'
      aria-label='Previous'
      onClick={onPrevClick}
    >
      <Next style={{ transform: "rotate(180deg)" }} />
    </button>
    <button className='replay' onClick={onReplayBack}>
      <Back10Seconds />
    </button>
    {isPlaying ? (
      <button
        type='button'
        className='pause'
        onClick={() => onPlayPauseClick(false)}
        aria-label='Pause'
      >
        <Pause />
      </button>
    ) : (
      <button
        type='button'
        className='play'
        onClick={() => onPlayPauseClick(true)}
        aria-label='Play'
      >
        <Play />
      </button>
    )}
    <button className='playback-rate' onClick={onPlaybackRate}>
      <div className='playback-rate'>x{playbackRate}</div>
    </button>

    <button
      type='button'
      className='next'
      aria-label='Next'
      onClick={onNextClick}
    >
      <Next />
    </button>
  </div>
);

export default AudioControls;
