import { t } from "i18next"
import customToast from "../components/CustomToast"
import { store } from "../redux"
import {
	addContentToPlaylist,
	addToPlaylist,
	removeContentFromPlaylist,
	removeFromPlaylist
} from "./playlists.services"
import {
	setLikes,
	setPlaylists,
	setSaves,
	setUser
} from "../redux/user/user.reducer"
import {
	convertContentToItemData,
	gtmItemData,
	gtmItemsData
} from "../tools/reactgaEvents"
import { iosGenerateHaptic, iosPlaySound } from "../tools/ios"
import {
	HapticEffect,
	IMeta,
	ItemDataVariant,
	ItemsDataEvent,
	SoundEffect
} from "../interfaces"
import { likeArticle, unlikeArticle } from "./user.services"
import { IPlaylist } from "../interfaces/playlist.interface"
import { IVideo } from "../interfaces/video.interface"
import { IArticle } from "../interfaces/article.interface"
import { IUser } from "../interfaces/user.interface"
import { IContent } from "../interfaces/content.interface"

export function saveContentToPlaylists(
	savedContent: IVideo | IArticle,
	selectedPlaylistsIds: string[]
) {
	const isVideoContent = (savedContent as IVideo).content_format === "Video"
	const userPlaylists = (
		store.getState().user.playlists as {
			docs: IPlaylist[]
			meta: {
				total: number
				limit: number
				offset: number
			}
		}
	).docs

	// Get playlists where the content was added
	const playlistsToAdd = [
		...userPlaylists
			.filter((element) => {
				const { _id, playlist, contentVideos } = element
				const idPool = isVideoContent
					? contentVideos
					: playlist.map((pl) => pl._id)
				return (
					!idPool?.find((id) => id === savedContent._id) &&
					selectedPlaylistsIds?.includes(_id)
				)
			})
			.map((element) => element._id)
	]

	// Get playlists the content was removed from
	const playlistsToRemove = [
		...userPlaylists
			.filter(({ _id, playlist, contentVideos }) => {
				const idPool = isVideoContent
					? contentVideos
					: playlist.map((pl) => pl._id)
				return (
					idPool?.find((id) => id === savedContent._id) &&
					!selectedPlaylistsIds?.includes(_id)
				)
			})
			.map(({ _id }) => _id)
	]

	// Fetch GTM if content is added to any playlist
	if (!isVideoContent && playlistsToAdd.length)
		gtmItemData({
			event: "article_save",
			item_name: savedContent.title,
			item_list_name: window.location.pathname,
			item_id: savedContent._id,
			item_brand: savedContent?.journal?.name,
			item_category: savedContent.medical_specialties[0]?.uid,
			item_category2: undefined,
			item_variant: "playlist"
		})

	// API requests
	if (!isVideoContent) {
		for (const playlistId of playlistsToAdd) {
			addToPlaylist(savedContent.slug, playlistId)
		}
		for (const playlistId of playlistsToRemove) {
			removeFromPlaylist(savedContent.slug, playlistId)
		}
	} else {
		for (const playlistId of playlistsToAdd) {
			addContentToPlaylist(playlistId, savedContent._id)
		}
		for (const playlistId of playlistsToRemove) {
			removeContentFromPlaylist(playlistId, savedContent._id)
		}
	}

	// Make new playlists for the store
	const newPlaylists = [...userPlaylists]

	for (const playlistId of playlistsToAdd) {
		const playlistIndex = newPlaylists.findIndex(
			(playlist) => playlist._id === playlistId
		)

		if (!isVideoContent) {
			newPlaylists[playlistIndex] = {
				...newPlaylists[playlistIndex],
				playlist: [
					...newPlaylists[playlistIndex].playlist,
					savedContent as IArticle
				]
			}
		} else {
			newPlaylists[playlistIndex] = {
				...newPlaylists[playlistIndex],
				contentVideos: [
					...newPlaylists[playlistIndex].contentVideos,
					savedContent._id
				]
			}
		}
	}

	for (const playlistId of playlistsToRemove) {
		const playlistIndex = newPlaylists.findIndex(
			(playlist) => playlistId === playlist._id
		)

		if (!isVideoContent) {
			newPlaylists[playlistIndex] = {
				...newPlaylists[playlistIndex],
				playlist: [
					...newPlaylists[playlistIndex].playlist.filter(
						(content) => content._id !== savedContent._id
					)
				]
			}
		} else {
			newPlaylists[playlistIndex] = {
				...newPlaylists[playlistIndex],
				contentVideos: [
					...newPlaylists[playlistIndex].contentVideos.filter(
						(videoId) => videoId !== savedContent._id
					)
				]
			}
		}
	}

	// Make new saves for the store
	const userSaves = store.getState().user.saves as string[]
	const newSaves =
		selectedPlaylistsIds.length > 0
			? // If any playlist is checked, add the content ID to the saves (using Set avoid duplicates)
			  Array.from(new Set([...userSaves, savedContent._id]))
			: // If no playlist is checked, remove the content ID from the saves
			  userSaves.filter((contentId) => contentId !== savedContent._id)

	// Dispatch saves changes to the store
	store.dispatch(setSaves(newSaves as any))
	store.dispatch(setPlaylists(newPlaylists as any))

	// Set flags
	const playlistsWithContent = newPlaylists.filter((playlist) => {
		if (!isVideoContent) {
			return playlist.playlist.some(
				(content) => content._id === savedContent._id
			)
		} else {
			return playlist.contentVideos.includes(savedContent._id)
		}
	})

	const contentIsInPlaylist = playlistsWithContent.length > 0
	const contentWasAdded = playlistsToAdd.length > 0
	const contentWasRemoved = playlistsToRemove.length > 0

	// User notifications & side effects
	if (playlistsToAdd.length) {
		customToast(
			t(
				!isVideoContent
					? "one more article was saved to your playlist"
					: "One more video saved in your playlist. Your juice of science looks great 🍋."
			),
			"success"
		)

		iosPlaySound(SoundEffect.SAVE)
		iosGenerateHaptic(HapticEffect.SUCCESS)
	} else if (playlistsToRemove.length) {
		customToast(
			t(
				!isVideoContent
					? "this article has been removed from your playlist"
					: "This video is no longer part of your playlist"
			),
			"success"
		)
	}

	return {
		contentIsInPlaylist,
		contentWasAdded,
		contentWasRemoved,
		playlistsToAdd,
		playlistsToRemove
	}
}

export async function likeContent(
	likedContent: IContent | IArticle,
	gtmEvent?: any
) {
	const user = store.getState().user.user as IUser

	// User notifications & side effects
	iosGenerateHaptic(HapticEffect.SUCCESS)

	const isContentLiked = user.likes.some(
		(like) => like.slug === likedContent.slug
	)

	if (!isContentLiked) {
		store.dispatch(
			setUser({
				...user,
				likes: [...user.likes, likedContent as IArticle]
			})
		)
		iosPlaySound(SoundEffect.LIKE)
		customToast(t("toast.success.liked"), "success")
		const { likes } = await likeArticle(likedContent._id)
		store.dispatch(setUser({ ...user, likes }))

		if (gtmEvent) gtmEvent()
		else
			gtmItemsData(
				ItemsDataEvent.ARTICLE_LIKE,
				convertContentToItemData(
					[likedContent],
					window.location.pathname,
					ItemDataVariant.ARTICLE
				)
			)
	} else {
		store.dispatch(
			setUser({
				...user,
				likes: user.likes.filter(
					(like) => like.slug !== likedContent.slug
				)
			})
		)
		customToast(t("toast.success.unliked"), "success")
		const { likes } = await unlikeArticle(likedContent._id)
		store.dispatch(setUser({ ...user, likes }))
	}

	return {
		isContentLiked,
		increment: isContentLiked ? 1 : -1
	}
}
