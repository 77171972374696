import React, { Component } from "react"
import styled from "styled-components"
import { Row, Col } from "react-grid-system"
import { push } from "react-router-redirect"
import Swipeout from "rc-swipeout"
import "rc-swipeout/assets/index.css"
import CustomIcon from "./CustomIcon"
import { renderPublicationDate } from "../tools/utils"
import { t } from "i18next"
import { convertContentToItemData, gtmItemsData } from "../tools/reactgaEvents"
import { ItemDataVariant, ItemsDataEvent } from "../interfaces"

class ProfilePost extends Component {
	state = {
		isDeleted: false
	}

	pickGradient(type) {
		switch (type) {
			case 0:
				return "linear-gradient(#FFD000, #FFA100)"

			case 1:
				return "linear-gradient(180deg, #FFD3A6 0%, #FF9C96 51.56%, #FC6F89 100%)"

			case 2:
				return "linear-gradient(180deg, #FFC408 0%, #FF734B 51.04%, #FF306E 100%)"

			case 3:
				return "linear-gradient(180deg, #FDB955 0%, #FF8A00 100%)"

			case 4:
				return "linear-gradient(180deg, #FF699C 0%, #FE5763 100%)"

			default:
				return "linear-gradient(180deg, #F42CAB 0%, #F1353A 100%)"
		}
	}

	handleClick = () => {
		this.props.onClick()

		if (this.props.itemListName)
			gtmItemsData(
				ItemsDataEvent.SELECT_ITEM,
				convertContentToItemData(
					[this.props.data],
					this.props.itemListName,
					ItemDataVariant.ARTICLE
				)
			)
	}

	render() {
		const { title, publication_date, primary_author, slug, journal } =
			this.props?.data ?? {}
		const { isSearch, type, isPublishedArticle } = this.props

		return (
			<ProfilePostWrapper
				style={{
					display:
						this.state.isDeleted || title === undefined
							? "none"
							: "block"
				}}
			>
				<Row
					gutterWidth={10}
					justify="start"
					style={{ width: this.props.width ?? "100%" }}
				>
					<Col
						xs={1}
						onClick={() =>
							!this.props.disablePush && push(`/post/${slug}`)
						}
					>
						<PostAvatar
							style={{
								background: this.pickGradient(type)
							}}
						/>
					</Col>
					<Col
						xs={11}
						style={{
							boxShadow: "2px 2px 4px rgba(33, 33, 33, 0.1)",
							borderRadius: "10px",
							background: "white",
							padding: 0
						}}
					>
						{isSearch ? (
							<CardInfo onClick={() => this.handleClick()}>
								<h5>{title}</h5>
								<Row
									justify="between"
									style={{ padding: "0 15px" }}
								>
									<span className="lighter">
										{renderPublicationDate(
											publication_date
										)}
										<br />
										{journal?.name}
									</span>
									<span style={{ textAlign: "left" }}>
										{t("common.mainAuthor")} <br />
										{primary_author}
									</span>
								</Row>
							</CardInfo>
						) : isPublishedArticle ? (
							<CardInfo onClick={() => this.handleClick()}>
								<h5>{title}</h5>
								<Row
									justify="between"
									style={{ padding: "0 15px" }}
								>
									<span className="lighter">
										{renderPublicationDate(
											publication_date
										)}
										<br />
										{journal?.name}
									</span>
									<span style={{ textAlign: "left" }}>
										{t("common.mainAuthor")} <br />
										{primary_author}
									</span>
								</Row>
							</CardInfo>
						) : (
							<Swipeout
								style={{ borderRadius: "10px" }}
								disabled={this.props.disabled}
								right={[
									{
										text: (
											<CustomIcon
												iconName="trash"
												color="white"
											/>
										),
										onPress: () => {
											this.props.onRemove()
											this.setState({ isDeleted: true })
										},
										style: {
											backgroundColor: "red",
											color: "white",
											borderRadius: "0px 10px 10px 0px"
										}
									}
								]}
							>
								<CardInfo
									onClick={() =>
										!this.props.disablePush &&
										push({
											pathname: `/post/${slug}`,
											state: {
												isLastRead:
													this.props.isLastRead ??
													false
											}
										})
									}
								>
									<h5>{title}</h5>
									<Row
										justify="between"
										style={{ padding: "0 15px" }}
									>
										<span className="lighter">
											{renderPublicationDate(
												publication_date
											)}
											<br />
											{journal?.name}
										</span>
										<span style={{ textAlign: "left" }}>
											{t("common.mainAuthor")} <br />
											{primary_author}
										</span>
									</Row>
								</CardInfo>
							</Swipeout>
						)}
					</Col>
				</Row>
			</ProfilePostWrapper>
		)
	}
}

export default ProfilePost

const ProfilePostWrapper = styled.div`
	margin: 10px 0;
	cursor: pointer;
`

const PostAvatar = styled.div`
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	background: linear-gradient(180deg, #ffd000 0%, #ffa100 100%);
	box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
	border-radius: 8px;
`

const CardInfo = styled.div`
	background: #fff;
	padding: 15px 20px;

	border-radius: 10px;
	font-family: "Poppins";
	color: #212121;

	h5 {
		font-family: "Poppins";
		font-size: 15px;
		font-weight: 700;
		margin: 10px 0;
	}

	span {
		font-size: 9px;
		line-height: 12px;
		font-weight: 300;
		margin-top: 10px;
	}
`
