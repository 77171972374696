import React, { Component } from "react"
import styled from "styled-components"
import { Slider } from "@mui/material"
import { Link, withRouter } from "react-router-dom"
import { userApi } from "../../redux/user/user.service"
import { connect } from "react-redux"
import { store } from "../../redux"
import { setPlaylists, setUser } from "../../redux/user/user.reducer"
import customToast from "../CustomToast"
import PlaylistSheet from "../playlists/PlaylistSheet"
import ShareIcon from "../../assets/icons/card_share.svg"
import LikeIcon from "../../assets/icons/card_like.svg"
import EditorialIcon from "../../assets/icons/card_editorial.svg"
import LikeActiveIcon from "../../assets/icons/card_like_active.svg"

import VolumeOn from "../../assets/icons/video-player/volume-on.svg"
import VolumeOff from "../../assets/icons/video-player/volume-off.svg"
import { kycMiddleware } from "../../tools/utils"
import { androidShare } from "../../tools/android"
import i18n from "../../config/i18n"
import { t } from "i18next"
import { isAndroid } from "react-device-detect"
import ApiVideoPlayer from "@api.video/react-player"
import { addContentToPlaylist } from "../../services/playlists.services"
import { iosPlaySound } from "../../tools/ios"
import {
	ItemDataEventListName,
	ItemDataVariant,
	ItemsDataEvent,
	SoundEffect
} from "../../interfaces"
import { saveContentToPlaylists } from "../../services/contentApi"
import { incrementUserMetric } from "../../services/user.services"

import { getFirebaseToken } from "../../services/firebase"
import { pickUserImage } from "../../services/image.services"
import {
	convertContentToItemData,
	gtmItemsData
} from "../../tools/reactgaEvents"

class StoryVideo extends Component {
	playerRef = React.createRef(null)
	controlsRef = React.createRef()
	navbarRef = React.createRef()

	videoWatchTimer = null

	state = {
		playing: false,
		played: 0,
		isLiked:
			!!this.props.user.user &&
			!!this.props.user.user.likedContent.some(
				(el) => el._id === this.props.story._id
			),
		isSaved: false,
		showSave: false,
		volumeMode: "off",
		showVolumeIcon: false,
		isMuted: true,
		videoViewed: false
	}

	componentDidMount() {
		// Check if video was saved in a playlist
		this.props.getUser().then(() => {
			if (this.props.user.saves) {
				const isSaved = this.props.user.saves.find(
					(storyId) => storyId === this.props.story._id
				)
				this.setState({ isSaved })
			}
		})

		// Des vidéos se lancent toute seules au début sans raison...
		this.checkInterval = setInterval(() => {
			if (!this.props.visible) {
				this.setState({ playing: false })
				this.playerRef.current?.pause()
				this.playerRef.current?.setCurrentTime(0)
			}
		}, 1000)
	}

	componentDidUpdate() {
		if (this.props.visible && !this.state.videoViewed) {
			this.setState({ videoViewed: true })
			setTimeout(() => {
				if (this.state.videoViewed) {
					incrementUserMetric("contentViews").then(({ metrics }) => {
						store.dispatch(
							setUser({ ...this.props.user.user, metrics })
						)
					})
				}
			}, 15000)
		} else if (!this.state.playing) {
			clearTimeout(this.videoWatchTimer)
			if (this.state.videoViewed) this.setState({ videoViewed: false })
		}

		if (this.props.visible && !this.state.playing) {
			this.setState({ playing: true })
			this.handleToggleVolumeIcon(this.state.isMuted ? "off" : "on", 2000)
			this.playerRef.current?.play()
		} else if (!this.props.visible && this.state.playing) {
			this.playerRef.current?.pause()
			this.playerRef.current?.setCurrentTime(0)
			this.setState({ playing: false })
		}
	}

	componentWillUnmount() {
		clearInterval(this.checkInterval)
		clearTimeout(this.videoWatchTimer)
	}

	handlePlayerReady = (ready) => {
		this.setState({ ready })
		this.handleToggleVolumeIcon("off", 4500)
		if (this.props.visible) {
			this.setState({ playing: true })
			gtmItemsData(
				ItemsDataEvent.VIDEO_PLAYED,
				convertContentToItemData(
					[this.props.story],
					ItemDataEventListName.VIDEO_ARTICLE_PAGE,
					ItemDataVariant.VIDEO_STORY
				)
			)
		}
	}

	handlePlayPause = () => {
		this.setState({ isMuted: !this.state.isMuted })
		this.handleToggleVolumeIcon(!this.state.isMuted ? "off" : "on")
	}

	handleLike = async () => {
		const { isLiked } = this.state

		const isAuthentified = await getFirebaseToken()

		if (!isAuthentified) {
			customToast(t("toast.error.notLogin"))
			return this.props.history.replace("/login")
		}

		if (!isLiked) {
			gtmItemsData(
				ItemsDataEvent.VIDEO_LIKE,
				convertContentToItemData(
					[this.props.story],
					ItemDataEventListName.VIDEO_ARTICLE_PAGE,
					ItemDataVariant.VIDEO_STORY
				)
			)
		}

		const body = {
			slug: this.props.story.slug,
			action: isLiked ? "unlike" : "like"
		}

		this.setState({ isLiked: !isLiked })
		iosPlaySound(SoundEffect.LIKE)
		customToast(
			isLiked
				? t("toast.success.unlikedVideo")
				: t("toast.success.likedVideo")
		)

		const { data } = await this.props.patchUserContent(body)
		store.dispatch(
			setUser({
				...this.props.user.user,
				likedContent: data[0].likedContent
			})
		)
	}

	handleShare = async () => {
		const { slug, title, journal, company, publisher, congress } =
			this.props.story
		const url =
			window.location.origin +
			`/video/story/${slug}?shared=${
				this.props.user?.user?.uid ?? null
			}&org=${
				this.props.user.user?.organisations?.[0]?.uid || "null"
			}&org=${this.props.user.user?.organisations?.[0]?.uid || "null"}`

		const publisherName =
			publisher === "journal"
				? journal.name
				: publisher === "congress"
				? congress.name
				: company.name

		const shareText =
			i18n.resolvedLanguage === "fr"
				? `Je viens de regarder cette vidéo qui pourrait t'intéresser : ${title} par ${publisherName}`
				: `I’ve just watched this video you may be interested in: ${title} by ${publisherName}`

		const isAuthentified = await getFirebaseToken()

		if (isAuthentified && !this.props.user.user.profession) {
			kycMiddleware(this, t("toast.error.notOnboarded.shareVideo"))
		} else if (isAndroid) {
			androidShare(url, shareText)
		} else if (navigator.share) {
			navigator
				.share({
					title: document.title,
					text: shareText,
					url
				})
				.then(async () => {
					await this.props.patchUserContent({ slug, action: "share" })
					customToast(t("toast.success.sharedVideo"))
					iosPlaySound(SoundEffect.SHARE)
					gtmItemsData(
						ItemsDataEvent.VIDEO_SHARE,
						convertContentToItemData(
							[this.props.story],
							ItemDataEventListName.VIDEO_ARTICLE_PAGE,
							ItemDataVariant.VIDEO_STORY
						)
					)
				})
		} else {
			customToast(t("toast.error.browser"))
		}
	}

	handleShowSaveSheet = async () => {
		const isAuthentified = await getFirebaseToken()

		if (!isAuthentified) {
			customToast(t("toast.error.notLogin"))
			return this.props.history.replace("/login")
		}

		this.setState({ showSave: true })
	}

	handleToggleVolumeIcon = (volumeMode, timeout = 1500) => {
		clearTimeout(this.volumeTimeoutIconId)

		this.setState(
			{
				showVolumeIcon: true,
				volumeMode
			},
			() => {
				this.volumeTimeoutIconId = setTimeout(() => {
					this.setState({
						showVolumeIcon: false
					})
				}, timeout)
			}
		)
	}

	handleTimeUpdate = (currentTime) => {
		if (!this.props.visible && this.state.playing) {
			this.playerRef.current?.setCurrentTime(0)
			this.setState({ playing: false })
		} else {
			this.setState({ currentTime })
		}
	}

	addPlaylistSuccess = ({ contentIsInPlaylist }) => {
		this.setState({ showSave: false, isSaved: contentIsInPlaylist })
	}

	confirmAddPlaylist = async (checkedPlaylists) => {
		const { contentIsInPlaylist } = saveContentToPlaylists(
			this.props.story,
			checkedPlaylists
		)

		this.addPlaylistSuccess({ contentIsInPlaylist })
	}

	onConfirmCreatePlaylist = (newPlaylist) => {
		const playlist = {
			...newPlaylist,
			contentVideos: [this.props.story._id]
		}

		store.dispatch(setPlaylists([playlist, ...this.props.user.playlists]))
		addContentToPlaylist(playlist._id, this.props.story._id)

		this.setState({
			isSaved: true
		})

		customToast(t("toast.success.savedVideo"))

		gtmItemsData(
			ItemsDataEvent.VIDEO_SAVE,
			convertContentToItemData(
				[this.props.story],
				ItemDataEventListName.VIDEO_ARTICLE_PAGE,
				ItemDataVariant.VIDEO_STORY
			)
		)
	}

	getPublisherData = () => {
		const { story } = this.props

		switch (story.publisher) {
			case "journal":
				return {
					path: `/journal/${story.journal?.uid}`,
					image: story.journal?.image?.url
				}

			case "congress":
				return {
					path: null,
					image: story.congress?.image?.url
				}

			case "user":
				return {
					path: `/profile/user/${story.user?.uid}`,
					image: pickUserImage(story.user)
				}

			default:
				return {
					path: `/company/${story.company?._id}`,
					image: story.company?.images[0]?.url
				}
		}
	}

	saveLastScrollPosition = () => {
		this.props.forYouOnclick && this.props.forYouOnclick()

		this.props.videoIndex &&
			localStorage.setItem("storyFeedLastPosition", this.props.videoIndex)
	}

	render() {
		const { story, height } = this.props

		const videoPublisher = this.getPublisherData()

		return (
			<VideoWrapper
				className={this.state.started ? "started" : ""}
				style={{ height }}
			>
				{this.state.showSave && (
					<PlaylistSheet
						type="video"
						playlists={this.props.user?.playlists}
						showSheet={this.state.showSave}
						onClose={() => this.setState({ showSave: false })}
						article={story}
						getPlaylists={() => this.props.getPlaylists()}
						getUser={() => this.props.getUser()}
						getSaves={() => this.props.getSaves()}
						onConfirm={this.confirmAddPlaylist.bind(this)}
						onConfirmCreatePlaylist={this.onConfirmCreatePlaylist.bind(
							this
						)}
					/>
				)}
				<div
					className="player-wrapper"
					onClick={this.handlePlayPause.bind(this)}
				/>
				{this.state.started && (
					<div>
						<div
							className={
								!!this.state.showVolumeIcon
									? "volume-icons"
									: "volume-icons hidden"
							}
						>
							{this.state.volumeMode === "on" ? (
								<img src={VolumeOn} alt="" />
							) : (
								<img src={VolumeOff} alt="" />
							)}
						</div>
						<div className="controllers">
							<div
								onClick={() => {
									this.saveLastScrollPosition()
									videoPublisher.path &&
										this.props.history.push(
											videoPublisher.path
										)
								}}
								className={`publisher-logo ${
									story.publisher === "user" ? "is-user" : ""
								}`}
							>
								<img src={videoPublisher.image} alt="" />
							</div>

							<div onClick={this.handleShare.bind(this)}>
								<img src={ShareIcon} alt="" />
							</div>
							<div onClick={this.handleLike.bind(this)}>
								<img
									src={
										this.state.isLiked
											? LikeActiveIcon
											: LikeIcon
									}
									alt=""
								/>
							</div>
							{/* <div onClick={this.handleShowSaveSheet.bind(this)}>
                <img
                  src={this.state.isSaved ? SaveActiveIcon : SaveIcon}
                  alt=''
                />
              </div> */}

							{!!story.associatedArticles[0]?.slug && (
								<Link
									onClick={() =>
										this.saveLastScrollPosition()
									}
									to={
										"/post/" +
										story.associatedArticles[0].slug
									}
								>
									<img src={EditorialIcon} alt="" />
								</Link>
							)}
						</div>
					</div>
				)}

				<ApiVideoPlayer
					ref={this.playerRef}
					video={{ id: story.apiVideo.videoId }}
					style={{
						width: "auto",
						height: "100%"
					}}
					videoStyleObjectFit={
						window.innerWidth > 550 ? "fit" : "cover"
					}
					chromeless
					autoplay
					muted={this.state.isMuted}
					onReady={this.handlePlayerReady.bind(this)}
					onFirstPlay={() => this.setState({ started: true })}
					onEnded={() => this.props.onEnded()}
					onTimeUpdate={this.handleTimeUpdate.bind(this)}
					onDurationChange={(duration) => this.setState({ duration })}
				/>

				<div className="player-slider" ref={this.controlsRef}>
					<Slider
						size="small"
						sx={{
							color: "#fff",
							width: "90%",
							borderRadius: 0,
							margin: "auto",
							display: "block",
							visibility: this.state.started
								? "visible"
								: "hidden"
						}}
						value={
							this.state.duration && this.state.currentTime
								? (this.state.currentTime /
										this.state.duration) *
								  100
								: 0
						}
						onChange={(_, percent) => {
							const currentTime =
								(percent * this.state.duration) / 100
							this.playerRef.current?.pause()
							this.setState({
								currentTime
							})
						}}
						onChangeCommitted={(_, percent) => {
							this.playerRef.current?.setCurrentTime(
								(percent * this.state.duration) / 100
							)
							this.playerRef.current?.play()
						}}
						step={2}
					/>
				</div>
			</VideoWrapper>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate,
	getSaves: userApi.endpoints.getSaves.initiate,
	getPlaylists: userApi.endpoints.getPlaylists.initiate,
	patchUserContent: userApi.endpoints.patchUserContent.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withRouter(StoryVideo))

const VideoWrapper = styled.div`
	background: #000;
	height: 100%;
	position: relative;
	box-sizing: border-box;

	.controllers {
		position: absolute;
		right: 21px;
		bottom: 20%;
		z-index: 2 !important;

		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 32px;

		img {
			width: 40px;
			height: 40px;
		}

		.publisher-logo {
			background: #fff;
			width: 56px;
			height: 56px;
			object-fit: contain;
			overflow: hidden;
			border-radius: 112px;
			box-sizing: border-box;
			padding: 10px;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}

			&.is-user {
				padding: 0;

				img {
					object-fit: cover;
				}
			}
		}
	}

	.player-wrapper {
		box-sizing: border-box;
		background: transparent;
		position: absolute;
		z-index: 1 !important;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
	}

	.volume-icons {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		opacity: 1;
		transition: opacity ease-out 0.3s;

		&.hidden {
			opacity: 0;
		}

		img {
			width: 54px;
			height: 54px;
		}
	}

	.player-slider {
		position: absolute;
		z-index: 2 !important;
		bottom: 3vh;
		left: 0;
		right: 0;
		background: transparent;
		box-sizing: border-box;
		padding: 16px 0;
	}
`
