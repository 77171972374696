import React, { Component } from "react"
import { t } from "i18next"
import SimpleNavbar from "../../../components/SimpleNavbar"
import { StyledOnboarding } from "./onboardingProfession"
import { UploadAvatarButton } from "../../editProfilePage/editAvatarPage"
import CustomIcon from "../../../components/CustomIcon"
import { CustomButton, Spacer } from "../../../components/global"
import { defaultAvatarImages } from "../../../services/image.services"

class OnboardingAvatar extends Component {
	state = {
		customAvatarFile: "",
		selectedDefaultAvatar: ""
	}

	componentDidMount() {
		const { state } = this.props.location
		this.setState({ ...state })
		window.scrollTo(0, 0)
	}

	handleChangeAvatarCustomPreprocess = (e) => {
		const file = e.target.files[0]
		const blob = URL.createObjectURL(file)

		setTimeout(() => this.compressImage(file, blob), 500)

		// Reset image when switching to default then back to upload the same image
		e.target.value = ""
	}

	async compressImage(file, blob) {
		const reader = new FileReader()
		reader.readAsDataURL(file)

		reader.onload = async () => {
			const img = document.createElement("img")
			img.src = blob

			img.onload = () => {
				const canvas = document.createElement("canvas")

				// Avatar dimensions
				const MAX_WIDTH = 600
				const MAX_HEIGHT = 600
				let width = img.width
				let height = img.height

				if (width > height) {
					if (width > MAX_WIDTH) {
						height *= MAX_WIDTH / width
						width = MAX_WIDTH
					}
				} else {
					if (height > MAX_HEIGHT) {
						width *= MAX_HEIGHT / height
						height = MAX_HEIGHT
					}
				}
				canvas.width = width
				canvas.height = height
				const ctx = canvas.getContext("2d")
				ctx.drawImage(img, 0, 0, width, height)

				const dataurl = canvas.toDataURL("image/jpeg")
				this.handleChangeAvatarCustom(dataurl)
			}
		}
	}

	handleChangeAvatarCustom = (url) => {
		this.setState({
			selectedDefaultAvatar: "",
			customAvatarFile: url,
			isEdited: true
		})
	}

	handleChangeAvatarDefault = (slug) => {
		this.setState({
			selectedDefaultAvatar: slug,
			customAvatarFile: null,
			isEdited: true
		})
	}

	handleGoNext() {
		const { state } = this

		this.props.history.push({
			// pathname: "/signup/onboarding/specialty/other",
			pathname: "/signup/success",
			state
		})
	}

	render() {
		const { customAvatarFile, selectedDefaultAvatar } = this.state

		return (
			<StyledOnboarding>
				<SimpleNavbar />
				<div className="header">
					<h2>{t("signup.onboarding.h2.avatar")}</h2>
					<h5>{t("signup.onboarding.h5.avatar")}</h5>
				</div>
				<div className="content-section">
					{customAvatarFile || selectedDefaultAvatar ? (
						<img
							className="avatar-image"
							src={
								customAvatarFile
									? customAvatarFile
									: defaultAvatarImages[selectedDefaultAvatar]
											?.default
							}
							alt=""
						/>
					) : (
						<div className="avatar-image" />
					)}

					<div className="upload-button">
						<UploadAvatarButton
							onChange={this.handleChangeAvatarCustomPreprocess.bind(
								this
							)}
						>
							<div className="btn-text">
								<CustomIcon iconName="camera" />
								{t("signup.onboarding.button.avatar")}
							</div>
						</UploadAvatarButton>
					</div>
					<Spacer />

					<h3>{t("signup.onboarding.h3.avatar")}</h3>
					<div className="default-grid">
						{Object.entries(defaultAvatarImages).map(
							([slug, imgSet]) => (
								<img
									onClick={() =>
										this.handleChangeAvatarDefault(slug)
									}
									key={slug + "keyarr"}
									src={imgSet.default}
									alt=""
									className={
										selectedDefaultAvatar === slug
											? "picked"
											: ""
									}
								/>
							)
						)}
					</div>
				</div>
				<div
					className={`footer ${
						!(customAvatarFile || selectedDefaultAvatar)
							? "hide"
							: ""
					}`}
				>
					<CustomButton
						onClick={this.handleGoNext.bind(this)}
						className="pink"
					>
						{t("signup.onboarding.finalPhrase")}
					</CustomButton>
				</div>
			</StyledOnboarding>
		)
	}
}

export default OnboardingAvatar
