import React, { Component } from "react"
import styled from "styled-components"
import Lottie from "lottie-react"
import JuisciFillTube from "../../assets/animations/tube-filling.json"
import { t } from "i18next"
import { patchUser } from "../../services/user.services"
import { followJournal } from "../../services/journals.services"
import { uploadAvatar } from "../../services/image.services"
import { connect } from "react-redux"
import { userApi } from "../../redux/user/user.service"
import { getArticleTags } from "../../services/articles.services"
import { withRouter } from "react-router-dom"
import { GTMBasicEvent, OrganisationUid } from "../../interfaces"
import { gtmBasicEvent } from "../../tools/reactgaEvents"
import { Trans } from "react-i18next"

class SuccessSignup extends Component {
	async componentDidMount() {
		window.scrollTo(0, 0)

		gtmBasicEvent(GTMBasicEvent.COMPLETE_ONBOARDING)

		if (!this.props.location.state) return (window.location.href = "/home")

		const {
			profession,
			specialty,
			customAvatarFile,
			selectedDefaultAvatar,
			otherSpecialties,
			format,
			firstname,
			lastname,
			birthdate,
			country
		} = this.props.location.state

		let data = {
			firstname,
			lastname,
			birthdate,
			firstname,
			country,
			profession: profession.uid,
			main_specialty: specialty.uid,
			medical_specialties: [...otherSpecialties.map((spe) => spe.uid)],
			preferredFormats: format
		}

		const tagsPromises = [specialty, ...otherSpecialties].map((spe) =>
			getArticleTags(spe.uid)
		)
		const tagsResults = await Promise.all(tagsPromises)
		const tags = tagsResults.reduce((acc, cur) => [...acc, ...cur], [])
		data = { ...data, tags: tags.map((el) => el.uid) }

		await patchUser(data)

		if (customAvatarFile || selectedDefaultAvatar) this.handleUpdateAvatar()
	}

	handleFollowJournals(journals) {
		if (journals.length > 1)
			setTimeout(
				() =>
					journals.forEach((slug, i) => {
						setTimeout(() => followJournal(slug), i * 1000)
					}),
				1000
			)
		else followJournal(journals[0])
	}

	async handleUpdateAvatar() {
		const { customAvatarFile, selectedDefaultAvatar } =
			this.props.location.state

		const formData = new FormData()

		let file
		if (selectedDefaultAvatar) {
			formData.set("pictureName", selectedDefaultAvatar)
		} else {
			await fetch(customAvatarFile)
				.then((res) => res.blob())
				.then(
					(blob) =>
						(file = new File([blob], "avatar", {
							type: "image/jpeg"
						}))
				)
			formData.set("file", file)
		}

		if (selectedDefaultAvatar || file) {
			try {
				await uploadAvatar(formData)
			} catch (error) {
				// Silent error
				console.error("Couldn't upload avatar", error.message)
			}
		}
	}

	handleComplete() {
		const userOrganisation = this.props.user.user?.organisations?.[0]?.uid

		// On supprime les spécialités et tags au niveau du localStorage
		localStorage.removeItem("stored_specialty")
		localStorage.removeItem("stored_tags")

		// On fait ensuite la redirection vers la homepage
		setTimeout(() => {
			switch (userOrganisation) {
				case OrganisationUid.SFRO:
					window.location.href =
						"/home?onboarding=complete&specialty=radiotherapy-oncology"
					break

				case OrganisationUid.SFR:
					window.location.href =
						"/home?onboarding=complete&specialty=radiology"
					break

				case OrganisationUid.BIOCODEX:
					window.location.href = "/home?onboarding=complete"
					break

				default:
					window.location.href = `/home?onboarding=complete&specialty=${this.props.location.state.specialty._id}`
					break
			}
		}, 2000)
	}

	render() {
		return (
			<StyledSignup>
				<div className="header">
					<h1>{t("signup.success.title")}</h1>
					<p>{t("signup.success.subtitle")}</p>
				</div>

				<Lottie
					animationData={JuisciFillTube}
					className="lottie-animation"
					loop={false}
					autoplay={true}
					onComplete={this.handleComplete.bind(this)}
				/>
				<h3>
					<Trans
						i18nKey="signup.success.JUiSCI"
						values={{ juice: "Ju", science: "Sci" }}
						components={{ 1: <span />, 3: <span /> }}
					/>
				</h3>
				<Trans
					i18nKey="signup.success.keepUpdated"
					values={{ juice: "Ju", science: "Sci" }}
					components={{ 1: <p /> }}
				/>
			</StyledSignup>
		)
	}
}
function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withRouter(SuccessSignup))

const StyledSignup = styled.div`
	height: 100vh;
	box-sizing: border-box;
	overflow-y: hidden;
	background: #fff;
	padding: 0px 21px 80px;
	overflow-y: hidden;
	white-space: pre-line;

	.header {
		color: #212121;
		text-align: center;
		display: block;
		margin: auto;
		background: #fff;
		z-index: 2;
		position: relative;
		padding-top: 140px;

		h1 {
			font-family: "Inter";
			font-style: normal;
			font-weight: 700;
			font-size: 32px;
			line-height: 120%;
			text-align: center;
			color: #ce0868;
			margin: 0;
		}

		p {
			margin: 0;
		}
	}

	h3 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 21px;
		line-height: 110%;
		text-align: center;
		color: #212121;
		margin: 0;

		span {
			color: #ff8800;
		}
	}

	.lottie-animation {
		height: 400px;
		/* transform: translateY(-150px); */
		z-index: 1;
		margin: -150px auto 24px;
	}

	p {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		white-space: pre-wrap;
	}
`
