import React, { Component } from "react"
import styled from "styled-components"
import { Row } from "react-grid-system"
import {
	CustomButton,
	DisabledCustomButton,
	Spacer
} from "../../components/global"
import { CustomInput } from "../../components/CustomInput"
import { auth, loginWithApple, loginWithGoogle } from "../../services/firebase"
import CustomNavBar from "../../components/CustomNavbar"
import { withTranslation } from "react-i18next"
import Loader from "../../components/Loader"
import { Link } from "react-router-dom"
import { checkEmail } from "../../services/auth.services"
import customToast from "../../components/CustomToast"
import { t } from "i18next"
import { gtmPageView } from "../../tools/reactgaEvents"

class PrevSignupPage extends Component {
	state = {
		email: "",
		loading: false
	}

	componentDidMount() {
		window.scrollTo(0, 0)
		gtmPageView()

		auth.onAuthStateChanged(async (user) => {
			if (user) this.setState({ loading: true })
		})
	}

	async handleSubmit(e) {
		e.preventDefault()

		try {
			const { isExisting, massImportNeedsOnboarding } = await checkEmail(
				this.state.email
			)
			if (!isExisting)
				this.props.history.push({
					pathname: "/signup",
					state: {
						email: this.state.email
					}
				})
			else if (massImportNeedsOnboarding) {
				this.props.history.push({
					pathname: "/signup/magiclink",
					state: {
						email: this.state.email
					}
				})
			} else customToast(t("toast.error.mailAlready"))
		} catch (error) {
			customToast(t("toast.error.global"))
			throw error
		}
	}

	handleOAuth(provider) {
		if (provider === "apple") loginWithApple()
		else if (provider === "google") loginWithGoogle()
	}

	render() {
		const { email, loading } = this.state
		return (
			<>
				<CustomNavBar />
				{loading ? (
					<Loader />
				) : (
					<PrevSignWrapper>
						<Row justify="center" align="center">
							<PrevSignTitle>
								<h1>
									{this.props.t("signup.preSignup.title")}
								</h1>
								<p>
									{this.props.t("signup.preSignup.subtitle")}
								</p>
							</PrevSignTitle>

							<form
								onSubmit={this.handleSubmit.bind(this)}
								style={{ width: "100%" }}
							>
								<CustomInput
									placeholder="email@address.com"
									filled={this.state.email}
									type="email"
									onInput={(e) =>
										this.setState({ email: e.target.value })
									}
									style={{ width: "100%" }}
								/>
								<Spacer height="15px" />

								{email !== "" ? (
									<CustomButton
										onClick={() =>
											this.setState({
												showActionSheet: false
											})
										}
										type="submit"
									>
										{this.props.t(
											"signup.preSignup.registerButton"
										)}
									</CustomButton>
								) : (
									<DisabledCustomButton
										disabled
										className="disabled"
									>
										{this.props.t(
											"signup.preSignup.registerButton"
										)}
									</DisabledCustomButton>
								)}
							</form>
							<p className="credits">
								{t("login.credits.byCreating")}{" "}
								<Link
									className="link"
									to={{
										pathname: "/terms",
										state: {
											isPush: true,
											hideTitle: true
										}
									}}
								>
									{t("settings.terms")}
								</Link>{" "}
								{t("login.credits.and")}{" "}
								<Link
									className="link"
									to={{
										pathname: "/policy",
										state: {
											isPush: true,
											hideTitle: true
										}
									}}
								>
									{t("settings.policies")}
								</Link>
								.
							</p>
						</Row>
					</PrevSignWrapper>
				)}
			</>
		)
	}
}

export default withTranslation()(PrevSignupPage)

const PrevSignWrapper = styled.div`
	padding: 0px 40px;
	height: 100vh;
	text-align: center;
	background: #ecf0f5;

	p.credits {
		font-family: "Roboto" !important;
		font-style: normal;
		font-weight: 600;
		font-size: 13px;
		line-height: 140%;
		text-align: center;
		color: #212121;
		padding: 0 30px;
		margin-top: 50px;

		.link {
			color: #ff8800;
			text-decoration: underline;
		}
	}
`

const PrevSignTitle = styled.div`
	color: #212121;
	text-align: center;
	margin-top: 100px;

	h1 {
		font-family: "Poppins";
		font-size: 24px;
		line-height: 33.6px;
		margin-bottom: 0px;
	}

	p {
		font-family: Roboto !important;
		font-size: 16px;
		margin-top: 5px;
		margin-bottom: 35px;
	}
`
