import { Modal } from "antd-mobile"
import React from "react"
import { withTranslation } from "react-i18next"
import Disclaimer from "../../assets/images/disclaimer.svg"
import Hand from "../../assets/images/hand.svg"
import disableScroll from "disable-scroll"
import { store } from "../../redux"
import { IUser } from "../../interfaces/user.interface"
import { OrganisationUid } from "../../interfaces"
import i18n from "../../config/i18n"
import styled from "styled-components"
import { t } from "i18next"
import { CustomButton } from "../global"

const disclaimerTakedaContent = {
	en: "Attention, the data presented may not be validated by French health authorities and therefore should not be implemented (except under marketing authorization).\n\n This content is the sole responsibility of JUISCI and its Medical Team, who are responsible for its objectivity.",
	fr: "Attention, les données présentées sont susceptibles de ne pas être validées par les autorités de santé françaises et ne doivent donc pas être mises en pratique (hors AMM).\n\nCe contenu est sous la seule responsabilité de JUISCI et de son Équipe Médicale qui sont garants de son objectivité."
}

const topTextTakeda = {
	fr: "Réalisé avec le soutien institutionnel de Takeda France.",
	en: "Developed with the institutional support of Takeda France."
}

function closest(el: any, selector: any) {
	const matchesSelector =
		el.matches ||
		el.webkitMatchesSelector ||
		el.mozMatchesSelector ||
		el.msMatchesSelector
	while (el) {
		if (matchesSelector.call(el, selector)) {
			return el
		}
		el = el.parentElement
	}
	return null
}

interface DisclaimerProps {
	onPress: () => void
	t: (value: string) => any
	visible: boolean
}

class DisclaimerModal extends React.Component<DisclaimerProps> {
	componentDidMount() {
		disableScroll.on()
	}

	onWrapTouchStart = (e: any) => {
		// fix touch to scroll background page on iOS
		if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
			return
		}
		const pNode = closest(e.target, ".am-modal-content")
		if (!pNode) {
			e.preventDefault()
		}
	}

	handlePressAccept = () => {
		this.props.onPress()
		localStorage.setItem("acceptedRulesTakeda", "true")
	}

	render() {
		const user = store.getState().user.user as IUser
		const isTakeda =
			user?.organisations?.[0]?.uid === OrganisationUid.TAKEDA
		const language = i18n.resolvedLanguage as "fr" | "en"
		const isVisible = !isTakeda
			? this.props.visible
			: !JSON.parse(
					localStorage.getItem("acceptedRulesTakeda") || "false"
			  )

		return (
			<StyledModal>
				{isVisible ? (
					<>
						{isTakeda && (
							<div className="top-text">
								{topTextTakeda[language]}
							</div>
						)}
					</>
				) : null}
				<Modal
					className="scrollbar-hidden"
					visible={isVisible}
					transparent
					maskClosable={true}
					onClose={() => this.setState({ showModal: false })}
					title={
						<StyledHeader>
							<h3>{t("modal.disclaimer.title")}</h3>
							<img
								className="disclaimer-hand-image"
								src={Hand}
								alt=""
							/>
						</StyledHeader>
					}
					footer={[
						{
							text: (
								<CustomButton
									className="white small no-border"
									style={{ width: "fit-content" }}
								>
									{t("modal.disclaimer.accept")}
								</CustomButton>
							) as any,
							onPress: this.handlePressAccept
						}
					]}
					wrapProps={{ onTouchStart: this.onWrapTouchStart }}
				>
					<StyledContent className="scrollbar-hidden">
						{isTakeda
							? disclaimerTakedaContent[language]
							: t("modal.disclaimer.body")}
					</StyledContent>
				</Modal>
			</StyledModal>
		)
	}
}

export default withTranslation()(DisclaimerModal)

const StyledModal = styled.div`
	.top-text {
		color: white;
		font-family: Inter;
		font-size: 16px;
		font-weight: 600;
		line-height: 22px;
		letter-spacing: -0.02em;
		text-align: center;

		z-index: 9999;
		text-align: center;
		position: absolute;
		margin: 0 24px;
		top: calc(10dvh);
	}
`

const StyledHeader = styled.div`
	h3 {
		font-family: Inter;
		font-size: 24px;
		font-weight: 900;
		line-height: 110%;
		letter-spacing: 0em;
		text-align: center;
		white-space: pre-line;
	}
`

const StyledContent = styled.div`
	max-height: 60dvh;
	overflow: scroll;
	width: 100%;
	color: #212121;

	padding: 16px 0;
	white-space: pre-line;
`
