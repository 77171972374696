import React, { Component } from "react"
import { Row } from "react-grid-system"
import CustomIcon from "./CustomIcon"
import { t } from "i18next"

/**
 * PROPS
 * style: style of the button
 * active: if the button is active
 * onClick: function to execute on click
 */

class FilterButton extends Component {
	render() {
		return (
			<div
				style={{
					background: this.props.active ? "white" : "#313B42",
					padding: 6,
					minWidth: 50,
					height: 32,
					borderRadius: "100px",
					boxSizing: "border-box",
					...this.props.style
				}}
				onClick={() => this.props.onClick()}
			>
				<Row style={{ margin: 0 }} align="center">
					<CustomIcon
						iconName="filter"
						color={this.props.active ? "#212121" : "white"}
						style={{ marginLeft: 10 }}
						scale={0.8}
					/>
					<span
						style={{
							fontSize: "14px",
							fontWeight: 700,
							fontFamily: "Inter",
							marginLeft: "8px",
							marginRight: 10,
							marginTop: -2,
							display: this.props.active ? "block" : "none"
						}}
					>
						{t("settings.editFilters")}
					</span>
				</Row>
			</div>
		)
	}
}

export default FilterButton
