import React, { Component } from "react"
import styled from "styled-components"
import "./home.css"
import CustomNavbar from "../../components/CustomNavbar"
import { getArticleTags } from "../../services/articles.services"
import { RemoveScrollBar } from "react-remove-scroll-bar"
import ArticleCard from "../../components/ArticleCard"
import { CustomButton, Spacer } from "../../components/global"
import disableScroll from "disable-scroll"
import Loader, { SingleLoader } from "../../components/Loader"
import { withTranslation } from "react-i18next"
import InfiniteScroll from "react-infinite-scroll-component"
import { isNativeIOS } from "../../tools/ios"
import {
	convertContentToItemData,
	gtmFilter,
	gtmItemsData
} from "../../tools/reactgaEvents"
import { articlesApi } from "../../redux/articles/articles.service"
import { connect } from "react-redux"
import DisclaimerModal from "../../components/home/DisclaimerModal"
import { searchApi } from "../../redux/search/search.service"
import { userApi } from "../../redux/user/user.service"
import CustomIcon from "../../components/CustomIcon"
import CustomSheet from "../../components/CustomSheet"
import CustomCheckbox from "../../components/CustomCheckbox"
import FadeIn from "react-fade-in"
import { getAnnouncements } from "../../services/user.services"
import { getPublicPlaylist } from "../../services/playlists.services"
import PlaylistCard from "../../components/PlaylistCard"
import { store } from "../../redux"
import SkeletonCard from "../../components/skeleton/SkeletonCard"
import {
	resetArticles,
	setPublicPlaylists,
	setPublicPlaylistsFiltered
} from "../../redux/articles/articles.reducer"
import { Skeleton } from "@mui/material"
import DownloadNativeModal from "../../components/home/DownloadNativeModal"
import { isNativeAndroid } from "../../tools/android"
import {
	ItemDataEventListName,
	ItemDataVariant,
	ItemsDataEvent,
	OrganisationUid
} from "../../interfaces"
import HomeNavigation from "../../components/home/HomeNavigation"
import {
	getPageBackground,
	SPECIALTIES_EXCEPTION,
	TAKEDA_TAGS_EXCEPTION
} from "../../tools/utils"
import { getFirebaseToken } from "../../services/firebase"
import FilterButton from "../../components/FilterButton"
import i18n from "../../config/i18n"
import AnnouncementModal from "../../components/home/AnnouncementModal"

const SPONSORED_OFFSET = 2

class HomePage extends Component {
	player = React.createRef()

	state = {
		articles: [],
		playlists: [],
		userLoaded: false,
		currentYear: 0,

		// Filtering system...
		currentSpecialty: JSON.parse(localStorage.getItem("stored_specialty")),
		articleTags: [],
		currentTags: [],
		savedArticles: [],

		// Modals...
		showDisclaimerModal: false,
		showNativeModal: false,
		announcement: null,

		// Infinite Scroll...
		offsetArticles: 0,
		loadMore: true,
		allLoaded: false,

		// Article specialties
		articleSpecialties: [],

		// Language for articles...
		contentLanguage: "en",

		// Filters sheet
		openFilterSheet: false,
		hideFields: false,

		// Public playlist
		sponsored: this.props.articles.sponsoredFiltered
	}

	componentWillMount() {
		const query = new URLSearchParams(this.props.location.search)
		// SFRO redirect to specialty pre-selected page...

		const settings =
			this.props.user?.user?.organisations?.[0]?.organisationSettings

		if (!settings) return

		if (
			settings?.medicalSpecialties?.length &&
			!query.get("specialty") !== settings.medicalSpecialties?.[0]
		)
			this.props.history.replace(
				`/home?specialty=${settings.medicalSpecialties?.[0]}`
			)
	}

	async componentDidMount() {
		if (await getFirebaseToken()) this.fetchProfile()
		this.loadPage()
	}

	async componentWillUnmount() {
		this.saveLocalSpecialties()
		this.fetchPublicPlaylist()

		localStorage.removeItem("prevPath")
	}

	async fetchProfile() {
		try {
			// Get user
			await this.props.getUser()
			await this.props.getSaves()

			// Start listing queries
			const queries = []

			// If user has profession
			if (!!this.props.user.user.profession) {
				queries.push(this.props.getPlaylists)
			}

			// If followers haven't been initialized
			if (this.props.user.followers.isUninitialized) {
				queries.push(
					this.props.getFollowers,
					this.props.getPendingFollowers,
					this.props.getFollowing,
					this.props.getPendingFollowing
				)
			}

			// Run parallel queries
			await Promise.all(queries)
		} catch (error) {
			console.log("Err HomePage fetchProfile", error.message)
		}
	}

	async loadPage() {
		// Getting query params from URL...
		const query = new URLSearchParams(this.props.location.search)

		// Setting default contentLanguage...
		const lang = localStorage.getItem("contentLanguage") ?? "en"
		localStorage.setItem("contentLanguage", lang)
		this.setState({ contentLanguage: lang })

		// Get previously selected field and tags
		if (localStorage.getItem("stored_specialty"))
			this.getPreviousFieldsTags()

		this.setState({
			contentLanguage: localStorage.getItem("contentLanguage")
		})

		if (
			!localStorage.getItem("stored_specialty") &&
			!query.get("specialty")
		) {
			this.fetchArticles()
		} else if (!!query.get("specialty")) {
			this.setState({ articlesLoading: true }) // Pour afficher directement les skeleton cards

			if (this.props.articles.specialties.isUninitialized)
				await this.fetchArticlesSpecialties(query.get("specialty"))

			const currentSpecialty = this.props.articles.specialties.list.find(
				(el) => el._id === query.get("specialty")
			)
			this.filterSpecialty(currentSpecialty)
		}

		this.fetchArticlesSpecialties()
		this.fetchPublicPlaylist()

		// Before refreshing (F5) page
		window.onbeforeunload = () => {
			this.saveLocalSpecialties()
			window.scrollTo(0, 0)
			return
		}

		const acceptedRules = localStorage.getItem("acceptedRules")
		if (acceptedRules === "true") {
			window.scrollTo(0, 0)
			disableScroll.off()
			this.setState({ showDisclaimerModal: false })
		} else {
			this.setState({ showDisclaimerModal: true })
		}

		if (this.props?.location?.state?.articles?.length > 0) {
			this.setState({
				articles: this.props.location?.state.articles
			})
		}

		if (query.get("onboarding") === "complete") {
			this.setState({ showNativeModal: true })
		} else {
			this.handleAnnouncements()
		}
	}

	fetchArticles = async (showLoader = false) => {
		try {
			const {
				offsetArticles,
				currentSpecialty,
				currentYear,
				currentTags
			} = this.state

			const contentLanguage = localStorage.getItem("contentLanguage")
			const stored_tags =
				JSON.parse(localStorage.getItem("stored_tags")) || ""

			const settings =
				this.props.user?.user?.organisations?.[0]?.organisationSettings

			if (showLoader) {
				this.setState({ articlesLoading: true })
			}

			let reqParams = {
				offset: offsetArticles ?? 0,
				publication_year: currentYear ?? 0,
				lang: contentLanguage ?? this.props.i18n.language
			}

			if (settings?.discoveryFilter) {
				reqParams = {
					...reqParams,
					medical_specialties: currentSpecialty?.uid
						? currentSpecialty.uid
						: "",
					tags:
						currentTags?.length > 0
							? [
									...this.state.currentTags.map(
										(el) => el.uid
									)
							  ].toString()
							: stored_tags.length
							? stored_tags
							: null
				}
			}

			const { data } = await this.props.getArticlesList(reqParams)
			const { docs: list } = data

			gtmItemsData(
				ItemsDataEvent.VIEW_ITEM_LIST,
				convertContentToItemData(
					list.slice(0, 5),
					ItemDataEventListName.HOME,
					ItemDataVariant.ARTICLE
				)
			)
		} catch (error) {
			console.error("Err fetchArticles", error.message)
		} finally {
			this.setState({ articlesLoading: false })
		}
	}

	fetchArticlesSpecialties = async () => {
		if (this.props.articles.specialties.isUninitialized) {
			await this.props.getArticleSpecialties({
				profession_uid: this.props.user?.user?.profession?.uid
			})
		}

		this.setState({
			articleSpecialties: this.props.articles.specialties.list
		})
	}

	handleFetchNext = async () => {
		if (!this.state.allLoaded) {
			this.setState({ loadMore: false })
			var newOffset = this.state.offsetArticles + 10
			this.setState(
				{
					offsetArticles: newOffset
				},
				() => {
					this.fetchArticles()
					if (
						this.props.articles.meta.offset >
						this.props.articles.meta.total
					)
						this.setState({ allLoaded: true })

					setTimeout(() => {
						this.setState({ loadMore: true })
					}, 1000)
				}
			)
		} else console.log("handleFetchNext - error")
	}

	getCards() {
		const { loadMore, allLoaded, currentSpecialty } = this.state
		const articles = this.props.articles.list

		if (
			!this.props.articles.isUninitialized &&
			articles?.length > 0 &&
			!this.state.articlesLoading
		) {
			return (
				<div>
					<InfiniteScroll
						dataLength={articles.length + this.state.offsetArticles} // This is important field to render the next data
						next={this.handleFetchNext.bind(this)}
						hasMore={!allLoaded && loadMore}
						loader={
							<div>
								<Loader loaderOnly />
							</div>
						}
						pullDownToRefresh={false}
					>
						{articles.map((article, index) => (
							<div key={"plorvodcard" + index}>
								{!(
									index === 0 ||
									index % SPONSORED_OFFSET ||
									(index - SPONSORED_OFFSET) /
										SPONSORED_OFFSET >=
										this.state.sponsored?.length
								) &&
									articles.length > 2 && (
										<PlaylistCard
											playlist={
												this.state.sponsored[
													(index - SPONSORED_OFFSET) /
														SPONSORED_OFFSET
												]
											}
										/>
									)}

								<ArticleCard
									currentPath="/home"
									key={article?._id}
									article={article}
									itemListName={ItemDataEventListName.HOME}
									user={this.props.user.user}
									currentField={
										!!currentSpecialty && currentSpecialty
									}
									link={{
										pathname: `/post/${article.slug}`,
										updateArticles: () =>
											this.fetchArticles()
									}}
								/>
							</div>
						))}
					</InfiniteScroll>
					<Spacer height={isNativeIOS ? "100px" : "60px"} />
				</div>
			)
		} else {
			if (
				this.props.articles.isFetching ||
				this.props.articles.isLoading ||
				this.props.articles.isError ||
				this.state.articlesLoading
			) {
				return (
					<FadeIn>
						{Array(10)
							.fill()
							.map((_, index) => (
								<SkeletonCard key={`skeleton-card-${index}`} />
							))}
					</FadeIn>
				)
			} else {
				if (!!currentSpecialty && this.state.sponsored.length)
					return this.state.sponsored.map((playlist) => (
						<PlaylistCard
							key={playlist._id + "sponsored-playlist"}
							playlist={playlist}
						/>
					))
				return <Loader emptyContent />
			}
		}
	}

	switchLanguage(newLanguage) {
		store.dispatch(resetArticles())

		this.setState(
			{ offsetArticles: 0, contentLanguage: newLanguage },
			() => {
				localStorage.setItem("contentLanguage", newLanguage)
				this.fetchArticlesSpecialties()
				this.fetchArticles()
				this.fetchPublicPlaylist()
			}
		)
	}

	acceptModal = () => {
		this.setState({ showDisclaimerModal: false })
		localStorage.setItem("acceptedRules", true)
		disableScroll.off()
		window.scrollTo(0, 0)
	}

	async fetchPublicPlaylist() {
		try {
			// Fetch all playlists (not filtered by specialty)
			// and store them for other pages
			getPublicPlaylist()
				.then(({ docs }) => {
					store.dispatch(setPublicPlaylists(docs))
				})
				.catch((error) =>
					console.error("Err getPublicPlaylist", error.message)
				)

			// Fetch playlists filtered by specialty
			const { docs: filteredPlaylists } = await getPublicPlaylist({
				medical_specialties: this.state.currentSpecialty?.uid
			})
			store.dispatch(setPublicPlaylistsFiltered(filteredPlaylists))

			this.setState({ sponsored: filteredPlaylists })

			if (filteredPlaylists.length > 0)
				gtmItemsData(
					ItemsDataEvent.VIEW_ITEM_LIST,
					convertContentToItemData(
						filteredPlaylists,
						ItemDataEventListName.HOME,
						ItemDataVariant.PLAYLIST
					)
				)
		} catch (error) {
			console.error("Err fetchPublicPlaylist", error.message)
		}
	}

	filterSpecialty(currentSpecialty) {
		if (!currentSpecialty) return

		gtmFilter({
			filter_value: currentSpecialty.uid,
			filter_name: "specialty"
		})

		this.setState({ currentSpecialty }, () => {
			getArticleTags(currentSpecialty.uid)
				.then((articleTags) => {
					this.setState({ articleTags }, () => {
						this.handleConfirmFilters()
					})
				})
				.catch((error) =>
					console.error("Err filterSpecialty", error.message)
				)
		})
	}

	toggleTagFilter(tag) {
		gtmFilter({
			filter_value: tag.uid,
			filter_name: "subspecialty"
		})

		const currentTags = this.state.currentTags.find(
			(el) => el.uid === tag.uid
		)
			? this.state.currentTags.filter((el) => el.uid !== tag.uid)
			: [...this.state.currentTags, tag]

		this.setState({ currentTags }, () => {
			this.handleConfirmFilters()
		})
	}

	resetFiltersSpecialties() {
		this.setState(
			{
				currentTags: [],
				currentSpecialty: "",
				articleTags: [],
				hideFields: false
			},
			() => {
				this.handleConfirmFilters()
			}
		)
	}

	saveLocalSpecialties() {
		const { currentTags, currentSpecialty } = this.state

		if (!!currentSpecialty) {
			localStorage.setItem(
				"stored_specialty",
				JSON.stringify(currentSpecialty)
			)
			localStorage.setItem("stored_tags", JSON.stringify(currentTags))
		} else {
			localStorage.removeItem("stored_specialty")
			localStorage.removeItem("stored_tags")
		}
	}

	async getPreviousFieldsTags() {
		try {
			const storedSpecialty = JSON.parse(
				localStorage.getItem("stored_specialty")
			)
			const storedTags = JSON.parse(localStorage.getItem("stored_tags"))

			const articleTags = await getArticleTags(storedSpecialty.uid)

			this.setState(
				{
					articleTags,
					currentSpecialty: storedSpecialty,
					currentTags: storedTags
				},
				() => {
					this.fetchArticles()
					this.fetchPublicPlaylist()
				}
			)
		} catch (error) {
			console.error("Err getPreviousFieldsTags", error.message)
		}
	}

	handleConfirmFilters = async (closeSheet = false) => {
		this.setState({ offsetArticles: 0 }, () => {
			this.fetchPublicPlaylist()
			this.fetchArticles(true)
			if (closeSheet) this.setState({ openFilterSheet: false })
		})
	}

	handleAnnouncements = async () => {
		const announcementData = await getAnnouncements()

		const [organisationId] = Object.keys(announcementData).filter(
			(key) => key !== "global"
		)
		if (!organisationId) return

		const unreadLangContents = announcementData[
			organisationId
		]?.contents.find((content) => {
			return (
				content.language === i18n.resolvedLanguage &&
				!this.props.user.readAnnouncements.includes(content._id)
			)
		})

		if (unreadLangContents) {
			unreadLangContents.organisationId = organisationId
			this.setState({ announcement: unreadLangContents })
		}
	}

	render() {
		const {
			currentSpecialty,
			showDisclaimerModal,
			articleTags,
			currentTags,
			openFilterSheet
		} = this.state

		const contentLanguage = localStorage.getItem("contentLanguage")

		const userOrganisation = this.props.user?.user?.organisations
			? this.props.user?.user?.organisations[0]?.uid
			: undefined

		const settings =
			userOrganisation &&
			this.props.user?.user?.organisations?.[0]?.organisationSettings

		const articlesLoading =
			this.props.articles.isUninitialized &&
			this.props.articles?.list.length === 0

		return (
			<StyledHomePage
				style={{ background: getPageBackground(articlesLoading)[0] }}
			>
				<CustomNavbar
					{...this.props}
					onSwitchLanguage={(newLanguage) =>
						this.switchLanguage(newLanguage)
					}
				/>

				<RemoveScrollBar />

				<DisclaimerModal
					onPress={this.acceptModal}
					t={this.props.t}
					visible={showDisclaimerModal}
				/>
				<DownloadNativeModal
					visible={
						!showDisclaimerModal &&
						this.state.showNativeModal &&
						!isNativeIOS &&
						!isNativeAndroid
					}
					onClose={() => {
						this.setState({ showNativeModal: false })
						this.props.history.replace("/home") // Pour ne pas l'avoir encore...
					}}
				/>

				{this.state.announcement && (
					<AnnouncementModal
						announcement={this.state.announcement}
						onClose={() => {
							this.setState({ announcement: null })
						}}
					/>
				)}

				{/* Page Content */}
				<div>
					{/* Discovery Page */}
					<HomeNavigation />

					{articlesLoading ? (
						<div className="space-between">
							<Skeleton
								width={48}
								height={30}
								variant="rounded"
								sx={{ bgcolor: "grey.600", borderRadius: 15 }}
							/>
							<Skeleton
								width={64}
								height={30}
								variant="rounded"
								sx={{ bgcolor: "grey.600", borderRadius: 15 }}
							/>
						</div>
					) : (
						<div
							style={{
								display:
									userOrganisation &&
									settings?.discoveryFilter
										? "flex"
										: "none",
								justifyContent: "end",
								alignItems: "center",
								padding: "10px 10px 0"
							}}
						>
							<FilterButton
								active={!!currentSpecialty}
								onClick={() =>
									this.setState({ openFilterSheet: true })
								}
							/>
						</div>
					)}

					<div style={{ minHeight: window.innerHeight - 280 }}>
						{this.getCards()}
					</div>

					{/* Bottom sheet for filters */}
					<CustomSheet
						show={openFilterSheet}
						title={this.props.t("common.filters")}
						bodyStyle={{
							height: isNativeIOS
								? window.innerHeight * 0.6
								: window.innerHeight * 0.8
						}}
						bodyId="top-sheet"
						onChange={() => {
							this.setState({ openFilterSheet: false })
						}}
						bottomContent={
							!!currentSpecialty ? (
								<>
									<CustomButton
										className="black"
										onClick={() => {
											this.setState({
												openFilterSheet: false
											})
										}}
									>
										{this.props.t("common.confirm")}
									</CustomButton>
									<Spacer height="25px" />
									{isNativeIOS && <Spacer height="40px" />}
								</>
							) : null
						}
					>
						<div>
							<h5 style={{ marginBottom: 10 }}>
								{this.props.t("sheet.filters.selectField")}
							</h5>
							{!!currentSpecialty && (
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between"
									}}
									onClick={this.resetFiltersSpecialties.bind(
										this
									)}
								>
									{/* Specialty checkbox (checked) */}
									<CustomCheckbox
										checked={true}
										title={
											currentSpecialty.translations[
												contentLanguage
											]
										}
										onChange={() => {
											this.resetFiltersSpecialties.bind(
												this
											)
										}}
									/>
									<CustomIcon
										style={{ marginBottom: -10 }}
										scale={0.8}
										iconName="close_alt"
									/>
								</div>
							)}
							{!!currentSpecialty && (
								<FadeIn
									visible={!!currentSpecialty}
									onComplete={() =>
										this.setState({ hideFields: true })
									}
								>
									<h5 style={{ marginBottom: 10 }}>
										{this.props.t(
											"sheet.filters.selectField"
										)}
									</h5>

									{[...articleTags]
										.sort((a, b) =>
											a.translations[
												contentLanguage
											]?.localeCompare(
												b.translations[contentLanguage]
											)
										)
										.filter((tag) =>
											userOrganisation?.includes("takeda")
												? !TAKEDA_TAGS_EXCEPTION.includes(
														tag.translations["en"]
												  )
												: true
										)
										.map((tag) => {
											return (
												<CustomCheckbox
													key={tag?._id}
													onChange={() =>
														this.toggleTagFilter(
															tag
														)
													}
													checked={currentTags?.find(
														(el) =>
															el.uid === tag.uid
													)}
													title={
														tag.translations[
															contentLanguage
														]
													}
												/>
											)
										})}
								</FadeIn>
							)}
							<div
								style={{
									opacity: !!currentSpecialty ? 0 : 1,
									transition: "all ease-in-out 0.3s",
									display: this.state.hideFields
										? "none"
										: "block"
								}}
							>
								{[...this.props.articles.specialties.list]
									?.filter(
										(el) =>
											!SPECIALTIES_EXCEPTION.includes(
												el.translations.en
											)
									)
									?.filter((el) =>
										settings?.medicalSpecialties?.includes(
											el._id
										)
									)
									.sort((a, b) =>
										a.translations[
											contentLanguage
										]?.localeCompare(
											b.translations[contentLanguage]
										)
									)
									?.map((specialty) => (
										// Specialty checkbox (unchecked)
										<CustomCheckbox
											key={specialty?._id}
											onChange={() => {
												this.filterSpecialty(specialty)
												document
													.getElementById("top-sheet")
													.scrollIntoView({
														behavior: "smooth",
														block: "start",
														inline: "start"
													})
											}}
											checked={false}
											title={
												specialty.translations[
													contentLanguage
												]
											}
										/>
									))}
							</div>
						</div>
					</CustomSheet>
				</div>
			</StyledHomePage>
		)
	}
}

export const MyLoader = () => (
	<div
		style={{
			marginTop: "50%",
			marginBottom: "50%",
			height: "100vh"
		}}
	>
		<center>
			<SingleLoader />
		</center>
	</div>
)

function mapState(state) {
	const articles = state.articles
	const users = state.users
	const user = state.user
	return {
		articles,
		users,
		user
	}
}

const mapDispatch = {
	getArticlesList: articlesApi.endpoints.getArticlesList.initiate,
	getUsersList: searchApi.endpoints.getUsersList.initiate,
	getArticleSpecialties: articlesApi.endpoints.getArticleSpecialties.initiate,
	getUser: userApi.endpoints.getUser.initiate,
	getPlaylists: userApi.endpoints.getPlaylists.initiate,
	getSaves: userApi.endpoints.getSaves.initiate,
	getFollowers: userApi.endpoints.getFollowers.initiate,
	getPendingFollowers: userApi.endpoints.getPendingFollowers.initiate,
	getFollowing: userApi.endpoints.getFollowing.initiate,
	getPendingFollowing: userApi.endpoints.getPendingFollowing.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(HomePage))

export const StyledHomePage = styled.div`
	// background: #f7f8fc;
	background: linear-gradient(
		180deg,
		#ff699c 0%,
		#fe5763 26.99%,
		#ff8a00 51.99%,
		#fdb955 77.51%,
		#ffc408 100%
	);

	min-height: ${window.innerHeight}px;
	max-height: 100%;
	width: 100%;
	overflow-y: hidden;
	padding-top: 30px;

	h3 {
		font-family: "Poppins" !important;
		font-size: 16px;
		font-weight: semibold !important;
		color: #212121;
		text-align: center;
	}

	.space-between {
		display: flex;
		justify-content: space-between;
		align-items: center;

		margin: 10px 10px 0px;
	}
`
