import { t } from "i18next"
import customToast from "../components/CustomToast"
import { APP_MAIN_DOMAIN } from "../config"
import { HapticEffect } from "../interfaces"
import { iosGenerateHaptic, iosSetAppUrl } from "../tools/ios"
import { auth } from "./firebase"

class StatusError extends Error {
	public status
	public messageShort

	constructor(
		message: string,
		messageShort: string,
		status: number,
		options: object
	) {
		super(message, options)
		this.status = status
		this.messageShort = messageShort
	}
}

export function handleError(error: any) {
	// Axios errors
	if (error.response) {
		// Side effect (401), check if ever used
		if (error.response.status === 401) {
			auth.onAuthStateChanged(async (user) => {
				if (!user) iosGenerateHaptic(HapticEffect.ERROR)
			})
		}

		// Side effect (404, /profile)
		if (
			error.response.status === 404 &&
			window.location.pathname === "/profile"
		) {
			if (window.location.pathname === "/profile") {
				if (
					["/me", "/playlist"].some((prefix) =>
						error.response.config.url.startsWith(prefix)
					)
				) {
					iosGenerateHaptic(HapticEffect.ERROR)
					customToast(t("toast.error.global"))
				}
			}
		}

		return error
	}
	return error
}

// Old error handler. Calls will be replaced with time.
export const catchError = async (error: any) => {
	if (
		error?.response?.status === 404 &&
		window.location.pathname === "/profile" &&
		(error.response?.config?.url.startsWith("/me") ||
			error.response?.config?.url.startsWith("/playlist"))
	) {
		console.log("Disconnected for 404")
		iosGenerateHaptic(HapticEffect.ERROR)
		customToast(t("toast.error.global"))

		throw error
	} else if (error?.response?.status === 401) {
		auth.onAuthStateChanged(async (user) => {
			if (!user) {
				iosGenerateHaptic(HapticEffect.ERROR)
			}
		})

		throw error
	}

	if (
		error?.response?.status === 404 &&
		window.location.pathname.startsWith("/post") &&
		error?.response?.config?.url?.startsWith("/article")
	) {
		window.location.href = "/home?postNotFound=1"
	}

	const errorMessage =
		error?.response?.data?.errorMessage ??
		error?.errorMessage ??
		"une erreur s'est produite"

	return {
		status: error?.response?.status ?? 500,
		message: `${errorMessage}`,
		error: error?.response?.data?.error ?? {}
	}

	throw error
}

export const clearLocalStorage = () => {
	localStorage.removeItem("didAuth")
	localStorage.removeItem("my_uid")
	localStorage.removeItem("conversationsList")
	localStorage.removeItem("app_lang")
	localStorage.removeItem("homeListContentFilters")
	localStorage.removeItem("ios_signin_callback")
	localStorage.removeItem("android_signin_callback")

	let newURL = `https://${process.env.REACT_APP_MAIN_REDIRECTION_DOMAIN}/login`

	if (window.location.hostname === "localhost")
		newURL = "http://localhost:3000/login"

	iosSetAppUrl(newURL)
	window.location.href = newURL

	localStorage.removeItem("stored_specialty")
	localStorage.removeItem("stored_tags")
}
