import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Flex } from "./global";
import CustomIcon from "./CustomIcon";
import Loader from "./Loader";

const PDFViewer = ({ url }: { url: string }) => {
  const [pdfBlobUrl, setPdfBlobUrl] = useState<string | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scale, setScale] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    const fetchPDF = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch: ${response.statusText}`);
        }
        const blob = await response.blob();
        if (blob.type !== "application/pdf") {
          throw new Error("File is not a PDF.");
        }
        const blobUrl = URL.createObjectURL(blob);
        setPdfBlobUrl(blobUrl);
      } catch (error) {
        console.error("Error on getting the PDF file:", error);
      }
    };

    fetchPDF();

    return () => {
      if (pdfBlobUrl) {
        URL.revokeObjectURL(pdfBlobUrl);
      }
    };
  }, []);

  // return (
  //   <Document
  //     file={pdfBlobUrl}
  //     onLoadSuccess={onDocumentLoadSuccess}
  //     loading=''
  //   >
  //     <Page
  //       pageNumber={pageNumber}
  //       width={window.innerWidth}
  //       renderTextLayer={false}
  //       renderAnnotationLayer={false}
  //     />
  //   </Document>
  // );

  return (
    <StyledViewer>
      <Document
        file={pdfBlobUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        loading=''
      >
        <Page
          pageNumber={pageNumber}
          scale={scale}
          width={window.innerWidth - 16}
          height={window.innerHeight - 200}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          renderMode='custom'
        />
      </Document>
      {numPages ? (
        numPages > 1 ? (
          <div className='pdf-controls'>
            <button
              disabled={pageNumber === 1}
              onClick={() => setPageNumber(pageNumber - 1)}
              className='rotate'
            >
              <CustomIcon iconName='chevron' />
            </button>
            <p>{`Page ${pageNumber}/${numPages}`}</p>
            <button
              disabled={pageNumber === numPages}
              onClick={() => setPageNumber(pageNumber + 1)}
            >
              <CustomIcon iconName='chevron' />
            </button>
          </div>
        ) : null
      ) : (
        <Loader loaderOnly />
      )}
    </StyledViewer>
  );
};

export default PDFViewer;

const StyledViewer = styled.div`
  width: 100%;
  margin: 0px auto;
  padding: 64px 8px 16px;
  box-sizing: border-box;
  background: #212121;
  height: 100%;

  canvas {
    border-radius: 8px;
  }

  .pdf-controls {
    margin: 0 auto;
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 16px;

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
    }

    .rotate {
      scale: -1;
    }

    .--custom-icon,
    .--custom-icon * {
      width: 16px;
      height: 16px;
      transform: translateX(1px);
    }

    button {
      margin: 0 8px;
      padding: 8px;

      width: 32px;
      height: 32px;
      border-radius: 100px;
      background-color: #ffc408;
      border: none;
      box-shadow: none;
      cursor: pointer;

      &:disabled {
        opacity: 0.5;
        background: #f5f5f5;

        cursor: not-allowed;
      }
    }
  }
`;
