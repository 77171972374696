import { Component } from "react"
import styled from "styled-components"
import { Col, Row } from "react-grid-system"
import { withRouter } from "react-router-dom"
import { BottomSheet } from "react-spring-bottom-sheet"
import CustomIcon from "./CustomIcon"
import { SpecialtyTag, Spacer } from "./global"
import ShareBtn from "../assets/icons/share-btn.svg"
import customToast from "./CustomToast"
import { connect } from "react-redux"
import { userApi } from "../redux/user/user.service"
import { androidShare } from "../tools/android"
import { isAndroid } from "react-device-detect"
import { renderPublicationDate } from "../tools/utils"
import { t } from "i18next"
import disableScroll from "disable-scroll"
import { gtmItemData } from "../tools/reactgaEvents"
import i18n from "../config/i18n"
import { store } from "../redux"

class VideoCard extends Component {
	state = {
		showSheet: false
	}

	handleShare() {
		const { title, slug, organisations, medical_specialties } =
			this.props.video

		const user = store.getState().user.user

		const videoURL = `/video/${slug}?shared=${user.uid}&org=${
			this.props.user.user?.organisations?.[0]?.uid || "null"
		}`

		const shareText =
			i18n.resolvedLanguage === "fr"
				? `I’ve just watched this video you may be interested in: ${title}`
				: `Je viens de regarder cette vidéo qui pourrait t'intéresser : ${title}`

		if (isAndroid) {
			androidShare(videoURL, shareText)
		} else if (navigator.share) {
			navigator.share({
				title: document.title,
				text: shareText,
				url: videoURL
			})
			gtmItemData({
				event: "article_share",
				item_name: title,
				item_list_name: window.location.pathname,
				item_id: slug,
				item_brand: organisations[0]?.name,
				item_category: medical_specialties[0]?.uid,
				item_category3: "video"
			})
		} else {
			customToast(t("toast.error.browser"))
		}
	}

	renderFooter = (publisher) => {
		const imageUrl =
			publisher === "company"
				? this.props.video.company?.images?.[0]?.url
				: this.props.video.congress?.image?.url

		const name =
			publisher === "company"
				? this.props.video.company?.name
				: this.props.video.congress?.name

		const contentLanguage =
			localStorage.getItem("contentLanguage") || i18n.resolvedLanguage

		return (
			<div className="card-bottom">
				<img
					src={imageUrl}
					alt="Loading..."
					className="journal-img"
					onClick={() =>
						publisher === "company" &&
						this.props.history.push(
							"/company/" + this.props.video.company._id
						)
					}
				/>
				<p className="bottom-title">
					{contentLanguage === "fr" ? "Présenté par" : "Presented by"}
					<br />
					<span className="bottom-title bold">{name}</span>
				</p>
			</div>
		)
	}

	render() {
		const contentLanguage =
			localStorage.getItem("contentLanguage") || i18n.resolvedLanguage

		return (
			<div id="videoSection">
				<HeaderWrapper
					className={this.props.isPinned ? "pinned" : ""}
					thumbnail={this.props.video.thumbnail}
					onClick={() =>
						this.props.history.push(
							"/video/" + this.props.video.slug
						)
					}
				>
					<div className="pinned-badge">
						<CustomIcon iconName="post-pin" color="#FFFFFF" />
						<div>{t("common.pinned")}</div>
					</div>

					<div className="black-opacity-layer" />
					<CustomIcon scale={1} iconName="play_circle" />
				</HeaderWrapper>

				<CustomWrapper
					style={this.props.style}
					onClick={(e) =>
						["share-btn", "journal-img"].includes(
							e.target?.className
						)
							? e.preventDefault()
							: this.props.history.push(
									"/video/" + this.props.video.slug
							  )
					}
				>
					{/* Card Header - Date / Category / Ellpsis */}
					<div className="card-header">
						<div>
							<p className="publication_date">
								{renderPublicationDate(
									this.props.video.publication_date
								)}
							</p>
							{this.props.video.medical_specialties.map(
								(specialty) => (
									<SpecialtyTag
										key={specialty.uid}
										style={{ marginRight: "5px" }}
									>
										{
											specialty.translations[
												contentLanguage
											]
										}
									</SpecialtyTag>
								)
							)}
						</div>
						{/* SHARE BUTTON */}
						<img
							src={ShareBtn}
							alt=""
							className="share-btn"
							onClick={(e) => {
								e.preventDefault()
								disableScroll.on()
								this.setState({ showSheet: true })
							}}
						/>
					</div>
					{/* Card Body - Carousel */}
					<div>
						<Spacer />
						<div className="content-format-text">video</div>
						<p className="title">{this.props.video.title}</p>
						<Spacer height="24px" />

						{this.renderFooter(
							this.props.video.company
								? "company"
								: this.props.video.congress
								? "congress"
								: null
						)}
						<Spacer height="10px" />
					</div>
				</CustomWrapper>

				<BottomSheet
					expandOnContentDrag
					maxHeight={window.innerHeight * 0.3}
					open={this.state.showSheet}
					className="remove-scrollbar"
					onDismiss={() => {
						disableScroll.off()
						setTimeout(() => this.setState({ showSheet: false }))
					}}
				>
					<div
						style={{ float: "right", marginRight: "20px" }}
						onClick={() => {
							disableScroll.off()
							this.setState({ showSheet: false })
						}}
					>
						<CustomIcon iconName="close_alt" />
					</div>
					<div
						style={{
							padding: "55px 15px"
						}}
					>
						<Row
							justify="around"
							align="center"
							gutterWidth={20}
							onClick={this.handleShare.bind(this)}
						>
							<Col xs={1}>
								<Spacer height="5px" />

								<CustomIcon
									iconName="share-alt"
									scale={0.8}
									color="#212121"
									style={{
										marginLeft: 5
									}}
								/>
							</Col>
							<Col xs={10}>
								<p
									style={{
										fontSize: "17px",
										fontWeight: 600,
										margin: 0
									}}
								>
									{t("common.share")}
								</p>
								<p
									style={{
										fontSize: "15px",
										fontWeight: 400,
										margin: 0
									}}
								>
									{t("sheet.video.share")}
								</p>
							</Col>
						</Row>
						<Spacer height="30px" />
					</div>
				</BottomSheet>
			</div>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate,
	getPlaylists: userApi.endpoints.getPlaylists.initiate,
	getSaves: userApi.endpoints.getSaves.initiate
}
const connector = connect(mapState, mapDispatch)

export default connector(withRouter(VideoCard))

const CustomWrapper = styled.div`
	background-color: #f9f9f9;
	box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
	border-radius: 0px 0px 15px 15px;
	position: relative;
	padding: 20px;

	h5 {
		color: #212121;
		font-weight: 800;
		font-size: 21px;
		margin: 0;
		margin-bottom: 10px;
		margin-top: 20px;
	}

	small {
		color: #ffaa00;
		font-size: 11px;
	}

	.card-header {
		display: flex;
		justify-content: space-between;
		align-items: start;
	}

	.card-bottom {
		display: flex;
		justify-content: start;
		align-items: center;
		gap: 32px;
	}

	.content-format-text {
		font-family: Poppins;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		letter-spacing: 0.72px;
		text-transform: uppercase;
		color: #f1353a;
	}

	.bottom-title {
		font-family: "Poppins";
		color: #212121;
		font-size: 12px;
		font-weight: 300;
		text-align: left;
		line-height: 140%;
		margin: 0px;

		&.bold {
			font-weight: 500;
		}
	}

	.publication_date {
		font-size: 14px;
		margin-bottom: 0;
		text-transform: capitalize;
	}

	.title {
		font-weight: 700;
		font-size: 16px;
		text-align: left;
		margin: 10px 0;
	}

	.journal-img {
		object-fit: contain;
		object-position: center;
		max-height: 50px;
		width: auto;
		max-width: 90px;
	}
`

const HeaderWrapper = styled.div`
	position: relative;
	background-image: url(${(props) => props.thumbnail});
	background-color: #f9f9f9;
	background-size: cover;
	background-position: 0px -60px;
	height: 200px;
	border-radius: 15px 15px 0 0;
	box-sizing: border-box;

	.pinned-badge {
		display: none;
	}

	&.pinned {
		border-radius: 0px;

		&::before {
			content: "";
			position: absolute;
			top: -10px;
			left: 0;
			right: 0;
			height: 10px;
			background-color: #ff8800;
			border-radius: 15px 15px 0 0;
		}

		.pinned-badge {
			display: flex;
			align-items: center;
			gap: 4px;

			padding: 12px 14px;

			font-family: Inter;
			font-size: 12px;
			font-weight: 700;
			line-height: 12px;
			letter-spacing: 0em;
			text-align: left;
			color: #fff;

			.--custom-icon {
				position: static;
				margin-top: 2px;
			}
		}
	}

	.--custom-icon {
		position: absolute;
		left: calc(40% - 10px);
		top: calc(40% - 20px);
	}

	.black-opacity-layer {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: linear-gradient(
			rgba(0, 0, 0, 0.1) 0%,
			rgba(0, 0, 0, 0.1) 50%,
			rgba(0, 0, 0, 0.5) 100%
		);
	}

	div.tag {
		background: #212121;
		border-radius: 10px;
		color: #fff;
		font-size: 12px;
		font-weight: 400;
		font-family: "Poppins";
		display: table;
		padding: 5px 10px;
		box-sizing: border-box;
	}
`
