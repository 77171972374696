import { Component } from "react"
import "../../styles/editProfile.css"
import styled from "styled-components"
import disableScroll from "disable-scroll"
import EditProfileBar from "../../components/EditProfileBar"
import Loader from "../../components/Loader"
import { withTranslation } from "react-i18next"
import { userApi } from "../../redux/user/user.service"
import { connect } from "react-redux"
import { CustomButton, Spacer } from "../../components/global"
import CustomIcon from "../../components/CustomIcon"
import { CustomInput } from "../../components/CustomInput"
import CustomSelect from "../../components/customSelect"
import { getProfessionsList } from "../../services/professions.services"
import CustomSheet from "../../components/CustomSheet"
import { Col, Row } from "react-grid-system"
import { patchUser } from "../../services/user.services"
import customToast from "../../components/CustomToast"
import PlacesAutocomplete from "react-places-autocomplete"
import { geocodeByAddress, getLatLng } from "react-places-autocomplete"
import { articlesApi } from "../../redux/articles/articles.service"
import { gtmSearchArticle } from "../../tools/reactgaEvents"
import i18n from "../../config/i18n"
import { MOST_POPULAR } from "../signupPage/onboarding/onboardingProfession"
import { getFirebaseToken } from "../../services/firebase"
import ProfilePicture from "../../components/ProfilePicture"
import { getArticleTags } from "../../services/articles.services"
import PreviewCard from "../../components/PreviewCard"
import { t } from "i18next"

class EditProfilePage extends Component {
	state = {
		userLoaded: false,
		user: undefined,
		professions: undefined,
		awardSheet: false,
		paperSheet: false,
		tmpAward: {
			label: 2022,
			content: ""
		},
		searchValue: "",
		articles: [],
		articlesLoaded: true,
		edited: false
	}

	articleSearchBuffer = null

	fetchProfile = async () => {
		await this.props.getUser()
		this.setState({
			user: this.props.user.user,
			userLoaded: true
		})
	}

	async componentDidMount() {
		window.scrollTo(0, 0)
		disableScroll.off()
		const isAuthentified = await getFirebaseToken()

		if (!isAuthentified) {
			this.props.history.replace("/login")
			return
		}

		// Get User
		this.fetchProfile()

		// Professions & Specialties
		this.fetchProfessions()
	}

	componentDidUpdate(_, prevState) {
		if (!!this.props.user?.user)
			if (
				prevState?.user !== this.state?.user ||
				prevState?.tmpAward !== this.state?.tmpAward
			)
				if (!this.state.edited) this.setState({ edited: true })
	}

	handleUpdateProfile(e, preventRedirect = true, showToaster = true) {
		e.preventDefault()
		const {
			firstname,
			lastname,
			username,
			bio,
			location,
			awards,
			published_articles,
			profession,
			main_specialty,
			tags,
			link
		} = this.state.user

		let data = {
			firstname,
			lastname,
			username,
			bio,
			awards,
			location,
			published_articles,
			profession: profession.uid,
			main_specialty: main_specialty?.uid || null,
			tags: tags?.map((tag) => tag.uid) || null,
			link
		}

		if (showToaster)
			customToast(t("toast.success.updatedProfile"), "success")

		patchUser(data).then((res) => {
			if (res.status === 404) {
				this.setState({ errorMessage: res.message })
			} else if (res.status === 500) {
				this.setState({ errorMessage: res.message })
			} else {
				if (!preventRedirect) {
					this.props
						.getUser()
						.then(() => this.props.history.push("/profile"))
				} else this.props.getUser()
			}
		})
	}

	fetchProfessions() {
		getProfessionsList().then((professions) => {
			this.setState({ professions })
		})
	}

	handleSelect = (location) => {
		this.setState({
			user: {
				...this.state.user,
				location: {
					...this.state.user.location,
					location
				}
			},
			hasSelected: true,
			isEdited: true
		})

		geocodeByAddress(location)
			.then((results) => getLatLng(results[0]))
			.then((latLng) => {
				console.log(latLng)
				this.setState({
					user: {
						...this.state.user,
						location: {
							...this.state.user.location,
							lat: latLng.lat,
							lon: latLng.lng
						}
					}
				})
			})
			.catch((error) => console.error("Error", error))
	}

	handleAddAward = () => {
		if (this.state?.tmpAward?.content?.length > 0) {
			let awards = [...this.state.user?.awards, this.state.tmpAward]
			awards.sort((a, b) => parseInt(b.label) - parseInt(a.label))
			this.setState({
				user: {
					...this.state.user,
					awards
				},
				awardSheet: false,
				tmpAward: {
					content: "",
					label: 2022
				}
			})
		}
	}

	handleDeleteAward = (index) => {
		let awards = [...this.state.user?.awards]
		awards.splice(index, 1)
		this.setState({
			user: {
				...this.state.user,
				awards
			}
		})
	}

	handleSearchPaper(searchValue) {
		if (!!this.articleSearchBuffer) {
			clearTimeout(this.articleSearchBuffer)
			this.articleSearchBuffer = null
		}

		this.setState(
			{
				searchValue,
				articlesLoaded: false
			},
			() => {
				this.articleSearchBuffer = setTimeout(() => {
					if (searchValue === "") {
						this.setState({
							articles: [],
							articlesLoaded: true
						})
					} else
						this.props
							.searchArticles({
								offset: 0,
								limit: 1000,
								search: searchValue
							})
							.then((articles) => {
								console.log(articles)
								if (
									articles.data[0] &&
									articles.data[0]?.docs?.length > 0
								) {
									gtmSearchArticle(
										searchValue,
										articles.data[0].docs
									)
									this.setState({
										articles: articles.data[0].docs.filter(
											(article) => {
												const authors =
													article?.authors?.map(
														(author) => {
															return author.toLowerCase()
														}
													)
												return authors?.includes(
													this.state.user?.fullname
												)
											}
										),
										// articles: articles.data[0].docs,
										articlesLoaded: true
									})
								}
							})
				}, 500)
			}
		)
	}

	handleAddPaper(article) {
		this.setState({
			user: {
				...this.state.user,
				published_articles: [
					...this.state.user.published_articles,
					article
				]
			},
			paperSheet: false
		})
	}

	handleRemovePaper(index) {
		let published_articles = [...this.state.user?.published_articles]
		published_articles.splice(index, 1)
		this.setState((prevState) => ({
			user: {
				...this.state.user,
				published_articles
			}
		}))
	}

	async handleChangeProfession(e) {
		const profession = this.state.professions.find(
			(el) => el.uid === e.target.value
		)
		const main_specialty = profession.medical_specialties[0]

		const tags = await getArticleTags(main_specialty.uid)

		this.setState({
			edited: true,
			user: {
				...this.state.user,
				profession,
				main_specialty,
				tags,
				medical_specialties: undefined
			}
		})
	}

	handlePatchProfession = async () => {
		const user = this.state.user

		if (
			this.props.user.user.profession.uid !==
			this.state.user.profession.uid
		)
			await patchUser({
				profession: this.state.user.profession.uid
			})

		this.props.history.push({
			pathname: "/settings/edit/profile/specialty",
			state: {
				user,
				current: user?.main_specialty,
				isMainSpecialty: true
			}
		})
	}

	render() {
		const user = this.state.user

		let years = []
		for (let i = new Date().getFullYear(); i >= 1950; i--) {
			years.push(i)
		}

		const userLoaded = !!this.state.user && !!this.state.professions

		return (
			<>
				<form onSubmit={this.handleUpdateProfile.bind(this)}>
					<EditProfileBar
						user={user}
						{...this.props}
						title={t("profile.editProfile.title")}
						save
						edited={this.state.edited}
					/>
					{userLoaded ? (
						<div style={{ minHeight: window.innerHeight * 0.8 }}>
							<Spacer height="80px" />
							<EditAvatar
								onClick={(e) => {
									this.handleUpdateProfile(e, true, false)
									this.props.history.push(
										"/settings/edit/profile/avatar"
									)
								}}
							/>
							<EditInputWrapper>
								<h2>
									{t("profile.editProfile.mainInformations")}
								</h2>
								<CustomInput
									value={user?.firstname}
									onInput={(e) =>
										this.setState({
											user: {
												...this.state.user,
												firstname: e.target.value
											}
										})
									}
									placeholder={t("signup.firstname")}
								/>
								<Spacer height="15px" />
								<CustomInput
									value={user?.lastname}
									onInput={(e) =>
										this.setState({
											user: {
												...this.state.user,
												lastname: e.target.value
											}
										})
									}
									placeholder={t("signup.lastname")}
								/>
								<Spacer height="15px" />

								<CustomSelect
									label="Profession"
									renderOptions={[
										...this.state.professions.filter((el) =>
											MOST_POPULAR.includes(el.uid)
										),
										...this.state.professions.filter(
											(el) =>
												!MOST_POPULAR.includes(el.uid)
										)
									].map((el, index) => (
										<option
											key={el._id + index}
											value={el.uid}
										>
											{
												el.translations[
													i18n.resolvedLanguage
												]
											}
										</option>
									))}
									defaultValue={user.profession?.uid}
									onChange={this.handleChangeProfession.bind(
										this
									)}
								/>

								<Spacer height="15px" />

								{!!user.profession && !!user.main_specialty && (
									<FakeSelect
										label={t("common.mainSpecialty")}
										content={
											user?.main_specialty.translations[
												i18n.resolvedLanguage
											]
										}
										onClick={() =>
											this.handlePatchProfession()
										}
									/>
								)}

								<p className="disclaimer">
									{t(
										"profile.editProfile.specialtiesDisclaimer"
									)}
								</p>

								{!!user.profession &&
									!!user.main_specialty &&
									!!user.medical_specialties &&
									this.state.user.medical_specialties.map(
										(medical_specialty, index) => (
											<div
												key={
													medical_specialty._id +
													"--edit-prof-sec-spe-key" +
													index
												}
											>
												<FakeSelect
													label={t(
														"common.secondarySpecialty"
													)}
													content={
														medical_specialty
															.translations[
															i18n
																.resolvedLanguage
														]
													}
													onClick={() =>
														this.props.history.push(
															{
																pathname:
																	"/settings/edit/profile/specialty",
																state: {
																	user: this
																		.state
																		.user,
																	current:
																		medical_specialty
																}
															}
														)
													}
												/>

												<Spacer height="16px" />
											</div>
										)
									)}
								{!!user.profession && !!user.main_specialty && (
									<div
										className="flex-add-specialty"
										onClick={() =>
											this.props.history.push({
												pathname:
													"/settings/edit/profile/specialty",
												state: {
													user: this.state.user,
													current:
														user.profession
															.medical_specialties[0],
													isNew: true
												}
											})
										}
									>
										<CustomIcon
											scale={1}
											iconName="add_circle"
										/>
										<p>
											{t(
												"profile.editProfile.addSpecialtyButton"
											)}
										</p>
									</div>
								)}

								<Spacer />
								<CustomInput
									value={user?.link}
									onInput={(e) =>
										this.setState({
											user: {
												...this.state.user,
												link: e.target.value.toLowerCase()
											}
										})
									}
									placeholder="https://example.com"
								/>
								<Spacer />
								<CustomInput
									value={user?.bio}
									onInput={(e) =>
										this.setState({
											user: {
												...this.state.user,
												bio: e.target.value
											}
										})
									}
									placeholder={t(
										"profile.editProfle.updateBio"
									)}
									multiline
								/>
								<Spacer />
								<PlacesAutocomplete
									value={this.state.user?.location?.location}
									onChange={(value) => {
										this.setState({
											user: {
												...this.state.user,
												location: {
													...this.state.user
														?.location,
													location: value
												}
											},
											hasSelected: false,
											isEdited: true
										})
									}}
									onSelect={this.handleSelect}
								>
									{({
										getInputProps,
										suggestions,
										getSuggestionItemProps,
										loading
									}) => (
										<div>
											<CustomInput
												InputProps={{
													...getInputProps({
														placeholder:
															t(
																"profile.workplace"
															)
													})
												}}
												placeholder={t(
													"profile.workplace"
												)}
												suffix={
													<div
														style={{
															display: this.state
																.user?.location
																?.location
																?.length
																? "block"
																: "none",
															marginBottom:
																"-3px",
															marginRight: "10px"
														}}
													>
														<CustomIcon iconName="cross_circle_big_background" />
													</div>
												}
												suffixClick={() =>
													this.setState({
														user: {
															...this.state.user,
															location: {
																...this.state
																	.user
																	?.location,
																location: ""
															}
														}
													})
												}
											/>

											<SuggestionWrapper>
												{loading ? (
													<div>
														{t("common.loading")}...
													</div>
												) : null}

												{suggestions.map(
													(suggestion) => {
														return (
															<p
																{...getSuggestionItemProps(
																	suggestion
																)}
															>
																{
																	suggestion.description
																}
															</p>
														)
													}
												)}
											</SuggestionWrapper>
										</div>
									)}
								</PlacesAutocomplete>
								<Spacer />
							</EditInputWrapper>
							<WhiteBar />
							<EditInputWrapper>
								<Row
									justify="between"
									align="center"
									style={{ margin: 0 }}
									onClick={() =>
										this.setState({ awardSheet: true })
									}
								>
									<h2>{t("profile.awards")}</h2>
									<CustomIcon
										scale={1.4}
										iconName="add_circle"
									/>
								</Row>
								{user?.awards?.length ? (
									user?.awards.map(
										({ content, label }, index) => (
											<AwardInput
												content={content}
												label={label}
												onDelete={() =>
													this.handleDeleteAward(
														index
													)
												}
											/>
										)
									)
								) : (
									<p
										style={{
											textAlign: "center",
											fontSize: "14px",
											fontWeight: 400,
											fontFamily: "Poppins"
										}}
									>
										{t("sheet.award.title")} 🎖
									</p>
								)}

								<CustomSheet
									show={this.state.awardSheet}
									title={t("sheet.award.title")}
									onChange={() =>
										this.setState({ awardSheet: false })
									}
									bottomContent={
										<CustomButton
											type="button"
											style={{
												background: this.state.tmpAward
													?.content?.length
													? "linear-gradient(180deg, #FF699C 0%, #FE5763 100%)"
													: "#D2DCE2",
												color: this.state.tmpAward
													?.content?.length
													? "white"
													: "81939C"
											}}
											onClick={this.handleAddAward}
										>
											{t("common.confirm") +
												" " +
												t("common.award").toLowerCase()}
										</CustomButton>
									}
								>
									<h5>{t("sheet.award.subtitle")}</h5>
									<CustomSelect
										background="#ECF0F5"
										label={t("common.year")}
										options={years}
										value={this.state.tmpAward.label}
										onChange={(e) =>
											this.setState({
												tmpAward: {
													content:
														this.state.tmpAward
															.content,
													label: e.target.value
												}
											})
										}
									/>
									<Spacer height="15px" />
									<CustomInput
										background="#ECF0F5"
										placeholder={t(
											"sheet.award.placeholder"
										)}
										value={this.state.tmpAward.content}
										onInput={(e) =>
											this.setState({
												tmpAward: {
													label: this.state.tmpAward
														.label,
													content: e.target.value
												}
											})
										}
									/>
									<Spacer height="100px" />
								</CustomSheet>
							</EditInputWrapper>
							<WhiteBar />
							<EditInputWrapper>
								<Row
									justify="between"
									align="center"
									style={{ margin: 0 }}
									onClick={() =>
										this.setState({ paperSheet: true })
									}
								>
									<h2>{t("profile.publishedPapers")}</h2>
									<CustomIcon
										scale={1.4}
										iconName="add_circle"
									/>
								</Row>

								{user?.published_articles?.length ? (
									this.state.user.published_articles?.map(
										(article, index) => (
											<PreviewCard
												key={article._id}
												content={article}
												onRemove={() =>
													this.handleRemovePaper(
														index
													)
												}
											/>
										)
									)
								) : (
									<p
										style={{
											textAlign: "center",
											fontSize: "14px",
											fontWeight: 400,
											fontFamily: "Poppins"
										}}
									>
										{t(
											"profile.editProfile.publishedPapers"
										)}{" "}
										📰
									</p>
								)}

								<CustomSheet
									show={this.state.paperSheet}
									title={t(
										"profile.editProfile.publishedPapers"
									)}
									onChange={() =>
										this.setState({ paperSheet: false })
									}
								>
									<h5>
										{t(
											"profile.editProfile.editPapers.search"
										)}
									</h5>
									<CustomInput
										type="text"
										onInput={(e) =>
											this.handleSearchPaper(
												e.target.value
											)
										}
										value={this.state.searchValue}
										prefix={
											<CustomIcon
												style={{ marginBottom: -20 }}
												iconName="search"
												color="#81939C"
												scale={0.7}
											/>
										}
										background="#ECF0F5"
										placeholder={t(
											"profile.publishedPapers"
										)}
									/>
									<Spacer />
									<div
										style={{
											background:
												this.state.articles.length &&
												"#ECF0F5",
											padding: 20,
											borderRadius: 15
										}}
									>
										{this.state.articlesLoaded ? (
											this.state?.articles?.length ? (
												this.state.articles?.map(
													(article) => (
														<PreviewCard
															key={
																article._id +
																"--edit-profile-published-paper"
															}
															content={article}
															onClick={() => {
																this.handleAddPaper(
																	article
																)
															}}
														/>
													)
												)
											) : this.state?.searchValue
													?.length ? (
												<p
													style={{
														textAlign: "center",
														fontSize: "14px",
														fontWeight: 400,
														fontFamily: "Poppins"
													}}
												>
													{t(
														"profile.publishedPapersNotFound"
													)}
												</p>
											) : null
										) : (
											<Loader loaderOnly />
										)}
									</div>
								</CustomSheet>
							</EditInputWrapper>
							<Spacer height="100px" />
						</div>
					) : (
						<Loader />
					)}
				</form>
			</>
		)
	}
}

function mapState(state) {
	const user = state.user
	const articles = state.articles
	return {
		user,
		articles
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate,
	searchArticles: articlesApi.endpoints.searchArticles.initiate
}

const connector = connect(mapState, mapDispatch)

const EditAvatar = (props) => {
	return (
		<center>
			<div
				style={{
					position: "relative",
					height: "100px",
					width: "100px"
				}}
				onClick={props.onClick}
			>
				<ProfilePicture height={90} />
				<div style={{ position: "absolute", bottom: 0, right: 12 }}>
					<CustomIcon iconName="edit_avatar" />
				</div>
			</div>
		</center>
	)
}

const AwardInput = (props) => (
	<div
		style={{
			color: "#212121",
			fontFamily: "Poppins",
			fontWeight: 400,
			background: "white",
			border: "none",
			borderRadius: "5px",
			padding: "12px 12px",
			margin: "15px 0",
			fontSize: "14px"
		}}
	>
		<Row justify="between" align="center">
			<Col>
				<Row align="center">
					<Col xs={2}>
						<p
							style={{
								color: "#FF699C",
								fontFamily: "Poppins",
								fontWeight: 700,
								fontSize: "14px"
							}}
						>
							{props.label}
						</p>
					</Col>
					<Col>{props.content}</Col>
				</Row>
			</Col>
			<Col xs={2} onClick={props.onDelete}>
				<div style={{ marginLeft: -5 }}>
					<CustomIcon iconName="trash_circle" />
				</div>
			</Col>
		</Row>
	</div>
)

const FakeSelect = (props) => (
	<StyledFake onClick={props.onClick}>
		<div className="core">
			<div className="label">{props.label}</div>
			<div className="content">{props.content}</div>
		</div>
		<CustomIcon scale={0.8} iconName="select-arrow" />
	</StyledFake>
)

export default connector(withTranslation()(EditProfilePage))

const EditInputWrapper = styled.div`
	padding: 16px 40px;

	h2 {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 900;
		font-size: 21px;
		line-height: 30px;
		letter-spacing: 0.02em;
		color: #212121;
	}

	p {
		&.disclaimer {
			font-family: "Poppins";
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 110%;
			letter-spacing: 0.02em;
			color: #4c5861;
			margin: 24px 0 16px;
		}
	}

	.flex-add-specialty {
		cursor: pointer;
		display: flex;
		gap: 8px;
		align-items: start;

		p {
			font-family: "Poppins";
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 100%;
			letter-spacing: 0.02em;
			color: #ce0868;
			margin: 0;
		}
	}
`

const SuggestionWrapper = styled.div`
	padding: 10px;
	border-radius: 20px;

	p {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 18px;
		letter-spacing: 0.02em;
		color: #212121;
		margin-bottom: 12px;
	}
`

const WhiteBar = styled.div`
	height: 20px;
	background: white;
	width: 100%;
	margin: 0;
`

const StyledFake = styled.div`
	display: flex;
	align-items: center;
	background: #fff;
	border-radius: 5px;
	padding: 8px 16px;

	.core {
		width: 100%;

		.label {
			font-family: "Poppins";
			color: #81939c;
			font-size: 12px;
			font-weight: 500;
			margin: 0 0 4px;
		}

		.content {
			font-family: "Poppins";
			color: #212121;
			font-size: 16px;
			font-weight: 500;
		}
	}
`
