import React, { Component } from "react"
import {
	CustomButton,
	CustomContainer,
	Divider,
	PolicyText,
	Spacer
} from "../../components/global"
import SettingsNavbar from "../../components/SettingsNavbar"
import CustomNavBar from "../../components/CustomNavbar"
import disableScroll from "disable-scroll"
import { iosSetBackgroundColor, isNativeIOS } from "../../tools/ios"
import ReactMarkdown from "react-markdown"
import PrivacyMarkdown from "../../assets/markdowns/privacy.md"
import BiocodexPrivacyMarkdown from "../../assets/markdowns/biocodex-privacy.md"
import TakedaPrivacyMarkdown from "../../assets/markdowns/takeda-privacy.md"
import TakedaPrivacyMarkdownFr from "../../assets/markdowns/takeda-privacy-fr.md"
import { withRouter } from "react-router-dom"
import Loader from "../../components/Loader"
import remarkGfm from "remark-gfm"
import rehypeRaw from "rehype-raw"
import styled from "styled-components"
import i18n from "../../config/i18n"
import { SupportedLanguagesEnum } from "../../interfaces"
import { t } from "i18next"

class PrivacyPage extends Component {
	state = {
		privacy: ""
	}

	componentDidMount() {
		disableScroll.off()
		window.scrollTo(0, 0)
		if (isNativeIOS) {
			iosSetBackgroundColor("#FFFFFF")
		}

		const query = new URLSearchParams(window.location.search)

		let mdFile

		const interfaceLanguage =
			localStorage.getItem("i18nextLng") || i18n.resolvedLanguage

		switch (query.get("version")) {
			case "biocodex":
				mdFile = BiocodexPrivacyMarkdown
				break
			case "takeda":
				mdFile =
					interfaceLanguage === SupportedLanguagesEnum.FR
						? TakedaPrivacyMarkdownFr
						: TakedaPrivacyMarkdown
				break
			default:
				mdFile = PrivacyMarkdown
		}

		fetch(mdFile)
			.then((response) => response.text())
			.then((text) => {
				this.setState({ privacy: text })
			})
			.catch((error) => {
				console.error("Err fetch privacy", error.message)
			})
	}

	render() {
		const query = new URLSearchParams(window.location.search)
		const isPush =
			this.props.location?.state?.isPush || !!query.get("isPush")
		const hideTitle = this.props.location?.state?.hideTitle

		if (!this.state.privacy) return <Loader />

		return (
			<>
				{isPush ? (
					<>
						<SettingsNavbar
							pageTitle={
								hideTitle ? "" : t("settings.editSettings")
							}
							{...this.props}
						/>
						<Spacer height="60px" />
					</>
				) : (
					<CustomNavBar />
				)}
				<PageContainer
					style={{
						padding: isPush
							? "10px 30px 100px 30px"
							: "80px 30px 100px 30px",
						background: "#F7F8FC"
					}}
				>
					<PolicyText>
						<ReactMarkdown
							className="markdown-body"
							children={this.state.privacy}
							remarkPlugins={[remarkGfm]}
							rehypePlugins={[rehypeRaw]}
							skipHtml={false}
						/>
						<Divider />

						<CustomButton
							onClick={() => {
								this.props.history.replace({
									pathname: "/terms",
									search: query.get("version")
										? `?version=${query.get("version")}`
										: "",
									state: { isPush: true }
								})
							}}
						>
							{t("content.privacy.seeOurPrivacyPolicy")}
						</CustomButton>
					</PolicyText>
				</PageContainer>
			</>
		)
	}
}

export default withRouter(PrivacyPage)

const PageContainer = styled(CustomContainer)`
	th,
	td {
		padding: 8px;
		text-align: left;
		border: 1px solid #ddd;
		white-space: pre-wrap;
		font-size: 10px;
	}
`
