import { Component } from "react"
import styled from "styled-components"
import ProfileBar from "../../components/ProfileBar"
import { Row, Col } from "react-grid-system"
import { Avatar, Divider, Spacer } from "../../components/global"
import {
	deleteNotification,
	getNotifications,
	readAllNotifications,
	readNotification
} from "../../services/notifications.services"
import { acceptFollowRequest } from "../../services/user.services"
import Loader from "../../components/Loader"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Swipeout from "rc-swipeout"
import CustomIcon from "../../components/CustomIcon"
import customToast from "../../components/CustomToast"
import { userApi } from "../../redux/user/user.service"
import { connect } from "react-redux"
import JuisciLogo from "../../assets/core/juisci-logo-circle.svg"
import { iosOpenNewTab, isNativeIOS } from "../../tools/ios"
import { androidOpen } from "../../tools/android"
import { isAndroid } from "react-device-detect"
import { getFirebaseToken } from "../../services/firebase"
import { pickUserImage } from "../../services/image.services"

class NotificationCenter extends Component {
	state = {
		notifications: [],
		loaded: false
	}

	async componentDidMount() {
		const isAuthentified = !!(await getFirebaseToken())
		if (!isAuthentified) {
			this.props.history.replace("/discovery")
			return
		}

		this.fetchNotifications()
	}

	async fetchNotifications() {
		console.log("fetching notifications")
		const res = await getNotifications()
		const notifications = res.filter((v) => !v.deleted)
		await readAllNotifications()
		this.setState({ notifications, loaded: true })
	}

	handleAcceptFollow(request_id) {
		acceptFollowRequest(request_id).then((_) => {
			customToast(
				this.props.t("toast.success.acceptedRequest"),
				"success"
			)
			this.props.getUser()
			this.fetchNotifications()
		})
	}

	handleDeleteNotification(notification_id, index) {
		const { notifications } = this.state

		deleteNotification(notification_id).then((_) => {
			notifications.splice(index, 1)
			this.setState({ notifications })
		})
	}

	handleReadNotification(id) {
		readNotification(id)
		this.fetchNotifications()
	}

	renderNotifications(notification, index) {
		let type = notification.action.notificationType
		let emitters = notification.action.emitters

		switch (type) {
			case "FollowRequestReceived":
				return (
					<NotificationContent
						key={"notification-card-" + index}
						button={!notification?.read && "accept"}
						avatar={
							emitters?.[0]
								? pickUserImage(emitters[0])
								: JuisciLogo
						}
						onPress={() => {
							this.handleAcceptFollow(
								notification?.action?.follow?._id
							)
							this.handleReadNotification(notification?._id)
						}}
						profileURL={`/profile/user/${emitters[0]?.uid}`}
						onDelete={() =>
							this.handleDeleteNotification(
								notification?._id,
								index
							)
						}
						title={notification?.title}
					>
						{notification?.body}
					</NotificationContent>
				)

			case "ExternalLink":
				return (
					<NotificationContent
						key={"notification-card-" + index}
						avatar={JuisciLogo}
						profileURL="/profile/notifications"
						onClick={() => {
							this.props.history.replace("/profile/notifications")
							if (
								notification?.action?.url.includes("juisci.com")
							) {
								this.props.history.push(
									notification?.action?.url.split(".com")[1]
								)
							} else if (isNativeIOS)
								iosOpenNewTab(notification?.action?.url)
							else if (isAndroid && window.services)
								androidOpen(notification?.action?.url)
							else
								window.open(notification?.action?.url, "_blank")
						}}
						onDelete={() =>
							this.handleDeleteNotification(
								notification?._id,
								index
							)
						}
						title={notification?.title}
					>
						{notification?.body}
					</NotificationContent>
				)

			case "DirectMessage":
				return (
					<NotificationContent
						key={"notification-card-" + index}
						avatar={
							emitters?.[0]
								? pickUserImage(emitters[0])
								: JuisciLogo
						}
						profileURL={`/profile/messages/conversation/${notification?.action?.conversation?._id}`}
						onDelete={() =>
							this.handleDeleteNotification(
								notification?._id,
								index
							)
						}
						title={notification?.title}
					>
						{notification?.body}
					</NotificationContent>
				)

			case "FollowRequestAccepted":
				return (
					<NotificationContent
						key={"notification-card-" + index}
						avatar={
							emitters?.[0]
								? pickUserImage(emitters[0])
								: JuisciLogo
						}
						profileURL={`/profile/user/${emitters[0]?.uid}`}
						onDelete={() =>
							this.handleDeleteNotification(
								notification?._id,
								index
							)
						}
						onPress={() =>
							this.props.history.push(
								`/profile/user/${emitters[0]?.uid}`
							)
						}
						title={notification?.title}
					>
						{notification?.body}
					</NotificationContent>
				)

			case "FollowUnfollow":
				return (
					<NotificationContent
						key={"notification-card-" + index}
						avatar={
							emitters?.[0]
								? pickUserImage(emitters[0])
								: JuisciLogo
						}
						profileURL={`/profile/user/${emitters[0]?.uid}`}
						onDelete={() =>
							this.handleDeleteNotification(
								notification?._id,
								index
							)
						}
						onPress={() =>
							this.props.history.push(
								`/profile/user/${emitters[0]?.uid}`
							)
						}
						title={notification?.title}
					>
						{notification?.body}
					</NotificationContent>
				)

			case "FollowActivity":
				return (
					<NotificationContent
						key={"notification-card-" + index}
						avatar={
							emitters?.[0]
								? pickUserImage(emitters[0])
								: JuisciLogo
						}
						onDelete={() =>
							this.handleDeleteNotification(
								notification?._id,
								index
							)
						}
						profileURL={{
							pathname: `/post/${notification.action.article.slug}`,
							state: {
								article: notification.action.article
							}
						}}
						title={notification?.title}
					>
						{notification?.body}
					</NotificationContent>
				)

			case "ContentNew":
				return (
					<NotificationContent
						key={"notification-card-" + index}
						avatar={JuisciLogo}
						profileURL="/discovery"
						onDelete={() =>
							this.handleDeleteNotification(
								notification?._id,
								index
							)
						}
						title={notification?.title}
					>
						{notification?.body}
					</NotificationContent>
				)

			case "ContentRecommended":
				return (
					<NotificationContent
						key={"notification-card-" + index}
						profileURL="/foryou"
						avatar={JuisciLogo}
						onDelete={() =>
							this.handleDeleteNotification(
								notification?._id,
								index
							)
						}
						title={notification?.title}
					>
						{notification?.body}
					</NotificationContent>
				)

			case "ContentShare":
				return (
					<NotificationContent
						key={"notification-card-" + index}
						avatar={JuisciLogo}
						onDelete={() =>
							this.handleDeleteNotification(
								notification?._id,
								index
							)
						}
						title={notification?.title}
					>
						{notification?.body}
					</NotificationContent>
				)

			case "ArticlePublished":
				return (
					<NotificationContent
						key={"notification-card-" + index}
						avatar={
							notification.action?.journal?.image?.url ||
							JuisciLogo
						}
						profileURL={{
							pathname: `/post/${notification.action.article?.slug}`,
							state: {
								article: notification.action.article
							}
						}}
						onDelete={() =>
							this.handleDeleteNotification(
								notification?._id,
								index
							)
						}
						title={notification?.title}
					>
						{notification?.body}
					</NotificationContent>
				)

			// Note: these notifications don't appear or
			// get read until the user is notified on the home page
			case "LemonCertifiedAnswered":
				return notification.read ? (
					<NotificationContent
						key={"notification-card-" + index}
						avatar={JuisciLogo}
						profileURL="/settings"
						onDelete={() =>
							this.handleDeleteNotification(
								notification?._id,
								index
							)
						}
						title={notification?.title}
					>
						{notification?.body}
					</NotificationContent>
				) : null
			default:
				break
		}
	}

	render() {
		const { loaded, notifications } = this.state

		return (
			<div>
				<ProfileBar
					user={this.props.user.user}
					{...this.props}
					isMe={true}
				/>
				{loaded ? (
					<>
						<Spacer height="55px" />
						<h1
							style={{
								margin: "20px 21px 0px",
								fontFamily: "Work Sans",
								fontStyle: "normal",
								fontWeight: 900,
								fontSize: 21
							}}
						>
							Notification Center
						</h1>
						<Divider style={{ marginTop: 20, marginBottom: 0 }} />
						<ProfileContent>
							{notifications.length == 0 && (
								<center>
									<p>{this.props.t("notifications.empty")}</p>
								</center>
							)}
							{notifications.map((notification, index) =>
								this.renderNotifications(notification, index)
							)}
						</ProfileContent>
					</>
				) : (
					<Loader />
				)}
			</div>
		)
	}
}

function mapState(state) {
	const user = state.user
	return {
		user
	}
}
const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(NotificationCenter))

export const ClipToken = () => {
	return (
		<button onClick={() => navigator.clipboard.writeText(ios_fbtoken)}>
			Copy token
		</button>
	)
}

export const NotificationContent = (props) => {
	return (
		<div>
			{" "}
			<Swipeout
				autoClose={true}
				style={{ padding: 0, margin: 0 }}
				right={[
					{
						text: <CustomIcon iconName="trash" color="white" />,
						onPress: props.onDelete,
						style: {
							backgroundColor: "red",
							transform: "scale(0.99)",
							color: "white",
							height: "auto",
							margin: 0,
							padding: 0
						}
					}
				]}
			>
				<div onClick={props.onClick}>
					<NotifRowWrapper>
						<Row gutterWidth={12} align="center">
							<Col xs={2}>
								<Link to={props.profileURL}>
									<Avatar
										src={props.avatar}
										height="40px"
										style={{
											borderWidth: "2px",
											background: "white",
											objectFit: "contain"
										}}
									/>
								</Link>
							</Col>
							<Col>
								<Link to={props.profileURL}>
									{props.title && <h5>{props.title}</h5>}
									<p
										style={{
											width: props.button ? "95%" : "auto"
										}}
									>
										{props.children}
									</p>
								</Link>
							</Col>
							{props.button && (
								<Col xs={3}>
									{/* <ActionButton onClick={props.onPress}>
             {props.button}
           </ActionButton> */}
									<div className="row_controllers">
										<div
											onClick={props.onDelete}
											style={{ marginRight: 10 }}
										>
											<CustomIcon iconName="deny_invite" />
										</div>
										<div onClick={props.onPress}>
											<CustomIcon iconName="accept_invite" />
										</div>
									</div>
								</Col>
							)}
						</Row>
					</NotifRowWrapper>
				</div>
			</Swipeout>
			<Divider style={{ margin: "0" }} />
		</div>
	)
}

const ProfileContent = styled.div`
	padding: 0px 21px 20px;
	background: #ecf0f5;
	color: #212121;
	font-family: "Inter";
	min-height: calc(${window.innerHeight}px - 115px);

	h1 {
		font-family: "Work Sans";
		font-style: normal;
		font-weight: 900;
		font-size: 21px;
	}

	h3 {
		line-height: 17px;
		font-size: 17px;
		font-weight: 300;
		margin: 10px 0;
	}

	h3 b {
		font-weight: 700;
	}

	h4 {
		line-height: 15px;
		font-size: 11px;
		font-weight: 300;
		margin: 0;
		margin-right: 20px;
	}

	h4 b {
		font-weight: 600;
		font-size: 14px;
	}

	h5 {
		margin: 0;
		text-transform: capitalize;
		font-size: 11px;
		line-height: 15.4px;
		font-weight: 300;
	}

	h5 span {
		text-transform: uppercase;
	}

	p {
		font-size: 14px;
		line-height: 22.4px;
		font-weight: 400;
		margin: 0;
	}

	.rc-swipeout-content {
		background: #ecf0f5;
	}
`

const NotifRowWrapper = styled.div`
	height: 100%;
	padding: 20px 0;

	span {
		font-family: "Inter";
		font-style: normal;
		font-weight: 300;
		font-size: 11px;
		line-height: 16px;
		color: #81939c;
		background: #ecf0f5;
	}

	p {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 130%;
		letter-spacing: 0.02em;
		color: #4c5861;
		margin: 0;

		b {
			font-weight: 600;
			color: #212121;
		}
	}

	h5 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 130%;
		letter-spacing: 0.02em;
		color: #212121;
		margin: 0;
		text-transform: none;
	}

	div.row_controllers {
		display: inline-flex;
	}
`

const ActionButton = styled.button`
	outline: none;
	border: none;
	box-shadow: none;
	padding: 4px 13px;
	border-radius: 20px;
	background: #ffc408;
	border-radius: 20px;
	color: #212121;
	font-family: "Inter";
	font-weight: 700;
	font-size: 14px;
	text-transform: capitalize;
`
