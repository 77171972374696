import React, { Component } from "react"
import styled from "styled-components"
import CustomNavBar from "../../components/CustomNavbar"
import { CustomContainer, CustomButton } from "../../components/global"
import { CustomInput } from "../../components/CustomInput"
import { Row } from "react-grid-system"
import disableScroll from "disable-scroll"
import { RemoveScrollBar } from "react-remove-scroll-bar"
import { resetPassword } from "../../services/auth.services"
import { push } from "react-router-redirect"
import { t } from "i18next"
import Illustration from "../../assets/images/illustrations/brain-eyes.svg"

class ResetPage extends Component {
	state = {
		email: ""
	}

	componentDidMount() {
		disableScroll.on()
	}

	handleSubmit(e) {
		e.preventDefault()
		const { email } = this.state

		resetPassword({ email }).then(() => {
			push("/login/reset/confirm")
		})
	}

	render() {
		return (
			<>
				<RemoveScrollBar />
				<CustomNavBar />
				<CustomContainer>
					<form onSubmit={(e) => this.handleSubmit(e)}>
						<LoginImage
							className="illustration"
							alt=""
							src={Illustration}
						/>
						<Row justify="center" align="center">
							<LoginTitle>{t("login.reset.title")}</LoginTitle>
							<LoginSubtitle>
								{t("login.reset.text")}
							</LoginSubtitle>
							<CustomInput
								placeholder={t("signup.email")}
								type="email"
								onInput={(e) =>
									this.setState({ email: e.target.value })
								}
								required
							/>
						</Row>
						<Row justify="center" align="center">
							<CustomButton
								style={{ width: "100%", marginTop: "20px" }}
								type="submit"
							>
								{t("login.resetPassword")}
							</CustomButton>
						</Row>
					</form>
				</CustomContainer>
			</>
		)
	}
}

export default ResetPage

const LoginTitle = styled.div`
	color: #212121;
	font-family: "Poppins";
	font-size: 24px;
	line-height: 120%;
	font-weight: 700;
	text-align: center;
	margin-top: 32px;
	margin-bottom: 10px;
`

const LoginSubtitle = styled.div`
	font-family: "Roboto";
	color: #212121;
	font-size: 16px;
	line-height: 140%;
	font-weight: 400;
	text-align: center;
	margin-bottom: 30px;
	width: 70%;
`

const LoginImage = styled.img`
	display: block;
	margin: 20px auto 0;
`
