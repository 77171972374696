import React, { Component } from "react"
import CustomNavbar from "../CustomNavbar"
import { Row, Col } from "react-grid-system"
import { Flex, ShareText, Spacer, SpecialtyTag } from "../global"
import FadeIn from "react-fade-in"
import styled from "styled-components"
import BlendAgainImage from "../../assets/icons/blend_again.svg"
import PatternJuisci from "../../assets/images/bg_pattern.png"
import PostTitle from "../PostTitle"
import ImageViewer from "../post/ImageViewer"
import { Carousel } from "react-responsive-carousel"
import {
	CustomIndicators,
	IconWrapper,
	OriginalLinkButton
} from "../../view/postPage/postPage"
import disableScroll from "disable-scroll"
import {
	iosGenerateHaptic,
	iosPlaySound,
	iosSetBackgroundColor,
	isNativeIOS
} from "../../tools/ios"
import { t } from "i18next"
import customToast from "../CustomToast"
import CustomIcon from "../CustomIcon"
import { userApi } from "../../redux/user/user.service"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import ShareCircle from "../../assets/icons/share-btn.svg"
import {
	convertContentToItemData,
	gtmAudioProgress,
	gtmItemData,
	gtmItemsData
} from "../../tools/reactgaEvents"
import PlaylistSheet from "../playlists/PlaylistSheet"
import i18n from "../../config/i18n"
import { preventScroll, renderPublicationDate } from "../../tools/utils"
import ReactAudioPlayer from "react-audio-player"
import { BottomSheet } from "react-spring-bottom-sheet"
import Loader from "../Loader"
import { androidShare } from "../../tools/android"
import { isAndroid } from "react-device-detect"
import { setSuggestedArticlesDatas, store } from "../../redux"
import { setLikes, setSaves, setUser } from "../../redux/user/user.reducer"
import {
	HapticEffect,
	ItemDataEventListName,
	ItemDataVariant,
	ItemsDataEvent,
	PlaylistAction,
	SoundEffect
} from "../../interfaces"
import { likeContent } from "../../services/contentApi"

export const defaultCategories = [
	"conclusion",
	"background",
	"objectives",
	"figures",
	"methodology",
	"results",
	"limitations",
	"authors",
	"disclosure"
]

class CustomStory extends Component {
	player = React.createRef()

	overflowRef = React.createRef()

	state = {
		indexArticles: 0,
		currentIndex: 0,
		article: {},
		visible: false,
		isViewerOpen: false,
		imageIndex: 0,
		categories: defaultCategories,
		currentGradient: this.pickGradient(
			this.props.location?.state?.indexArticles ?? 0
		),
		// More options
		isLiked: false, // Optimistic update
		isMarked: false,
		showSave: false,
		showSheet: false,
		stateBased: false,
		// Detect content overflow
		contentFinished: true,
		// Audio controller
		isPlayed: false,
		audioLink: "",
		audioCurrentTime: 0,
		audioSpeed: 1
	}

	componentDidMount() {
		// Redirect if location state is missing
		if (!this.props.location.state) {
			customToast(t("toast.error.global"))
			this.props.history.replace("/home/foryou")
			return
		}

		const { articles } = this.props.location.state
		preventScroll(true)

		if (!!articles) {
			this.setState(
				{
					articles,
					visible: true
				},
				() => {
					this.fetchUser()
					this.fetchLikes()
					this.formatCategories()
					this.preloadFigures()
					this.handleGetSuggested()
				}
			)
		} else {
			this.props.history.replace("/home")
		}
	}

	preloadFigures(index = 0) {
		const article = this.state.articles[this.state.indexArticles]
		if (article?.images[index]) {
			const img = new Image()
			img.src = article?.images[index].url

			img.onload = () => {
				this.preloadFigures(index + 1)
			}
		}
	}

	componentDidUpdate() {
		const { isPlayed, audioCurrentTime, articles, indexArticles } =
			this.state
		const article = articles[indexArticles]

		if (isPlayed)
			if (audioCurrentTime === this.player?.audioEl?.current?.duration) {
				this.player.audioEl.current.currentTime = 0
				this.setState({ audioCurrentTime: 0, isPlayed: false })
				gtmItemData({
					event: "audio_complete",
					item_name: article?.title,
					item_list_name: "/post",
					item_id: article?._id,
					item_brand: article?.journal?.name,
					item_category: article?.medical_specialties[0].uid,
					item_category3: "article"
				})
			} else {
				let percentages = parseInt(
					(audioCurrentTime /
						this.player?.audioEl?.current?.duration) *
						100
				)
				gtmAudioProgress(
					"audio_progress",
					percentages,
					article?.title,
					"/post",
					article?._id,
					article?.journal?.name,
					article?.medical_specialties
				)
			}
	}

	componentWillUnmount() {
		preventScroll(false)
	}

	async fetchUser() {
		if (!this.props.user.user) {
			await this.props.getUser()
			await this.props.getSaves()
			await this.props.getPlaylists()
		}
	}

	formatCategories() {
		const { articles, indexArticles } = this.state
		const article = articles[indexArticles]

		let categories = [...this.state.categories]
		this.state.categories?.forEach(
			(category) =>
				!Object.keys(article).includes(category) &&
				categories.splice(categories.indexOf(category), 1)
		)

		if (!categories.includes("figures") && !categories.includes("images"))
			categories.splice(categories.indexOf("methodology"), 0, "figures")

		if (article?.images?.length === 0)
			categories = categories.filter((cat) => cat !== "figures")

		categories.unshift("start")
		categories.push("suggestion")
		this.setState({ categories })
	}

	pickGradient(gradientIndex) {
		switch (gradientIndex) {
			case 1:
				return "linear-gradient(180deg, rgba(253, 185, 85, 0.97) 0%, rgba(255, 138, 0, 0.97) 49.48%, rgba(255, 255, 255, 0.97) 100%)"

			case 2:
				return "linear-gradient(180deg, rgba(255, 196, 8, 0.97) 0%, rgba(255, 115, 75, 0.97) 26.56%, rgba(255, 48, 110, 0.97) 53.65%, rgba(255, 255, 255, 0.97) 100%)"

			case 3:
				return "linear-gradient(180deg, rgba(255, 211, 166, 0.97) 0%, rgba(255, 211, 166, 0.97) 0.01%, rgba(255, 156, 150, 0.97) 25%, rgba(252, 111, 137, 0.97) 52.6%, rgba(255, 255, 255, 0.97) 100%)"

			case 4:
				return " linear-gradient(180deg, rgba(255, 105, 156, 0.97) 0%, rgba(254, 87, 99, 0.97) 48.96%, rgba(255, 255, 255, 0.97) 100%)"

			default:
				return "linear-gradient(180deg, rgba(255, 208, 0, 0.97) 0%, rgba(255, 161, 0, 0.97) 49.48%, rgba(255, 255, 255, 0.97) 100%)"
		}
	}

	handleCheckOverflow() {
		this.setState({
			contentFinished:
				this.overflowRef.current.scrollHeight -
					this.overflowRef.current.scrollTop <
				1.02 * this.overflowRef.current.clientHeight
		})
	}

	renderContent() {
		const {
			articles,
			indexArticles,
			currentIndex,
			categories,
			isViewerOpen
		} = this.state
		const article = articles[indexArticles]

		switch (categories[currentIndex]) {
			case "start":
				return (
					<div
						style={{
							position: "relative",
							height: window.innerHeight * 0.6
						}}
					>
						<div
							style={{
								margin: "30px 0",
								display: "flex"
							}}
							ref={this.overflowRef}
						>
							<h2
								id="responsive_headline"
								style={{
									margin: 0,
									fontSize: 27,
									height: "100%",
									maxHeight: window.innerHeight * 0.4
								}}
								className="masked-overflow story-content scrollbar-hidden"
							>
								{article?.title}
							</h2>
						</div>
						<div
							style={{
								position: "absolute",
								bottom: 0,
								left: 0,
								right: 0
							}}
						>
							<Row
								justify="between"
								align="start"
								style={{ margin: 0 }}
							>
								<div
									onClick={() =>
										this.props.history.push(
											"/journal/" + article?.journal?.uid
										)
									}
								>
									<Row style={{ margin: 0 }} align="center">
										<span
											style={{
												fontFamily: "Poppins",
												color: "#212121",
												marginRight: 5,
												fontSize: 12,
												fontWeight: 300
											}}
										>
											Journal
										</span>{" "}
										<CustomIcon
											style={{ marginTop: 4 }}
											iconName="link"
										/>
									</Row>
									<img
										src={article?.journal?.image?.url}
										alt={<Loader loaderOnly />}
										style={{
											height: 40,
											objectFit: "contain",
											maxWidth: 100,
											marginTop: 6
										}}
									/>
								</div>
								<div style={{ maxWidth: 200 }}>
									<p
										style={{
											fontSize: "12px",
											color: "#212121",
											fontWeight: 300,
											margin: 0,
											textAlign: "left"
										}}
									>
										{localStorage.getItem(
											"contentLanguage"
										) === "fr"
											? "Auteurs"
											: "Authors"}
									</p>
									{article?.authors && (
										<p
											style={{
												fontSize: "12px",
												color: "#212121",
												fontWeight: 500,
												margin: 0,
												lineHeight: "12px",
												textAlign: "left",
												marginTop: 6
											}}
										>
											{article?.primary_author}
											{article?.authors?.length
												? {
														en: ` and ${article?.authors?.length} other authors`,
														fr: ` et ${article?.authors?.length} autres auteurs`
												  }[i18n.resolvedLanguage] ?? ""
												: ""}
										</p>
									)}
								</div>
							</Row>
						</div>
					</div>
				)

			case "conclusion":
				return (
					<div>
						<PostTitle title="conclusion" />
						<div
							className="story-content masked-overflow"
							ref={this.overflowRef}
							onScroll={() => this.handleCheckOverflow()}
						>
							<p className="story-text">
								<span>• </span>
								{article?.conclusion?.length > 0
									? article?.conclusion
									: "No conclusion available"}
							</p>
						</div>
					</div>
				)

			case "background":
				return (
					<div>
						<PostTitle title="background" />
						<div
							className="story-content masked-overflow"
							ref={this.overflowRef}
							onScroll={() => this.handleCheckOverflow()}
						>
							<p className="story-text">
								<span>• </span>
								{article?.background?.length > 0
									? article?.background
									: "No background available"}
							</p>
						</div>
					</div>
				)

			case "objectives":
				return (
					<div>
						<PostTitle title="objectives" />
						<div
							className="story-content masked-overflow"
							ref={this.overflowRef}
							onScroll={() => this.handleCheckOverflow()}
						>
							<p className="story-text">
								<span>• </span>
								{article?.objectives?.length > 0
									? article?.objectives
									: "No objectives available"}
							</p>
						</div>
					</div>
				)

			case "methodology":
				return (
					<div>
						<PostTitle title="methodology" />
						<div
							className="story-content masked-overflow"
							ref={this.overflowRef}
							onScroll={() => this.handleCheckOverflow()}
						>
							<p className="story-text">
								<span>• </span>
								{article?.methodology ??
									"No methodology available"}
							</p>
						</div>
					</div>
				)

			case "results":
				return (
					<div>
						<PostTitle title="results" />
						<div
							className="story-content masked-overflow"
							ref={this.overflowRef}
							onScroll={() => this.handleCheckOverflow()}
						>
							{this.overflowRef.current?.scrollTop > 0 && (
								<button>ok</button>
							)}
							{article?.results?.length < 1 &&
								"No results for this article"}
							{article?.results?.map((sentence, index) => {
								if (sentence?.length)
									return (
										<div
											key={`${index}`}
											className="story-text"
										>
											<span>• </span>
											<span
												className="bullet-text"
												style={{ margin: 0 }}
											>
												{`${sentence}`}
												<br />
												<br />
											</span>
										</div>
									)
							})}
						</div>
					</div>
				)

			case "limitations":
				return (
					<div>
						<PostTitle title="limitations" />
						<div
							className="story-content masked-overflow"
							ref={this.overflowRef}
							onScroll={() => this.handleCheckOverflow()}
						>
							<p className="story-text">
								<span>• </span>
								{article.limitations?.length > 0
									? article.limitations
									: "No limitations available"}
							</p>
						</div>
					</div>
				)

			case "authors":
				return (
					<div>
						<PostTitle title="authors" />
						<div
							className="story-content masked-overflow"
							ref={this.overflowRef}
							onScroll={() => this.handleCheckOverflow()}
						>
							{article?.authors?.length < 1 &&
								"No authors for this article"}
							{article?.authors?.map((author, index) => {
								return (
									<div
										key={`${index}`}
										className="bullet-text"
									>
										<Row
											align="center"
											style={{ marginBottom: 10 }}
										>
											<Col xs={3 / 2}>
												<AvatarPlaceholder />
											</Col>
											<Col>
												<AuthorName>
													{author}
												</AuthorName>
											</Col>
										</Row>
									</div>
								)
							})}
						</div>
					</div>
				)

			case "disclosure":
				return (
					<div>
						<PostTitle title="disclosure" />

						<div
							className="story-content masked-overflow"
							ref={this.overflowRef}
							onScroll={() => this.handleCheckOverflow()}
						>
							<p>
								<span>• </span>
								{article.disclosure?.length > 0
									? article.disclosure
									: "No disclosure statement available"}
							</p>
						</div>
					</div>
				)

			case "figures":
				return (
					<div ref={this.overflowRef}>
						<PostTitle title="figures" />
						<h5
							style={{
								fontSize: 17,
								fontWeight: 700,
								fontFamily: "Poppins",
								margin: 0,
								display:
									article?.images?.length > 0
										? "block"
										: "none"
							}}
						>
							Figure {this.state.imageIndex + 1}
						</h5>
						<p>
							{article?.images?.length === 0
								? t("content.articles.noFigures") + "."
								: article?.images[this.state.imageIndex]
										?.legend ??
								  t("content.articles.noLegendFigures")}
						</p>
						<Spacer />
						{article?.images?.length > 0 && (
							<>
								<div
									onClick={(e) => {
										e.preventDefault()
										this.setState(
											{ isViewerOpen: true },
											() => {
												disableScroll.on()
												if (isNativeIOS)
													iosSetBackgroundColor(
														"#191919"
													)
											}
										)
									}}
								>
									<Carousel
										autoFocus={false}
										autoPlay={false}
										preventMovementUntilSwipeScrollTolerance
										swipeScrollTolerance={30}
										interval={1e12}
										stopOnHover={true}
										infiniteLoop={true}
										showIndicators={false}
										showStatus={false}
										showThumbs={false}
										showArrows={false}
										centerMode={false}
										selectedItem={this.state.imageIndex}
										onChange={(to) =>
											this.setState({ imageIndex: to })
										}
										style={{
											margin: 0
										}}
									>
										{article?.images?.map(
											(figure, index) => (
												<div
													key={figure?._id + index}
													style={{
														// margin: "0 5px",
														width:
															window.innerWidth *
															0.9,
														textAlign: "center"
													}}
													className="preventGoNext"
												>
													<img
														src={figure?.url}
														style={{
															width: "100%",
															height: "200px",
															objectFit: "cover",
															border: "1px solid #212121"
														}}
													/>
												</div>
											)
										)}
									</Carousel>
								</div>
								<ImageViewer
									open={isViewerOpen}
									imgs={article?.images}
									imageIndex={this.state.imageIndex}
									updateIndex={(to) =>
										this.setState({ imageIndex: to })
									}
									onClose={() =>
										this.setState({ isViewerOpen: false })
									}
								/>
								<center>
									{article?.images?.map(
										(_, index) =>
											index < 14 && (
												<CustomIndicators
													key={
														index +
														"customindicatorsstoyyforyou"
													}
													active={
														this.state
															.imageIndex ===
														index
													}
													primaryColor="white"
													secondary="#212121"
												/>
											)
									)}
								</center>
							</>
						)}
					</div>
				)

			case "suggestion":
				return (
					<div ref={this.overflowRef}>
						<Spacer height="80px" />
						<OriginalLinkButton
							style={{
								background: "white",
								borderRadius: "20px"
							}}
							article={article}
							href={`${article?.doi ?? ""}`}
						/>
						<Spacer height="40px" />
						<BlendAgainButton
							style={{
								background: "white",
								borderRadius: "20px"
							}}
							nextArticle={this.handleGoNext.bind(this)}
						/>
					</div>
				)

			default:
				break
		}
	}

	handleClick(e) {
		const screenWidth = window.innerWidth

		if (
			e.target.className !== "preventGoNext" &&
			typeof e.target.className !== "object" &&
			!e.target?.className?.includes("preventGoNextHard")
		)
			if (!this.state.isViewerOpen) {
				e.pageX < screenWidth * 0.3 &&
					this.state.currentIndex > 0 &&
					this.setState(
						{ currentIndex: this.state.currentIndex - 1 },
						() => this.handleCheckOverflow()
					)
				e.pageX > screenWidth * 0.6 &&
					this.state.currentIndex <
						this.state.categories.length - 1 &&
					this.setState(
						{ currentIndex: this.state.currentIndex + 1 },
						() => this.handleCheckOverflow()
					)
			}
	}

	handleGoNext() {
		const { articles, indexArticles } = this.state

		if (articles.length - 1 > indexArticles)
			this.setState(
				{
					visible: false
				},
				() => {
					setTimeout(() => {
						this.setState(
							{
								visible: true,
								indexArticles: indexArticles + 1,
								currentIndex: 0
							},
							() => {
								this.formatCategories()
								this.preloadFigures()
							}
						)
					}, 500)
				}
			)
		else this.props.history.replace("/home/foryou")
	}

	fetchLikes = async () => {
		this.setState({
			isLiked: this.getStoreIsLiked()
		})
	}

	fetchPlaylists = async () => {
		await this.props.getPlaylists()
	}

	handleShowMore(e) {
		e.preventDefault()
		this.fetchLikes()
		this.fetchPlaylists()
		this.setState({ showSheet: true })
	}

	shareArticle() {
		const article = this.state.articles[this.state.indexArticles]
		const { title, journal } = article

		const shareUrl = `/post/${article.slug}?shared=${
			this.props.user?.user?.uid ?? "null"
		}&org=${this.props.user.user?.organisations?.[0]?.uid || "null"}`

		if (isAndroid) {
			androidShare(shareUrl, ShareText(title, journal?.name))
		} else if (navigator.share) {
			navigator
				.share({
					title: document.title,
					text: ShareText(title, journal?.name),
					url: shareUrl
				})
				.then(() => {
					sharedArticle(article?.slug).then(() => {
						iosGenerateHaptic(HapticEffect.SUCCESS)
						iosPlaySound(SoundEffect.SHARE)
						customToast(t("toast.success.sharedArticle"), "success")
					})

					gtmItemsData(
						ItemsDataEvent.ARTICLE_SHARE,
						convertContentToItemData(
							[article],
							ItemDataEventListName.FORYOU,
							ItemDataVariant.ARTICLE
						)
					)
				})
				.catch(() => {})
		} else {
			customToast(t("toast.error.browser"))
		}
	}

	getStoreIsLiked() {
		const article = this.state.articles[this.state.indexArticles]

		const storeIsLiked =
			this.props.user?.user?.likes?.find(
				(el) => el.slug === article?.slug
			) !== undefined

		return storeIsLiked
	}

	async handleLike() {
		try {
			// Prevent successive clicks/taps
			if (this.state.isWaitingForLikeResponse) {
				return
			}

			const article = this.state.articles[this.state.indexArticles]
			// As of 2023-07, the like route accepts both id and slug,
			// but the unlike route only accepts the slug.

			this.setState({ isWaitingForLikeResponse: true })

			likeContent(
				article,
				gtmItemsData(
					ItemsDataEvent.ARTICLE_LIKE,
					convertContentToItemData(
						[article],
						ItemDataEventListName.FORYOU,
						ItemDataVariant.ARTICLE
					)
				)
			)

			this.setState({ isWaitingForLikeResponse: false })
		} catch (error) {
			console.error("Err CustomStory handleLike", error.message)
		}
	}

	handlePlayPause() {
		let { articles, indexArticles, isPlayed } = this.state
		const article = articles[indexArticles]

		if (!isPlayed) {
			gtmItemData({
				event: "audio_played",
				item_name: article?.title,
				item_list_name: "/post",
				item_id: article?._id,
				item_brand: article?.journal?.name,
				item_category: article?.medical_specialties[0].uid,
				item_category3: "article"
			})

			this.player.audioEl.current.play()
		} else {
			this.player.audioEl.current.pause()
		}
		this.setState({ isPlayed: !isPlayed })
	}

	async handleGetSuggested() {
		const { articles } = this.state
		setSuggestedArticlesDatas([...articles])
	}

	addPlaylistSuccess = async (playlistAction) => {
		const { articles, indexArticles } = this.state
		const article = articles[indexArticles]

		if (!this.props.user.saves) await this.props.getSaves()

		let savesReducer = [
			...this.props.user.saves.filter((el) =>
				playlistAction === PlaylistAction.REMOVE
					? el.slug !== this.state.article.slug
					: el
			)
		]

		if (playlistAction === PlaylistAction.ADD)
			savesReducer.push(this.state.article)

		store.dispatch(setSaves(savesReducer))

		iosGenerateHaptic(HapticEffect.SUCCESS)
		iosPlaySound(SoundEffect.SAVE)

		if (playlistAction === PlaylistAction.REMOVE)
			customToast(t("toast.success.unsavedArticle"), "success")
		else {
			customToast(t("toast.success.savedArticle"), "success")
		}
	}

	render() {
		if (!this.state.articles) return <Loader />

		const isLogin = !!store.getState().user
		const { articles, indexArticles, showSheet } = this.state
		const article = articles[indexArticles]

		const isMarked =
			this.props?.user?.saves &&
			this.props?.user?.saves?.find((el) => el.slug === article.slug)

		const isLiked = this.props.user.user?.likes?.some(
			(el) => el.slug === article.slug
		)

		return (
			<>
				<ReactAudioPlayer
					listenInterval={100}
					onPause={() => {
						this.setState({
							audioCurrentTime:
								this.player.audioEl?.current?.currentTime
						})
					}}
					onListen={() => {
						this.setState({
							audioCurrentTime:
								this.player.audioEl?.current?.currentTime
						})
					}}
					src={article?.speech?.url}
					on
					ref={(element) => {
						this.player = element
					}}
				/>
				<div
					style={{
						background: "#212121",
						position: "fixed",
						overflow: "hidden",
						overscrollBehavior: "none",
						width: window.innerWidth,
						touchAction: "none",
						msTouchAction: "none"
					}}
					className="scrollbar-hidden"
				>
					<CustomNavbar {...this.props} />
					<FadeIn visible={this.state.visible}>
						<Spacer height="56px" />
						<StoryWrapper
							gradient={this.pickGradient(
								this.state.indexArticles % 5
							)}
							onClick={(e) => this.handleClick(e)}
						>
							<StoryIndicator
								count={this.state.categories.length}
								currentIndex={this.state.currentIndex}
							/>
							<Spacer height="20px" />
							<StoryHeader
								{...this.props}
								nextArticle={this.handleGoNext.bind(this)}
								article={article}
								showBlender={
									this.state.currentIndex <
									this.state.categories?.length - 1
								}
								handleShowMore={this.handleShowMore.bind(this)}
							/>
							<Spacer height="30px" />

							<Row
								justify="between"
								align="center"
								style={{ margin: 0 }}
							>
								<div style={{ maxWidth: "80%" }}>
									<p
										style={{
											fontSize: "12px",
											fontWeight: 300,
											fontFamily: "Poppins",
											marginTop: 0,
											marginBottom: 8
										}}
									>
										{renderPublicationDate(
											article.publication_date
										)}
									</p>
									{article?.medical_specialties?.map(
										(specialty, index) =>
											index < 4 && (
												<SpecialtyTag
													style={{
														color: "#CE0868",
														background: "#fff"
													}}
													key={index + "keywinazeza"}
												>
													{
														specialty.translations[
															localStorage.getItem(
																"contentLanguage"
															)
														]
													}
												</SpecialtyTag>
											)
									)}{" "}
								</div>
								<div
									className="preventGoNextHard"
									style={{
										boxSizing: "border-box"
									}}
								>
									<IconWrapper
										className="preventGoNextHard"
										style={{
											background:
												"linear-gradient(180deg, #F42CAC 0%, #F1353B 100%)"
										}}
										onClick={() => this.handlePlayPause()}
									>
										<CustomIcon
											className="preventGoNextHard"
											iconName={
												this.state.isPlayed
													? "pause"
													: "play"
											}
											scale={0.9}
											color="white"
											style={{
												marginLeft: 5,
												marginTop: 1
											}}
										/>
									</IconWrapper>
								</div>
							</Row>

							<Spacer height="20px" />

							{this.renderContent()}
							<ScrollButton active={this.state.contentFinished} />
						</StoryWrapper>
					</FadeIn>
					<BottomSheet
						expandOnContentDrag
						open={showSheet}
						onDismiss={() =>
							setTimeout(
								() =>
									this.setState({ showSheet: false }, () => {
										// if (this.state.stateBased) this.handleLike();
									}),
								100
							)
						}
					>
						<SheetWrapper>
							<Row
								style={{ margin: 0 }}
								justify="around"
								align="center"
								gutterWidth={16}
								onClick={() => {
									if (isLogin) {
										this.setState({
											showSave: true,
											showSheet: false
										})
									} else {
										push("/login")
									}
								}}
							>
								<Col xs={1}>
									<Spacer height="5px" />
									<CustomIcon
										iconName={
											isMarked ? "save.fill" : "save"
										}
										color="#212121"
										style={{ marginLeft: -3 }}
									/>
								</Col>
								<Col
									xs={10}
									style={{
										color: isMarked ? "#FF699C" : "#212121",
										marginLeft: -12
									}}
								>
									<p
										style={{
											fontSize: "17px",
											fontWeight: 600,
											margin: 0
										}}
									>
										{isMarked
											? t("common.modify")
											: t("common.save")}
									</p>
									<p
										style={{
											fontSize: "15px",
											fontWeight: 400,
											width: "100%",
											margin: 0
										}}
									>
										{isMarked
											? t("sheet.article.unsave")
											: t("sheet.article.save")}
									</p>
								</Col>
							</Row>
							<Spacer height="30px" />
							<Row
								justify="around"
								align="center"
								gutterWidth={20}
								onClick={() => {
									if (isLogin) {
										this.handleLike()
									} else {
										push("/login")
									}
								}}
							>
								<Col xs={1}>
									<Spacer height="10px" />
									<CustomIcon
										scale={1.5}
										style={{ marginTop: -5, marginLeft: 7 }}
										iconName={
											isLiked ? "heart" : "heart_outlined"
										}
										color={isLiked ? "#FF699C" : "#212121"}
									/>
								</Col>
								<Col
									xs={10}
									style={{
										color: isLiked ? "#FF699C" : "#212121"
									}}
								>
									<p
										style={{
											fontSize: "17px",
											fontWeight: 600,
											margin: 0
										}}
									>
										{isLiked
											? t("common.unlike")
											: t("common.like")}
									</p>
									<p
										style={{
											fontSize: "15px",
											fontWeight: 400,
											margin: 0
										}}
									>
										{t("sheet.article.like")}
									</p>
								</Col>
							</Row>
							<Spacer height="30px" />
							<Row
								justify="around"
								align="center"
								gutterWidth={20}
								onClick={this.shareArticle.bind(this)}
							>
								<Col xs={1}>
									<Spacer height="5px" />

									<CustomIcon
										iconName="share-alt"
										scale={0.8}
										color="#212121"
										style={{
											marginLeft: 5
										}}
									/>
								</Col>
								<Col xs={10}>
									<p
										style={{
											fontSize: "17px",
											fontWeight: 600,
											margin: 0
										}}
									>
										{t("common.share")}
									</p>
									<p
										style={{
											fontSize: "15px",
											fontWeight: 400,
											margin: 0
										}}
									>
										{t("sheet.playlist.share.subtitle")}
									</p>
								</Col>
							</Row>
						</SheetWrapper>
					</BottomSheet>
					{this.state.showSave && (
						<PlaylistSheet
							playlists={this.props.user?.playlists}
							showSheet={this.state.showSave}
							onClose={() => this.setState({ showSave: false })}
							article={article}
							getUser={() => this.props.getUser()}
							getPlaylists={() => this.props.getPlaylists()}
							getSaves={() => this.props.getSaves()}
							onComplete={this.addPlaylistSuccess.bind(this)}
						/>
					)}
				</div>
			</>
		)
	}
}
function mapState(state) {
	const user = state.user
	const articles = state.articles
	return {
		user,
		articles
	}
}

const mapDispatch = {
	getUser: userApi.endpoints.getUser.initiate,
	getPlaylists: userApi.endpoints.getPlaylists.initiate,
	getSaves: userApi.endpoints.getSaves.initiate
}

const connector = connect(mapState, mapDispatch)

export default connector(withTranslation()(CustomStory))

const StoryIndicator = (props) => (
	<>
		<div
			style={{
				display: "grid",
				gridTemplateColumns: "1fr ".repeat(props.count),
				gridTemplateRows: "1fr",
				gap: 10
			}}
		>
			{Array(props.count)
				.fill(undefined)
				?.map((_, index) => {
					return (
						<div
							key={"customstoryind" + index}
							style={{
								height: 2,
								border: "none",
								borderRadius: 200,
								transition: "background ease-in-out 0.3s",
								background:
									index <= props.currentIndex
										? "#212121"
										: "rgba(33, 33, 33, 0.25)"
							}}
						></div>
					)
				})}
		</div>
	</>
)

const StoryHeader = (props) => (
	<Flex justify="space-between" align="center">
		<div>
			<div
				className="preventGoNext"
				onClick={() => props.history.push("/home")}
			>
				<CustomIcon
					iconName="close_alt"
					color="#fff"
					color2="#212121"
				/>
			</div>
		</div>
		<Flex align="center">
			<img
				className="preventGoNext"
				onClick={(e) => props.handleShowMore(e)}
				src={ShareCircle}
				alt=""
				style={{ width: 31.5, height: 31.5 }}
			/>
			<BlendAgain
				nextArticle={props.nextArticle}
				style={{
					display: props.showBlender ? "block" : "none"
				}}
			/>
		</Flex>
	</Flex>
)

const BlendAgain = (props) => {
	return (
		<img
			className="preventGoNext"
			onClick={(e) => {
				e.preventDefault()
				props?.nextArticle()
			}}
			src={BlendAgainImage}
			alt=""
			style={{
				height: "31.5px",
				width: "31.5px",
				margin: 0,
				marginLeft: 15,
				...props.style
			}}
		/>
	)
}

export const BlendAgainButton = (props) => {
	return (
		<div
			className="preventGoNext"
			style={{
				position: "relative",
				background: "#DFE6EC",
				borderRadius: "8px",
				padding: 20,
				...props.style
			}}
			onClick={(e) => {
				e.preventDefault()
				props?.nextArticle()
			}}
		>
			<Row style={{ margin: 0 }} align="center">
				<Col xs={10}>
					<p
						style={{
							fontFamily: "Poppins",
							fontWeight: 700,
							color: "#4C5861",
							lineHeight: "100%",
							fontSize: "16px",
							marginBottom: 0
						}}
					>
						{i18n.language === "en"
							? "Still thirsty?"
							: "Vous en voulez encore ?"}
					</p>
					<p
						style={{
							fontFamily: "Roboto",
							fontWeight: 400,
							color: "#4C5861",
							lineHeight: "16px",
							fontSize: "14px",
							marginTop: "6px"
						}}
					>
						{i18n.language === "fr"
							? "Mixez de nouveau pour un shot de science supplémentaire."
							: "Blend it again for another shot of science"}
					</p>
				</Col>
				<Col xs={2} style={{ padding: 0 }}>
					<img
						src={BlendAgainImage}
						alt=""
						style={{
							height: 50,
							width: 50,
							margin: 0
						}}
					/>
				</Col>
			</Row>
		</div>
	)
}

const StoryWrapper = styled.div`
	display: flex;
	flex-direction: column;
	font-family: "Poppins" !important;
	background-image: ${(props) => props.gradient}, url(${PatternJuisci});
	height: calc(${window.innerHeight}px - 66px);
	width: 100%;
	overflow: hidden;
	border-radius: 8px 8px 0px 0px;
	padding: 20px;
	box-sizing: border-box;
	margin-top: 10px;

	h2.story-title {
		font-family: "Work Sans";
		font-size: 30px;
	}

	div.story-content {
		height: ${window.innerHeight * 0.6}px;
		overflow-y: scroll;
		overflow-x: hidden;
		padding-bottom: 40px;
		box-sizing: border-box;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */

		touch-action: pan-y;
		-ms-touch-action: pan-y;
	}

	div.story-content::-webkit-scrollbar {
		display: none;
	}

	div.story-content span {
		color: #f42cab;
		font-size: 24px;
		vertical-align: top;
	}

	div.story-content p,
	div.story-content span.bullet-text {
		font-size: 16px;
		font-family: Poppins;
		line-height: 26px;
		margin-top: 0;
		color: #212121;
	}
`

const AvatarPlaceholder = styled.div`
	background: linear-gradient(
		180deg,
		#ffd3a6 0%,
		#ff9c96 51.56%,
		#fc6f89 100%
	);
	height: 27px;
	width: 27px;
	border-radius: 50%;
`

const AuthorName = styled.p`
	font-size: 14px;
	font-family: Poppins;
	font-weight: 400;
	color: #212121;
	margin-bottom: 0px;
	&:hover,
	&:focus {
		color: #212121;
	}
`

const SheetWrapper = styled.div`
	padding: 55px 15px;
	min-height: ${window.innerHeight / 3}px;
`

const ScrollButton = (props) => (
	<div
		style={{
			position: "absolute",
			bottom: 50,
			left: "50%",
			transform: "translateX(-50%)",
			opacity: props.active ? 0 : 1,
			transition: "opacity ease 0.3s"
		}}
	>
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="16"
				cy="16"
				r="15.75"
				fill="white"
				stroke="#ECF0F5"
				strokeWidth="0.5"
			/>
			<path
				d="M9 14.5L16.5 22L24 14.5"
				stroke="#FF699C"
				strokeWidth="3"
				strokeLinecap="round"
			/>
		</svg>
	</div>
)
