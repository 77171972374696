import i18next, { t } from "i18next"
import React, { Component } from "react"
import styled from "styled-components"
import CustomIcon from "../CustomIcon"

class LearningActivity extends Component {
	render() {
		return (
			<StyledWrapper onClick={this.props.onClick}>
				<div className="card-title">
					<CustomIcon iconName="brain" />
					<h5>{t("profile.gamification.activity.title")}</h5>
				</div>
				<div className="description">
					{i18next.resolvedLanguage === "fr" ? (
						<>
							Acquérir des connaissances médicales est{" "}
							<b>un parcours en constante évolution</b>.{" "}
							{this.props.isMe && (
								<>
									Continue sur <b>ta lancée</b> et collecte
									des badges.
								</>
							)}
						</>
					) : (
						<>
							Acquiring medical knowledge is{" "}
							<b>a constantly evolving journey</b>. Keep{" "}
							<b>your streak</b> going and collect achievements.
						</>
					)}
				</div>

				<button>
					{this.props.isMe
						? t("profile.gamification.activity.button")
						: t("profile.gamification.activity.buttonIsMe")}
				</button>
			</StyledWrapper>
		)
	}
}

export default LearningActivity

const StyledWrapper = styled.div`
	padding: 20px 21px;
	background: linear-gradient(
		360deg,
		#ffc408 0%,
		#ff8800 51.56%,
		#ff7fbd 100%
	);
	border-radius: 15px;

	.card-title {
		display: flex;
		justify-content: center;
		gap: 10px;

		h5 {
			font-family: "Work Sans";
			font-style: normal;
			font-weight: 900;
			font-size: 21px;
			line-height: 120%;
			margin: 0;
		}
	}

	.description {
		margin: 5px 0px;
		font-family: "Roboto";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 120%;
		text-align: center;
		letter-spacing: 0.02em;
	}

	button {
		display: block;
		outline: none;
		border: none;
		box-shadow: none;
		padding: 12px 16px;
		margin: auto;
		margin-top: 20px;
		background-color: #fff;
		color: #212121;
		border-radius: 50px;
		font-family: "Poppins";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 100%;
		text-align: center;
		letter-spacing: 0.02em;
	}
`
