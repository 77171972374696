import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "./styles/theme";
import { LocaleProvider } from "antd-mobile";
import enUS from "antd-mobile/lib/locale-provider/en_US";
import "./App.css";
import SplashPage from "./view/static/splashPage";
import WelcomePage from "./view/static/welcomePage";
import HomePage from "./view/homePage/homePage";
import PostPage from "./view/postPage/postPage";
import ErrorPage from "./view/static/errorPage";
import SignupPage from "./view/signupPage/signupPage";
import SuccessSignup from "./view/signupPage/successSignup";
import LoginPage from "./view/loginPage/loginPage";
import SettingsPage from "./view/SettingsPage/settingsPage";
import EditSettings from "./view/SettingsPage/editSettings";
import PrivacyPage from "./view/SettingsPage/privacyPage";
import TermsPage from "./view/SettingsPage/termsPage";
import SearchPage from "./view/searchPage/searchPage";
import { Redirector } from "react-router-redirect";
import ResetPage from "./view/loginPage/resetPage";
import ConfirmResetPage from "./view/loginPage/confirmResetPage";
import TagManager from "react-gtm-module";
import PrevSignupPage from "./view/signupPage/prevSignupPage";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import PlaylistPage from "./view/profilePage/playlistPage";
import CreatePlaylist from "./view/profilePage/createPlaylist";
import ProfilePage from "./view/profilePage/profilePage";
import EditProfilePage from "./view/editProfilePage/editProfilePage";
import EditBioPage from "./view/editProfilePage/editBioPage";
import EditLocationPage from "./view/editProfilePage/editLocationPage";
import EditSpecialtiesPage from "./view/editProfilePage/editSpecialtiesPage";
import EditAvatarPage from "./view/editProfilePage/editAvatarPage";
import FollowView from "./view/profilePage/followView";
import MessageList from "./view/messagePage/messageList";
import DirectMessage from "./view/messagePage/directMessage";
import EditPublishedArticles from "./view/editProfilePage/editPublishedArticle";
import EditAwardsPage from "./view/editProfilePage/editAwardsPage";
import NotificationCenter from "./view/notificationsPage/notificationCenter";
import NewMessage from "./view/messagePage/newMessage";

import { initFirebaseCloudMessaging } from "./services/firebase";
import { iosRequestPushAuthorization, isNativeIOS } from "./tools/ios";
import { withTranslation } from "react-i18next";
import { SocketProvider } from "./context/socket.context";
import { socketWrapper } from "./view/messagePage/socketWrapper";
import NotificationRouter from "./view/notificationsPage/notificationRouter";
import { connect } from "react-redux";
import { articlesApi } from "./redux/articles/articles.service";
import ForYou from "./components/home/ForYou";
import PrevLoginPage from "./view/loginPage/prevLoginPage";
import EditLanguage from "./view/SettingsPage/editLanguage";
import JournalPage from "./view/journalPage/journalPage";
import CustomStory from "./components/home/CustomStory";
import AccountPage from "./view/SettingsPage/accountPage";
import PublicPlaylist from "./view/companyPage/PublicPlaylist";
import CompanyPage from "./view/companyPage/CompanyPage";
import ProfileGamification from "./view/profilePage/gamification/profileGamification";
import OnboardingProfession from "./view/signupPage/onboarding/onboardingProfession";
import OnboardingSpecialty from "./view/signupPage/onboarding/onboardingSpecialty";
import OnboardingAvatar from "./view/signupPage/onboarding/onboardingAvatar";
import OnboardingOtherSpecialties from "./view/signupPage/onboarding/onboardingOtherSpecialties";
import OnboardingFormat from "./view/signupPage/onboarding/onboardingFormat";
import OnboardingJournals from "./view/signupPage/onboarding/onboardingJournals";
import PasswordPage from "./view/static/passwordPage";
import EditPreferredContentPage from "./view/editProfilePage/editPreferredContentPage";
import EditSpecialtyPage from "./view/editProfilePage/editSpecialtyPage";
import MaintenancePage from "./view/static/maintenancePage";
import OnboardingProfile from "./view/signupPage/onboarding/onboardingProfile";
import MagicLinkPage from "./view/signupPage/magicLinkPage";
import NewPasswordPage from "./view/signupPage/newPasswordPage";
import RouteChecker from "./components/RouteChecker";
import CongressPage from "./view/homePage/CongressPage";
import VideoPage from "./view/VideoPage";
import InfographicPage from "./view/InfographicPage/InfographicPage";
import { gtmPageView } from "./tools/reactgaEvents";
import { store } from "./redux";
import QrCodePage from "./view/SettingsPage/qrCodePage";

class App extends React.Component {
  state = {
    currentPath: window.location.pathname,
  };

  componentDidMount() {
    // If new day, reset article views
    this.handleArticleViews();

    localStorage.removeItem("didAuth");

    // request ios notifications
    if (isNativeIOS) {
      iosRequestPushAuthorization();
    }

    ////// FCM NOTIFICATIONS NATIVE IOS //////
    initFirebaseCloudMessaging();

    // GOOGLE ANALYTICS
    if (window.location.host !== "localhost:3000")
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID,
      });
  }

  componentDidUpdate = () => {
    this.handlePageView();
  };

  handleArticleViews() {
    const _nb_views_tm = new Date(
      Date.parse(localStorage.getItem("_nb_views_tm") ?? "")
    );
    const now = new Date();

    const _nb_views_exp =
      (now.getTime() - _nb_views_tm.getTime()) / (1000 * 60 * 60 * 24) > 1;

    if (_nb_views_exp) localStorage.setItem("numberViews", "0");
  }

  handlePageView = () => {
    if (window.location.pathname !== this.state.currentPath) {
      this.setState({ currentPath: window.location.pathname });
      const userUid = store.getState().user?.user?.uid || null;

      if (window.location.pathname === "/profile" && !userUid) return;

      gtmPageView();
    }
  };

  render() {
    const _accessAuthorized = localStorage.getItem("_accessAuthorized");

    if (
      !parseInt(_accessAuthorized) &&
      window.location.host === "dev.juisci.com"
    )
      return <PasswordPage />;

    // return <MaintenancePage />;

    return (
      <LocaleProvider locale={enUS}>
        <ThemeProvider theme={theme}>
          <Router>
            <SocketProvider>
              <Redirector />
              <RouteChecker />
              <Switch>
                <Route exact path='/'>
                  <SplashPage />
                </Route>
                <Route
                  exact
                  path='/fromV2'
                  render={(props) => {
                    const { search } = props.location;
                    const query = new URLSearchParams(search);

                    localStorage.setItem(
                      "customToken",
                      query.get("customToken")
                    );

                    return <Redirect to='/?fromV2=true' />;
                  }}
                />
                <Route path='/welcome'>
                  <WelcomePage />
                </Route>

                <Route
                  exact
                  path='/home'
                  render={(props) => <HomePage {...props} />}
                />

                <Route
                  exact
                  path='/home/congress'
                  render={(props) => <CongressPage {...props} />}
                />

                <Route
                  exact
                  path='/home/foryou'
                  render={(props) => <ForYou {...props} />}
                />

                <Route
                  exact
                  path='/home/foryou/story'
                  render={(props) => <CustomStory {...props} />}
                />

                <Route
                  path='/home'
                  render={(props) => <Redirect {...props} to='/home' />}
                />

                <Route
                  exact
                  path='/post/:id'
                  render={(props) => (
                    <PostPage {...props} key={props.match.params.id} />
                  )}
                />

                <Route
                  exact
                  path='/infographic/:slug'
                  render={(props) => <InfographicPage {...props} />}
                />

                <Route
                  exact
                  path='/video/:slug'
                  render={(props) => <VideoPage {...props} />}
                />

                <Route
                  exact
                  path='/journal/:uid'
                  render={(props) => <JournalPage {...props} />}
                />

                <Route
                  exact
                  path='/signup'
                  render={(props) => <SignupPage {...props} />}
                />

                <Route
                  exact
                  path='/signup/prev'
                  render={(props) => <PrevSignupPage {...props} />}
                />

                <Route
                  exact
                  path='/signup/magiclink'
                  render={(props) => <MagicLinkPage {...props} />}
                />

                <Route
                  exact
                  path='/invite/:inviteCode'
                  render={(props) => {
                    const { inviteCode } = props.match.params;

                    return (
                      <Redirect
                        to={{
                          pathname: "/signup",
                          search: `?inviteCode=${inviteCode}`,
                        }}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path='/signup/newpassword'
                  render={(props) => <NewPasswordPage {...props} />}
                />

                <Route
                  exact
                  path='/signup/success'
                  render={(props) => <SuccessSignup {...props} />}
                />

                <Route
                  exact
                  path='/signup/onboarding'
                  render={(props) => <OnboardingProfession {...props} />}
                />

                <Route
                  exact
                  path='/signup/onboarding/profile'
                  render={(props) => <OnboardingProfile {...props} />}
                />

                <Route
                  exact
                  path='/signup/onboarding/specialty'
                  render={(props) => <OnboardingSpecialty {...props} />}
                />

                <Route
                  exact
                  path='/signup/onboarding/avatar'
                  render={(props) => <OnboardingAvatar {...props} />}
                />

                <Route
                  exact
                  path='/signup/onboarding/specialty/other'
                  render={(props) => <OnboardingOtherSpecialties {...props} />}
                />

                <Route
                  exact
                  path='/signup/onboarding/format'
                  render={(props) => <OnboardingFormat {...props} />}
                />

                <Route
                  exact
                  path='/signup/onboarding/journals'
                  render={(props) => <OnboardingJournals {...props} />}
                />

                <Route
                  exact
                  path='/login'
                  render={(props) => <LoginPage {...props} />}
                />

                <Route
                  exact
                  path='/login/prev'
                  render={(props) => <PrevLoginPage {...props} />}
                />

                <Route exact path='/login/reset'>
                  <ResetPage />
                </Route>
                <Route exact path='/login/reset/confirm'>
                  <ConfirmResetPage />
                </Route>

                <Route
                  exact
                  path='/profile'
                  render={(props) => <ProfilePage {...props} isMe />}
                />

                <Route
                  exact
                  path='/profile/user/:id'
                  render={(props) => <ProfilePage {...props} isMe={false} />}
                />

                <Route
                  exact
                  path='/profile/followers'
                  render={(props) => (
                    <FollowView {...props} type='followers' isMe={true} />
                  )}
                />

                <Route
                  exact
                  path='/profile/following'
                  render={(props) => (
                    <FollowView {...props} type='following' isMe={true} />
                  )}
                />

                <Route
                  exact
                  path='/profile/followers/:id'
                  render={(props) => (
                    <FollowView {...props} type='followers' isMe={false} />
                  )}
                />

                <Route
                  exact
                  path='/profile/following/:id'
                  render={(props) => (
                    <FollowView {...props} type='following' isMe={false} />
                  )}
                />

                <Route
                  exact
                  path='/profile/notifications'
                  render={(props) => <NotificationCenter {...props} />}
                />

                <Route
                  exact
                  path='/profile/notifications/:id'
                  render={(props) => <NotificationRouter {...props} />}
                />

                <Route
                  exact
                  path='/profile/messages'
                  // render={(props) => <MessageList {...props} />}
                  component={socketWrapper(MessageList)}
                />

                <Route
                  exact
                  path='/profile/messages/new'
                  // render={(props) => <NewMessage {...props} />}
                  component={socketWrapper(NewMessage)}
                />

                <Route
                  exact
                  path='/profile/messages/conversation/:id'
                  // render={(props) => <DirectMessage {...props} />}
                  component={socketWrapper(DirectMessage)}
                />

                <Route
                  exact
                  path='/profile/playlist/:id'
                  render={(props) => <PlaylistPage {...props} isMe={true} />}
                />

                <Route
                  exact
                  path='/profile/user/:uid/playlist/:id'
                  render={(props) => <PlaylistPage {...props} isMe={false} />}
                />

                <Route
                  exact
                  path='/profile/playlist/create/new'
                  render={(props) => <CreatePlaylist {...props} />}
                />

                <Route
                  exact
                  path='/profile/game/activity'
                  render={(props) => <ProfileGamification {...props} />}
                />

                <Route
                  exact
                  path='/user/game/activity/:user_id'
                  render={(props) => <ProfileGamification {...props} />}
                />

                <Route
                  exact
                  path='/settings'
                  render={(props) => <SettingsPage {...props} />}
                />

                <Route
                  exact
                  path='/settings/edit/profile'
                  render={(props) => <EditProfilePage {...props} />}
                />

                <Route
                  exact
                  path='/settings/edit/profile/avatar'
                  render={(props) => <EditAvatarPage {...props} />}
                />

                <Route
                  exact
                  path='/settings/edit/profile/bio'
                  render={(props) => <EditBioPage {...props} />}
                />

                <Route
                  exact
                  path='/settings/edit/profile/awards'
                  render={(props) => <EditAwardsPage {...props} />}
                />

                <Route
                  exact
                  path='/settings/edit/profile/location'
                  render={(props) => <EditLocationPage {...props} />}
                />

                <Route
                  exact
                  path='/settings/edit/profile/preferredContent'
                  render={(props) => <EditPreferredContentPage {...props} />}
                />

                <Route
                  exact
                  path='/settings/edit/profile/specialty'
                  render={(props) => <EditSpecialtyPage {...props} />}
                />

                <Route
                  exact
                  path='/settings/edit/profile/published_articles'
                  render={(props) => <EditPublishedArticles {...props} />}
                />

                <Route
                  exact
                  path='/settings/edit/profile/specialties'
                  render={(props) => <EditSpecialtiesPage {...props} />}
                />

                <Route
                  exact
                  path='/settings/edit'
                  render={(props) => <EditSettings {...props} />}
                />
                <Route
                  exact
                  path='/settings/edit/language'
                  render={(props) => <EditLanguage {...props} />}
                />
                <Route
                  exact
                  path='/settings/account'
                  render={(props) => <AccountPage {...props} />}
                />
                <Route
                  exact
                  path='/settings/qr-code'
                  render={(props) => <QrCodePage {...props} />}
                />
                <Route
                  exact
                  path='/policy'
                  render={(props) => <PrivacyPage {...props} />}
                />

                <Route
                  exact
                  path='/terms'
                  render={(props) => <TermsPage {...props} />}
                />

                <Route
                  path='/search'
                  render={(props) => <SearchPage {...props} />}
                />

                <Route
                  path='/playlist/public/:id'
                  render={(props) => <PublicPlaylist {...props} />}
                />

                <Route
                  path='/company/:id'
                  render={(props) => <CompanyPage {...props} />}
                />

                <Route>
                  <ErrorPage />
                </Route>
              </Switch>
            </SocketProvider>
          </Router>
        </ThemeProvider>
      </LocaleProvider>
    );
  }
}

function mapState(state) {
  const articles = articlesApi.endpoints.getArticlesList.select({})(state);
  return {
    articles,
  };
}

const mapDispatch = {
  getArticles: articlesApi.endpoints.getArticlesList.initiate,
};

const connector = connect(mapState, mapDispatch);

export default connector(withTranslation()(App));
