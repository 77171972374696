import React, { Component } from "react";
import styled from "styled-components";
import Lottie from "lottie-react";
import IntroEN from "../../assets/animations/intro-en.json";
import IntroFR from "../../assets/animations/intro-fr.json";
import disableScroll from "disable-scroll";
import { push } from "react-router-redirect";
import {
  iosRequestReview,
  iosSetBackgroundColor,
  isNativeIOS,
} from "../../tools/ios";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { androidRequestReview, isNativeAndroid } from "../../tools/android";
import i18n from "../../config/i18n";
import FadeIn from "react-fade-in/lib/FadeIn";
import Loader from "../../components/Loader";
import { loginWithCustomToken } from "../../services/firebase";

const punchlines = {
  en: "Juisci puts science in motion",
  fr: "Juisci fait bouger la science",
};

class SplashPage extends Component {
  lottieRef = React.createRef();
  redirectionTimeout = null;

  state = {
    currentPunchline: 0,
    opacity: 1,
  };

  componentDidMount() {
    disableScroll.on();

    if (isNativeIOS) iosSetBackgroundColor("#FFFFFF");

    this.requestReview();
    this.clearStorage();

    this.checkFromV2();
  }

  componentWillUnmount() {
    localStorage.setItem("i18nextLng", i18n.resolvedLanguage ?? "en");
  }

  requestReview = () => {
    const countReqReview = !!localStorage.getItem("countReqReview")
      ? parseInt(localStorage.getItem("countReqReview"))
      : 0;

    if (isNativeIOS | isNativeAndroid && countReqReview >= 0) {
      if ((countReqReview && !(countReqReview % 7)) || countReqReview === 1) {
        if (isNativeIOS) iosRequestReview();
        if (isNativeAndroid) androidRequestReview();
      }

      localStorage.setItem("countReqReview", countReqReview + 1);
    } else localStorage.setItem("countReqReview", 0);
  };

  clearStorage = () => {
    localStorage.removeItem("isShared");
    localStorage.removeItem("userShared");
    localStorage.setItem("msgTabIndex", 0);
    localStorage.setItem("profileTab", 0);
    localStorage.setItem("wasUsersMode", false);

    if (!localStorage.getItem("contentLanguage"))
      localStorage.setItem("contentLanguage", "en");
  };

  checkFromV2 = async () => {
    const query = new URLSearchParams(window.location.search);
    if (JSON.parse(query?.get("fromV2"))) {
      await loginWithCustomToken(localStorage.getItem("customToken"));
      localStorage.removeItem("customToken");
      window.location.href = "/home";
    }
  };

  render() {
    const query = new URLSearchParams(window.location.search);
    if (JSON.parse(query?.get("fromV2"))) return <Loader />;

    return (
      <SplashContainer>
        <div className='animation-container'>
          <Lottie
            lottieRef={this.lottieRef}
            animationData={i18n?.resolvedLanguage === "fr" ? IntroFR : IntroEN}
            className='animation-lottie'
            loop={false}
            autoplay={true}
            onComplete={() => {
              this.redirectionTimeout = setTimeout(() => push("/home"), 2000);
            }}
          />
          <div className='punchline'>
            <FadeIn delay={1000} transitionDuration={1000}>
              {punchlines[i18n.resolvedLanguage ?? "en"]}
            </FadeIn>
          </div>
        </div>
      </SplashContainer>
    );
  }
}

function mapState(state) {
  const articles = state.articles;
  return {
    articles,
  };
}

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
export default connector(withTranslation()(SplashPage));

const SplashContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  .animation-container {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -45%);
    z-index: 1;
    width: 100%;
  }

  .animation-lottie {
    width: 300px;
    margin: auto;
  }

  .punchline {
    font-family: "Inter";
    font-weight: 400;
    font-size: 21px;
    position: relative;
    text-align: center;
    z-index: 2;
    margin-top: -36px;
    padding: 0 24px;
    white-space: pre-wrap;
  }
`;
