import { Component } from "react"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import { renderPublicationDate } from "../tools/utils"
import customToast from "./CustomToast"
import { Spacer, SpecialtyTag } from "./global"
import ShareBtn from "../assets/icons/share-btn.svg"
import disableScroll from "disable-scroll"
import { BottomSheet } from "react-spring-bottom-sheet"
import i18n from "../config/i18n"
import { androidShare, isNativeAndroid } from "../tools/android"
import {
	convertContentToItemData,
	gtmItemData,
	gtmItemsData
} from "../tools/reactgaEvents"
import { t } from "i18next"
import CustomIcon from "./CustomIcon"
import { Col, Row } from "react-grid-system"
import { store } from "../redux"
import { iosPlaySound } from "../tools/ios"
import {
	ItemDataEventListName,
	ItemDataVariant,
	ItemsDataEvent,
	SoundEffect
} from "../interfaces"
import { incrementShareInfographic } from "../services/content.services"

class InfographicCard extends Component {
	state = {
		showSheet: false
	}

	async handleShare() {
		const { title, slug } = this.props.infographic

		const user = store.getState().user.user

		const contentURL = `/infographic/${slug}&shared=${user.uid}&org=${
			user.user?.organisations?.[0]?.uid || "null"
		}`

		const shareText =
			i18n.resolvedLanguage === "fr"
				? `I’ve just viewed this infographics you may be interested in: ${title}`
				: `Je viens de regarder cette infographie qui pourrait t'intéresser : ${title}`

		if (isNativeAndroid) {
			androidShare(contentURL, shareText)
		} else if (navigator.share) {
			navigator.share({
				title: document.title,
				text: shareText,
				url: contentURL
			})

			customToast(t("toast.success.sharedInfographic"))
			iosPlaySound(SoundEffect.SHARE)
			gtmItemsData(
				ItemsDataEvent.INFOGRAPHIC_SHARE,
				convertContentToItemData(
					[this.props.infographic],
					ItemDataEventListName.HOME,
					ItemDataVariant.INFOGRAPHIC
				)
			)

			await incrementShareInfographic(slug)
		} else {
			customToast(t("toast.error.browser"))
		}
	}

	render() {
		const contentLanguage = localStorage.getItem("contentLanguage")
		const thumbnail =
			this.props.infographic?.thumbnail?.url ||
			this.props.infographic?.image?.url

		return (
			<div>
				<StyledCard
					className={this.props.isPinned ? "pinned" : ""}
					onClick={(e) =>
						e.target?.className?.includes("share-btn") ||
						e.target?.className?.includes("prevent-bg-click")
							? e.preventDefault()
							: this.props.history.push({
									pathname: `/infographic/${this.props.infographic.slug}`,
									state: {
										infographic: this.props.infographic
									}
							  })
					}
				>
					<div className="infographic-card-content">
						{this.props.isPinned && (
							<div className="pinned-badge">
								<CustomIcon iconName="post-pin" />
								<div>{t("common.pinned")}</div>
							</div>
						)}
						<div className="flex-header">
							<div>
								<div className="publication-date">
									{renderPublicationDate(
										this.props.infographic.publication_date
									)}
								</div>
								{this.props.infographic.medical_specialties && (
									<SpecialtyTag>
										{
											this.props.infographic
												.medical_specialties[0]
												?.translations[contentLanguage]
										}
									</SpecialtyTag>
								)}
							</div>
							<img
								src={ShareBtn}
								alt=""
								className="share-btn"
								onClick={(e) => {
									e.preventDefault()
									disableScroll.on()
									this.setState({ showSheet: true })
								}}
							/>
						</div>
						<div className="content-format-text">
							{contentLanguage === "fr"
								? "Infographie"
								: "Infographic"}
						</div>
						<p className="title">{this.props.infographic.title}</p>
					</div>

					<CardImage image={thumbnail} />
					{this.state.showSheet && (
						<div className="prevent-bg-click" />
					)}
				</StyledCard>
				<BottomSheet
					expandOnContentDrag
					maxHeight={window.innerHeight * 0.3}
					open={this.state.showSheet}
					className="remove-scrollbar"
					onDismiss={() => {
						disableScroll.off()
						setTimeout(() => this.setState({ showSheet: false }))
					}}
					scrollLocking
					blocking
				>
					<div
						style={{ float: "right", marginRight: "20px" }}
						onClick={() => {
							disableScroll.off()
							this.setState({ showSheet: false })
						}}
					>
						<CustomIcon iconName="close_alt" />
					</div>
					<div
						style={{
							padding: "55px 15px"
						}}
					>
						<Row
							justify="around"
							align="center"
							gutterWidth={20}
							onClick={this.handleShare.bind(this)}
						>
							<Col xs={1}>
								<Spacer height="5px" />

								<CustomIcon
									iconName="share-alt"
									scale={0.8}
									color="#212121"
									style={{
										marginLeft: 5
									}}
								/>
							</Col>
							<Col xs={10}>
								<p
									style={{
										fontSize: "17px",
										fontWeight: 600,
										margin: 0
									}}
								>
									{t("common.share")}
								</p>
								<p
									style={{
										fontSize: "15px",
										fontWeight: 400,
										margin: 0
									}}
								>
									{t(
										"playlist.sheet.share.infographics.subline"
									)}
								</p>
							</Col>
						</Row>
						<Spacer height="30px" />
					</div>
				</BottomSheet>
			</div>
		)
	}
}

export default withRouter(InfographicCard)

const StyledCard = styled.div`
	position: relative;
	background: #ffffff;
	border-radius: 15px;
	overflow: hidden;

	&.pinned {
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 10px;
			background-color: #ff8800;
			border-radius: 15px 15px 0 0;
		}
		.infographic-card-content {
			padding-top: 26px;
		}
	}
	.prevent-bg-click {
		width: 100vw;
		min-height: 100vh;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		background: transparent;
	}

	.pinned-badge {
		display: flex;
		align-items: center;
		gap: 4px;

		margin-bottom: 8px;

		font-family: Inter;
		font-size: 12px;
		font-weight: 700;
		line-height: 12px;
		letter-spacing: 0em;
		text-align: left;
		color: #ff8800;
	}

	.infographic-card-content {
		width: 100%;
		box-sizing: border-box;
		padding: 28px 20px 21px;
	}

	.flex-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.content-format-text {
		font-family: Poppins;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		letter-spacing: 0.72px;
		text-transform: uppercase;
		color: #f1353a;
		margin-top: 24px;
	}

	.title {
		font-weight: 700;
		font-size: 16px;
		text-align: left;
		margin: 8px 0 0;
		color: #212121;
	}

	.publication_date {
		font-size: 14px;
		margin-bottom: 0;
		text-transform: capitalize;
	}
`

const CardImage = styled.div`
	border-radius: 0 0 15px 15px;
	background-image: linear-gradient(
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0.1) 50%,
			rgba(255, 255, 255, 0) 80%
		),
		url(${(props) => props.image});

	background-size: 100%;
	background-repeat: no-repeat;
	background-position: bottom;
	height: 200px;
`
