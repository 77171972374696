import axios from "axios";
import { API_URL } from "../config";
import { catchError } from "./response";
import { getApiRequestService } from "./index.services";

export const getProfessionsList = async () => {
  try {
    const language = localStorage.getItem("i18nextLng");
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/profession?language=${language}`);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const getProfessionSpecialization = async (profession: string) => {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(
      `/profession/${profession}?language=${localStorage.getItem("i18nextLng")}`
    );

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};

export const getSpecialtiesFromProfession = async (uid: string) => {
  try {
    const language = localStorage.getItem("i18nextLng");
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/profession/${uid}`);

    return res.data;
  } catch (error) {
    return catchError(error);
  }
};
