import { Component } from "react"
import { t } from "i18next"
import { BottomSheet } from "react-spring-bottom-sheet"
import styled from "styled-components"
import CustomIcon from "./CustomIcon"
import JuisciLogo from "../assets/core/juisci-logo.svg"
import { CustomButton } from "./global"
import disableScroll from "disable-scroll"
import i18n from "../config/i18n"
import { redirectToStore } from "../tools/utils"
import { getUserById } from "../services/user.services"
import ProfilePicture from "./ProfilePicture"
import { store } from "../redux"
import { withRouter } from "react-router-dom"
import customToast from "./CustomToast"
import { getFirebaseToken } from "../services/firebase"
import { userApi } from "../redux/user/user.service"

class ShareSheet extends Component {
	state = {
		showActionSheet: false,
		user: null
	}

	async componentDidMount() {
		await this.getOrganisationShare()
		await this.getUserShare()
	}

	async componentDidUpdate() {
		if (!!this.state.user) {
			disableScroll.on()
		} else {
			disableScroll.off()
		}
	}

	async getUserShare() {
		const query = new URLSearchParams(window.location.search)
		const userSharing = query.get("shared")

		// Si un contenu a été partagé par un utilisateur
		if (userSharing && this.state.user !== undefined) {
			const user = await getUserById(userSharing)
			if (user) this.setState({ user, showActionSheet: true })
			else this.setState({ user: undefined, showActionSheet: true })
		}
	}

	async getOrganisationShare() {
		const query = new URLSearchParams(window.location.search)
		const organisationUid = query.get("org")
		const isAuthentified = await getFirebaseToken()

		if (isAuthentified)
			await store.dispatch(userApi.endpoints.getUser.initiate())

		const curentUserOrganisation =
			store.getState().user.user.organisations?.[0]?.uid

		const isSignUpPage = window.location.pathname.includes("/signup")

		if (
			!organisationUid ||
			curentUserOrganisation === organisationUid ||
			isSignUpPage
		)
			return

		if (!isAuthentified) {
			localStorage.setItem("authRedirectPath", window.location.pathname)
			this.props.history.replace(`/signup?org=${organisationUid}`)
			customToast(t("toast.error.notSignUp"))
		} else {
			customToast(t("toast.error.notAllowed"))
			this.props.history.replace("/")
		}
	}

	renderContent(type = "content") {
		switch (type) {
			case "download":
				return (
					<div>
						<h3>{t("sheet.sharing.download.title")}</h3>
						<h5>{t("sheet.sharing.download.subtitle")}</h5>
						<img src={JuisciLogo} alt="" className="juisci-logo" />
						<CustomButton
							className="xsmall custom-button"
							onClick={() => redirectToStore()}
						>
							{t("sheet.sharing.download.button")}
						</CustomButton>
					</div>
				)

			default:
				return (
					<div>
						<h3>
							{this.state.user?.firstname
								? `${this.state.user.firstname} ${this.state.user.lastname[0]}.`
								: t("common.peer")}
						</h3>
						<h5>
							{type === "profile"
								? t("sheet.sharing.profile")
								: type === "playlist"
								? t("sheet.sharing.playlist")
								: t("sheet.sharing.content")}
						</h5>
						<div className="user-informations">
							{!this.state.user?.firstname ? (
								<CustomIcon
									scale={1.5}
									iconName="user-circle"
									color="#000000"
								/>
							) : (
								<ProfilePicture user={this.state.user} />
							)}
							{this.state.user?.firstname && (
								<p>
									<b>
										{
											this.state.user.profession
												?.translations[
												i18n.resolvedLanguage
											]
										}
									</b>
									<br />
									{
										this.state.user.main_specialty
											?.translations[
											i18n.resolvedLanguage ?? "en"
										]
									}
								</p>
							)}
						</div>
					</div>
				)
		}
	}

	handleClose = () => {
		this.setState({
			showActionSheet: false
		})
	}

	render() {
		return (
			<div>
				<BottomSheet
					maxHeight={window.innerHeight * 0.8}
					open={this.state.showActionSheet}
					onDismiss={() => this.handleClose()}
				>
					<StyledSheetContent>
						<div className="close-area">
							<CustomIcon
								iconName="close_alt"
								color="#90A4AE"
								onClick={() => this.handleClose()}
							/>
						</div>
						{this.renderContent(this.props.type)}
					</StyledSheetContent>
				</BottomSheet>
			</div>
		)
	}
}

export default withRouter(ShareSheet)

const StyledSheetContent = styled.div`
	background: #ffffff;
	padding: 4px 48px 64px;
	box-sizing: border-box;
	overflow: hidden;

	&::-webkit-scrollbar {
		display: none;
	}

	.close-area {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 24px;
	}

	h3 {
		font-family: Inter;
		font-size: 24px;
		font-weight: 700;
		line-height: 29px;
		letter-spacing: -0.025em;
		text-align: center;
		margin: 4px 0;
	}

	h5 {
		font-family: Inter;
		font-size: 16px;
		font-weight: 400;
		line-height: 26px;
		letter-spacing: -0.0001em;
		text-align: center;
		margin: 0;
	}

	.juisci-logo {
		width: 100%;
		margin: 32px 0 21px;
		height: 64px;
		object-fit: contain;
	}

	.custom-button {
		margin: auto;
		display: block;
	}

	.user-informations {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 12px;

		margin-top: 32px;

		p {
			font-family: Roboto;
			font-size: 14px;
			font-weight: 400;
			line-height: 16px;
			letter-spacing: 0em;
			text-align: center;
			margin: 0;
		}
	}
`
